import React, { useContext, useEffect, useState } from "react";
import "./BuilderComponent.scss";
import Layout from "../../components/layout/LayoutComponent";
import { useHistory, withRouter } from "react-router-dom";
import QuestionSettinsComponent from "../../components/questionSettings/QuestionSettingsComponent";
import AppService from "../../service/AppService";
import BranchingLogicModal from "../../components/branchingLogicPopup/BranchingLogicPopupComponent";
import FeedbackSettings from "../../components/feedbackComponent/FeedbackComponent";
import Languages from "./Languages/Languages";
import i18n from "i18next";
import BuilderHeader from "../../components/BuilderHeader/BuilderHeader";
import { BuilderSurveyService } from "../../service/BuilderSurveyService/BuilderSurveyService";
import BuilderPopups from "./BuilderPopups/BuilderPopupsLayout";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";
import SurveyInformationComponent from "../../components/SurveyInformationComponent/SurveyInformationComponent";
import SurveyQuestions from "../../components/SurveyQuestions/SurveyQuestions";
import cloneDeep from "lodash/cloneDeep";
import { showErrorNotification } from "../../helper/NotificationHelper";

function BuilderComponent() {
    const [showValidateLang, setShowValidateLang] = useState(false);
    const [languageTab, setLanguageTab] = useState(false);
    const [showPublishPopup, setShowPublishPopup] = useState(false);
    const [showActivationPopup, setShowActivationPopup] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [showBranchingLogicPopup, setShowBranchingLogicPopup] = useState(false);
    const [showConditionsBranchingLogicPopup, setShowConditionsBranchingLogicPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showSavePopup, setShowSavePopup] = useState(false);

    const history = useHistory();
    const context = useContext(SurveyBuilderContext);
    let questionsInfo = [];
    let answersInfo = [];

    if (!AppService.selectedApp) {
        window.location.href = "/";
    }

    useEffect(() => {
        context.setSurveyInitialValue(JSON.stringify(AppService.selectedApp));
        i18n.changeLanguage("en");
        return () => {
            AppService.selectedApp = null;
        };
    }, []);

    const showPublish = () => {
        if (AppService.selectedApp.status === "CONSTRUCT") {
            setShowPublishPopup(true);
        } else {
            setLanguageTab(false);
        }
    };

    const closeConditionsBranchingLogicPopup = (visibilities) => {
        const question = BuilderSurveyService.closeConditionsBranchingLogicPopup(
            visibilities,
            context.selectedQuestion,
            context.selectedSectionIndex,
            context.selectedQuestionIndex,
            context.selectedGroupQuestionIndex,
            context.selectedCondition
        );
        context.setSelectedQuestion(question);
        setShowConditionsBranchingLogicPopup(false);
    };

    const showBranchingLogic = (answerId) => {
        context.setSelectedAnswerId(answerId);
        context.setSelectedQuestion(cloneDeep(context.selectedQuestion));
        setShowBranchingLogicPopup(true);
    };

    const showConditionsBranchingLogic = (question, condition) => {
        setShowConditionsBranchingLogicPopup(true);
        context.setSelectedCondition(condition);
    };

    const closeBranchingLogicPopup = () => {
        getQuestionsInfo();
        context.setSelectedQuestion(cloneDeep(context.selectedQuestion));
        setShowBranchingLogicPopup(false);
    };

    const onAppEdit = () => {
        context.changeSubSurvey(0);
        setShowEditPopup(true);
    };

    const removeVisibility = (question) => {
        const { selectedSectionIndex, setSelectedQuestionIndex, setSelectedQuestion } = context;

        const selectedSection = AppService.selectedApp.sections[selectedSectionIndex];
        BuilderSurveyService.removeVisibility(question, questionsInfo);
        let selectedGroupIndex;
        let selectedQuestionIndex;
        let selectedQuestion;

        if (question?.groupId) {
            selectedGroupIndex = selectedSection.questions.findIndex((q) => q.sequenceId === question.groupId);
            selectedQuestionIndex = selectedSection.questions[selectedGroupIndex].questions.findIndex(
                (q) => q.sequenceId === question.sequenceId
            );
            selectedQuestion =
                AppService.selectedApp.sections[selectedSectionIndex].questions[selectedGroupIndex].questions[selectedQuestionIndex];

            if (selectedQuestion) {
                selectedQuestion.visibility = [];
                AppService.selectedApp.sections[selectedSectionIndex].questions[selectedGroupIndex].questions[selectedQuestionIndex] =
                    selectedQuestion;
            }
        } else {
            selectedQuestionIndex = selectedSection.questions.findIndex((q) => q.sequenceId === question?.sequenceId);
            selectedQuestion = AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex];

            if (selectedQuestion) {
                selectedQuestion.visibility = [];
                AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = selectedQuestion;
            }
        }
        getQuestionsInfo();
        setSelectedQuestionIndex(selectedQuestionIndex);
        setSelectedQuestion(cloneDeep(selectedQuestion));
    };

    const getQuestionsInfo = () => {
        let result = [];
        questionsInfo = [];
        if (AppService.selectedApp && AppService.selectedApp.sections?.length === 0) {
            questionsInfo = result;
        }
        AppService.selectedApp.sections.forEach((section) => {
            result = result.concat(BuilderSurveyService.getQuestions(section.questions, []));
        });
        questionsInfo = result;
        getAnswersInfo();
    };

    const getAnswersInfo = () => {
        answersInfo = [];
        questionsInfo.forEach((q) => {
            answersInfo = answersInfo.concat(q.answers);
        });
    };

    const onQuestionRemove = (id) => {
        const { selectedSectionIndex, setSelectedQuestion, setSelectedQuestionIndex, saveSurvey, selectQuestion } = context;

        const section = AppService.selectedApp.sections[selectedSectionIndex];
        const currenQuestionIndex = AppService.selectedApp.sections[selectedSectionIndex].questions.findIndex((q) => q.sequenceId === id);
        removeVisibility(AppService.selectedApp.sections[selectedSectionIndex].questions[currenQuestionIndex]);

        if (isDemoEmailQuestion(currenQuestionIndex)) {
            showErrorNotification("Can't be removed", "The email question can't be removed from DEMO of GC Survey");
            return;
        }
        AppService.selectedApp.sections[selectedSectionIndex].questions = AppService.selectedApp.sections[
            selectedSectionIndex
        ].questions.filter((q) => q.sequenceId !== id);
        const selectedQuestionIndex = currenQuestionIndex <= 0 ? 0 : currenQuestionIndex - 1;
        if (section.questions.length > 0) {
            selectQuestion(section.questions[selectedQuestionIndex].sequenceId);
        } else {
            setSelectedQuestion(null);
            setSelectedQuestionIndex(-1);
            saveSurvey(null);
        }
    };
    const removeQuestionFromGroup = (groupId, id) => {
        const { selectedSectionIndex, setSelectedQuestion, setSelectedQuestionIndex, setSelectedGroupQuestionIndex, selectQuestion } =
            context;

        const section = AppService.selectedApp.sections[selectedSectionIndex];
        const groupIndex = section.questions.findIndex((q) => q.sequenceId === groupId);
        const currenQuestionIndex = section.questions[groupIndex].questions.findIndex((q) => q.sequenceId === id);
        const selectedQuestionIndex = currenQuestionIndex <= 0 ? 0 : currenQuestionIndex - 1;

        removeVisibility(AppService.selectedApp.sections[selectedSectionIndex].questions[groupIndex].questions[currenQuestionIndex]);

        AppService.selectedApp.sections[selectedSectionIndex].questions[groupIndex].questions = AppService.selectedApp.sections[
            selectedSectionIndex
        ].questions[groupIndex].questions.filter((q) => q.sequenceId !== id);

        if (AppService.selectedApp.sections[selectedSectionIndex].questions[groupIndex].questions.length > 0) {
            setSelectedQuestion(
                AppService.selectedApp.sections[selectedSectionIndex].questions[groupIndex].questions[selectedQuestionIndex]
            );
            setSelectedQuestionIndex(currenQuestionIndex);
            setSelectedGroupQuestionIndex(groupIndex);
        } else {
            selectQuestion(section.questions[groupIndex].sequenceId);
        }
    };
    const isDemoEmailQuestion = (currenQuestionIndex) => {
        const { selectedSubSurvey, selectedSectionIndex } = context;
        return (
            selectedSectionIndex === 0 &&
            AppService.selectedApp.subSurveys[selectedSubSurvey].show === "As a demo" &&
            AppService.selectedApp.sections[selectedSectionIndex].questions[currenQuestionIndex]?.salesForceField?.fieldLabel ===
                AppService.salesForceFields.EMAIL.text
        );
    };

    const onSaveSurvey = async () => {
        const body = BuilderSurveyService.prepareSurveyBody(context.selectedSubSurvey);
        setShowProgress(true);

        await AppService.getAxios()
            .put("/api/survey", body)
            .then((response) => {
                context.setStateAfterSave(response);
                setShowProgress(false);
            })
            .catch((e) => console.log(e));
    };

    const hasAnythingChanged = () => {
        const { selectedAppCopy, normalizedInitialValue } = BuilderSurveyService.getSurveyChangedValues(
            context.selectedSubSurvey,
            context.surveyInitialValue
        );
        if (
            JSON.stringify(selectedAppCopy) === JSON.stringify(normalizedInitialValue) ||
            AppService.selectedApp.status === "PUBLISH" ||
            AppService.selectedApp.active
        ) {
            history.goBack();
        } else {
            setShowSavePopup(true);
        }
    };

    getQuestionsInfo();

    if (AppService.selectedApp.reset) {
        context.setSelectedSectionIndex(0);
        context.setSelectedSubSurvey(0);
        AppService.selectedApp.reset = false;
    }

    return (
        <Layout>
            <BuilderPopups
                showProgress={showProgress}
                setShowProgress={setShowProgress}
                showPublishPopup={showPublishPopup}
                setShowActivationPopup={setShowActivationPopup}
                showActivationPopup={showActivationPopup}
                showSavePopup={showSavePopup}
                showEditPopup={showEditPopup}
                setShowSavePopup={setShowSavePopup}
                setShowPublishPopup={setShowPublishPopup}
                setShowValidateLang={setShowValidateLang}
                setLanguageTab={setLanguageTab}
                setShowEditPopup={setShowEditPopup}
            />
            {showConditionsBranchingLogicPopup && (
                <BranchingLogicModal
                    section={AppService.selectedApp.sections[0].sequenceId}
                    isConditions={true}
                    conditionVisibilities={context.selectedCondition?.visibilities}
                    onClose={closeConditionsBranchingLogicPopup}
                />
            )}
            {showBranchingLogicPopup && (
                <BranchingLogicModal
                    section={AppService.selectedApp.sections[context.selectedSectionIndex].sequenceId}
                    onClose={closeBranchingLogicPopup}
                />
            )}
            <BuilderHeader
                languageTab={languageTab}
                setLanguageTab={setLanguageTab}
                onSaveSurvey={onSaveSurvey}
                hasAnythingChanged={hasAnythingChanged}
                showPublishPopup={showPublish}
                setShowActivationPopup={setShowActivationPopup}
            />
            <div className="builder-header-separator" />
            {languageTab ? (
                <Languages app={AppService.selectedApp} showValidateLang={showValidateLang} setShowValidateLang={setShowValidateLang} />
            ) : (
                <div className="builder-container">
                    <SurveyInformationComponent onAppEdit={onAppEdit} />
                    <SurveyQuestions
                        onQuestionRemove={onQuestionRemove}
                        removeQuestionFromGroup={removeQuestionFromGroup}
                        showBranchingLogic={showBranchingLogic}
                        showConditionsBranchingLogic={showConditionsBranchingLogic}
                        removeVisibility={removeVisibility}
                    />
                    <aside className="right">
                        {context.selectedQuestion && !context.showFeedbackSettings && (
                            <QuestionSettinsComponent onVisibilityClick={showBranchingLogic} />
                        )}
                        {context.showFeedbackSettings && <FeedbackSettings />}
                    </aside>
                </div>
            )}
        </Layout>
    );
}

export default withRouter(BuilderComponent);
