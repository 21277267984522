import React, { useContext, useState } from "react";
import SavePopup from "../../../components/popups/SavePopup/SavePopup";
import ActivationPopup from "../../../components/popups/ActivationPopup/ActivationPopup";
import PublishPopup from "../../../components/popups/PublishPopup/PublishPopup";
import AppService from "../../../service/AppService";
import CreateAppPopup from "../../../components/createAppPopup/CreateAppPopupComponent";
import { BuilderSurveyService } from "../../../service/BuilderSurveyService/BuilderSurveyService";
import { LanguageService } from "../../../service/LanguageService";
import { SurveyBuilderContext } from "../../../SurveyBuilderContext";
import { showErrorNotification, showSuccessNotification } from "../../../helper/NotificationHelper";
import ProgressWrapper from "../../../components/createAppPopup/ProgressWrapper";

function BuilderPopups({
    showPublishPopup,
    showProgress,
    setShowProgress,
    showActivationPopup,
    setShowActivationPopup,
    setShowPublishPopup,
    setShowValidateLang,
    showSavePopup,
    showEditPopup,
    setShowEditPopup,
    setShowSavePopup,
    setLanguageTab
}) {
    const { setSurveyInitialValue, saveSurvey } = useContext(SurveyBuilderContext);

    const [showPublishLoader, setShowPublishLoader] = useState(false);
    const publishSurvey = () => {
        if (!isSurveyCanBePublished()) {
            setShowValidateLang(true);
            setShowPublishPopup(false);
            return;
        }

        setShowPublishLoader(true);
        saveSurvey().then((resp) => {
            const mailingQuestion = BuilderSurveyService.isCurrentSurveyHasMailingAddressQuestion();
            if (mailingQuestion.length > 1) {
                setShowPublishPopup(false);
                showErrorNotification(
                    "Can't publish survey",
                    "Survey should not content two different questions with Mailing Address Salesforce link"
                );
                setShowPublishLoader(false);
                return;
            }
            if (mailingQuestion[0] && !BuilderSurveyService.isZipCodesAreValid(mailingQuestion[0])) {
                setShowPublishPopup(false);
                showErrorNotification(
                    "Can't publish survey",
                    "Survey that contains Mailing Address question, should have a valid zip codes file"
                );
                setShowPublishLoader(false);
                return;
            }

            const firstQuestionWithoutAnswer = BuilderSurveyService.getQuestionWithoutAnswerInput(AppService.selectedApp.subSurveys);

            if (firstQuestionWithoutAnswer) {
                setShowPublishPopup(false);
                showErrorNotification(
                    "Can't publish survey",
                    `You cannot publish a survey as the question '${firstQuestionWithoutAnswer.text}' with type [${firstQuestionWithoutAnswer.type}] doesn't have an answer. Please provide an answer to proceed.`
                );
                setShowPublishLoader(false);
                return;
            }

            const checkZipScoreFile = !!mailingQuestion[0];
            if (AppService.selectedApp.status === "CONSTRUCT") {
                if (isSurveyCanBePublished()) {
                    setShowProgress(true);
                    AppService.getAxios()
                        .post(`/api/survey/publish?id=${AppService.selectedApp.id}&validateZipCodes=${checkZipScoreFile}`)
                        .then(() => {
                            showSuccessNotification(
                                "Successfully published",
                                `The survey "${AppService.selectedApp.title}" with version "${AppService.selectedApp.version}" has been published`
                            );
                            AppService.selectedApp.status = "PUBLISH";
                            setShowPublishPopup(false);
                            setShowProgress(false);
                            setShowValidateLang(false);
                            setSurveyInitialValue(JSON.stringify(AppService.selectedApp));
                            setShowPublishLoader(false);
                        });
                } else {
                    setShowPublishPopup(false);
                    setSurveyInitialValue(JSON.stringify(AppService.selectedApp));
                    setLanguageTab(true);
                    setShowPublishLoader(false);
                }
            }
        });
    };

    const isSurveyCanBePublished = () => {
        const { allFieldsCount, filledFieldsCount } = LanguageService.getValidatedFields();
        return allFieldsCount === filledFieldsCount || !AppService.selectedApp.internationalizationSupport;
    };

    const activeSurvey = () => {
        if (AppService.selectedApp.status === "ARCHIVED") {
            showErrorNotification("Can't activate the survey", "Sorry, but you can't activate an archived survey");
            setShowProgress(false);
            setShowActivationPopup(false);
            return;
        }
        setShowProgress(true);
        AppService.getAxios()
            .post(`/api/survey/activate`, { id: AppService.selectedApp.id })
            .then(() => {
                showSuccessNotification(
                    "Successfully activated",
                    `The survey "${AppService.selectedApp.title}" with version "${AppService.selectedApp.version}" has been activated for "${AppService.selectedApp.type}" users`
                );
                AppService.selectedApp.active = true;
                setShowProgress(false);
                setShowActivationPopup(false);
            });
    };

    return (
        <div>
            {showProgress && <div className="animated-gradient" />}
            {showSavePopup && <SavePopup setShowSavePopup={setShowSavePopup} />}
            {showActivationPopup && <ActivationPopup action={activeSurvey} setShowActivationPopup={setShowActivationPopup} />}
            {showPublishPopup && (
                <PublishPopup action={publishSurvey} setShowPublishPopup={setShowPublishPopup} showPublishLoader={showPublishLoader} />
            )}
            {showEditPopup && (
                <ProgressWrapper>
                    <CreateAppPopup lang="EU" onClose={setShowEditPopup} app={AppService.selectedApp} />
                </ProgressWrapper>
            )}
        </div>
    );
}

export default BuilderPopups;
