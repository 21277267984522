import React, { useContext } from "react";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";

function AddSurveyItemButtons() {
    const { addQuestion, addNote, addGroup, addDynamicGroup } = useContext(SurveyBuilderContext);
    return (
        <div className="add-new-question">
            <div>
                <span onClick={addQuestion}>Question</span>
                <i className="border" />
                <span onClick={addNote}>Note</span>
                <i className="border" />
                <span onClick={addGroup}>Group</span>
                <i className="border" />
                <span onClick={addDynamicGroup}>Dynamic Group</span>
            </div>
        </div>
    );
}

export default AddSurveyItemButtons;
