import React from "react";
import AvatarEditor from "react-avatar-editor";

function ImageCrop({ imageSrc, onCancel, setImageEditor, onSave }) {
    return (
        <>
            <div className="single-preview">
                <AvatarEditor
                    ref={(editor) => setImageEditor(editor)}
                    image={imageSrc}
                    width={333}
                    height={333}
                    className="avatar-editor"
                />
            </div>
            <div className="single-buttons">
                <button className="btn-cancel" onClick={onCancel}>
                    Cancel
                </button>
                <button className="btn-save" onClick={onSave}>
                    Save
                </button>
            </div>
        </>
    );
}

export default ImageCrop;
