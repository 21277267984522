import React from "react";
import { Multiselect } from "multiselect-react-dropdown";
import "./MultiSelectComponent.scss";
import { LanguageService } from "../../../service/LanguageService";
import SettingsButtonComponent from "../../settingsButton/SettingsButtonComponent";

function MultiSelectComponent({ lang, required, label, answers, placeholder, onVisibilityClick }) {
    const labelText = required ? (
        <label className="drop-label">
            {label}
            <span className="required">*</span>{" "}
        </label>
    ) : (
        <label className="drop-label">{label}</label>
    );

    const translatedAnswers = structuredClone(answers).map((item) => ({
        ...item,
        text: LanguageService.getTranslatedText(item.text, lang)
    }));

    const renderOptionWithSettings = (optionText, option) => (
        <div className="custom-option">
            <span dangerouslySetInnerHTML={{ __html: optionText }}></span>
            <SettingsButtonComponent answer={option} hasVisibility={option.hasVisibility} onVisibilityClick={onVisibilityClick} />
        </div>
    );

    return (
        <>
            {labelText}
            <Multiselect
                placeholder={placeholder}
                displayValue="text"
                showArrow={true}
                showCheckbox={true}
                options={translatedAnswers}
                optionValueDecorator={renderOptionWithSettings}
                onSelect={(selectedList, selectedItem) => {}}
                onRemove={(selectedList, removedItem) => {}}
            />
        </>
    );
}

export default MultiSelectComponent;
