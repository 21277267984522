import React from "react";
import "./SectionItemComponent.scss";

import close from "../../assets/images/close-icon.svg";
import reorder from "../../assets/images/reorder-icon.svg";
import { LanguageService } from "../../service/LanguageService";

class SectionItemComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            section: props.section
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({ section: props.section });
    }

    handleChange({ target }) {
        this.props.onChange(this.state.section.sequenceId, target.value, this.props.selectedSubSurvey);
        this.setState({ [target.name]: target.value });
    }

    render() {
        return (
            <div className="one-col-section">
                <span className="reorder-section">
                    <img src={reorder} alt="" />
                </span>
                <span className="text-box">
                    <input
                        type="text"
                        placeholder="Name your Section"
                        value={LanguageService.getTranslatedText(this.state.section.name, this.props.lang) || ""}
                        name="sectionName"
                        onChange={this.handleChange}
                    />
                </span>
                <span>
                    <img
                        src={close}
                        alt=""
                        className="remove-section"
                        onClick={() => this.props.onRemove(this.state.section.sequenceId, this.props.selectedSubSurvey)}
                    />
                </span>
            </div>
        );
    }
}

export default SectionItemComponent;
