import { useContext, useEffect, useState } from "react";
import { NewSurveyBuilderContext } from "../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { NewSurveyBuilderService } from "../../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";

const InfoTabComponent = ({ infoRef, surveyId, onSave }) => {
    const { surveyData, setSurveyData } = useContext(NewSurveyBuilderContext);
    const [data, setData] = useState({ title: surveyData?.title, description: surveyData?.description });

    useEffect(() => {
        infoRef.current = { saveInfo };
    }, [data]);

    const onTextValueChange = (event) => {
        event.persist();
        const { target } = event;
        const destination = target.name;
        switch (destination) {
            case "title":
                setData({ ...data, title: target.value });
                break;
            case "description":
                setData({ ...data, description: target.value });
                break;
        }
    };

    const saveInfo = () => {
        if (data.title.trim() === "" || data.description.trim() === "") {
            onSave(true);
            return;
        }
        setSurveyData((prevData) => ({
            ...prevData,
            title: data.title,
            description: data.description
        }));
        NewSurveyBuilderService.saveSurveyInfo(surveyId, data)
            .then(() => {
                onSave(false);
            })
            .catch(() => onSave(true));
    };

    return (
        <div className="info-tab-body">
            <div className="info-tab-type">
                <label>Application type:</label>
                <span>{surveyData?.type}</span>
            </div>
            <label>Title:</label>
            <input
                className={data.title.trim() === "" ? "error-input" : ""}
                type="text"
                value={data.title}
                placeholder="Survey Title"
                onChange={onTextValueChange}
                name="title"
            />
            <label>Description:</label>
            <input
                className={data.description.trim() === "" ? "error-input" : ""}
                type="text"
                value={data.description}
                placeholder="Survey Description"
                onChange={onTextValueChange}
                name="description"
            />
        </div>
    );
};

export default InfoTabComponent;
