import React from "react";

import "./UploadZipCodeComponent.scss";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";
import GlobalLoaderComponent from "../globalLoader/GlobalLoaderComponent";
import AppService from "../../service/AppService";

class UploadZipCodeComponent extends React.Component {
    static contextType = SurveyBuilderContext;
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.state = {
            fileName: this.props.answer.accountableZipCode ? this.props.answer.accountableZipCode.fileName : "",
            isCorrectFile: true,
            showGlobalLoader: false
        };
    }

    componentDidMount() {
        if (this.props.answer.accountableZipCode && this.props.answer.accountableZipCode.zipCodes) {
            for (let item of this.props.answer.accountableZipCode.zipCodes) {
                this.checkZipAndScore(item.zipCode, item.score);
            }
        }
    }

    showFileDialog = () => {
        this.fileInputRef.current.click();
    };

    onZipFileUpload = (e) => {
        this.setState({
            showGlobalLoader: true
        });
        this.handleNewFile(e);
    };

    hideGlobalLoader = () => {
        this.context?.onAnswersChanged && this.context.onAnswersChanged([this.props.answer]);
        this.setState({
            showGlobalLoader: false
        });
    };

    handleNewFile = (e) => {
        const file = e.target.files[0];
        if (!file) {
            this.setState({
                showGlobalLoader: false
            });
            return;
        }
        const reader = new FileReader();
        reader.onload = this.readFileContent;
        reader.readAsText(file);
        this.props.answer.accountableZipCode = {
            ...this.props.answer.accountableZipCode,
            fileName: e.target.files[0].name
        };
        this.setState({ fileName: e.target.files[0].name });
    };

    readFileContent = async (e) => {
        this.setState({ isCorrectFile: true });
        let zipCodesScoreList = new Set();
        e.target.result.split("\r\n").forEach((item) => {
            const splitItem = item.split(";");
            if (splitItem[0]) {
                const zipCodesScore = {
                    zipCode: splitItem[0],
                    score: splitItem[1] ? splitItem[1] : 0
                };
                zipCodesScoreList.add(zipCodesScore);
            }
            this.checkZipAndScore(splitItem[0], splitItem[1]);
        });
        zipCodesScoreList = [...zipCodesScoreList];
        if (this.props.answer.accountableZipCode) {
            this.props.answer.accountableZipCode.zipCodes = zipCodesScoreList;
            this.props.answer.accountableZipCode.valid = this.state.isCorrectFile;
        }
        await this.props.saveZipCodeFile?.(this.props.answer.accountableZipCode);
        this.context?.onAnswersChanged([this.props.answer]);
        await this.context?.saveSurvey(this.hideGlobalLoader);
        this.hideGlobalLoader();
    };

    checkZipAndScore(zipCode, score) {
        if (!(parseInt(zipCode) && parseInt(score))) {
            this.setState({ isCorrectFile: false });
        }
    }

    render() {
        const { fileName, isCorrectFile, showGlobalLoader } = this.state;
        const inputClassname = isCorrectFile ? "uploader-text-input" : "uploader-text-input error";
        const globalLoaderTitle = "Zip codes file uploading and validating";
        const globalLoaderMessage = "Please, wait until zip code file processes finish";
        const app = this.props.selectedApp || AppService.selectedApp;
        return (
            <div>
                {showGlobalLoader && <GlobalLoaderComponent title={globalLoaderTitle} message={globalLoaderMessage} />}

                <div className="uploader-file">
                    <input
                        id="upload"
                        ref={this.fileInputRef}
                        type="file"
                        accept=".csv"
                        onChange={(e) => this.onZipFileUpload(e)}
                        className="uploader-file-input"
                        disabled={app.status === "PUBLISH"}
                    />
                    <input
                        type="text"
                        className={inputClassname}
                        placeholder={"Select a zip code file"}
                        onClick={() => this.showFileDialog()}
                        readOnly={true}
                        value={fileName}
                        onChange={() => {}}
                    />
                    {!isCorrectFile && <div className="uploader-file-error">File is not valid. Please check it and upload again.</div>}
                </div>
            </div>
        );
    }
}

export default UploadZipCodeComponent;
