import AppService from "../../../service/AppService";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useContext } from "react";
import QuestionComponent from "../QuestionComponent";
import { SurveyBuilderContext } from "../../../SurveyBuilderContext";

function Group({ showActions, index, questions, onVisibilityClick, onVisibilityRemoveClicked, lang }) {
    const {
        selectedSectionIndex,
        saveSurvey,
        addQuestionToGroup,
        addNoteToGroup,
        selectQuestionFromGroup,
        removeQuestionFromGroup,
        replaceDraggedItems
    } = useContext(SurveyBuilderContext);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        questions = replaceDraggedItems(questions, result);
        AppService.selectedApp.sections[selectedSectionIndex].questions[index - 1].questions = replaceDraggedItems(
            AppService.selectedApp.sections[selectedSectionIndex].questions[index - 1].questions,
            result
        );
        questions = sortGroupQuestionList();
        saveSurvey();
    };

    const sortGroupQuestionList = () => {
        return AppService.selectedApp.sections[selectedSectionIndex]?.questions[index - 1]?.questions?.map((q, qi, array) => {
            if (q.visibility.length > 0) {
                q.visibility.map((item, i) => {
                    const linkedQuestionId = item.sourceQuestion;
                    const questionIndex = questions.findIndex((el) => el.sequenceId === linkedQuestionId);
                    if (questionIndex > qi) {
                        array.splice(qi, 1);
                        array.splice(questionIndex, 0, q);
                    }
                    return item;
                });
            }
            return q;
        });
    };

    let questionList = questions.map((q, i) => {
        sortGroupQuestionList();
        return (
            <Draggable key={`${i}_question_component_group`} draggableId={String(i)} index={i + 1}>
                {(provided, snapshot) => (
                    <QuestionComponent
                        key={`${i}_question_component_group`}
                        index={i + 1}
                        question={q}
                        isSelected={true}
                        onQuestionRemove={() => {
                            removeQuestionFromGroup(q.groupId, q.sequenceId);
                        }}
                        onClick={() => {
                            selectQuestionFromGroup(q, i);
                        }}
                        onVisibilityRemoveClicked={onVisibilityRemoveClicked}
                        showActions={false}
                        addQuestionToGroup={() => {}}
                        addNoteToGroup={() => {}}
                        visibilityInfo={[]}
                        onVisibilityClick={onVisibilityClick}
                        provided={provided}
                        snapshot={snapshot}
                        lang={lang}
                        isGroup={true}
                    />
                )}
            </Draggable>
        );
    });

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable-1">
                {(provided) => (
                    <div className="content">
                        <div {...provided.droppableProps} ref={provided.innerRef} {...provided.dragHandleProps}>
                            {questionList}
                            {provided.placeholder}
                        </div>
                        {showActions && (
                            <div className="add-new-question">
                                <div>
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            addQuestionToGroup();
                                        }}
                                    >
                                        Question
                                    </span>
                                    <i className="border" />
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            addNoteToGroup();
                                        }}
                                    >
                                        Note
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default Group;
