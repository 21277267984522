import React, { useContext, useEffect } from "react";
import { ReactComponent as Spinner } from "../../../../../assets/images/grid.svg";
import { NewSurveyBuilderService } from "../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";
import "./SurveyInfoComponent.scss";
import { NewSurveyBuilderContext } from "../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import SurveyInfoSection from "./sections/SurveyInfoSection";
import SurveyAcceptanceSection from "./sections/SurveyAcceptanceSection";
import SurveyScoringSection from "./sections/SurveyScoringSection";

const SurveyInfoComponent = ({ surveyId, onEditClick }) => {
    const { surveyData, setSurveyData } = useContext(NewSurveyBuilderContext);

    useEffect(() => {
        NewSurveyBuilderService.loadSurveyInfo(surveyId).then((resp) => setSurveyData(resp.data));
    }, []);

    return (
        <div>
            {surveyData ? (
                <>
                    <SurveyInfoSection onEditClick={onEditClick} />
                    <SurveyAcceptanceSection onEditClick={onEditClick} />
                    {surveyData.type === "GC" && <SurveyScoringSection onEditClick={onEditClick} />}
                </>
            ) : (
                <div className="survey-info-loader">
                    <Spinner />
                </div>
            )}
        </div>
    );
};
export default SurveyInfoComponent;
