import React from "react";
import { IMaskInput } from "react-imask";
import { LanguageService } from "../../../service/LanguageService";

const IMaskPatternSpecialSymbolsForScreening = [
    "[", // start of optional input
    "]", // end of optional input
    "{", // start of fixed sub-value in unmasked value
    "}", // end of fixed sub-value in unmasked value
    "`" // prevent shifting
];

class MaskedTextInput extends React.Component {
    constructor(props) {
        super(props);

        this.screenPatternsSpecialChars = this.screenPatternsSpecialChars.bind(this);

        this.state = {
            value: "",
            key: props.key,
            answerText: props.answerText,
            mask: this.screenPatternsSpecialChars(props.mask)
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            key: props.key,
            answerText: props.answerText,
            mask: this.screenPatternsSpecialChars(props.mask)
        });
    }

    screenPatternsSpecialChars(rawMask) {
        let processedMask = "";

        for (let i = 0; i < rawMask.length; i++) {
            let charToAppend;

            if (IMaskPatternSpecialSymbolsForScreening.indexOf(rawMask[i]) !== -1) {
                charToAppend = "\\" + rawMask[i];
            } else {
                charToAppend = rawMask[i];
            }

            processedMask += charToAppend;
        }
        return processedMask;
    }

    render() {
        return (
            <div>
                <label>{LanguageService.getTranslatedText(this.state.answerText, this.props.lang) ?? ""}</label>
                <IMaskInput
                    mask={this.state.mask}
                    lazy={false}
                    value={this.state.value}
                    type="text"
                    className="masked"
                    placeholderChar="_"
                />
            </div>
        );
    }
}

export default MaskedTextInput;
