import React from "react";
import "./AppHeaderComponent.scss";
import AppLogo from "../../assets/images/conceiveAbilities-logo.svg";
import AppService from "../../service/AppService";
import Auth from "../../Auth";

class AppHeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProfileMenu: false,
            fullName: ""
        };
        this.showProfileMenu = this.showProfileMenu.bind(this);
    }

    showProfileMenu() {
        this.setState({ showProfileMenu: !this.state.showProfileMenu });
    }

    componentWillMount() {
        const profile = Auth.getInstance().getProfile();
        Promise.resolve(profile)
            .then(() => {
                this.setState({
                    fullName: `${Auth.getInstance().profile.firstName} ${Auth.getInstance().profile.lastName}`
                });
            })
            .catch((e) => {
                console.log(e);
                window.location.href = "/login";
            });
    }

    render() {
        return (
            <header className="app-header">
                <div className="desktop">
                    <div className="left-column">
                        <a href="/start">
                            <img className="app-logo" src={AppLogo} alt="ConceiveAbilities" />
                        </a>
                    </div>
                    <div className="center-column">
                        <div className="app-menu">
                            <ul>
                                <li
                                    className={window.location.pathname !== "/settings" ? "active" : ""}
                                    onClick={() => {
                                        window.location.href = "/apps";
                                    }}
                                >
                                    Applications
                                </li>
                            </ul>
                        </div>
                        <div className="app-menu">
                            <ul>
                                <li
                                    className={window.location.pathname === "/settings" ? "active" : ""}
                                    onClick={() => {
                                        window.location.href = "/settings";
                                    }}
                                >
                                    Settings
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="right-column">
                        <div className="user-profile" onClick={this.showProfileMenu}>
                            {this.state.fullName}
                        </div>
                        {this.state.showProfileMenu ? (
                            <nav className="profile-menu">
                                <ul>
                                    <li>
                                        <a href={`/profile`}>Edit my profile</a>
                                    </li>
                                    <li>
                                        <a onClick={() => AppService.logout()}>Logout</a>
                                    </li>
                                </ul>
                            </nav>
                        ) : null}
                    </div>
                </div>
            </header>
        );
    }
}

export default AppHeaderComponent;
