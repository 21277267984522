import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React from "react";

function Phone({ answers, onVisibilityClick }) {
    const inputs = answers
        ? answers.map((answer, index) => {
              return (
                  <div className="phone-input" key={`${index}_phone_input_wrapper`}>
                      <PhoneInput key={`${index}_phone_input`} country={"us"} />
                  </div>
              );
          })
        : null;

    return <div className="content">{inputs}</div>;
}

export default Phone;
