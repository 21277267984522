import { useContext, useEffect, useState } from "react";
import { NewSurveyBuilderContext } from "../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import DropDownComponent from "../../../../../../components/dropDowns/dropDown/DropDownComponent";
import { ReactComponent as Close } from "../../../../../../assets/images/close.svg";
import { ReactComponent as Drag } from "../../../../../../assets/images/draggable_indicator.svg";
import { ReactComponent as Plus } from "../../../../../../assets/images/plus-white.svg";
import { ReactComponent as Loader } from "../../../../../../assets/images/grid.svg";
import classNames from "classnames";
import { uuid } from "uuidv4";
import { NewSurveyBuilderService } from "../../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";
import cloneDeep from "lodash/cloneDeep";
import { showErrorNotification } from "../../../../../../helper/NotificationHelper";

const StructureTabComponent = ({ surveyId, onSave, setSaved, setFail, setShowLoader }) => {
    const { surveyData, setSurveyData, setSelectedSection, selectedSection } = useContext(NewSurveyBuilderContext);
    const [selectedSubSurveyItem, setSelectedSubSurveyItem] = useState(surveyData?.subSurveys[0]);
    const [selectedSectionItem, setSelectedSectionItem] = useState(null);
    const [timer, setTimer] = useState(null);
    const [addDisabled, setAddDisabled] = useState(false);
    const [showSubSurveyRemove, setShowSubSurveyRemove] = useState(false);
    const [showSectionRemove, setShowSectionRemove] = useState(false);
    const [targetForRemove, setTargetForRemove] = useState("");
    const [showDialogLoader, setShowDialogLoader] = useState(false);

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    const getSubSurveyShowItems = (index) => {
        let items = [{ text: "Always" }];

        if (index !== 0) {
            items.push({ text: "If not disqualified" });
        }

        if (surveyData?.type === "GC" && index === 0) {
            items = [{ text: "As a demo" }];
        }

        return items;
    };

    const getSubSurveyClasses = (item) => {
        return classNames("sub-survey-card", {
            "sub-survey-card-selected": item.sequenceId === selectedSubSurveyItem?.sequenceId
        });
    };

    const getSectionClasses = (item) => {
        return classNames("section-card", {
            "section-card-selected": item.sequenceId === selectedSectionItem?.sequenceId
        });
    };

    const setSubSurvey = (sub) => {
        setSelectedSectionItem(null);
        setSelectedSubSurveyItem(sub);
    };

    const addSubSurvey = () => {
        resetLoaders();
        setAddDisabled(true);
        const item = {
            sequenceId: uuid(),
            name: "",
            show: surveyData?.type === "GC" && surveyData?.subSurveys.length === 0 ? "As a demo" : "Always",
            sections: []
        };
        setSurveyData({
            ...surveyData,
            subSurveys: [...surveyData.subSurveys, item]
        });

        NewSurveyBuilderService.saveSubSurvey(surveyId, item.sequenceId, item)
            .then(() => {
                onSave(false);
                setAddDisabled(false);
            })
            .catch(() => onSave(true));
    };

    const addSection = () => {
        resetLoaders();

        setAddDisabled(true);
        const item = {
            sequenceId: uuid(),
            name: "",
            questionsCount: 0
        };
        const updatedSubSurvey = {
            ...selectedSubSurveyItem,
            sections: [...selectedSubSurveyItem.sections, item]
        };
        saveSurveyData(updatedSubSurvey);
        if (surveyData?.subSurveys.flatMap((sub) => sub.sections).length === 1) {
            setSelectedSectionItem(item);
        }
        NewSurveyBuilderService.saveSection(surveyId, selectedSubSurveyItem?.sequenceId, item.sequenceId, item)
            .then(() => {
                onSave(false);
                setAddDisabled(false);
            })
            .catch(() => onSave(true));
    };

    const onChangeHandle = (updatedItem, destination) => {
        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            switch (destination) {
                case "sub":
                    NewSurveyBuilderService.saveSubSurvey(surveyId, updatedItem.sequenceId, {
                        name: updatedItem.name,
                        show: updatedItem.show
                    })
                        .then(() => onSave(false))
                        .catch(() => onSave(true));
                    break;
                case "section":
                    NewSurveyBuilderService.saveSection(surveyId, selectedSubSurveyItem?.sequenceId, updatedItem.sequenceId, {
                        name: updatedItem.name
                    })
                        .then(() => onSave(false))
                        .catch(() => onSave(true));
                    break;
            }
        }, 300);

        setTimer(newTimer);
    };

    const onInputChange = (event, destination, currentIndex) => {
        resetLoaders();

        const { target } = event;
        let updatedItem;
        switch (destination) {
            case "sub":
                if (target.value.trim() !== "") {
                    updatedItem = { ...selectedSubSurveyItem, name: target.value };
                } else {
                    updatedItem = { ...selectedSubSurveyItem, name: "" };
                }
                setSelectedSubSurveyItem(updatedItem);
                saveSurveyData(updatedItem);
                onChangeHandle(updatedItem, destination);
                break;
            case "section":
                if (event.key === "Tab" && currentIndex + 1 !== selectedSubSurveyItem?.sections.length) {
                    const newSection = cloneDeep(selectedSubSurveyItem?.sections[currentIndex + 1]);
                    setSelectedSectionItem(newSection);
                    break;
                }
                if (target.value.trim() !== "") {
                    updatedItem = { ...selectedSectionItem, name: target.value };
                } else {
                    updatedItem = { ...selectedSectionItem, name: "" };
                }
                setSelectedSectionItem(updatedItem);
                updateSectionsInSelectedSurvey(updatedItem);
                onChangeHandle(updatedItem, destination);
                break;
        }
    };

    const removeSection = (sequenceId) => {
        resetLoaders();
        const updatedSubSurvey = {
            ...selectedSubSurveyItem,
            sections: selectedSubSurveyItem.sections.filter((sec) => sec.sequenceId !== sequenceId)
        };
        saveSurveyData(updatedSubSurvey);
        NewSurveyBuilderService.removeSection(surveyId, selectedSubSurveyItem?.sequenceId, sequenceId)
            .then(() => {
                setShowSectionRemove(false);
                setShowDialogLoader(false);

                if (selectedSection?.sequenceId === sequenceId) {
                    selectDefaultSection(sequenceId);
                }
                onSave(false);
            })
            .catch(() => {
                setShowSectionRemove(false);
                onSave(true);
            });
    };

    const removeSubSurvey = async (sequenceId) => {
        resetLoaders();
        const surveyForRemove = surveyData?.subSurveys.find((sub) => sub.sequenceId === sequenceId);
        if (surveyForRemove?.show === "As a demo") {
            setShowSubSurveyRemove(false);
            showErrorNotification("Can't remove", "The Demo sub-survey can't be removed from GC survey");
            return;
        }
        const index = surveyData?.subSurveys.indexOf(surveyForRemove);
        setSelectedSubSurveyItem(null);
        await NewSurveyBuilderService.removeSubSurvey(surveyId, sequenceId)
            .then(() => {
                setShowSubSurveyRemove(false);
                setShowDialogLoader(false);
                if (surveyForRemove.sections?.find((sec) => sec.sequenceId === selectedSection?.sequenceId)) {
                    selectDefaultSection(null, sequenceId);
                }
                onSave(false);
            })
            .catch(() => {
                setShowSubSurveyRemove(false);
                onSave(true);
            });

        if (index === 0 && surveyData?.subSurveys.length > 1) {
            const newFirst = surveyData?.subSurveys[1];
            newFirst.show = surveyData?.type === "GC" ? "As a demo" : "Always";
            await NewSurveyBuilderService.saveSubSurvey(surveyId, newFirst.sequenceId, {
                name: newFirst.name,
                show: newFirst.show
            });
        }
        setSurveyData({
            ...surveyData,
            subSurveys: surveyData?.subSurveys.filter((sub) => sub.sequenceId !== sequenceId)
        });
    };

    const saveSurveyData = (updatedSubSurvey) => {
        const index = surveyData?.subSurveys.findIndex((sub) => sub.sequenceId === selectedSubSurveyItem?.sequenceId);
        const updatedSubSurveys = surveyData?.subSurveys;
        updatedSubSurveys[index] = updatedSubSurvey;
        setSelectedSubSurveyItem(updatedSubSurvey);
        const updateData = cloneDeep({ ...surveyData, subSurveys: [...updatedSubSurveys] });
        setSurveyData(updateData);
    };

    const updateSectionsInSelectedSurvey = (updatedSection) => {
        const index = selectedSubSurveyItem?.sections.findIndex((section) => section.sequenceId === selectedSectionItem?.sequenceId);
        const updatedSections = selectedSubSurveyItem?.sections;
        updatedSections[index] = updatedSection;
        const updatedSubSurvey = {
            ...selectedSubSurveyItem,
            sections: updatedSections
        };
        setSelectedSubSurveyItem(updatedSubSurvey);
        saveSurveyData(updatedSubSurvey);
    };

    const selectDefaultSection = (sequenceId, subSurvey) => {
        const subSurveysWithSection = subSurvey
            ? surveyData?.subSurveys.filter((sub) => sub.sequenceId !== subSurvey)
            : surveyData?.subSurveys.filter((sub) => sub.sections?.length > 0);
        if (subSurveysWithSection.length === 0) {
            setSelectedSection(null);
        } else {
            const newSection = subSurveysWithSection.flatMap((sub) => sub.sections).filter((sec) => sec.sequenceId !== sequenceId)[0];
            loadSection(newSection.sequenceId);
        }
    };

    const resetLoaders = () => {
        setFail(false);
        setSaved(false);
        setShowLoader(true);
        setShowDialogLoader(true);
    };

    const onShowChange = (e) => {
        resetLoaders();
        if (e === "As a demo") {
            surveyData?.subSurveys.forEach((sub) => {
                if (sub.show === "As a demo" && sub.sequenceId !== selectedSubSurveyItem.sequenceId) {
                    sub.show = "Always";
                }
            });
        }
        const updatedSubSurvey = { ...selectedSubSurveyItem, show: e };
        setSelectedSubSurveyItem(updatedSubSurvey);
        saveSurveyData(updatedSubSurvey);
        NewSurveyBuilderService.saveSubSurvey(surveyId, updatedSubSurvey.sequenceId, updatedSubSurvey)
            .then(() => onSave(false))
            .catch(() => onSave(true));
    };

    const getIfNotDisqualifiedMessage = (sub, index) => {
        if (index === 0 || sub.show !== "If not disqualified") {
            return "";
        }
        return `in "${surveyData?.subSurveys[index - 1].name || surveyData?.subSurveys[index - 1].sequenceId}"`;
    };

    const loadSection = (sequenceId) => {
        NewSurveyBuilderService.loadSection(surveyId, sequenceId).then((resp) => {
            localStorage.setItem("sectionSequence", sequenceId);
            setSelectedSection(resp.data);
        });
    };

    const renderSubSurveys = () => {
        return (
            <div className="sub-surveys-list">
                <div className="sub-surveys-list-header">
                    <h2>Sub surveys ({surveyData?.subSurveys.length})</h2>
                    <button onClick={addSubSurvey} disabled={addDisabled}>
                        <Plus />
                    </button>
                </div>
                <div className="sub-surveys-cards">
                    {surveyData?.subSurveys.map((sub, index) => {
                        return (
                            <div key={sub.sequenceId} className={getSubSurveyClasses(sub)} onClick={() => setSubSurvey(sub)}>
                                <div className="card-head">
                                    <div className="card-title">
                                        <label>Title:</label>
                                        <input
                                            type="text"
                                            value={
                                                selectedSubSurveyItem?.sequenceId === sub.sequenceId
                                                    ? selectedSubSurveyItem?.name
                                                    : sub.name
                                            }
                                            placeholder="Sub-survey Title"
                                            onChange={(e) => onInputChange(e, "sub")}
                                        />
                                    </div>
                                    <Close
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onSubSurveyRemove(sub.sequenceId);
                                        }}
                                        className="remove-sub-survey"
                                    />
                                </div>
                                <div className="show-row">
                                    <label>Show: </label>
                                    <DropDownComponent
                                        items={getSubSurveyShowItems(index)}
                                        selectedItem={sub["show"]}
                                        onChange={(e) => onShowChange(e)}
                                    />
                                    <span className="if-not-disqualified">{getIfNotDisqualifiedMessage(sub, index)}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderSections = () => {
        return (
            <div className="sections-list">
                <div className="sections-list-header">
                    <h2>
                        {selectedSubSurveyItem?.name || selectedSubSurveyItem?.sequenceId} - Sections (
                        {selectedSubSurveyItem?.sections.length})
                    </h2>
                    <button onClick={addSection} disabled={!selectedSubSurveyItem || addDisabled}>
                        <Plus />
                    </button>
                </div>
                <div className="sections-cards">
                    {selectedSubSurveyItem?.sections.map((section, index) => {
                        return (
                            <div
                                key={section.sequenceId}
                                className={getSectionClasses(section)}
                                onClick={() => setSelectedSectionItem(section)}
                            >
                                <div className="drag-icon">
                                    <Drag />
                                </div>
                                <div className="section-card-content">
                                    <input
                                        type="text"
                                        value={
                                            selectedSectionItem?.sequenceId === section.sequenceId
                                                ? selectedSectionItem?.name
                                                : section.name
                                        }
                                        placeholder="Section name"
                                        onChange={(e) => onInputChange(e, "section", index)}
                                        onKeyDown={(e) => onInputChange(e, "section", index)}
                                    />
                                    <Close
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSectionRemove(section.sequenceId);
                                        }}
                                        className="remove-section"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const getConfirmationDialog = (title, text, onConfirm, onCancel) => {
        return (
            <div className="remove-confirmation-dialog">
                <div className="dialog-content">
                    <h1>Do you confirm?</h1>
                    <h2>{title}</h2>
                    <p>{text}</p>
                    {showDialogLoader ? (
                        <Loader />
                    ) : (
                        <div className="confirmation-buttons">
                            <button className="confirm" onClick={() => onConfirm(targetForRemove)}>
                                Confirm
                            </button>
                            <button className="cancel" onClick={() => onCancel(false)}>
                                Cancel
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const onSectionRemove = (sequence) => {
        setShowDialogLoader(false);
        setTargetForRemove(sequence);
        setShowSectionRemove(true);
    };

    const onSubSurveyRemove = (sequence) => {
        setShowDialogLoader(false);
        setTargetForRemove(sequence);
        setShowSubSurveyRemove(true);
    };

    const renderConfirmationWindow = () => {
        return (
            <>
                {showSubSurveyRemove &&
                    getConfirmationDialog(
                        "Remove Sub-Survey",
                        "Current sub-survey and all sections inside will be removed",
                        removeSubSurvey,
                        setShowSubSurveyRemove
                    )}
                {showSectionRemove &&
                    getConfirmationDialog(
                        "Remove Section",
                        "Current section and all questions inside will be removed",
                        removeSection,
                        setShowSectionRemove
                    )}
            </>
        );
    };

    return (
        <div className="structure-tab-body">
            {renderSubSurveys()}
            {renderSections()}
            {renderConfirmationWindow()}
        </div>
    );
};
export default StructureTabComponent;
