import React, { useContext } from "react";
import classNames from "classnames";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";

function LanguageToggle() {
    const context = useContext(SurveyBuilderContext);

    const euClassname = classNames("eu-lang", {
        "selected-lang": context.lang === "EU"
    });
    const espClassname = classNames("esp-lang ", {
        "selected-lang": context.lang === "ESP"
    });
    return (
        <div className="language-toggle">
            <button className={euClassname} onClick={() => context.changeLanguage("EU", "en")}>
                English
            </button>
            <button className={espClassname} onClick={() => context.changeLanguage("ESP", "es")}>
                Espanol
            </button>
        </div>
    );
}

export default LanguageToggle;
