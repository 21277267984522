import React from "react";

import showPassword from "../../assets/images/showPassword.svg";
import hidePassword from "../../assets/images/hidePassword.svg";

import "./PasswordTextBoxComponent.scss";

class PasswordTextBoxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { password: "", isPasswordHidden: true };
        this.handleChange = this.handleChange.bind(this);
        this.handleShowHidePassword = this.handleShowHidePassword.bind(this);
    }

    handleShowHidePassword = () => {
        this.setState({ isPasswordHidden: !this.state.isPasswordHidden });
    };

    handleChange = ({ target }) => {
        this.setState({ password: target.value });
        this.props.onPasswordChange(target.value);
    };

    render() {
        const passwordClassName = this.props.error ? "field-error" : "";
        const error = this.props.error ? <label className="form-error">{this.props.errorText}</label> : "";
        return (
            <div className="password-form-item">
                <label htmlFor={this.props.id}>{this.props.title}</label>
                {this.state.isPasswordHidden ? (
                    <span>
                        <input
                            type="password"
                            id={this.props.id}
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            className={passwordClassName}
                        />
                        <img src={showPassword} alt="" className="hide-password" onClick={this.handleShowHidePassword} />
                        {error}
                    </span>
                ) : (
                    <span>
                        <input
                            type="text"
                            id={this.props.id}
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            className={passwordClassName}
                        />
                        <img src={hidePassword} alt="" className="hide-password" onClick={this.handleShowHidePassword} />
                        {error}
                    </span>
                )}
            </div>
        );
    }
}

export default PasswordTextBoxComponent;
