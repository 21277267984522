import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import en from "./assets/locales/en/translation.json";
import es from "./assets/locales/es/translation.json";

if (process.env.REACT_APP_ENV === "production") {
    disableReactDevTools();
}

i18n.use(initReactI18next)
    .use(HttpApi)
    .init({
        resources: {
            en: { translation: en },
            es: { translation: es }
        },
        lang: "en",
        fallbackLng: "en",
        interpolation: { escapeValue: false },
        supportedLngs: ["en", "es"],
        debug: process.env.NODE_ENV === "development"
    });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
