import React, { useContext } from "react";
import QuestionItem from "../../../pages/new-builder/components/NewSurveyBuilderContent/NewQuestionList/QuestionItem/QuestionItem";
import { NewSurveyBuilderContext } from "../../../pages/new-builder/NewSurveyBuilderContext/NewSurveyBuilderContext";
import { ReactComponent as AddItem } from "../../../assets/images/plus-white.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function NewGroup({ questions, group, openVisibilityModal }) {
    const { surveyData, swapQuestionsInGroup, addItemToGroup, removeQuestionFromGroup } = useContext(NewSurveyBuilderContext);

    const handleAddQuestion = (type) => {
        addItemToGroup(type, group);
    };

    return (
        <DragDropContext onDragEnd={(result) => swapQuestionsInGroup(result, questions, group.sequenceId)}>
            <Droppable droppableId="droppable-2">
                {(provided, snapshot) => (
                    <div className="question-group-content" {...provided.droppableProps} ref={provided.innerRef}>
                        <div>
                            {questions.map((groupQuestion, index) => (
                                <Draggable key={groupQuestion.sequenceId} draggableId={groupQuestion.sequenceId} index={index}>
                                    {(provided, innerSnapShot) => (
                                        <QuestionItem
                                            key={`${groupQuestion.sequenceId}-question`}
                                            question={groupQuestion}
                                            index={index}
                                            removeQuestion={(question) => removeQuestionFromGroup(question, group)}
                                            provided={provided}
                                            snapshot={innerSnapShot}
                                            isListDraggingOver={snapshot.isDraggingOver}
                                            openVisibilityModal={openVisibilityModal}
                                        />
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                        {surveyData?.status !== "PUBLISH" && (
                            <div className="question-group-actions">
                                <button
                                    className="action-first"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddQuestion("YES_NO");
                                    }}
                                >
                                    <AddItem /> <span>Question</span>
                                </button>
                                <button
                                    className="action-last"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddQuestion("NOTE");
                                    }}
                                >
                                    <span>Note</span>
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default NewGroup;
