import React from "react";
import AppService from "../../service/AppService";

function AgeInfo() {
    const allowedAge = AppService.selectedApp.disqualificationRules.allowedAge;
    if (allowedAge) {
        const from = allowedAge.from ? allowedAge.from : "0";
        const to = allowedAge.to ? allowedAge.to : "∞";
        return (
            <div className="acceptance-criteria-line">
                Age {from} - {to}
            </div>
        );
    }
    return null;
}

export default AgeInfo;
