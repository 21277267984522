import { useContext, useEffect, useState } from "react";
import { NewSurveyBuilderContext } from "../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import DropDownComponent from "../../../../../../components/dropDowns/dropDown/DropDownComponent";
import { ReactComponent as Close } from "../../../../../../assets/images/close.svg";
import { ReactComponent as Plus } from "../../../../../../assets/images/plus-white.svg";
import AppService from "../../../../../../service/AppService";
import { NewSurveyBuilderService } from "../../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";
import classNames from "classnames";
import { uuid } from "uuidv4";

const ScoringTabComponent = ({ scoringRef, surveyId, onSave }) => {
    const scoringDropdownItems = [
        { text: "\u003c" },
        { text: "\u2264" },
        { text: "\u003e" },
        { text: "\u2265" },
        { text: "\u003d" },
        { text: "Between" }
    ];
    const { surveyData, setSurveyData } = useContext(NewSurveyBuilderContext);
    const [ageScoring, setAgeScoring] = useState(surveyData?.ageScore);
    const [bmiScoring, setBMIScoring] = useState(surveyData?.bmiScore);
    const [showAgeError, setShowAgeError] = useState(false);
    const [showBMIError, setShowBMIError] = useState(false);

    useEffect(() => {
        scoringRef.current = { saveScoring };
    }, [ageScoring, bmiScoring, showAgeError, showBMIError]);

    useEffect(() => {
        scoringValidation(bmiScoring, "bmi");
    }, [bmiScoring]);

    useEffect(() => {
        scoringValidation(ageScoring, "age");
    }, [ageScoring]);

    const saveScoring = () => {
        if (showBMIError || showAgeError) {
            onSave(true);
            return;
        }
        ageScoring.map((i) => {
            if (i.sequenceId === null) {
                i.sequenceId = uuid();
            }
        });
        bmiScoring.map((i) => {
            if (i.sequenceId === null) {
                i.sequenceId = uuid();
            }
        });
        surveyData.ageScore = ageScoring;
        surveyData.bmiScore = bmiScoring;
        const data = {
            bmiScoring: bmiScoring,
            ageScoring: ageScoring
        };
        setSurveyData(surveyData);
        NewSurveyBuilderService.saveScoring(surveyId, data)
            .then(() => {
                onSave(false);
            })
            .catch(() => onSave(true));
    };

    const onOperatorChange = (item, operator, destination) => {
        item.operator = operator;
        if (destination === "age") {
            const index = ageScoring.findIndex((ageItem) => item === ageItem);
            if (index !== -1) {
                const updatedScoring = [...ageScoring];
                updatedScoring[index] = { ...updatedScoring[index], operator: operator };
                setAgeScoring(updatedScoring);
            }
        } else {
            const index = bmiScoring.findIndex((bmiItem) => item === bmiItem);
            if (index !== -1) {
                const updatedScoring = [...bmiScoring];
                updatedScoring[index] = { ...updatedScoring[index], operator: operator };
                setBMIScoring(updatedScoring);
            }
        }
    };

    const addScoringItem = (destination) => {
        const newItem = {
            sequenceId: uuid(),
            operator: "<",
            valueFrom: "",
            score: ""
        };
        if (destination === "bmi") {
            setBMIScoring((prevState) => [...prevState, newItem]);
        } else {
            setAgeScoring((prevState) => [...prevState, newItem]);
        }
    };

    const removeScoringItem = (destination, itemForRemove) => {
        if (destination === "bmi") {
            setBMIScoring((prevState) => prevState.filter((i) => i !== itemForRemove));
        } else {
            setAgeScoring((prevState) => prevState.filter((i) => i !== itemForRemove));
        }
    };

    const onValueChange = (event, item, destination) => {
        const { target } = event;
        const field = target.name;
        const updatedItem = { ...item, [field]: target.value };
        let updatedScoring;
        switch (destination) {
            case "age":
                updatedScoring = updateScoring(item, updatedItem, ageScoring);
                setAgeScoring(updatedScoring);
                break;
            case "bmi":
                updatedScoring = updateScoring(item, updatedItem, bmiScoring);
                setBMIScoring(updatedScoring);
                break;
        }
    };

    const updateScoring = (item, updatedItem, scoringArray) => {
        const updatedIndex = scoringArray.findIndex((i) => i === item);
        if (updatedIndex !== -1) {
            const updatedScoringArray = [...scoringArray];
            updatedScoringArray[updatedIndex] = updatedItem;
            return updatedScoringArray;
        }

        return scoringArray;
    };

    const scoringValidation = (scoringItems, destination) => {
        const validationItems = [];
        const wrongItems = scoringItems.filter(
            (i) =>
                i.valueFrom === "" ||
                i.score === "" ||
                (i.operator === "Between" && (i.valueTo === "" || Number(i.valueFrom) >= Number(i.valueTo)))
        );
        if (wrongItems.length > 0) {
            destination === "age" ? setShowAgeError(true) : setShowBMIError(true);
            return;
        }
        scoringItems.forEach((item) => {
            switch (item.operator) {
                case "Between":
                    validationItems.push(AppService.filledArray(parseInt(item.valueFrom), parseInt(item.valueTo)));
                    break;
                case "\u003c":
                    validationItems.push(AppService.filledArray(0, parseInt(item.valueFrom) - 1));
                    break;
                case "\u2264":
                    validationItems.push(AppService.filledArray(0, parseInt(item.valueFrom)));
                    break;
                case "\u003e":
                    validationItems.push(AppService.filledArray(parseInt(item.valueFrom) + 1, 100));
                    break;
                case "\u2265":
                    validationItems.push(AppService.filledArray(parseInt(item.valueFrom), 100));
                    break;
                case "\u003d":
                    validationItems.push(AppService.filledArray(parseInt(item.valueFrom), parseInt(item.valueFrom)));
                    break;
            }
        });
        const flatItems = validationItems.flat();
        if (new Set(flatItems).size !== flatItems.length) {
            destination === "age" ? setShowAgeError(true) : setShowBMIError(true);
        } else {
            destination === "age" ? setShowAgeError(false) : setShowBMIError(false);
        }
    };

    const getItemsClasses = (destination) => {
        return classNames("scoring-items", {
            "age-has-error": destination === "age" && showAgeError,
            "bmi-has-error": destination === "bmi" && showBMIError
        });
    };

    const renderScoreItems = (destination, items, title) => {
        return (
            <div className="scoring-tab">
                <div className="scoring-tab-header">
                    <div className="scoring-tab-title">{destination}:</div>
                    <div className="scoring-tab-error">
                        {destination === "age" && showAgeError && "Age Criteria is wrong"}
                        {destination === "bmi" && showBMIError && "BMI Criteria is wrong"}
                    </div>
                    <div className="add-scoring-item">
                        <button onClick={() => addScoringItem(destination)}>
                            <Plus /> Add {destination} scoring
                        </button>
                    </div>
                </div>
                <div className={getItemsClasses(destination)}>
                    {items.map((s) => {
                        return (
                            <div key={destination + "-" + s.operator + "-" + s.sequenceId} className="scoring-item">
                                <div className="score-item-text score-item-title">{title}</div>
                                <DropDownComponent
                                    items={scoringDropdownItems}
                                    selectedItem={s.operator}
                                    onChange={(o) => onOperatorChange(s, o, destination)}
                                />
                                {s.operator === "Between" ? (
                                    <div className="between-scoring">
                                        <input
                                            type="number"
                                            value={s.valueFrom}
                                            name="valueFrom"
                                            onChange={(e) => onValueChange(e, s, destination)}
                                            placeholder="0"
                                        />
                                        <div className="and-text">and</div>
                                        <input
                                            type="number"
                                            value={s.valueTo}
                                            name="valueTo"
                                            onChange={(e) => onValueChange(e, s, destination)}
                                            placeholder="&#8734;"
                                        />
                                    </div>
                                ) : (
                                    <div className="score-item-input">
                                        <input
                                            type="number"
                                            value={s.valueFrom}
                                            name="valueFrom"
                                            onChange={(e) => onValueChange(e, s, destination)}
                                            placeholder="&#8734;"
                                        />
                                    </div>
                                )}
                                <div className="score-item-text"> score:</div>
                                <div className="score-item-scoring">
                                    <input
                                        type="number"
                                        value={s.score}
                                        name="score"
                                        onChange={(e) => onValueChange(e, s, destination)}
                                        placeholder="0"
                                    />
                                </div>
                                <div className="score-item-remove">
                                    <Close onClick={() => removeScoringItem(destination, s)} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="scoring-tab-body">
            {renderScoreItems("bmi", bmiScoring, "BMI")}
            <div className="scoring-separator">
                <div className="vl"></div>
            </div>
            {renderScoreItems("age", ageScoring, "Age")}
        </div>
    );
};

export default ScoringTabComponent;
