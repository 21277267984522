import React from "react";
import "./SettingsButtonComponent.scss";

import eyeGrey from "../../assets/images/eye-grey.svg";
import reviewGrey from "../../assets/images/review-grey.svg";
import disqGrey from "../../assets/images/disq-grey.svg";
import disqSectionGrey from "../../assets/images/disq-section-grey.svg";
import classNames from "classnames";

class SettingsButtonComponent extends React.Component {
    render() {
        let disqImage = null;

        if (this.props.answer.isDisqualify) {
            disqImage = this.props.answer.disqType === "section" ? disqSectionGrey : disqGrey;
        }

        return (
            <div>
                {this.props.children}
                <div className={classNames("settings-buttons", this.props.className)}>
                    {this.props.hasVisibility ? (
                        <img src={eyeGrey} alt="" onClick={() => this.props.onVisibilityClick(this.props.answer.sequenceId)} />
                    ) : null}
                    {this.props.answer.isReview ? <img src={reviewGrey} alt="" /> : null}
                    {this.props.answer.isDisqualify ? <img src={disqImage} alt="" /> : null}
                </div>
            </div>
        );
    }
}
export default SettingsButtonComponent;
