import React, { useRef, useState } from "react";
import "./PhotoUpload.scss";
import plus from "../../assets/images/upload-icon.svg";
import removePhoto from "../../assets/images/remove-icon.svg";
import ImageCrop from "./imageCrop";
import { useTranslation } from "react-i18next";

function PhotoUpload({ multiple }) {
    const [previewFiles, setPreviewFiles] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [imageEditor, setImageEditor] = useState(null);
    const ref = useRef();
    const { t } = useTranslation();

    const onFileChange = (event) => {
        event.preventDefault();
        const imageList = event.target.files;
        uploadPreviewFiles(imageList);
    };

    const dragStartHandler = (event) => {
        event.preventDefault();
    };
    const dragLeaveHandler = (event) => {
        event.preventDefault();
    };
    const onDropHandler = (event) => {
        event.preventDefault();
        const filesList = [...event.dataTransfer.files];
        uploadPreviewFiles(filesList);
    };
    const uploadPreviewFiles = (files) => {
        if (files.length === 0) {
            return;
        }
        setFileList([...fileList, ...files]);
        const allFiles = [...previewFiles];

        for (const file of files) {
            allFiles.push(URL.createObjectURL(file));
        }
        setPreviewFiles(allFiles);
    };
    const removePreviewPhoto = (index) => {
        const removedPreviewFiles = previewFiles.filter((file, i) => i !== index);
        const removedFilesList = fileList.filter((file, i) => i !== index);
        setPreviewFiles(removedPreviewFiles);
        setFileList(removedFilesList);
    };
    const renderMultiplePreview = () => {
        if (previewFiles.length > 0) {
            return (
                <div className="preview-container">
                    {previewFiles.map((file, i) => (
                        <div key={i} className="preview-wrapper">
                            <img src={file} className="preview-photo" />
                            <img src={removePhoto} className="remove-photo" onClick={() => removePreviewPhoto(i)} />
                        </div>
                    ))}
                </div>
            );
        }
    };
    const onCancel = () => {
        setPreviewFiles([]);
    };
    const onCropImage = () => {
        if (imageEditor !== null) {
            const url = imageEditor.getImageScaledToCanvas().toDataURL();
            previewFiles[0] = url;
        }
    };

    const buildPreviewPhoto = () => {
        if (multiple) {
            return renderMultiplePreview();
        } else {
            return <ImageCrop imageSrc={previewFiles[0]} onCancel={onCancel} setImageEditor={setImageEditor} onCropImage={onCropImage} />;
        }
    };

    return (
        <div className="photo-upload" ref={ref}>
            <div
                className="upload-container"
                onDragStart={dragStartHandler}
                onDragLeave={dragLeaveHandler}
                onDragOver={dragStartHandler}
                onDrop={onDropHandler}
            >
                <label className="upload-label">
                    <input
                        type="file"
                        className="upload-input"
                        accept="image/*"
                        onChange={onFileChange}
                        multiple={multiple}
                        alt={"upload"}
                    />
                    <div className="label-text">
                        <img src={plus} alt="add" />
                        <div className="title">{t("Upload Photo")}</div>
                        <div className="size-text">{t("Upload size")}</div>
                        <div className="size-text types-text">{t("Upload types")}</div>
                    </div>
                </label>
            </div>
            {previewFiles.length > 0 && buildPreviewPhoto()}
        </div>
    );
}

export default PhotoUpload;
