import React from "react";
import { LanguageService } from "../../../../service/LanguageService";
import ToggleAccordion from "../../../../components/ToggleAccordion/ToggleAccordion";
import NoteContentItem from "../NoteContent/NoteContent";

function GroupTableItems({
    question,
    subI,
    secI,
    questionI,
    lang,
    section,
    handleChangeGroupQuestion,
    handleChangeGroupHint,
    handleChangeGroupNoteContent,
    handleKeyDown,
    buildText,
    buildAnswers
}) {
    return question.questions.map((groupQuestion, groupI) => (
        <div key={groupI}>
            <div className="group-item" style={{ height: LanguageService.getTableItemHeight(groupQuestion.text, "groupQuestion") }}>
                <ToggleAccordion
                    open={groupQuestion.open}
                    onClick={() => LanguageService.toggleGroupQuestionAccordion(subI, secI, questionI, groupI)}
                />
                <span onClick={() => LanguageService.toggleEditGroupQuestion(subI, secI, questionI, groupI, lang)}>
                    {LanguageService.isEspEditing(groupQuestion, lang) ? (
                        <textarea
                            className="edit-input"
                            onChange={(event) => handleChangeGroupQuestion(event, subI, secI, questionI, groupI, section.sequenceId)}
                            value={groupQuestion.text[lang]}
                            placeholder={groupQuestion.text.EU}
                            onKeyDown={handleKeyDown}
                        />
                    ) : (
                        buildText(groupQuestion.text, lang)
                    )}
                </span>
            </div>
            {groupQuestion.open && groupQuestion.hint?.EU?.length > 0 && (
                <div className="group-hint" style={{ height: LanguageService.getTableItemHeight(groupQuestion.hint, "groupQuestion") }}>
                    <span onClick={() => LanguageService.toggleEditGroupHint(subI, secI, questionI, groupI, lang)}>
                        {LanguageService.isEspHintEditing(groupQuestion, lang) ? (
                            <textarea
                                className="edit-input"
                                onChange={(event) => handleChangeGroupHint(event, subI, secI, questionI, groupI, section.sequenceId)}
                                value={groupQuestion.hint[lang]}
                                placeholder={groupQuestion.hint.EU}
                                onKeyDown={handleKeyDown}
                            />
                        ) : (
                            buildText(groupQuestion.hint, lang)
                        )}
                    </span>
                </div>
            )}
            {groupQuestion.type === "NOTE"
                ? groupQuestion.open && (
                      <NoteContentItem
                          section={section}
                          question={groupQuestion}
                          subI={subI}
                          secI={secI}
                          questionI={questionI}
                          groupI={groupI}
                          lang={lang}
                          buildText={buildText}
                          handleChangeGroupNoteContent={handleChangeGroupNoteContent}
                          handleKeyDown={handleKeyDown}
                      />
                  )
                : groupQuestion.open &&
                  buildAnswers(
                      groupQuestion.answers,
                      true,
                      lang,
                      {
                          subI,
                          secI,
                          questionI,
                          groupI
                      },
                      section.sequenceId
                  )}
        </div>
    ));
}

export default GroupTableItems;
