import React, { forwardRef, useCallback, useContext, useImperativeHandle, useRef } from "react";
import "./NewQuestionList.scss";
import { NewSurveyBuilderContext } from "../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { NewSurveyBuilderService } from "../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";
import cloneDeep from "lodash/cloneDeep";
import { useParams } from "react-router-dom";
import QuestionItem from "./QuestionItem/QuestionItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import classNames from "classnames";
import AppService from "../../../../../service/AppService";

function NewQuestionList({ questions, openVisibilityModal }, ref) {
    const { id } = useParams();
    const {
        setSelectedQuestion,
        selectedSection,
        setSelectedSection,
        selectedQuestion,
        changeSectionQuestionsCount,
        sortAccordingToVisibility
    } = useContext(NewSurveyBuilderContext);
    const handleOnDragEndRef = useRef();

    const removeQuestion = (question) => {
        NewSurveyBuilderService.removeQuestion(id, selectedSection.sequenceId, question.sequenceId).then(() => {
            if (selectedQuestion?.sequenceId === question.sequenceId) {
                setSelectedQuestion(null);
            }
            const newQuestions = selectedSection.questions.filter((q) => q.sequenceId !== question.sequenceId);
            const updatedSection = { ...selectedSection, questions: newQuestions };
            setSelectedSection(cloneDeep(updatedSection));
            changeSectionQuestionsCount(question, updatedSection, "DECREASE");
        });
    };

    const getChangedPositionQuestions = (originalQuestions, sortedQuestions) => {
        return originalQuestions?.filter((question, index) => {
            const sortedIndex = sortedQuestions.findIndex((q) => q.sequenceId === question.sequenceId);
            return sortedIndex !== index;
        });
    };
    const hasAnyVisibilityDependencies = (questions) => {
        return questions?.some((question) => question.visibility && question.visibility.length > 0);
    };

    const handleOnDragEnd = useCallback(
        async (result) => {
            if (!result.destination) {
                return;
            }

            const draggedQuestion = selectedSection.questions[result.source.index];
            const questionsBeforeSort = AppService.reorder(selectedSection.questions, result.source.index, result.destination.index);

            const useVisibilitySort = hasAnyVisibilityDependencies(questionsBeforeSort);
            const finalSortedQuestions = useVisibilitySort
                ? sortAccordingToVisibility(questionsBeforeSort, draggedQuestion.sequenceId)
                : questionsBeforeSort;

            setSelectedSection({ ...selectedSection, questions: finalSortedQuestions });
            setSelectedQuestion(draggedQuestion);

            const changedQuestions = getChangedPositionQuestions(questionsBeforeSort, finalSortedQuestions);

            if (changedQuestions?.length > 0) {
                for (const question of changedQuestions) {
                    const newPosition = finalSortedQuestions.findIndex((q) => q.sequenceId === question.sequenceId);
                    await NewSurveyBuilderService.swapQuestion(id, selectedSection.sequenceId, question.sequenceId, newPosition);
                }
            } else {
                const newPosition = finalSortedQuestions?.findIndex((q) => q.sequenceId === draggedQuestion.sequenceId);
                await NewSurveyBuilderService.swapQuestion(id, selectedSection.sequenceId, draggedQuestion.sequenceId, newPosition);
            }
        },
        [selectedSection, setSelectedSection, setSelectedQuestion]
    );

    handleOnDragEndRef.current = handleOnDragEnd;

    useImperativeHandle(ref, () => ({
        invokeHandleOnDragEnd: handleOnDragEndRef.current
    }));

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div className={classNames("questions-list")} {...provided.droppableProps} ref={provided.innerRef}>
                        {questions?.map((question, index) => (
                            <Draggable key={question.sequenceId} draggableId={question.sequenceId} index={index}>
                                {(provided, innerSnapShot) => (
                                    <QuestionItem
                                        key={question.sequenceId}
                                        question={question}
                                        index={index}
                                        removeQuestion={removeQuestion}
                                        provided={provided}
                                        snapshot={innerSnapShot}
                                        isListDraggingOver={snapshot.isDraggingOver}
                                        openVisibilityModal={openVisibilityModal}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default forwardRef(NewQuestionList);
