import { useHistory } from "react-router-dom";
import React, { useContext, useState } from "react";
import { NewSurveyBuilderContext } from "../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { ReactComponent as ArrowBack } from "../../../../assets/images/arrow-back.svg";
import classNames from "classnames";
import "./NewSurveyBuilderHeader.scss";
import switchActive from "../../../../assets/images/switch-active.svg";
import switchInactive from "../../../../assets/images/switch-inactive.svg";
import PublishPopup from "../../../../components/popups/PublishPopup/PublishPopup";
import { showErrorNotification, showSuccessNotification } from "../../../../helper/NotificationHelper";
import ActivationPopup from "../../../../components/popups/ActivationPopup/ActivationPopup";
import { NewSurveyBuilderService } from "../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";

const NewSurveyBuilderHeader = () => {
    const history = useHistory();
    const { surveyData, setSurveyData } = useContext(NewSurveyBuilderContext);

    const [showPublishPopup, setShowPublishPopup] = useState(false);
    const [showPublishLoader, setShowPublishLoader] = useState(false);
    const [showActivationPopup, setShowActivationPopup] = useState(false);

    const getButtonClassNames = (current) => {
        return classNames("header-button", {
            "active-button": surveyData.status === current.toUpperCase()
        });
    };

    const setStatus = (status) => {
        setSurveyData({ ...surveyData, status: status });
    };

    const setActive = (value) => {
        setSurveyData({ ...surveyData, active: value });
    };

    const publishSurvey = () => {
        setShowPublishLoader(true);
        NewSurveyBuilderService.publishSurvey(surveyData.id, false)
            .then(() => {
                showSuccessNotification(
                    "Successfully published",
                    `The survey "${surveyData.title}" with version "${surveyData.version}" has been published`
                );
                setStatus("PUBLISH");
                setShowPublishPopup(false);
                setShowPublishLoader(false);
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message || "An error occurred";
                showErrorNotification("Can't publish survey", errorMessage);
                setShowPublishPopup(false);
                setShowPublishLoader(false);
            });
    };

    const activeSurvey = () => {
        if (surveyData.status === "ARCHIVED") {
            showErrorNotification("Can't activate the survey", "Sorry, but you can't activate an archived survey");
            setShowActivationPopup(false);
            return;
        }
        NewSurveyBuilderService.activeSurvey({ id: surveyData.id }).then(() => {
            showSuccessNotification(
                "Successfully activated",
                `The survey "${surveyData.title}" with version "${surveyData.version}" has been activated for "${surveyData.type}" users`
            );
            setActive(true);
            setShowActivationPopup(false);
        });
    };

    const onPublishClick = () => {
        if (surveyData.active) return;
        switch (surveyData.status) {
            case "CONSTRUCT":
                setShowPublishPopup(true);
                break;
            default:
                showSuccessNotification(
                    `Has been already published`,
                    `The survey "${surveyData.title}" with version "${surveyData.version}"`
                );
        }
    };

    const renderButtons = () => {
        return (
            surveyData && (
                <div className="survey-header-buttons">
                    {surveyData.status === "PUBLISH" && (
                        <div className="activation-button">
                            {surveyData.active ? (
                                <>
                                    <span>Active</span> <img src={switchActive} alt="active" />
                                </>
                            ) : (
                                <>
                                    <span>Inactive</span>{" "}
                                    <img src={switchInactive} alt="Inactive" onClick={() => setShowActivationPopup(true)} />
                                </>
                            )}
                        </div>
                    )}
                    <div className="main-buttons">
                        <button className={getButtonClassNames("Construct")}>Construct</button>
                        <button className={getButtonClassNames("Publish")} onClick={onPublishClick}>
                            Publish
                        </button>
                    </div>
                </div>
            )
        );
    };

    return (
        <>
            <div className="survey-back-item">
                <button onClick={() => history.goBack()}>
                    <ArrowBack />
                    Back to Apps
                </button>
            </div>
            {renderButtons()}
            <div className="empty-header" />
            {showPublishPopup && (
                <PublishPopup action={publishSurvey} setShowPublishPopup={setShowPublishPopup} showPublishLoader={showPublishLoader} />
            )}
            {showActivationPopup && <ActivationPopup action={activeSurvey} setShowActivationPopup={setShowActivationPopup} />}
        </>
    );
};

export default NewSurveyBuilderHeader;
