import React, { useState } from "react";
import "./AppsComponent.scss";
import Layout from "../../components/layout/LayoutComponent";
import AppsList from "../../components/appsList/AppsListComponent";
import AppListFilter from "../../components/appListFilter/AppListFilterComponent";
import CreateAppPopup from "../../components/createAppPopup/CreateAppPopupComponent";
import SurveyBuilderStore from "../../SurveyBuilderContext";
import ProgressWrapper from "../../components/createAppPopup/ProgressWrapper";

function AppsComponent() {
    const [showCreateAppPopup, setShowCreateAppPopup] = useState(false);
    const [filter, setFilter] = useState({ name: "", value: "" });
    const [searchField, setSearchField] = useState("");

    const filterApps = (name, value) => {
        let filterValue = "";
        if (name === "apps") {
            if (value[0] !== "All") {
                filterValue = value[0].toUpperCase();
            }
            filter.apps = filterValue;
        } else {
            if (value[0] !== "Any") {
                switch (value[0]) {
                    case "Published":
                        filter.status = "PUBLISH";
                        break;
                    case "Under construction":
                        filter.status = "CONSTRUCT";
                        break;
                    case "Archived":
                        filter.status = "ARCHIVED";
                        break;
                    case "Active":
                        filter.status = "ACTIVE";
                        break;
                    default:
                        filter.status = "CONSTRUCT";
                }
            }
        }
        if (value[0] === "All" || value[0] === "Any") {
            filter[name] = "";
        }
        setFilter({ name: name, value: value });
    };
    const searchFieldChanged = (e) => setSearchField(e.target.value);

    return (
        <SurveyBuilderStore>
            <Layout>
                <AppListFilter
                    onAppCreate={() => setShowCreateAppPopup(true)}
                    onFilterChanged={filterApps}
                    searchFieldChanged={searchFieldChanged}
                />
                <div className="app-content">
                    <AppsList filter={filter} searchField={searchField} />
                </div>
                {showCreateAppPopup && (
                    <ProgressWrapper>
                        <CreateAppPopup onClose={() => setShowCreateAppPopup(false)} app={null} />
                    </ProgressWrapper>
                )}
            </Layout>
        </SurveyBuilderStore>
    );
}
export default AppsComponent;
