import React, { useContext, useState } from "react";
import "./QuestionComponent.scss";
import AppService from "../../service/AppService";

import close from "../../assets/images/close-icon.svg";
import DragIndicator from "../../assets/images/draggable_indicator.svg";
import Feedback from "./components/Feedback/Feedback";
import CheckBox from "../checkBox/CheckBoxComponent";
import YesNo from "./answers/YesNo";
import Phone from "./answers/Phone";
import Radio from "./answers/Radio";
import NumberInput from "./answers/NumberInput";
import Text from "./answers/Text";
import Select from "./answers/Select";
import MultiSelect from "./answers/MultiSelect";
import Upload from "./answers/Upload";
import Note from "./answers/Note";
import DatePicker from "./answers/DatePicker";
import Group from "./answers/Group";
import { LanguageService } from "../../service/LanguageService";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";
import classNames from "classnames";
import Conditions from "./components/Conditions/Conditions";
import VisibilityButton from "./components/VisibilityButton/VisibilityButton";
import DynamicGroup from "./answers/DynamicGroup";

function QuestionComponent({
    index,
    question,
    isGroup,
    provided,
    snapshot,
    onQuestionRemove,
    onClick,
    removeQuestionFromGroup,
    showActions,
    onVisibilityClick,
    onVisibilityRemoveClicked,
    onConditionVisibilityClick
}) {
    const { lang, selectedQuestion, showFeedback } = useContext(SurveyBuilderContext);

    const [showVisibilityInfo, setShowVisibilityInfo] = useState(false);

    const questionClassNames = [
        "yes-no",
        "radio",
        "picklist",
        "multi-picklist",
        "free-text",
        "date",
        "number",
        "address",
        "phone",
        "dropdown",
        "dropdownMultiSelect",
        "upload",
        "multi-upload",
        "note",
        "group-section",
        "dynamic-group"
    ];
    let answer = null;

    switch (question.type) {
        case AppService.questionType.YES_NO:
            answer = <YesNo lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} />;
            break;
        case AppService.questionType.PHONE:
            answer = <Phone answers={question.answers} onVisibilityClick={onVisibilityClick} />;
            break;
        case AppService.questionType.SINGLE_ONE:
            answer = <Radio lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} />;
            break;
        case AppService.questionType.SELECT_MANY:
            answer = <CheckBox lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} showSettings={true} />;
            break;
        case AppService.questionType.MULTI_SELECT:
            answer = <CheckBox lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} showSettings={true} />;
            break;
        case AppService.questionType.NUMBER:
            answer = <NumberInput lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} />;
            break;
        case AppService.questionType.TEXT:
            answer = <Text lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} />;
            break;
        case AppService.questionType.DATE:
            answer = <DatePicker lang={lang} readOnly={false} onVisibilityClick={onVisibilityClick} question={question} />;
            break;
        case AppService.questionType.ADDRESS:
            answer = <Text lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} />;
            break;
        case AppService.questionType.DROPDOWN:
            answer = <Select lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} />;
            break;
        case AppService.questionType.DROPDOWN_MULTISELECT:
            answer = <MultiSelect lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} />;
            break;
        case AppService.questionType.UPLOAD:
            answer = <Upload lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} multiple={false} />;
            break;
        case AppService.questionType.MULTI_UPLOAD:
            answer = <Upload lang={lang} answers={question.answers} onVisibilityClick={onVisibilityClick} multiple={true} />;
            break;
        case AppService.questionType.NOTE:
            answer = <Note lang={lang} content={question.noteContent} onVisibilityClick={onVisibilityClick} />;
            break;
        case AppService.questionType.GROUP:
            answer = (
                <Group
                    showActions={showActions}
                    removeQuestionFromGroup={removeQuestionFromGroup}
                    index={index}
                    questions={question.questions}
                    onVisibilityClick={onVisibilityClick}
                    onVisibilityRemoveClicked={onVisibilityRemoveClicked}
                    lang={lang}
                />
            );
            break;
        case AppService.questionType.DYNAMIC_GROUP:
            answer = (
                <DynamicGroup
                    dynamic={true}
                    showActions={showActions}
                    removeQuestionFromGroup={removeQuestionFromGroup}
                    index={index}
                    question={question}
                    questions={question.questions}
                    onVisibilityClick={onVisibilityClick}
                    onVisibilityRemoveClicked={onVisibilityRemoveClicked}
                    lang={lang}
                />
            );
    }
    let optionalFeedback = null;
    if (question.answers) {
        optionalFeedback = question.answers.map((a, i) => {
            if (typeof a.feedback.text === "object" ? a.feedback.text.EU.length > 0 : a.feedback.text.length > 0) {
                return <Feedback key={`${i}_feedback`} answer={a} question={question} onClick={showFeedback} lang={lang} />;
            }
        });
    }

    const containerClassName = classNames("survey-section", questionClassNames[AppService.convertTypeNameToIndex(question.type)], {
        "survey-section-selected": selectedQuestion?.sequenceId === question.sequenceId,
        "drag-active": snapshot.isDragging
    });

    const dragClassname = classNames({
        "visibility-drag-indicator": question.visibility.length > 0,
        "group-drag-indicator": isGroup,
        "drag-indicator": !isGroup
    });

    if (question.type === AppService.questionType.GROUP || question.type === AppService.questionType.DYNAMIC_GROUP) {
        return (
            <div
                className="survey-question-container"
                ref={provided.innerRef}
                {...provided.draggableProps}
                onClick={(e) => {
                    e.stopPropagation();
                    onClick(question.sequenceId);
                }}
            >
                <div className={containerClassName}>
                    <div className="question-container">
                        <img src={DragIndicator} className={dragClassname} {...provided.dragHandleProps} />
                        <div className="question-title">
                            {LanguageService.getTranslatedText(question.text, lang)}
                            <VisibilityButton
                                question={question}
                                showVisibilityInfo={showVisibilityInfo}
                                setShowVisibilityInfo={setShowVisibilityInfo}
                                onVisibilityRemoveClicked={onVisibilityRemoveClicked}
                            />
                        </div>
                        <div className="question-remove">
                            <img
                                src={close}
                                alt=""
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onQuestionRemove(question.sequenceId);
                                }}
                            />
                        </div>
                    </div>
                    {answer}
                    <Conditions lang={lang} onConditionVisibilityClick={onConditionVisibilityClick} question={question} />
                </div>
                {optionalFeedback}
            </div>
        );
    } else {
        return (
            <div
                className="survey-question-container"
                ref={provided.innerRef}
                {...provided.draggableProps}
                onClick={(e) => {
                    e.stopPropagation();
                    onClick(question.sequenceId);
                }}
            >
                <div className={containerClassName}>
                    <div className="question-container">
                        {AppService.selectedApp.status === "CONSTRUCT" && (
                            <img src={DragIndicator} className={dragClassname} {...provided.dragHandleProps} />
                        )}
                        <div className="question-number">
                            <p>{index}</p>
                            <VisibilityButton
                                question={question}
                                showVisibilityInfo={showVisibilityInfo}
                                setShowVisibilityInfo={setShowVisibilityInfo}
                                onVisibilityRemoveClicked={onVisibilityRemoveClicked}
                            />
                        </div>
                        <div className="question-remove">
                            <img
                                src={close}
                                alt="remove"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onQuestionRemove(question.sequenceId);
                                }}
                            />
                        </div>
                    </div>
                    <div className="header">
                        <p>{LanguageService.getTranslatedText(question.text, lang)}</p>
                        {question.optional ? <span className="optional">Optional</span> : ""}
                    </div>
                    {answer}
                    <Conditions lang={lang} onConditionVisibilityClick={onConditionVisibilityClick} question={question} />
                </div>
                {optionalFeedback}
            </div>
        );
    }
}

export default QuestionComponent;
