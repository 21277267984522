import React, { useContext, useEffect, useState } from "react";
import { NewSurveyBuilderContext } from "../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { NewSurveyBuilderService } from "../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";
import { ReactComponent as Edit } from "../../../../../assets/images/edit.svg";
import "./SurveyStructureComponent.scss";
import classNames from "classnames";

function SurveyStructureComponent({ surveyId, onEditClick }) {
    const { surveyData, isInConstruct, selectedSection, setSelectedSection, setSelectedQuestion } = useContext(NewSurveyBuilderContext);
    const [subSurveys, setSubSurveys] = useState(null);
    useEffect(() => {
        NewSurveyBuilderService.loadSidebar(surveyId).then((subSurveys) => {
            setSubSurveys(subSurveys?.data);
            if (subSurveys?.data.length === 0) {
                return;
            }
            const rememberedSection = localStorage.getItem("sectionSequence");
            const allSections = subSurveys.data.flatMap((sub) => sub.sections);
            if (allSections.length === 0) {
                return;
            }
            const section =
                allSections?.filter((s) => s.sequenceId === rememberedSection).length === 1
                    ? rememberedSection
                    : subSurveys?.data[0].sections[0].sequenceId;
            loadSection(section);
        });
    }, []);

    useEffect(() => {
        setSubSurveys(surveyData?.subSurveys);
    }, [surveyData]);

    const loadSection = (sequenceId) => {
        NewSurveyBuilderService.loadSection(surveyId, sequenceId).then((resp) => {
            localStorage.setItem("sectionSequence", sequenceId);
            setSelectedSection(resp.data);
        });
    };

    const onChangeSection = (sequenceId) => {
        setSelectedSection(null);
        setSelectedQuestion(null);
        loadSection(sequenceId);
    };

    const renderSubSurveys = () => {
        return (
            <div className="survey-structure">
                <div className="survey-section-header">
                    <span>structure</span>
                    {isInConstruct() && (
                        <span className="appEdit" onClick={() => onEditClick(2)}>
                            <Edit />
                        </span>
                    )}
                </div>

                {subSurveys?.map((sub) => (
                    <div key={sub.sequenceId} className="sub-survey-item">
                        <div className="sub-survey-heading">
                            <h1>{sub.name || sub.sequenceId}</h1>
                            <p>
                                <span>{sub.show}</span>
                            </p>
                        </div>
                        <div className="sub-survey-sections">
                            <h2>Sections</h2>
                            {renderSections(sub)}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const getSectionStyles = (section) => {
        return classNames("section-item", {
            "active-section": selectedSection?.sequenceId === section.sequenceId
        });
    };

    const renderSections = (subSurvey) => {
        return (
            <div>
                {subSurvey.sections.map((section) => (
                    <div key={section.sequenceId} className={getSectionStyles(section)} onClick={() => onChangeSection(section.sequenceId)}>
                        <h3>{section.name || section.sequenceId}</h3>
                        <span>{section.questionsCount}</span>
                    </div>
                ))}
            </div>
        );
    };

    return renderSubSurveys();
}
export default SurveyStructureComponent;
