import React, { useState } from "react";
import { BuilderSurveyService } from "./service/BuilderSurveyService/BuilderSurveyService";
import AppService from "./service/AppService";
import { Answer, DynamicGroup, Group, Question } from "./helper/Application/Application";
import cloneDeep from "lodash/cloneDeep";
import { setStaticAnswersDueToSFDC } from "./helper/StaticAnswers/StaticAnswers";
import { uuid } from "uuidv4";

export const SurveyBuilderContext = React.createContext(null);

export default function SurveyBuilderStore({ children }) {
    const [lang, setLang] = useState("EU");

    const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
    const [selectedSubSurvey, setSelectedSubSurvey] = useState(0);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1);
    const [selectedGroupQuestionIndex, setSelectedGroupQuestionIndex] = useState(-1);
    const [selectedAnswerId, setSelectedAnswerId] = useState(-1);

    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [feedbackAnswer, setFeedbackAnswer] = useState(null);
    const [feedbackQuestion, setFeedbackQuestion] = useState(null);
    const [selectedCondition, setSelectedCondition] = useState(null);
    const [surveyInitialValue, setSurveyInitialValue] = useState(null);
    const [showFeedbackSettings, setShowFeedbackSettings] = useState(false);

    const [sectionQuestions, setSectionQuestions] = useState([]);

    const changeLanguage = (lang, i18nLang) => {
        setLang(lang);
        AppService.changeLanguageHandler(i18nLang);
    };

    const resetLanguageToDefault = () => {
        setLang("EU");
        AppService.changeLanguageHandler("en");
    };

    const addQuestion = () => {
        addSurveyItem(AppService.questionType.YES_NO);
    };

    const addQuestionToGroup = () => {
        addSurveyItemToGroup(AppService.questionType.YES_NO);
    };

    const addNote = () => {
        addSurveyItem(AppService.questionType.NOTE);
    };

    const addNoteToGroup = () => {
        addSurveyItemToGroup(AppService.questionType.NOTE);
    };

    const addGroup = () => {
        const newGroup = new Group("");
        AppService.selectedApp.sections[selectedSectionIndex].questions.push(newGroup);
        const selectedQuestionIndex = AppService.selectedApp.sections[selectedSectionIndex].questions.length - 1;
        setSelectedQuestion(newGroup);
        setSelectedQuestionIndex(selectedQuestionIndex);
    };

    const addDynamicGroup = () => {
        const newGroup = new DynamicGroup("");
        AppService.selectedApp.sections[selectedSectionIndex].questions.push(newGroup);
        const selectedQuestionIndex = AppService.selectedApp.sections[selectedSectionIndex].questions.length - 1;
        setSelectedQuestion(newGroup);
        setSelectedQuestionIndex(selectedQuestionIndex);
    };

    const addSurveyItem = (type, text = "") => {
        const newQuestion = new Question(type, text, "");
        newQuestion.sequenceId = uuid();
        AppService.selectedApp.sections[selectedSectionIndex].questions.push(newQuestion);
        setSelectedQuestion(newQuestion);
        const selectedQuestionIndex = AppService.selectedApp.sections[selectedSectionIndex].questions.length - 1;
        setSelectedQuestionIndex(selectedQuestionIndex);
        setShowFeedbackSettings(false);
        saveSurvey();

        return newQuestion;
    };
    const addSurveyItemToGroup = (type) => {
        const question = selectedQuestion;
        const newQuestion = new Question(type, "", "");

        newQuestion.groupId = question.sequenceId;
        newQuestion.sequenceId = uuid();

        if (question.type === AppService.questionType.GROUP || question.type === AppService.questionType.DYNAMIC_GROUP) {
            question.questions.push(newQuestion);
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
            selectQuestionFromGroup(newQuestion, question.questions.length - 1);
        }
        saveSurvey();
    };

    const saveSurveyOnInputLeave = () => {
        saveSurvey();
    };

    const changeSection = (section, subIndex) => {
        const sectionIndex = AppService.selectedApp.subSurveys[subIndex].sections.findIndex((s) => s.sequenceId === section.sequenceId);
        if (sectionIndex !== -1) {
            setSelectedQuestion(null);
            setSelectedQuestionIndex(-1);
            setSelectedSectionIndex(sectionIndex);
        }
    };
    const changeSubSurvey = (index) => {
        AppService.selectedApp.allSubSurveySections[selectedSubSurvey] = AppService.selectedApp.subSurveys[selectedSubSurvey].sections;
        if (index !== selectedSubSurvey) {
            setSelectedSectionIndex(0);
            setSelectedSubSurvey(index);
            setSelectedQuestion(null);
            setSelectedQuestionIndex(-1);
        }
    };

    const saveSurvey = async (newQuestion, callback) => {
        const body = BuilderSurveyService.prepareSurveyBody(selectedSubSurvey);
        return await AppService.getAxios()
            .put("/api/survey", body)
            .then((response) => {
                if (AppService.removingLanguages) {
                    AppService.removingLanguages = false;
                    setStateAfterSave(response);
                } else {
                    setStateAfterSave(response, newQuestion);
                    setSurveyInitialValue(JSON.stringify(AppService.selectedApp));
                }
                if (callback) {
                    callback();
                }
            })
            .catch((e) => {
                if (callback) {
                    callback();
                }
                console.log(e);
            });
    };

    const setStateAfterSave = (response, newQuestion, startPosition = false) => {
        const parsedApp = JSON.parse(response.data.jsonStructure);
        const allSubSurveySections = parsedApp.subSurveys.map((item) => {
            return [...item.sections];
        });
        AppService.selectedApp = JSON.parse(response.data.jsonStructure);
        AppService.selectedApp.sections = allSubSurveySections[selectedSubSurvey];
        AppService.selectedApp.allSubSurveySections = allSubSurveySections;
        AppService.selectedApp.id = response.data.id;
        AppService.selectedApp.surveyId = response.data.surveyId;
        AppService.selectedApp.version = response.data.version;
        AppService.selectedApp.status = response.data.status;
        AppService.selectedApp.active = response.data.active;
        if (newQuestion || newQuestion === null) {
            setSelectedQuestion(newQuestion);
        }
        if (startPosition) {
            setSelectedSubSurvey(0);
            setSelectedSectionIndex(0);
        }
        setSurveyInitialValue(JSON.stringify(AppService.selectedApp));
    };

    const onTypeChanged = (type) => {
        const question = selectedQuestion;
        if (question.groupId) {
            question.type = type;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            question.type = type;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        setSelectedQuestion(cloneDeep(selectedQuestion));
        saveSurvey();
    };
    const onNoAnswerScoreChange = (score) => {
        const question = selectedQuestion;
        if (question.groupId) {
            question.noAnswerScore = score;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[
                this.state.selectedGroupQuestionIndex
            ] = question;
        } else {
            question.noAnswerScore = score;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        setSelectedQuestion(question);
        saveSurvey();
    };

    const onNoteContentChanged = (text) => {
        const question = selectedQuestion;
        if (question.groupId) {
            question.noteContent = text;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            question.noteContent = text;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        setSelectedQuestion(cloneDeep(selectedQuestion));
    };

    const onHintChanged = (text) => {
        const question = BuilderSurveyService.onHintChanged(
            text,
            selectedQuestion,
            selectedSectionIndex,
            selectedQuestionIndex,
            selectedGroupQuestionIndex
        );

        setSelectedQuestion(cloneDeep(question));
        saveSurvey();
    };

    const onConditionsChanged = (conditions) => {
        const question = selectedQuestion;
        if (question.groupId) {
            question.conditions = conditions;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            question.conditions = conditions;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        setSelectedQuestion(cloneDeep(selectedQuestion));
    };

    const onConditionAdded = (condition) => {
        const question = selectedQuestion;
        if (question.groupId) {
            question.conditions.push(condition);
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            question.conditions.push(condition);
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        setSelectedQuestion(cloneDeep(question));
        saveSurvey();
    };

    const onQuestionTextChanged = (text) => {
        const question = BuilderSurveyService.onQuestionTextChanged(
            text,
            selectedQuestion,
            selectedSectionIndex,
            selectedQuestionIndex,
            selectedGroupQuestionIndex
        );
        setSelectedQuestion(cloneDeep(question));
    };

    const onOptionalQuestionChanged = (optional) => {
        const question = BuilderSurveyService.onOptionalQuestionChanged(
            optional,
            selectedQuestion,
            selectedSectionIndex,
            selectedQuestionIndex,
            selectedGroupQuestionIndex
        );
        setSelectedQuestion(question);
        saveSurvey();
    };
    const onAnswersChanged = (answers) => {
        const question = BuilderSurveyService.onAnswersChanged(
            answers,
            selectedQuestion,
            selectedSectionIndex,
            selectedQuestionIndex,
            selectedGroupQuestionIndex
        );
        setSelectedQuestion(cloneDeep(question));
    };

    const changeFeedbackText = (value) => {
        const question = BuilderSurveyService.changeFeedbackText(
            feedbackQuestion,
            feedbackAnswer,
            value,
            selectedSectionIndex,
            selectedQuestionIndex,
            selectedGroupQuestionIndex
        );
        setSelectedQuestion(cloneDeep(question));
    };
    const changeFeedbackTitle = (value) => {
        const question = BuilderSurveyService.changeFeedbackTitle(
            feedbackQuestion,
            feedbackAnswer,
            value,
            selectedSectionIndex,
            selectedQuestionIndex,
            selectedGroupQuestionIndex
        );
        setSelectedQuestion(cloneDeep(question));
        setShowFeedbackSettings(true);
    };
    const onAnswerRemove = () => {
        saveSurvey(selectedQuestion);
    };

    const salesForceItemChanged = (item, changedQuestion) => {
        const question = BuilderSurveyService.salesForceItemChanged(
            item,
            changedQuestion || selectedQuestion,
            selectedSectionIndex,
            selectedQuestionIndex,
            selectedGroupQuestionIndex
        );
        setStaticAnswersDueToSFDC(question);
        setSelectedQuestion(cloneDeep(question));
        saveSurvey();
    };
    const showFeedback = (question, answer) => {
        selectQuestion(question.sequenceId);
        setShowFeedbackSettings(true);
        setFeedbackAnswer(answer);
        setFeedbackQuestion(question);
    };

    const selectQuestion = (id) => {
        const selectedSection = AppService.selectedApp.sections[selectedSectionIndex];
        const selectedQuestionIndex = selectedSection.questions.findIndex((q) => q.sequenceId === id);
        if (selectedQuestionIndex !== -1) {
            const selectedQuestion = selectedSection.questions[selectedQuestionIndex];
            setSelectedQuestion(selectedQuestion);
            setSelectedQuestionIndex(selectedQuestionIndex);
            setShowFeedbackSettings(false);
            saveSurvey();
        }
        if (selectedSection.questions.length === 0) {
            setSelectedQuestion(null);
            setSelectedQuestionIndex(-1);
            saveSurvey(null);
        }
    };

    const selectQuestionWithoutSaving = (id) => {
        const selectedSection = AppService.selectedApp.sections[selectedSectionIndex];
        const selectedQuestionIndex = selectedSection.questions.findIndex((q) => q.sequenceId === id);
        if (selectedQuestionIndex !== -1) {
            const selectedQuestion = selectedSection.questions[selectedQuestionIndex];
            setSelectedQuestion(selectedQuestion);
            setSelectedQuestionIndex(selectedQuestionIndex);
            setShowFeedbackSettings(false);
        }
        if (selectedSection.questions.length === 0) {
            setSelectedQuestion(null);
            setSelectedQuestionIndex(-1);
        }
    };

    const selectQuestionFromGroup = (question, index) => {
        const groupIndex = AppService.selectedApp.sections[selectedSectionIndex].questions.findIndex(
            (q) => q.sequenceId === question.groupId
        );
        setSelectedQuestion(question);
        setSelectedQuestionIndex(groupIndex);
        setSelectedGroupQuestionIndex(index);
        setShowFeedbackSettings(false);
    };
    const markAnswerForReview = (answerId) => {
        const question = BuilderSurveyService.markAnswerForReview(
            answerId,
            selectedQuestion,
            selectedSectionIndex,
            selectedQuestionIndex,
            selectedGroupQuestionIndex
        );
        onAnswersChanged(question.answers);
        setSelectedQuestion(cloneDeep(question));
    };

    const makeAnswerDisqualified = (answerId, type) => {
        const question = BuilderSurveyService.makeAnswerDisqualified(
            answerId,
            type,
            selectedQuestion,
            selectedSectionIndex,
            selectedQuestionIndex,
            selectedGroupQuestionIndex
        );
        setSelectedQuestion(cloneDeep(question));
        saveSurvey();
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        setSectionQuestions(replaceDraggedItems(sectionQuestions, result));
        AppService.selectedApp.sections[selectedSectionIndex].questions = replaceDraggedItems(
            AppService.selectedApp.sections[selectedSectionIndex].questions,
            result
        );
        BuilderSurveyService.sortQuestionList(selectedSectionIndex);
        saveSurvey();
    };
    const replaceDraggedItems = (items, result) => {
        const copyItems = cloneDeep(items);
        const [reorderedItem] = copyItems.splice(result.source.index - 1, 1);
        copyItems.splice(result.destination.index - 1, 0, reorderedItem);
        if (reorderedItem?.groupId) {
            setSelectedQuestion(reorderedItem);
            setSelectedGroupQuestionIndex(result.destination.index - 1);
        } else {
            setSelectedQuestion(reorderedItem);
            setSelectedQuestionIndex(result.destination.index - 1);
        }
        return copyItems;
    };

    const removeQuestionFromGroup = (groupId, sequenceId) => {
        const group = AppService.selectedApp.sections[selectedSectionIndex].questions.filter((gQ) => gQ.sequenceId === groupId)[0];
        const questionToRemove = group.questions.filter((q) => q.sequenceId === sequenceId)[0];
        group.questions.splice(group.questions.indexOf(questionToRemove), 1);
        if (group.questions.length > 0) {
            setSelectedGroupQuestionIndex(group.questions.length - 1);
            saveSurvey(group.questions[group.questions.length - 1]);
        } else {
            setSelectedGroupQuestionIndex(-1);
            saveSurvey(group);
        }
    };

    const surrogateDemoQuestions = () => {
        if (AppService.selectedApp.subSurveys[selectedSubSurvey].show !== "As a demo") {
            return;
        }
        setSelectedSectionIndex(0);
        setSelectedSubSurvey(0);
        for (let i = 0; i < 4; i++) {
            setSelectedQuestionIndex(i);
            buildDemoQuestions(i);
        }
        saveSurvey();
    };

    const buildDemoQuestions = (questionIndex) => {
        let newAnswer = new Answer("", "", "");
        let addedQuestion = null;
        switch (questionIndex) {
            case 0: {
                addedQuestion = addSurveyItem(AppService.questionType.TEXT, "First Name");
                newAnswer.sequenceId = uuid();
                addedQuestion.answers.push(newAnswer);
                salesForceItemChanged(AppService.salesForceFields.FIRST_NAME, addedQuestion);
                break;
            }
            case 1:
                addedQuestion = addSurveyItem(AppService.questionType.TEXT, "Last Name");
                newAnswer.sequenceId = uuid();
                addedQuestion.answers.push(newAnswer);
                salesForceItemChanged(AppService.salesForceFields.LAST_NAME, addedQuestion);
                break;
            case 2:
                addedQuestion = addSurveyItem(AppService.questionType.TEXT, "Email");
                newAnswer.sequenceId = uuid();
                addedQuestion.answers.push(newAnswer);
                salesForceItemChanged(AppService.salesForceFields.EMAIL, addedQuestion);
                break;
            case 3:
                addedQuestion = addSurveyItem(AppService.questionType.PHONE, "Phone");
                newAnswer.sequenceId = uuid();
                addedQuestion.answers.push(newAnswer);
                salesForceItemChanged(AppService.salesForceFields.PHONE, addedQuestion);
                break;
        }
    };

    return (
        <SurveyBuilderContext.Provider
            value={{
                lang,
                selectedQuestion,
                setSelectedQuestion,
                feedbackAnswer,
                feedbackQuestion,
                selectedCondition,
                setSelectedCondition,
                surveyInitialValue,
                setSurveyInitialValue,
                selectedAnswerId,
                setSelectedAnswerId,
                selectedGroupQuestionIndex,
                setSelectedGroupQuestionIndex,
                selectedQuestionIndex,
                setSelectedQuestionIndex,
                selectedSubSurvey,
                setSelectedSubSurvey,
                selectedSectionIndex,
                setSelectedSectionIndex,
                showFeedbackSettings,
                markAnswerForReview,
                makeAnswerDisqualified,
                addSurveyItem,
                addGroup,
                addDynamicGroup,
                addSurveyItemToGroup,
                addQuestion,
                addNote,
                addQuestionToGroup,
                addNoteToGroup,
                changeSection,
                changeSubSurvey,
                setStateAfterSave,
                saveSurvey,
                onTypeChanged,
                onNoteContentChanged,
                onHintChanged,
                onConditionsChanged,
                onConditionAdded,
                onQuestionTextChanged,
                onOptionalQuestionChanged,
                onAnswersChanged,
                changeFeedbackText,
                changeFeedbackTitle,
                onAnswerRemove,
                salesForceItemChanged,
                showFeedback,
                selectQuestion,
                selectQuestionFromGroup,
                sectionQuestions,
                setSectionQuestions,
                handleOnDragEnd,
                resetLanguageToDefault,
                changeLanguage,
                removeQuestionFromGroup,
                selectQuestionWithoutSaving,
                surrogateDemoQuestions,
                replaceDraggedItems,
                saveSurveyOnInputLeave,
                onNoAnswerScoreChange
            }}
        >
            {children}
        </SurveyBuilderContext.Provider>
    );
}
