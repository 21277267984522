import { useParams } from "react-router-dom";
import Layout from "../../components/layout/LayoutComponent";
import "./NewSurveyBuilderComponent.scss";
import NewSurveyBuilderAside from "./components/NewSurveyBuilderAside/NewSurveyBuilderAside";
import { NewSurveyBuilderProvider } from "./NewSurveyBuilderContext/NewSurveyBuilderContext";
import NewSurveyBuilderHeader from "./components/NewSurveyBuilderHeader/NewSurveyBuilderHeader";
import NewSurveyBuilderContent from "./components/NewSurveyBuilderContent/NewSurveyBuilderContent";
import NewSurveyBuilderEditor from "./components/NewSurveyBuilderEditor/NewSurveyBuilderEditor";

function NewSurveyBuilderComponent() {
    const { id } = useParams();

    return (
        <Layout>
            <NewSurveyBuilderProvider>
                <div className="survey-builder">
                    <div className="survey-builder-header">
                        <NewSurveyBuilderHeader />
                    </div>
                    <div className="survey-builder-body">
                        <div className="survey-builder-sidebar">
                            <NewSurveyBuilderAside surveyId={id} />
                        </div>
                        <div className="survey-builder-content">
                            <NewSurveyBuilderContent surveyId={id} />
                        </div>
                        <div className="survey-builder-editor">
                            <NewSurveyBuilderEditor />
                        </div>
                    </div>
                </div>
            </NewSurveyBuilderProvider>
        </Layout>
    );
}

export default NewSurveyBuilderComponent;
