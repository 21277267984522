import React, { useContext, useEffect, useState } from "react";
import "./QuestionTypeEdit.scss";
import BuilderDropDownComponent from "../../../../../components/builderDropDown/BuilderDropDownComponent";
import AppService from "../../../../../service/AppService";
import { NewSurveyBuilderContext } from "../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { getTypesConfig } from "./questionTypesConfig";
import QuestionIcon from "./QuestionIcon/QuestionIcon";

function QuestionTypeEdit({ isDynamicGroupQuestion }) {
    const { surveyData, selectedQuestion, updateQuestion } = useContext(NewSurveyBuilderContext);

    const [questionType, setQuestionType] = useState(selectedQuestion.type);
    const [listOpen, setListOpen] = useState(false);
    const [chosenGroupType, setChosenGroupType] = useState(null);
    const [isDisabled, setIsDisabled] = useState(surveyData?.status === "PUBLISH");

    useEffect(() => {
        setQuestionType(selectedQuestion.type);
    }, [selectedQuestion.type]);

    useEffect(() => {
        setIsDisabled(surveyData?.status === "PUBLISH");
    }, [surveyData?.status]);

    const typesConfig = getTypesConfig(isDynamicGroupQuestion);

    const isGroupTypeQuestion = (id) => {
        const groupTypes = [
            AppService.questionType.TEXT_GROUP,
            AppService.questionType.DROPDOWN_GROUP,
            AppService.questionType.DROPDOWNS_GROUP,
            AppService.questionType.UPLOAD_GROUP
        ];
        return groupTypes.includes(id);
    };

    const isQuestionTypeInGroup = (typeConfig, type) => typeConfig.id === type || typeConfig.items?.find((item) => item.id === type);

    const handleGroupListOpen = (typeConfig) => {
        if (isDisabled) {
            return;
        }
        if (!isGroupTypeQuestion(questionType) && !isGroupTypeQuestion(chosenGroupType)) {
            setListOpen(false);
        }
        setChosenGroupType(typeConfig.id);
        setListOpen(true);
    };

    const handleQuestionTypeChange = async (typeId) => {
        if (isDisabled) {
            return;
        }
        updateQuestion("type", typeId, true);
        setQuestionType(typeId);
        setChosenGroupType(typeId);
        setListOpen(false);
    };

    return (
        <div className="question-types-list">
            {typesConfig.map((typeConfig, i) => {
                const isActive = isQuestionTypeInGroup(typeConfig, questionType);
                if (isGroupTypeQuestion(typeConfig.id)) {
                    return (
                        <div
                            key={typeConfig.id}
                            className={isActive ? "text-box-wrapper active" : "text-box-wrapper"}
                            onClick={() => handleGroupListOpen(typeConfig)}
                        >
                            <QuestionIcon type={typeConfig} isActive={isActive} />
                            <BuilderDropDownComponent
                                listItems={typeConfig.items}
                                listOpen={listOpen && isQuestionTypeInGroup(typeConfig, chosenGroupType)}
                                onClickItem={handleQuestionTypeChange}
                                chosenItem={chosenGroupType || questionType}
                                showDropdown={setListOpen}
                            />
                        </div>
                    );
                } else {
                    return (
                        <QuestionIcon
                            key={typeConfig.id}
                            type={typeConfig}
                            isActive={isActive}
                            onClick={() => handleQuestionTypeChange(typeConfig.id)}
                        />
                    );
                }
            })}
        </div>
    );
}

export default QuestionTypeEdit;
