import React from "react";
import SimpleCheckBox from "../../../simpleCheckBox/SimpleCheckBoxComponent";
import { States } from "../../../../helper/Constants";

function AcceptanceCriteriaTab({ selectedStates, setSelectedStates, allowedBmi, allowedAge, setAllowedBmi, setAllowedAge }) {
    const selectAllStates = () => {
        if (selectedStates.length === States.length) {
            setSelectedStates([]);
        } else {
            const states = [];
            States.forEach((s) => states.push(s.name));
            setSelectedStates(states);
        }
    };
    const setSelectedState = (state, isSelected) => {
        let states = selectedStates;
        if (states.length > 0) {
            states = states.filter((s) => s !== state);
        }
        if (isSelected) {
            states.push(state);
        }
        setSelectedStates([...states]);
    };

    const getStates = () => {
        return States.map((state, i) => {
            const isSelected = selectedStates.includes(state.name);
            return <SimpleCheckBox onClick={setSelectedState} key={i} isSelected={isSelected} label={state.title} value={state.name} />;
        });
    };

    const toggleAllowedBmiDisqualification = () => {
        const bmi = allowedBmi
            ? undefined
            : {
                  from: undefined,
                  to: undefined
              };
        setAllowedBmi(bmi);
    };

    const toggleAllowedAgeDisqualification = () => {
        const age = allowedAge
            ? undefined
            : {
                  from: undefined,
                  to: undefined
              };
        setAllowedAge(age);
    };

    const handleAllowedAgeBoundsChange = ({ target }) => {
        if (allowedAge) {
            const value = target.value;
            if (target.name === "allowedAge.from") {
                allowedAge.from = value.length === 0 ? undefined : parseInt(value);
            }
            if (target.name === "allowedAge.to") {
                allowedAge.to = value.length === 0 ? undefined : parseInt(value);
            }
            setAllowedAge({ ...allowedAge });
        }
    };
    const handleAllowedBmiBoundsChange = ({ target }) => {
        if (allowedBmi) {
            const value = target.value;
            if (target.name === "allowedBmi.from") {
                allowedBmi.from = value.length === 0 ? undefined : limitToTwoDecimalPlacesForBMI(value);
            }
            if (target.name === "allowedBmi.to") {
                allowedBmi.to = value.length === 0 ? undefined : limitToTwoDecimalPlacesForBMI(value);
            }
            setAllowedBmi({ ...allowedBmi });
        }
    };

    const limitToTwoDecimalPlacesForBMI = (userInputValue) => {
        const floatInputValue = parseFloat(userInputValue);
        const stringInputValue = floatInputValue.toString();
        const dotIndex = stringInputValue.indexOf(".");

        return stringInputValue.substring(0, dotIndex + 2 + 1);
    };

    const totalStates = () => States.length;
    const selectedSatesTotal = () => selectedStates.length;

    return (
        <div className="content">
            <div className="selected-states">
                <>
                    <div className="sub-header">acceptance criteria</div>
                    <div className="controls-col">
                        <SimpleCheckBox onClick={toggleAllowedBmiDisqualification} label="BMI" isSelected={allowedBmi} />
                        <div className="simple-checkboxes-two-cols">
                            <input
                                type="number"
                                placeholder="0"
                                name="allowedBmi.from"
                                value={allowedBmi ? allowedBmi.from : ""}
                                onChange={handleAllowedBmiBoundsChange}
                                disabled={!allowedBmi}
                            />
                            <label>-</label>
                            <input
                                type="number"
                                placeholder="∞"
                                name="allowedBmi.to"
                                value={allowedBmi ? allowedBmi.to : ""}
                                onChange={handleAllowedBmiBoundsChange}
                                disabled={!allowedBmi}
                            />
                        </div>
                    </div>
                    <div className="controls-col">
                        <SimpleCheckBox onClick={toggleAllowedAgeDisqualification} label="Age" isSelected={allowedAge} />
                        <div className="simple-checkboxes-two-cols">
                            <input
                                type="number"
                                placeholder="0"
                                name="allowedAge.from"
                                value={allowedAge ? allowedAge.from : ""}
                                onChange={handleAllowedAgeBoundsChange}
                                disabled={!allowedAge}
                            />
                            <label>-</label>
                            <input
                                type="number"
                                placeholder="∞"
                                name="allowedAge.to"
                                value={allowedAge ? allowedAge.to : ""}
                                onChange={handleAllowedAgeBoundsChange}
                                disabled={!allowedAge}
                            />
                        </div>
                    </div>
                </>
                <div className="one-col">
                    <SimpleCheckBox
                        onClick={selectAllStates}
                        label={`Selected States ${selectedSatesTotal()}/${totalStates()}`}
                        isSelected={selectedStates.length === States.length}
                    />
                    <div className="simple-checkboxes-two-cols">{getStates()}</div>
                </div>
            </div>
        </div>
    );
}

export default AcceptanceCriteriaTab;
