import React, { useContext, useEffect, useState } from "react";
import "./FeedbackComponent.scss";
import AppService from "../../service/AppService";
import { LanguageService } from "../../service/LanguageService";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";

function FeedbackComponent() {
    const context = useContext(SurveyBuilderContext);

    const sectionName = AppService.selectedApp.sections[context.selectedSectionIndex].name;
    return (
        <div className="feedback">
            <div className="column-title">
                <span>Optional Feedback</span>
                <span></span>
            </div>
            <div className="question-info">
                <div className="section-name">Section: {LanguageService.getTranslatedText(sectionName, "EU")}</div>
                <div className="question-text">{LanguageService.getTranslatedText(context.feedbackQuestion.text, "EU")}</div>
                <div className="answer-title">Answer</div>
                <div className="answer-text">{LanguageService.getTranslatedText(context.feedbackAnswer.text, "EU")}</div>
            </div>
            <div className="form">
                <div className="field">
                    <label>Title</label>
                    <input
                        type="text"
                        placeholder="Enter title"
                        name="feedbackTitle"
                        onChange={(e) => context.changeFeedbackTitle(e.target.value)}
                        value={LanguageService.getTranslatedText(context.feedbackAnswer.feedback.title, "EU") || ""}
                    />
                </div>
                <div className="field">
                    <label>Optional feedback text</label>
                    <textarea
                        type="text"
                        placeholder="Enter text"
                        name="feedbackText"
                        onChange={(e) => context.changeFeedbackText(e.target.value)}
                        value={LanguageService.getTranslatedText(context.feedbackAnswer.feedback.text, "EU") || ""}
                    />
                </div>
            </div>
        </div>
    );
}
export default FeedbackComponent;
