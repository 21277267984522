import AppService from "../../service/AppService";
import { uuid } from "uuidv4";

export class Section {
    constructor(name) {
        this.sequenceId = "";
        this.name = AppService.selectedApp.internationalizationSupport ? { EU: name, ESP: "" } : name;
        this.questions = [];
    }
}

export class Answer {
    constructor(text, feedback, type) {
        this.sequenceId = uuid();
        this.text = AppService.selectedApp?.internationalizationSupport ? { EU: text, ESP: "" } : text;
        this.feedback = AppService.selectedApp?.internationalizationSupport
            ? {
                  title: {
                      EU: "",
                      ESP: ""
                  },
                  text: {
                      EU: "",
                      ESP: ""
                  }
              }
            : { title: "", text: "" };
        this.type = type;
        this.weight = "";
        this.hasVisibility = false;
        this.isReview = false;
        this.isDisqualify = false;
    }
}

export class Group {
    constructor(text) {
        this.type = AppService.questionType.GROUP;
        this.text = AppService.selectedApp?.internationalizationSupport ? { EU: text, ESP: "" } : text;
        this.questions = [];
        this.sequenceId = uuid();
        this.visibility = [];
    }
}
export class DynamicGroup {
    constructor(text) {
        this.type = AppService.questionType.DYNAMIC_GROUP;
        this.text = AppService.selectedApp?.internationalizationSupport ? { EU: text, ESP: "" } : text;
        this.questions = [];
        this.sequenceId = uuid();
        this.visibility = [];
        this.instanceGroupName = "";
        this.showInstanceCount = false;
        this.optional = true;
    }
}
export class Question {
    constructor(type, text, hint) {
        this.sequenceId = uuid();
        this.type = type;
        this.optional = false;
        this.text = AppService.selectedApp?.internationalizationSupport ? { EU: text, ESP: "" } : text;
        this.hint = AppService.selectedApp?.internationalizationSupport ? { EU: hint, ESP: "" } : hint;
        this.noteContent = AppService.selectedApp?.internationalizationSupport ? { EU: "", ESP: "" } : "";
        this.answers = [];
        this.visibility = [];
        this.conditions = [];
        this.noAnswerScore = 0;
    }
}

export class Condition {
    constructor() {
        this.sequenceId = uuid();
        this.isReview = false;
        this.isDisqualify = false;
        this.type = AppService.conditionTypes.SUM;
        this.value = "";
        this.feedback = "";
        this.visibilities = [];
    }
}

export default class Application {
    constructor(
        title,
        description,
        type,
        version,
        user,
        date,
        region,
        ageFrom,
        ageTo,
        states,
        allowedBmi,
        allowedAge,
        bmiScore,
        ageScore,
        subSurveys,
        internationalizationSupport
    ) {
        this.disqualificationRules = {
            states: []
        };
        this.title = title ?? "";
        this.description = description ?? "";
        this.type = type ?? "";
        this.version = version ?? "";
        this.user = user ?? "";
        this.date = date ?? "";
        this.region = region ?? "";
        this.ageFrom = ageFrom ?? "";
        this.ageTo = ageTo ?? "";
        this.disqualificationRules.states = states ?? [];
        this.disqualificationRules.allowedBmi = allowedBmi;
        this.disqualificationRules.allowedAge = allowedAge;
        this.bmiScore = bmiScore;
        this.ageScore = ageScore;
        this.subSurveys = subSurveys;
        this.internationalizationSupport = internationalizationSupport;
    }
}
