import React, { useContext } from "react";
import SubSurvey from "../../../subSurvey/SubSurvey";
import AppService from "../../../../service/AppService";
import { SurveyBuilderContext } from "../../../../SurveyBuilderContext";

function SubSurveyItems({ appType, subSurveys, setSubSurveys, sections, setSections, selectedSubSurvey, setSelectedSubSurvey, scrollTop }) {
    const context = useContext(SurveyBuilderContext);

    const deleteSubSurvey = (index) => {
        if (subSurveys[index].show === "As a demo") {
            return;
        }
        const shorterSubSurveys = subSurveys.filter((item, idx) => idx !== index);
        const shorterSection = sections.filter((item, idx) => idx !== index);
        const newSelectedSubSurvey = selectedSubSurvey === 0 ? 0 : index;
        if (shorterSubSurveys.length === 0) {
            setSections([]);
        }
        setSubSurveys(shorterSubSurveys);
        setSections(shorterSection);
        setSelectedSubSurvey(newSelectedSubSurvey);
    };

    const selectThisSubSurvey = (index) => {
        setSelectedSubSurvey(index);
    };

    const resetAnotherDemoSubSurveysToAlways = (doNotUpdateIndex) => {
        const updatedSubSurveys = subSurveys.map((subSurvey, index) => {
            if (subSurvey.show === "As a demo" && doNotUpdateIndex !== index) {
                return { ...subSurvey, show: "Always" };
            }
            return subSurvey;
        });
        setSubSurveys(updatedSubSurveys);
    };

    const onChangeSubSurveyTitle = (value, subSurvey, index) => {
        if (AppService.selectedApp?.internationalizationSupport) {
            subSurveys[index] = {
                ...subSurvey,
                name: {
                    ...subSurveys[index].name,
                    EU: value
                }
            };
        } else {
            subSurveys[index] = {
                ...subSurvey,
                name: value
            };
        }
        setSubSurveys([...subSurveys]);
    };

    const noOneSubSurveySelectedAsADemo = () => {
        let selected = true;
        subSurveys.forEach((survey) => {
            if (survey.show === "As a demo") {
                selected = false;
            }
        });
        return selected;
    };

    return subSurveys.map((subSurvey, index) => {
        return (
            <SubSurvey
                index={index}
                appType={appType}
                noOneSubSurveySelectedAsADemo={noOneSubSurveySelectedAsADemo()}
                subSurvey={subSurvey}
                key={`${index}_sub_survey`}
                scrollTop={scrollTop}
                previousSurveyName={subSurvey.show === "If not disqualified" ? subSurveys[index - 1]?.name : null}
                deleteSubSurvey={deleteSubSurvey}
                selected={index === selectedSubSurvey}
                selectThisSubSurvey={selectThisSubSurvey}
                resetAnotherDemoSubSurveysToAlways={resetAnotherDemoSubSurveysToAlways}
                changeShowValue={(value) => {
                    subSurveys[index] = {
                        ...subSurvey,
                        show: value
                    };
                    setSubSurveys([...subSurveys]);
                }}
                onChangeTitle={(e) => onChangeSubSurveyTitle(AppService.trimSpaces(e.target.value), subSurvey, index)}
                lang={context.lang}
            />
        );
    });
}

export default SubSurveyItems;
