import React from "react";
import classNames from "classnames";

function SelectButtonBasic({
    progressBarLong,
    progressBarStatus,
    selectFirstSectionLongBar,
    selectSecondSectionLongBar,
    selectThirdSectionLongBar,
    secondSectionDisabled,
    createButtonClassName,
    navigateToBuilder,
    saveButtonTitle
}) {
    switch (progressBarStatus.inProgress) {
        case progressBarLong.first:
            return (
                <button className="create-app-button" onClick={selectSecondSectionLongBar}>
                    Continue
                </button>
            );
        case progressBarLong.second:
            return (
                <>
                    <button className="create-app-button back" onClick={selectFirstSectionLongBar}>
                        Back
                    </button>
                    <button
                        className={classNames("create-app-button", { disabled: secondSectionDisabled })}
                        onClick={selectThirdSectionLongBar}
                        disabled={secondSectionDisabled}
                    >
                        Continue
                    </button>
                </>
            );
        case progressBarLong.third:
            return (
                <>
                    <button className="create-app-button back" onClick={selectSecondSectionLongBar}>
                        Back
                    </button>
                    <button className={createButtonClassName} onClick={navigateToBuilder}>
                        {saveButtonTitle}
                    </button>
                </>
            );
    }
}

export default SelectButtonBasic;
