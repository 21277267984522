import React from "react";
import "./CheckBoxComponent.scss";
import SettingsButtons from "../settingsButton/SettingsButtonComponent";
import { LanguageService } from "../../service/LanguageService";

function CheckBoxComponent(props) {
    const onChange = props.onClick ? props.onClick : () => {};

    const answers = props.answers.map((a, i) => {
        const selectedClassName = a.isChecked ? "checkbox-container checkbox-selected" : "checkbox-container";

        const answer =
            props.showSettings && props.showSettings === true ? (
                <SettingsButtons key={i} answer={a} hasVisibility={a.hasVisibility} onVisibilityClick={props.onVisibilityClick}>
                    <label className={selectedClassName} key={i}>
                        {LanguageService.getTranslatedText(a.text, props.lang)}
                        <input type="checkbox" checked={a.isChecked} name="checkbox" onChange={() => onChange(a)} />
                        <span className="checkmark"></span>
                    </label>
                </SettingsButtons>
            ) : (
                <label className={selectedClassName} key={i}>
                    {LanguageService.getTranslatedText(a.text, props.lang)}
                    <input type="checkbox" checked={a.isChecked} name="checkbox" onChange={() => onChange(a)} />
                    <span className="checkmark"></span>
                </label>
            );

        // if (i === 0) {
        //     answer = <label className={selectedClassName} key={i}>{a.text}
        //         <input type="checkbox" name="checkbox" checked={a.isChecked} onChange={() => onChange(a)} />
        //         <span className="checkmark"></span>
        //     </label>
        // }

        return answer;
    });
    return <div className="content">{answers}</div>;
}

export default CheckBoxComponent;
