import { useContext, useEffect, useState } from "react";
import { NewSurveyBuilderContext } from "../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { States } from "../../../../../../helper/Constants";
import { ReactComponent as Close } from "../../../../../../assets/images/close.svg";
import { ReactComponent as Saved } from "../../../../../../assets/images/check-icon.svg";
import classNames from "classnames";
import { NewSurveyBuilderService } from "../../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";

const CriteriaTabComponent = ({ criteriaRef, surveyId, onSave }) => {
    const { surveyData, setSurveyData } = useContext(NewSurveyBuilderContext);
    const [ageRanges, setAgeRanges] = useState({
        from: surveyData?.disqualificationRules?.allowedAge?.from,
        to: surveyData?.disqualificationRules?.allowedAge?.to
    });
    const [bmiRanges, setBmiRanges] = useState({
        from: surveyData?.disqualificationRules?.allowedBmi?.from,
        to: surveyData?.disqualificationRules?.allowedBmi?.to
    });
    const [selectedStates, setSelectedStates] = useState(surveyData?.disqualificationRules?.states);

    useEffect(() => {
        criteriaRef.current = { saveCriteria };
    }, [ageRanges, bmiRanges, selectedStates]);

    const saveCriteria = () => {
        const data = {
            states: selectedStates,
            allowedAge: ageRanges,
            allowedBmi: bmiRanges
        };

        const isBmiInCorrect = !!getErrorInput(data.allowedBmi.from, data.allowedBmi.to);
        const isAgeInCorrect = !!getErrorInput(data.allowedAge.from, data.allowedAge.to);

        if (isBmiInCorrect || isAgeInCorrect) {
            onSave(true);
            return;
        }

        surveyData.disqualificationRules.states = data.states;
        surveyData.disqualificationRules.allowedAge = data.allowedAge;
        surveyData.disqualificationRules.allowedBmi = data.allowedBmi;
        setSurveyData(surveyData);
        NewSurveyBuilderService.saveAcceptanceCriteria(surveyId, data)
            .then(() => {
                onSave(false);
            })
            .catch(() => onSave(true));
    };

    const getErrorInput = (from, to) => {
        if (Number(from) > Number(to)) {
            return "error-input";
        }
    };

    const onRangeChange = (event, destination) => {
        event.persist();
        const { target } = event;
        const option = target.name;
        switch (destination) {
            case "age":
                if (option === "to") {
                    setAgeRanges({ ...ageRanges, to: target.value });
                } else {
                    setAgeRanges({ ...ageRanges, from: target.value });
                }
                break;
            case "bmi":
                if (option === "to") {
                    setBmiRanges({ ...bmiRanges, to: target.value });
                } else {
                    setBmiRanges({ ...bmiRanges, from: target.value });
                }
                break;
        }
    };

    const clearData = (destination) => {
        switch (destination) {
            case "age":
                setAgeRanges({ from: "", to: "" });
                break;
            case "bmi":
                setBmiRanges({ from: "", to: "" });
        }
    };

    const isStateSelected = (name) => {
        return selectedStates.find((s) => s === name) !== undefined;
    };

    const getStateClasses = (name) => {
        return classNames("state-item", {
            "selected-state": isStateSelected(name)
        });
    };

    const stateSelection = (name) => {
        if (isStateSelected(name)) {
            setSelectedStates((prevStates) => prevStates.filter((s) => s !== name));
        } else {
            setSelectedStates((prevStates) => [...prevStates, name]);
        }
    };

    const fullSelection = () => {
        if (selectedStates.length === 51) {
            setSelectedStates([]);
        } else {
            setSelectedStates(States.map((s) => s.name));
        }
    };

    return (
        <div className="acceptance-tab-body">
            <div className="ranges">
                <label>BMI Criteria:</label>
                <input
                    className={getErrorInput(bmiRanges.from, bmiRanges.to)}
                    type="number"
                    value={bmiRanges.from}
                    name="from"
                    onChange={(e) => onRangeChange(e, "bmi")}
                    min="0"
                    placeholder="0"
                />
                <span>-</span>
                <input
                    className={getErrorInput(bmiRanges.from, bmiRanges.to)}
                    type="number"
                    value={bmiRanges.to}
                    name="to"
                    onChange={(e) => onRangeChange(e, "bmi")}
                    min="0"
                    placeholder="&#8734;"
                />
                <div className="clear-ranges">
                    <Close onClick={() => clearData("bmi")} />
                </div>
            </div>
            <div className="ranges">
                <label>Age Criteria:</label>
                <input
                    className={getErrorInput(ageRanges.from, ageRanges.to)}
                    type="number"
                    value={ageRanges.from}
                    onChange={(e) => onRangeChange(e, "age")}
                    name="from"
                    min="0"
                    placeholder="0"
                />
                <span>-</span>
                <input
                    className={getErrorInput(ageRanges.from, ageRanges.to)}
                    type="number"
                    value={ageRanges.to}
                    name="to"
                    onChange={(e) => onRangeChange(e, "age")}
                    min="0"
                    placeholder="&#8734;"
                />
                <div className="clear-ranges">
                    <Close onClick={() => clearData("age")} />
                </div>
            </div>
            <div className="states-header">
                <label>States:</label>
                <button onClick={fullSelection}>{selectedStates.length === 51 ? "Clear all" : "Select all"}</button>
            </div>

            <div className="states">
                {States.map((s) => {
                    return (
                        <div key={s.title} className={getStateClasses(s.name)} onClick={() => stateSelection(s.name)}>
                            <Saved />
                            <span>{s.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default CriteriaTabComponent;
