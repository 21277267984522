import React, { useContext, useState } from "react";
import { NewSurveyBuilderContext } from "../../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";

import disqSectionGrey from "../../../../../../../assets/images/disq-section-grey.svg";
import disqGrey from "../../../../../../../assets/images/disq-grey.svg";
import close from "../../../../../../../assets/images/close-icon.svg";
import eye from "../../../../../../../assets/images/eye.svg";
import eyeDisabled from "../../../../../../../assets/images/eye-disabled.svg";
import review from "../../../../../../../assets/images/review-blue.svg";
import warningDisabled from "../../../../../../../assets/images/warning-disabled.svg";
import squireDisabled from "../../../../../../../assets/images/disq-disabled.svg";
import disq from "../../../../../../../assets/images/disq-blue.svg";
import disqSectionBlue from "../../../../../../../assets/images/disq-section-blue.svg";
import TooltipIcon from "../../../../../../../components/TooltipIcon/TooltipIcon";
import VisibilityPopup from "../VisibilityPopup/VisibilityPopup";
import { NewSurveyBuilderService } from "../../../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";

function AnswerControls({ answer, isDynamicGroupQuestion, isNumberQuestion, showOnlyRemove, isDisabled }) {
    const { selectedQuestion, updateQuestion, updateAnswer } = useContext(NewSurveyBuilderContext);
    const [showDisqPopup, setShowDisqPopup] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === answer.sequenceId);
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const removeAnswer = () => {
        const updatedAnswers = selectedQuestion.answers.filter((a) => a.sequenceId !== answer.sequenceId);
        updateQuestion("answers", updatedAnswers, true);
        const items = updatedAnswers.map((a) => a.text);
        if (selectedQuestion?.jsonRelation?.relationId) {
            NewSurveyBuilderService.updateRelationItems(selectedQuestion?.jsonRelation?.relationId, items);
        }
    };
    const markAnswerForReview = () => {
        updateAnswer("isReview", !selectedQuestion.answers[answerIndex].isReview, answerIndex, true);
    };
    const markDisqualification = (type) => {
        const isDisqualify = selectedQuestion.answers[answerIndex].disqType !== type;

        updateAnswer("isDisqualify", isDisqualify, answerIndex, false);
        updateAnswer("disqType", isDisqualify && type, answerIndex, true);
        setShowDisqPopup(false);
    };

    const getDisqualificationImage = () => {
        if (answer.isDisqualify) {
            switch (answer.disqType) {
                case "app":
                    return disq;
                case "section":
                    return disqSectionBlue;
                default:
                    return squireDisabled;
            }
        }
        return squireDisabled;
    };

    if (showOnlyRemove) {
        return (
            <TooltipIcon
                id="remove-answer-tooltip"
                tooltipText="Remove Answer"
                imgSrc={close}
                additionalClass="close-image"
                onClick={removeAnswer}
                alt="remove-answer"
                isDisabled={isDisabled}
            />
        );
    }

    return (
        <>
            <div className="answer-icons-container">
                <TooltipIcon
                    id="visibility-tooltip"
                    tooltipText="Visibility"
                    imgSrc={answer.hasVisibility ? eye : eyeDisabled}
                    additionalClass="eye-icon"
                    alt="eye-icon"
                    onClick={openModal}
                    isDisabled={isDisabled}
                />
                {isNumberQuestion && <label>Branching</label>}
                {!isDynamicGroupQuestion && (
                    <>
                        <TooltipIcon
                            id="review-tooltip"
                            tooltipText="Review"
                            imgSrc={answer.isReview ? review : warningDisabled}
                            additionalClass="review-icon"
                            onClick={markAnswerForReview}
                            alt="review-icon"
                            isDisabled={isDisabled}
                        />
                        {isNumberQuestion && <label>Review</label>}
                        <TooltipIcon
                            id="disq-tooltip"
                            tooltipText="Disqualification"
                            imgSrc={getDisqualificationImage()}
                            additionalClass="disq-icon"
                            onClick={() => setShowDisqPopup((prev) => !prev)}
                            alt="disq-icon"
                            isDisabled={isDisabled}
                        />
                        {isNumberQuestion && <label>Disqualify</label>}
                    </>
                )}

                {!isNumberQuestion && (
                    <TooltipIcon
                        id="remove-answer-tooltip"
                        tooltipText="Remove Answer"
                        imgSrc={close}
                        additionalClass="close-image"
                        onClick={removeAnswer}
                        alt="remove-answer"
                        isDisabled={isDisabled}
                    />
                )}
                {showDisqPopup && (
                    <div className="disq-popup">
                        <ul>
                            <li onClick={(e) => markDisqualification("section")}>
                                <img src={disqSectionGrey} alt="" />
                                Section disqualification
                            </li>
                            <li onClick={(e) => markDisqualification("app")}>
                                <img src={disqGrey} alt="" />
                                Survey disqualification
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            {modalIsOpen && <VisibilityPopup closeModal={closeModal} answer={answer} answerIndex={answerIndex} />}
        </>
    );
}

export default AnswerControls;
