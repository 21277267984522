import React from "react";
import { IMaskInput } from "react-imask";
import "./NumericRangeComponent.scss";
import classNames from "classnames";

export default class NumericRangeComponent extends React.Component {
    inclusionTypes = {
        UPPER: "upperBoundary",
        LOWER: "lowerBoundary"
    };

    constructor(props) {
        super(props);

        if (props.range) {
            this.state = {
                upperBoundary: props.range.upperBoundary,
                lowerBoundary: props.range.lowerBoundary
            };
        } else {
            this.state = {};
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.range) {
            this.setState({
                upperBoundary: nextProps.range.upperBoundary,
                lowerBoundary: nextProps.range.lowerBoundary
            });
        }
    }

    handleInclusion = (inclusionType) => {
        this.setState((prevState) => {
            if (inclusionType === this.inclusionTypes.UPPER && prevState.upperBoundary && prevState.upperBoundary.value !== undefined) {
                prevState.upperBoundary.inclusive = !prevState.upperBoundary.inclusive;
            } else if (
                inclusionType === this.inclusionTypes.LOWER &&
                prevState.lowerBoundary &&
                prevState.lowerBoundary.value !== undefined
            ) {
                prevState.lowerBoundary.inclusive = !prevState.lowerBoundary.inclusive;
            }

            return prevState;
        });
        if (this.state.upperBoundary || this.state.lowerBoundary) {
            this.props.onRangeChange(this.props.answer.sequenceId, {
                upperBoundary: this.state.upperBoundary,
                lowerBoundary: this.state.lowerBoundary
            });
        } else {
            this.props.onRangeChange(this.props.answer.sequenceId, undefined);
        }
    };

    updateValue = (value, inclusive, inclusionType) => {
        this.setState(
            (prevState) => {
                if (value.trim() === "") {
                    prevState[inclusionType] = undefined;
                } else {
                    prevState[inclusionType] = {
                        value: value,
                        inclusive: inclusive
                    };
                }

                return prevState;
            },
            () => {
                if (this.state.upperBoundary || this.state.lowerBoundary) {
                    this.props.onRangeChange(this.props.answer.sequenceId, {
                        upperBoundary: this.state.upperBoundary,
                        lowerBoundary: this.state.lowerBoundary
                    });
                } else {
                    this.props.onRangeChange(this.props.answer.sequenceId, undefined);
                }
            }
        );
    };

    render() {
        const lowerBoundary = this.props.range ? this.state.lowerBoundary : {};
        const lowerBoundaryHasValue = lowerBoundary !== undefined && lowerBoundary.value !== undefined && lowerBoundary.value !== "";
        const lowerBoundaryInclusive = lowerBoundary !== undefined && lowerBoundary.inclusive === true;
        const lowerValue = lowerBoundaryHasValue ? lowerBoundary.value.toString() : "";

        const upperBoundary = this.props.range ? this.state.upperBoundary : {};
        const upperBoundaryHasValue = upperBoundary !== undefined && upperBoundary.value !== undefined && upperBoundary.value.length !== "";
        const upperBoundaryInclusive = upperBoundary !== undefined && upperBoundary.inclusive === true;
        const upperValue = upperBoundaryHasValue ? upperBoundary.value.toString() : "";

        const invalidLower = lowerBoundaryHasValue && parseInt(lowerValue) >= parseInt(upperValue);
        const invalidUpper = upperBoundaryHasValue && parseInt(upperValue) <= parseInt(lowerValue);

        const rangeHint = `${lowerValue ? lowerValue : "-∞"} ${lowerBoundaryInclusive ? "≤" : "<"} Result Value ${
            upperBoundaryInclusive ? "≤" : "<"
        } ${upperValue ? upperValue : "∞"}`;

        const disabled = this.props.disabled;
        const hintTitleClass = disabled ? "text-disabled" : "";
        const hintClass = disabled ? "text-disabled" : "hint-text";

        return (
            <div className="numeric-range-container">
                <div className="hint-container">
                    <label className={hintTitleClass}>{this.props.rangeHintTitle}</label>
                    <label className={hintClass}>{rangeHint}</label>
                </div>
                <div className="range-group">
                    <div className="range-inputs">
                        {disabled ? (
                            <input type="text" disabled className="disabled-input" value={lowerValue} placeholder="From" />
                        ) : (
                            <IMaskInput
                                className={classNames({ "invalid-input": invalidLower })}
                                mask={Number}
                                signed={true}
                                scale={0}
                                thousandsSeparator={""}
                                padFractionalZeros={false}
                                value={lowerValue}
                                onAccept={(value) => {
                                    const trimmedValue = value.trim();
                                    if (trimmedValue !== "-" && trimmedValue !== "+") {
                                        this.updateValue(trimmedValue, upperBoundaryInclusive, this.inclusionTypes.LOWER);
                                    }
                                }}
                                placeholder={"From"}
                            />
                        )}
                        <label className={hintTitleClass}>
                            <input
                                id="lower-inclusion"
                                type="checkbox"
                                disabled={disabled}
                                onChange={() => this.handleInclusion(this.inclusionTypes.LOWER)}
                                checked={lowerBoundaryInclusive}
                            />
                            Inclusive
                        </label>
                    </div>
                    <p className="dash">&nbsp;-&nbsp;</p>
                    <div className="range-inputs">
                        {disabled ? (
                            <input type="text" disabled className="disabled-input" value={upperValue} placeholder="To" />
                        ) : (
                            <IMaskInput
                                className={classNames({ "invalid-input": invalidUpper })}
                                mask={Number}
                                signed={true}
                                scale={0}
                                thousandsSeparator={""}
                                padFractionalZeros={false}
                                value={upperValue}
                                onAccept={(value) => {
                                    const trimmedValue = value.trim();
                                    if (trimmedValue !== "-" && trimmedValue !== "+") {
                                        this.updateValue(trimmedValue, upperBoundaryInclusive, this.inclusionTypes.UPPER);
                                    }
                                }}
                                placeholder={"To"}
                            />
                        )}
                        <label className={hintTitleClass}>
                            <input
                                id="upper-inclusion"
                                type="checkbox"
                                disabled={disabled}
                                onChange={() => this.handleInclusion(this.inclusionTypes.UPPER)}
                                checked={upperBoundaryInclusive}
                            />
                            Inclusive
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}
