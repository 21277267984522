import React from "react";
import AppService from "../../service/AppService";
import { States } from "../../helper/Constants";

function StatesInfo() {
    if (AppService.selectedApp.disqualificationRules.states.length > 0) {
        return (
            <div className="acceptance-criteria-line">
                States: {AppService.selectedApp.disqualificationRules.states.length}/{States.length}
            </div>
        );
    }
    return null;
}

export default StatesInfo;
