import ReactTooltip from "react-tooltip";
import classNames from "classnames";

function TooltipIcon({ id, tooltipText, imgSrc, additionalClass = "", alt, onClick, isActive = false, isDisabled = false }) {
    const activeClass = isActive ? "active" : "";

    return (
        <button data-tip data-for={id} className={classNames(activeClass, additionalClass)} disabled={isDisabled}>
            <img src={imgSrc} alt={alt} onClick={onClick} />
            <ReactTooltip id={id} className="tooltip">
                <p>{tooltipText}</p>
            </ReactTooltip>
        </button>
    );
}

export default TooltipIcon;
