import React from "react";
import DropDown from "../../../dropDowns/dropDown/DropDownComponent";
import AppService from "../../../../service/AppService";

function InfoTab({ appType, appTypeChanged, title, setTitle, description, setDescription }) {
    const handleChange = ({ target }) => {
        switch (target.name) {
            case "title":
                setTitle(AppService.trimSpaces(target.value));
                break;
            case "description":
                setDescription(AppService.trimSpaces(target.value));
                break;
        }
    };

    return (
        <div className="content">
            <div>
                <div className="sub-header">info</div>
                <div className="group-col">
                    <div className="one-col app-type">
                        <DropDown
                            label="Application Type"
                            isDisabled={!!AppService.selectedApp}
                            required={true}
                            selectedItem={appType}
                            placeholder="Select type"
                            items={AppService.surveyTypes}
                            onChange={appTypeChanged}
                        />
                    </div>
                    <div className="one-col">
                        <label>
                            Title <span className="required">*</span>
                        </label>
                        <input type="text" placeholder="Name your survey" name="title" value={title} onChange={handleChange} />
                    </div>
                </div>
                <div className="one-col app-description">
                    <label>
                        Description <span className="required">*</span>
                    </label>
                    <input type="text" placeholder="Describe this survey" name="description" value={description} onChange={handleChange} />
                </div>
            </div>
        </div>
    );
}

export default InfoTab;
