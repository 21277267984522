import "./RelationDialogComponent.scss";
import { ReactComponent as Close } from "../../../../../../assets/images/close.svg";
import { ReactComponent as Selected } from "../../../../../../assets/images/check-icon.svg";

import { useState } from "react";
import classNames from "classnames";

const RelationDialogComponent = ({ currentSequence, source, saveRelation, closeDialog }) => {
    const [selectedRelatedQuestion, setSelectedRelatedQuestion] = useState(null);

    const getClassnames = (q) => {
        return classNames("relation-question", {
            "relation-selected": q?.sequenceId === selectedRelatedQuestion?.sequenceId
        });
    };

    const selectQuestion = (q) => {
        if (selectedRelatedQuestion?.sequenceId === q.sequenceId) {
            setSelectedRelatedQuestion(null);
        } else {
            setSelectedRelatedQuestion(q);
        }
    };

    return (
        <div className="relation-dialog-body">
            <div className="relation-dialog">
                <div className="relation-dialog-header">
                    <h1>Select related question</h1>
                    <Close onClick={() => closeDialog()} />
                </div>
                <div className="relation-dialog-content">
                    {source?.questions
                        ?.filter((q) => q.type !== "GROUP" && q.sequenceId !== currentSequence)
                        .map((q, index) => {
                            return (
                                <div key={q.sequenceId} className={getClassnames(q)} onClick={() => selectQuestion(q)}>
                                    <div className="number">{index + 1}.</div>
                                    <div className="question">
                                        <span>{q.type}</span>
                                        <h2>{q.text}</h2>
                                    </div>
                                    <div className="selection">{selectedRelatedQuestion?.sequenceId === q.sequenceId && <Selected />}</div>
                                </div>
                            );
                        })}
                </div>
                <div className="relation-dialog-footer">
                    <button disabled={!selectedRelatedQuestion} onClick={() => saveRelation(selectedRelatedQuestion)}>
                        Save Relation
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RelationDialogComponent;
