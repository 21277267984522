import React, { useState } from "react";
import "./Tooltip.scss";

const Tooltip = ({ children, title, list }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div className="tooltip-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {showTooltip && (
                <div className="tooltip-text">
                    <h3>{title}</h3>
                    <ul>
                        {list.map((item, index) => (
                            <li key={index}>- {item}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
