const Constants = {
    REST_API_URL:
        process.env.REACT_APP_ENV === "production"
            ? "https://api.conceiveabilities.com/"
            : process.env.REACT_APP_ENV === "uat"
            ? "https://uat-api.conceiveabilities.com/"
            : "https://staging-api.conceiveabilities.com/"
};

export const States = [
    { title: "Alabama", name: "AL" },
    { title: "Alaska", name: "AK" },
    { title: "Arizona", name: "AZ" },
    { title: "Arkansas", name: "AR" },
    { title: "California", name: "CA" },
    { title: "Colorado", name: "CO" },
    { title: "Connecticut", name: "CT" },
    { title: "Delaware", name: "DE" },
    { title: "Florida", name: "FL" },
    { title: "Georgia", name: "GA" },
    { title: "Hawaii", name: "HI" },
    { title: "Idaho", name: "ID" },
    { title: "Illinois", name: "IL" },
    { title: "Indiana", name: "IN" },
    { title: "Iowa", name: "IA" },
    { title: "Kansas", name: "KS" },
    { title: "Kentucky", name: "KY" },
    { title: "Louisiana", name: "LA" },
    { title: "Maine", name: "ME" },
    { title: "Maryland", name: "MD" },
    { title: "Massachusetts", name: "MA" },
    { title: "Michigan", name: "MI" },
    { title: "Minnesota", name: "MN" },
    { title: "Mississippi", name: "MS" },
    { title: "Missouri", name: "MO" },
    { title: "Montana", name: "MT" },
    { title: "Nebraska", name: "NE" },
    { title: "Nevada", name: "NV" },
    { title: "New Hampshire", name: "NH" },
    { title: "New Jersey", name: "NJ" },
    { title: "New Mexico", name: "NM" },
    { title: "New York", name: "NY" },
    { title: "North Carolina", name: "NC" },
    { title: "North Dakota", name: "ND" },
    { title: "Ohio", name: "OH" },
    { title: "Oklahoma", name: "OK" },
    { title: "Oregon", name: "OR" },
    { title: "Pennsylvania", name: "PA" },
    { title: "Rhode Island", name: "RI" },
    { title: "South Carolina", name: "SC" },
    { title: "South Dakota", name: "SD" },
    { title: "Tennessee", name: "TN" },
    { title: "Texas", name: "TX" },
    { title: "Utah", name: "UT" },
    { title: "Vermont", name: "VT" },
    { title: "Virginia", name: "VA" },
    { title: "Washington", name: "WA" },
    { title: "West Virginia", name: "WV" },
    { title: "Wisconsin", name: "WI" },
    { title: "Wyoming", name: "WY" },
    { title: "District of Columbia", name: "DC" }
];

export default Constants;
