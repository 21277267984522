import React, { useContext } from "react";
import QuestionItem from "../../../pages/new-builder/components/NewSurveyBuilderContent/NewQuestionList/QuestionItem/QuestionItem";
import { NewSurveyBuilderContext } from "../../../pages/new-builder/NewSurveyBuilderContext/NewSurveyBuilderContext";
import removeGroup from "../../../assets/images/delete_basket.svg";
import addGroup from "../../../assets/images/user_add.svg";
import { ReactComponent as AddItem } from "../../../assets/images/plus-white.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function NewDynamicGroup({ group, questions, openVisibilityModal }) {
    const { surveyData, swapQuestionsInGroup, removeQuestionFromGroup, addItemToGroup } = useContext(NewSurveyBuilderContext);
    const { sequenceId, instanceGroupName, showInstanceCount } = group;

    return (
        <div style={{ position: "relative" }} className="group-content">
            <DragDropContext onDragEnd={(result) => swapQuestionsInGroup(result, questions, sequenceId)}>
                <div className="dynamic-group-question">
                    <div className="content">
                        <div key={sequenceId} className="dynamic-instance">
                            <div className="instance-title">
                                <div>
                                    {instanceGroupName}
                                    {showInstanceCount && instanceGroupName.length > 0 ? ` 1` : ""}
                                </div>
                                <img src={removeGroup} className="remove-icon" alt="remove Icon" />
                            </div>
                            <Droppable droppableId="droppable-2">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {questions.map((question, index) => (
                                            <Draggable key={question.sequenceId} draggableId={question.sequenceId} index={index}>
                                                {(provided, innerSnapShot) => (
                                                    <QuestionItem
                                                        key={question.sequenceId}
                                                        question={question}
                                                        removeQuestion={(question) => removeQuestionFromGroup(question, group)}
                                                        index={index}
                                                        provided={provided}
                                                        snapshot={innerSnapShot}
                                                        isListDraggingOver={snapshot.isDraggingOver}
                                                        openVisibilityModal={openVisibilityModal}
                                                    />
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div className="add-more">
                            <img src={addGroup} />
                            <span className="add-more-text">{`Add ${group?.instanceGroupName}`}</span>
                        </div>
                    </div>
                    {surveyData?.status !== "PUBLISH" && (
                        <div className="question-group-actions">
                            <button
                                className="action-first"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    addItemToGroup("YES_NO", group);
                                }}
                            >
                                <AddItem /> <span>Question</span>
                            </button>
                        </div>
                    )}
                </div>
            </DragDropContext>
        </div>
    );
}

export default NewDynamicGroup;
