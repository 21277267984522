import AppService from "../AppService";

export const NewSurveyBuilderService = {
    activeSurvey(body) {
        return AppService.getAxios().post(`/api/survey/activate`, body);
    },

    publishSurvey(id, validateZipCodes) {
        return AppService.getAxios().post(`/api/survey/publish?id=${id}&validateZipCodes=${validateZipCodes}`);
    },

    loadSurveyInfo(id) {
        return AppService.getAxios().get(`/api/survey/builder/${id}/short`);
    },

    loadSidebar(id) {
        return AppService.getAxios().get(`/api/survey/builder/${id}/sub-surveys`);
    },

    loadSection(surveyId, sequenceId) {
        return AppService.getAxios().get(`/api/survey/builder/${surveyId}/section/${sequenceId}`);
    },

    loadSalesForceFields(surveyType) {
        return AppService.getAxios().get(`/api/sales-force-field?type=${surveyType}`);
    },

    saveQuestion(surveyId, sequenceId, question) {
        return AppService.getAxios().post(`/api/survey/builder/${surveyId}/section/${sequenceId}/question/save`, question);
    },

    saveQuestionInGroup(surveyId, sectionId, groupId, question) {
        return AppService.getAxios().post(`/api/survey/builder/${surveyId}/section/${sectionId}/group/${groupId}/save`, question);
    },

    removeQuestion(surveyId, sectionSequence, questionSequence) {
        return AppService.getAxios().delete(
            `/api/survey/builder/${surveyId}/section/${sectionSequence}/question/${questionSequence}/remove`
        );
    },

    removeQuestionFromGroup(surveyId, sectionSequence, groupId, questionSequence) {
        return AppService.getAxios().delete(
            `/api/survey/builder/${surveyId}/section/${sectionSequence}/group/${groupId}/question/${questionSequence}/remove`
        );
    },

    saveSurveyInfo(surveyId, data) {
        return AppService.getAxios().put(`/api/survey/builder/${surveyId}/update/info`, data);
    },

    saveAcceptanceCriteria(surveyId, data) {
        return AppService.getAxios().put(`/api/survey/builder/${surveyId}/update/rules`, data);
    },

    saveScoring(surveyId, data) {
        return AppService.getAxios().put(`/api/survey/builder/${surveyId}/update/scoring`, data);
    },

    saveSubSurvey(surveyId, subSurveySequence, data) {
        return AppService.getAxios().put(`/api/survey/builder/${surveyId}/sub-survey/${subSurveySequence}/save`, data);
    },

    saveSection(surveyId, subSurveySequence, sectionSequence, data) {
        return AppService.getAxios().put(
            `/api/survey/builder/${surveyId}/sub-survey/${subSurveySequence}/section/${sectionSequence}/save`,
            data
        );
    },
    removeSubSurvey(surveyId, subSurveySequence) {
        return AppService.getAxios().delete(`/api/survey/builder/${surveyId}/sub-survey/${subSurveySequence}/remove`);
    },
    removeSection(surveyId, subSurveySequence, sectionSequence) {
        return AppService.getAxios().delete(
            `/api/survey/builder/${surveyId}/sub-survey/${subSurveySequence}/section/${sectionSequence}/remove`
        );
    },
    setupVisibility(surveyId, action, body) {
        return AppService.getAxios().put(`/api/survey/builder/${surveyId}/visibility/${action}`, body);
    },
    setupGroupVisibility(surveyId, action, body) {
        return AppService.getAxios().put(`/api/survey/builder/${surveyId}/visibility/group/${action}`, body);
    },
    removeAllVisibility(surveyId, sectionSequence, questionSequence) {
        return AppService.getAxios().delete(
            `/api/survey/builder/${surveyId}/visibility/all/section/${sectionSequence}/question/${questionSequence}`
        );
    },
    removeAllVisibilityInsideGroupQuestion(surveyId, sectionSequence, groupId, questionSequence) {
        return AppService.getAxios().delete(
            `/api/survey/builder/${surveyId}/visibility/all/section/${sectionSequence}/group/${groupId}/question/${questionSequence}`
        );
    },
    swapQuestion(surveyId, sequenceId, questionSequence, newIndex) {
        return AppService.getAxios().get(
            `/api/survey/builder/${surveyId}/section/${sequenceId}/question/${questionSequence}/swap?newIndex=${newIndex}`
        );
    },
    swapQuestionInGroup(surveyId, sequenceId, groupId, questionSequence, newIndex) {
        return AppService.getAxios().get(
            `/api/survey/builder/${surveyId}/section/${sequenceId}/group/${groupId}/question/${questionSequence}/swap?newIndex=${newIndex}`
        );
    },
    saveRelation(surveyId, body) {
        return AppService.getAxios().post(`/api/question-relation/${surveyId}/create`, body);
    },
    saveRelationInGroup(surveyId, body) {
        return AppService.getAxios().post(`/api/question-relation/${surveyId}/group/create`, body);
    },
    removeRelation(surveyId, section, relationId) {
        return AppService.getAxios().delete(`/api/question-relation/${surveyId}/section/${section}/remove/${relationId}`);
    },
    removeRelationFromGroup(surveyId, section, group, relationId) {
        return AppService.getAxios().delete(`/api/question-relation/${surveyId}/section/${section}/group/${group}/remove/${relationId}`);
    },
    updateRelationItems(relationId, items) {
        return AppService.getAxios().post(`/api/question-relation/${relationId}/items/save`, items);
    },
    addDynamicGroupRelation(surveyId, sectionId, targetId, sourceId) {
        return AppService.getAxios().put(
            `/api/survey/builder/${surveyId}/section/${sectionId}/update/dynamic/${targetId}/pair/${sourceId}`
        );
    },
    removeDynamicGroupRelation(surveyId, sectionId, targetId, sourceId) {
        return AppService.getAxios().delete(
            `/api/survey/builder/${surveyId}/section/${sectionId}/remove/dynamic/${targetId}/pair/${sourceId}`
        );
    }
};
