import React from "react";
import { LanguageService } from "../../../service/LanguageService";

function Note({ content, lang }) {
    return (
        <div className="content">
            <div>{LanguageService.getTranslatedText(content, lang)}</div>
        </div>
    );
}

export default Note;
