import React from "react";
import "./AppItemComponent.scss";
import { ReactComponent as MultilangIcon } from "../../assets/images/multilang-icon.svg";
import { ReactComponent as Spinner } from "../../assets/images/grid.svg";
import arrow from "../../assets/images/arrow_down.svg";
import dots from "../../assets/images/dots.svg";

class AppItemComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showActions: false,
            showVersions: false,
            showMenu: false,
            disableActions: false,
            showLoader: false
        };
        this.showVersions = this.showVersions.bind(this);
        this.showMenu = this.showMenu.bind(this);
        this.showActions = this.showActions.bind(this);
        this.hideActions = this.hideActions.bind(this);
        this.onDeleteOrArchiveSurvey = this.onDeleteOrArchiveSurvey.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
    }

    showVersions() {
        this.setState({ showVersions: !this.state.showVersions });
    }

    showActions() {
        if (!this.state.showMenu) {
            this.setState({ showActions: true });
        }
    }

    hideActions() {
        if (!this.state.showMenu) {
            this.setState({ showActions: false });
        }
    }

    showMenu() {
        const showMenu = !this.state.showMenu;
        this.setState({ showMenu: showMenu, showActions: showMenu });
    }

    getDeleteOrArchiveButton() {
        return (
            <>
                {this.props.app.canBeDeleted ? (
                    <li
                        className={!this.props.removeAvailable ? "hide-action" : ""}
                        onClick={() => this.onDeleteOrArchiveSurvey(this.props.app, "DELETE")}
                    >
                        Delete
                    </li>
                ) : (
                    <li
                        className={!this.props.removeAvailable ? "hide-action" : ""}
                        onClick={() => this.onDeleteOrArchiveSurvey(this.props.app, "ARCHIVE")}
                    >
                        Archive
                    </li>
                )}
            </>
        );
    }

    onDeleteOrArchiveSurvey(app, method) {
        this.setState({ showLoader: true });
        this.props.deleteOrArchiveSurvey(app, method, this.hideLoader);
    }

    onRestoreSurvey(app) {
        this.setState({ showLoader: true });
        this.props.restoreSurvey(app, this.hideLoader);
    }

    hideLoader() {
        this.setState({ showLoader: false });
    }

    getRestoreButton() {
        return (
            <li className={!this.props.removeAvailable ? "hide-action" : ""} onClick={() => this.onRestoreSurvey(this.props.app)}>
                Restore
            </li>
        );
    }

    getActionButton() {
        return (
            this.props.app.status !== "ACTIVE" &&
            (this.props.app.status !== "ARCHIVED" ? this.getDeleteOrArchiveButton() : this.getRestoreButton())
        );
    }

    onCloneSurvey(app, disabled) {
        this.setState({ disableActions: true, showLoader: true });
        !disabled &&
            this.props
                .cloneApp(app, this.hideLoader)
                .then(() => {
                    this.setState({ disableActions: false });
                })
                .catch(() => {
                    this.setState({ disableActions: false });
                });
    }

    onCreateNewVersion(app) {
        this.setState({ showLoader: true });
        this.props.addNewVersion(app, this.hideLoader);
    }

    render() {
        const { app, allowActions } = this.props;
        return (
            <div className="app-item-container">
                <div className="app-item" onMouseEnter={this.showActions} onMouseLeave={this.hideActions} onMouseMove={this.showActions}>
                    <div
                        onClick={() => {
                            this.props.onClick(this.props.app);
                        }}
                    >
                        <div>
                            <div className="left">
                                <div className={`status-${app.status}`} />
                                <div className="type">{app.type}</div>
                                <div className="internationalization">
                                    {app.internationalizationSupport ? (
                                        <>
                                            <MultilangIcon />
                                            <span>Multilingual</span>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                            <div className="right">
                                <div className="user">{app.user}</div>
                                <div className="date">{app.date}</div>
                                <div className="version">{app.version}</div>
                            </div>
                        </div>
                        <div onClick={() => this.props.onClick(this.props.app)}>{app.text}</div>
                    </div>
                    {(allowActions && this.state.showActions) || this.state.showVersions ? (
                        <div>
                            <span
                                className="menu-dots"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.showMenu();
                                }}
                            >
                                <img src={dots} alt="" />
                            </span>
                            <span
                                className="menu-arrow"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.showVersions();
                                }}
                            >
                                <img src={arrow} alt="" />
                            </span>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
                {this.state.showVersions ? <div className="app-versions">{this.props.children}</div> : null}
                {this.state.showMenu ? (
                    <div className="app-item-menu" onMouseLeave={this.showMenu}>
                        <ul>
                            <li onClick={() => this.onCreateNewVersion(this.props.app, this.hideLoader)}>New Version</li>
                            <li onClick={() => this.onCloneSurvey(this.props.app, this.state.disableActions)}>Duplicate Survey</li>
                            {this.getActionButton()}
                        </ul>
                    </div>
                ) : null}
                {this.state.showLoader && (
                    <div className="loader">
                        <Spinner />
                    </div>
                )}
            </div>
        );
    }
}

export default AppItemComponent;
