import React, { useContext } from "react";
import AppService from "../../../../service/AppService";
import { LanguageService } from "../../../../service/LanguageService";
import { useParams } from "react-router-dom";
import { NewSurveyBuilderContext } from "../../../../pages/new-builder/NewSurveyBuilderContext/NewSurveyBuilderContext";

function VisibilityPopup({ hasVisibilityItems, question }) {
    const { selectedSection } = useContext(NewSurveyBuilderContext);
    const { id } = useParams();
    let visibilityPopup = null;
    if (hasVisibilityItems) {
        const visibilityInfo = [];
        question.visibility.map((item) => {
            const section = id ? selectedSection : AppService.selectedApp.sections.find((s) => s.sequenceId === item.section);

            let questionGroupInfo;
            let questionInfo;
            let answerInfo;

            if (question?.groupId) {
                questionGroupInfo = section?.questions.find((q) => q.sequenceId === question.groupId);
                questionInfo = questionGroupInfo.questions.find((q) => q.sequenceId === item.sourceQuestion);
                answerInfo = questionInfo?.answers.find((a) => a.sequenceId === item.sequenceId);
            } else {
                questionInfo = section?.questions.find((q) => q.sequenceId === item.sourceQuestion);
                answerInfo = questionInfo?.answers.find((a) => a.sequenceId === item.sequenceId);
            }
            visibilityInfo.push({
                section,
                questionInfo: questionInfo,
                answerInfo: answerInfo
            });
        });

        visibilityPopup = visibilityInfo.map((vi, i) => {
            if (vi.questionInfo && vi.answerInfo) {
                return (
                    <div key={`${i}_popup_section`} className="popup-section">
                        <div className="header">Section: {LanguageService.getTranslatedText(vi.section.name)}</div>
                        <div className="content">{LanguageService.getTranslatedText(vi.questionInfo.text)}</div>
                        <div className="header">Answer</div>
                        <div className="content">{LanguageService.getTranslatedText(vi.answerInfo.text)}</div>
                    </div>
                );
            }
        });

        return <div className="visibility-popup">{visibilityPopup}</div>;
    }

    return null;
}

export default VisibilityPopup;
