import React from "react";
import "./AppInfoComponent.scss";
import AppService from "../../service/AppService";

class AppInfoComponent extends React.Component {
    render() {
        const { type, version, region, title, description } = this.props.app;

        return (
            <div className="app-info-section">
                <div className="column-title">
                    <span>info</span>
                    {AppService.selectedApp.status === "CONSTRUCT" && (
                        <span className="appEdit" onClick={this.props.onEdit}>
                            edit
                        </span>
                    )}
                </div>
                <div className="app-info">
                    <div className="app">{type}</div>
                    <div className="age" />
                    <div className="version">{version}</div>
                </div>
                <div className="region">{region}</div>
                <div className="disqualifying-question">{title}</div>
                <div className="disqualifying-question-description">{description}</div>
            </div>
        );
    }
}

export default AppInfoComponent;
