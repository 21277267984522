import React from "react";
import "./DropDownPortalComponent.scss";

import arrow from "../../assets/images/arrow_down.svg";

import eyeGrey from "../../assets/images/eye-grey.svg";
import reviewGrey from "../../assets/images/review-grey.svg";
import disqGrey from "../../assets/images/disq-grey.svg";
import { createPortal } from "react-dom";

class DropDownPortalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.sourceSelectedItem = props.selectedItem;
        this.state = {
            showDropDown: false,
            selectedItem: props.selectedItem ?? "",
            scrollHeight: null,
            scrollTopPosition: 0
        };
        this.containerRef = React.createRef();
        this.itemsRef = React.createRef();

        this.sourceItems = props.items;
        this.keyDown = this.keyDown.bind(this);
        this.showDropDown = this.showDropDown.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.getItemText = this.getItemText.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    keyDown(event) {
        if (event.keyCode === 27 && this.state.showDropDown) {
            this.showDropDown();
        }
    }

    componentWillReceiveProps(props) {
        this.sourceItems = props.items;
        this.setState({
            selectedItem: props.selectedItem ?? ""
        });
        if (props.scrollTop && this.state.scrollTopPosition !== props.scrollTop) {
            this.setState({
                showDropDown: false,
                scrollTopPosition: props.scrollTop
            });
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keyDown, false);
        document.addEventListener("mousedown", this.handleDocumentClick, false);
        const offset = this.wrapperRef.getBoundingClientRect();
        this.setState({ offset });
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleDocumentClick, false);
    }

    handleDocumentClick(e) {
        if (
            this.containerRef.current &&
            !this.containerRef.current.contains(e.target) &&
            this.itemsRef.current &&
            !this.itemsRef.current.contains(e.target)
        ) {
            this.setState({ showDropDown: false });
        }
    }

    showDropDown() {
        this.sourceItems = this.props.items;
        this.setState({ showDropDown: !this.state.showDropDown });
    }

    selectItem(text, value) {
        if (this.props.onChange) {
            this.props.onChange(text, value);
        }
        this.setState({ selectedItem: text, showDropDown: false });
    }

    handleSearchChange({ target }) {
        this.sourceItems = this.props.items.filter((i) => i.text.toLowerCase().startsWith(target.value.toLowerCase()));
        this.setState({ selectedItem: target.value, showDropDown: true });
    }

    getItemText(text) {
        if (!this.props.searchable || this.state.selectedItem.length === 0) {
            return text;
        }
        const reg = new RegExp(this.state.selectedItem, "gi");
        return text.replace(reg, (str) => `<b>${str}</b>`);
    }

    portalList() {
        const top = this.state.offset.top + this.state.offset.height - (this.props.scrollTop ? this.props.scrollTop : 0);
        const left = this.state.offset.left;
        const style = this.state.offset
            ? {
                  top,
                  left
              }
            : {};
        const itemsList = this.sourceItems.map((item, index) => {
            const isSelected = this.state.selectedItem === item.text;
            const className = isSelected ? "filter-selected" : "";
            const settings = (
                <span>
                    {item.hasVisibility ? (
                        <img
                            src={eyeGrey}
                            alt=""
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.onVisibilityClick(item.sequenceId);
                            }}
                        />
                    ) : null}
                    {item.isReview ? <img src={reviewGrey} alt="" /> : null}
                    {item.isDisqualify ? <img src={disqGrey} alt="" /> : null}
                </span>
            );

            return (
                <li
                    key={index}
                    className={this.props.disabled && item.text !== "As a demo" ? className + " disabled" : className}
                    onClick={() => {
                        if (this.props.disabled && item.text !== "As a demo") {
                            return;
                        }
                        this.selectItem(item.text, item);
                    }}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: this.getItemText(item.text)
                        }}
                    />{" "}
                    {settings}
                </li>
            );
        });

        return (
            <div style={style} className="drop-down-items-portal" ref={this.itemsRef}>
                <ul>{itemsList}</ul>
            </div>
        );
    }

    render() {
        const labelText = this.props.required ? (
            <label>
                {this.props.label}
                <span className="required">*</span>{" "}
            </label>
        ) : (
            <label>{this.props.label}</label>
        );

        return (
            <div className="drop-down-container" ref={this.containerRef}>
                {this.props.label && this.props.label.length > 0 ? labelText : null}
                <div className="drop-down">
                    <input
                        type="text"
                        disabled={this.props.isDisabled}
                        value={this.state.selectedItem}
                        onChange={this.handleSearchChange}
                        placeholder={this.props.placeholder}
                        readOnly={this.props.searchable}
                        onClick={this.showDropDown}
                        ref={(e) => (this.wrapperRef = e)}
                    />
                    <img src={arrow} alt="" onClick={this.showDropDown} />
                </div>
                {this.state.showDropDown && !this.props.isDisabled
                    ? createPortal(this.portalList(), document.querySelector(".create-app-popup"))
                    : null}
            </div>
        );
    }
}
export default DropDownPortalComponent;
