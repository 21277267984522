import React from "react";
import { useHistory } from "react-router-dom";

function SavePopup({ setShowSavePopup }) {
    const history = useHistory();
    return (
        <div className="status-popup-overlay">
            <div className="status-popup">
                <div className="container">
                    <div className="header">Are you sure you want to leave this survey without saving? </div>
                    <div className="buttons">
                        <div className="button-yes" onClick={() => history.goBack()}>
                            Yes
                        </div>
                        <div className="button-no" onClick={() => setShowSavePopup(false)}>
                            No
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SavePopup;
