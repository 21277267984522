import React from "react";
import classNames from "classnames";

function SelectButtonGc({
    progressBar,
    progressBarStatus,
    selectFirstSection,
    selectSecondSection,
    selectThirdSection,
    selectFourthSection,
    secondSectionDisabled,
    createButtonClassName,
    navigateToBuilder,
    saveButtonTitle
}) {
    switch (progressBarStatus.inProgress) {
        case progressBar.first:
            return (
                <button className="create-app-button" onClick={selectSecondSection}>
                    Continue
                </button>
            );
        case progressBar.second:
            return (
                <>
                    <button className="create-app-button back" onClick={selectFirstSection}>
                        Back
                    </button>
                    <button
                        className={classNames("create-app-button", { disabled: secondSectionDisabled })}
                        onClick={selectThirdSection}
                        disabled={secondSectionDisabled}
                    >
                        Continue
                    </button>
                </>
            );
        case progressBar.third:
            return (
                <>
                    <button className="create-app-button back" onClick={selectSecondSection}>
                        Back
                    </button>
                    <button className="create-app-button" onClick={selectFourthSection}>
                        Continue
                    </button>
                </>
            );
        case progressBar.fourth:
            return (
                <>
                    <button className="create-app-button back" onClick={selectThirdSection}>
                        Back
                    </button>
                    <button className={createButtonClassName} onClick={navigateToBuilder}>
                        {saveButtonTitle}
                    </button>
                </>
            );
    }
}

export default SelectButtonGc;
