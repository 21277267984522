import React from "react";
import { IMaskInput } from "react-imask";
import SettingsButtons from "../../settingsButton/SettingsButtonComponent";
import { LanguageService } from "../../../service/LanguageService";

class NumberInput extends React.Component {
    render() {
        const answers = this.props.answers.map((a, i) => {
            const range = a.range;
            let minValue = undefined;
            let maxValue = undefined;

            if (range) {
                const { upperBoundary, lowerBoundary } = range;

                if (upperBoundary) {
                    maxValue = upperBoundary.inclusive ? upperBoundary.value : (parseInt(upperBoundary.value) - 1).toString();
                }

                if (lowerBoundary) {
                    minValue = lowerBoundary.inclusive ? lowerBoundary.value : (parseInt(lowerBoundary.value) + 1).toString();
                }
            }

            const answer = (
                <IMaskInput
                    mask={Number}
                    lazy={false}
                    inputRef={(el) => (this.input = el)}
                    className={"masked-number"}
                    signed={true}
                    scale={0}
                    thousandsSeparator={""}
                    padFractionalZeros={false}
                    placeholder={LanguageService.getTranslatedText(a.text, this.props.lang)}
                    min={parseInt(minValue)}
                    max={parseInt(maxValue)}
                />
            );

            if (this.input) {
                this.input.type = "text";
            }
            return (
                <SettingsButtons
                    key={`${i}_settings_button`}
                    answer={a}
                    hasVisibility={a.hasVisibility}
                    onVisibilityClick={this.props.onVisibilityClick}
                >
                    {answer}
                </SettingsButtons>
            );
        });
        return <div className="content">{answers}</div>;
    }
}

export default NumberInput;
