import React from "react";
import "./CheckboxComponent.scss";

const CheckboxComponent = ({ isChecked }) => {
    const className = `checkbox-password`;
    return (
        <label className={className}>
            <input type="checkbox" checked={isChecked} disabled />
            <span />
        </label>
    );
};
export default CheckboxComponent;
