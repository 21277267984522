import AppService from "../AppService";
import cloneDeep from "lodash/cloneDeep";

export const BuilderSurveyService = {
    prepareSurveyBody(selectedSubSurvey) {
        let newSubSectionStructure;
        if (AppService.removingLanguages) {
            newSubSectionStructure = AppService.selectedApp.subSurveys;
        } else {
            AppService.selectedApp.allSubSurveySections[selectedSubSurvey] = cloneDeep(AppService.selectedApp.sections);
            newSubSectionStructure = AppService.selectedApp.subSurveys.map((item, index) => {
                return {
                    ...item,
                    sections: AppService.selectedApp.allSubSurveySections[index]
                };
            });
        }
        const selectedAppCopy = { ...AppService.selectedApp };
        selectedAppCopy.subSurveys = newSubSectionStructure;
        delete selectedAppCopy.sections;
        delete selectedAppCopy.allSubSurveySections;
        delete selectedAppCopy.reset;
        const selectedAppJSON = JSON.stringify(selectedAppCopy);

        return {
            id: AppService.selectedApp.id,
            name: AppService.selectedApp.title,
            type: AppService.selectedApp.type,
            description: AppService.selectedApp.description,
            internationalizationSupport: AppService.selectedApp.internationalizationSupport,
            jsonStructure: selectedAppJSON
        };
    },
    getQuestions(container, result) {
        let r = result;
        container.forEach((c) => {
            if (c.type === AppService.questionType.GROUP || c.type === AppService.questionType.DYNAMIC_GROUP) {
                r = this.getQuestions(c.questions, r);
            } else {
                if (c.type !== AppService.questionType.NOTE) {
                    r.push({
                        sequenceId: c.sequenceId,
                        t: c.text,
                        answers: c.answers,
                        groupId: c.groupId
                    });
                }
            }
        });
        return r;
    },

    getQuestionWithoutAnswerInput(subSurveys) {
        const questions = this.flattenQuestions(subSurveys);

        const unansweredQuestion = questions.find((q) => {
            if (q.type === "NOTE" && (q.text || q.noteContent)) {
                return false;
            } else if (q.answers?.length === 0) {
                return true;
            }
            return false;
        });

        return unansweredQuestion;
    },

    flattenQuestions(surveys) {
        return surveys.flatMap((sub) =>
            sub.sections.flatMap((section) =>
                section.questions.flatMap((question) =>
                    question.type === "GROUP" || question.type === "DYNAMIC_GROUP"
                        ? this.flattenQuestions([{ sections: [{ questions: question.questions }] }])
                        : question
                )
            )
        );
    },

    sortQuestionList(selectedSectionIndex) {
        const selectedSection = AppService.selectedApp.sections[selectedSectionIndex];

        selectedSection.questions.map((q, qi, array) => {
            if (q.visibility.length > 0) {
                q.visibility.map((item, i) => {
                    const linkedQuestionId = item.sourceQuestion;
                    const questionIndex = selectedSection.questions.findIndex((el) => el.sequenceId === linkedQuestionId);
                    if (questionIndex > qi) {
                        array.splice(qi, 1);
                        array.splice(questionIndex, 0, q);
                    }
                    return item;
                });
            }
            return q;
        });
    },
    onQuestionTextChanged(text, selectedQuestion, selectedSectionIndex, selectedQuestionIndex, selectedGroupQuestionIndex) {
        const question = selectedQuestion;
        if (question.groupId) {
            question.text = text;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            if (AppService.selectedApp.internationalizationSupport) {
                question.text = {
                    ...question.text,
                    EU: text
                };
            } else {
                question.text = text;
            }
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        return question;
    },
    onHintChanged(text, selectedQuestion, selectedSectionIndex, selectedQuestionIndex, selectedGroupQuestionIndex) {
        const question = selectedQuestion;
        if (question.groupId) {
            question.hint = text;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            if (AppService.selectedApp.internationalizationSupport) {
                question.hint = {
                    ...question.hint,
                    EU: text
                };
            } else {
                question.hint = text;
            }
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        return question;
    },
    onAnswersChanged(answers, selectedQuestion, selectedSectionIndex, selectedQuestionIndex, selectedGroupQuestionIndex) {
        const question = selectedQuestion;
        if (question?.groupId) {
            question.answers = answers;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            question.answers = answers;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        return question;
    },
    onOptionalQuestionChanged(optional, selectedQuestion, selectedSectionIndex, selectedQuestionIndex, selectedGroupQuestionIndex) {
        const question = selectedQuestion;
        if (question?.groupId) {
            question.optional = optional;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            question.optional = optional;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        return question;
    },
    changeFeedbackTitle(question, answer, value, selectedSectionIndex, selectedQuestionIndex, selectedGroupQuestionIndex) {
        const selectedQuestion = question;
        if (selectedQuestion?.groupId) {
            const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === answer.sequenceId);
            selectedQuestion.answers[answerIndex].feedback.title = value;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                selectedQuestion;
        } else {
            const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === answer.sequenceId);
            if (AppService.selectedApp.internationalizationSupport) {
                selectedQuestion.answers[answerIndex].feedback.title = {
                    ESP: selectedQuestion.answers[answerIndex].feedback.title.ESP,
                    EU: value
                };
            } else {
                selectedQuestion.answers[answerIndex].feedback.title = value;
            }
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = selectedQuestion;
        }
        return selectedQuestion;
    },
    changeFeedbackText(question, answer, value, selectedSectionIndex, selectedQuestionIndex, selectedGroupQuestionIndex) {
        const selectedQuestion = question;
        if (selectedQuestion?.groupId) {
            const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === answer.sequenceId);
            selectedQuestion.answers[answerIndex].feedback.text = value;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                selectedQuestion;
        } else {
            const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === answer.sequenceId);
            if (AppService.selectedApp.internationalizationSupport) {
                selectedQuestion.answers[answerIndex].feedback.text = {
                    ESP: selectedQuestion.answers[answerIndex].feedback.text.ESP,
                    EU: value
                };
            } else {
                selectedQuestion.answers[answerIndex].feedback.text = value;
            }
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = selectedQuestion;
        }
        return selectedQuestion;
    },
    salesForceItemChanged(item, selectedQuestion, selectedSectionIndex, selectedQuestionIndex, selectedGroupQuestionIndex) {
        if (selectedQuestion?.groupId) {
            selectedQuestion.salesForceField = {
                id: item.id,
                fieldLabel: item.text
            };
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                selectedQuestion;
        } else {
            selectedQuestion.salesForceField = {
                id: item.id,
                fieldLabel: item.text
            };
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = selectedQuestion;
        }
        return selectedQuestion;
    },
    getSurveyChangedValues(selectedSubSurvey, surveyInitialValue) {
        AppService.selectedApp.allSubSurveySections[selectedSubSurvey] = cloneDeep(AppService.selectedApp.sections);

        const selectedAppCopy = { ...AppService.selectedApp };
        selectedAppCopy.sections = [...AppService.selectedApp.allSubSurveySections];
        delete selectedAppCopy.allSubSurveySections;

        const normalizedInitialValue = JSON.parse(surveyInitialValue);
        normalizedInitialValue.sections = [...normalizedInitialValue.allSubSurveySections];
        delete normalizedInitialValue.allSubSurveySections;

        return { selectedAppCopy, normalizedInitialValue };
    },
    closeConditionsBranchingLogicPopup(
        visibilities,
        selectedQuestion,
        selectedSectionIndex,
        selectedQuestionIndex,
        selectedGroupQuestionIndex,
        selectedCondition
    ) {
        const question = selectedQuestion;
        if (question?.groupId) {
            const conditionIndex = question.conditions.findIndex((a) => a.sequenceId === question.sequenceId);
            question.conditions[conditionIndex].visibilities = visibilities;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            const conditionIndex = question.conditions.findIndex((a) => a.sequenceId === selectedCondition.sequenceId);
            question.conditions[conditionIndex].visibilities = visibilities;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        return question;
    },
    getVisibilityCount(answerId) {
        let visibilityCount = 0;
        AppService.selectedApp.sections.forEach((section) => {
            section.questions.forEach((question) => {
                question.visibility.forEach((visibility) => {
                    if (visibility.sequenceId === answerId) {
                        visibilityCount++;
                    }
                });
                if (question.type === AppService.questionType.GROUP || question.type === AppService.questionType.DYNAMIC_GROUP) {
                    question.questions.forEach((subQuestion) => {
                        subQuestion.visibility.forEach((visibility) => {
                            if (visibility.sequenceId === answerId) {
                                visibilityCount++;
                            }
                        });
                    });
                }
            });
        });
        return visibilityCount;
    },
    isCurrentSurveyHasMailingAddressQuestion() {
        let allQuestions = AppService.selectedApp.subSurveys
            .flatMap((s) => s.sections.flatMap((section) => section.questions.flatMap((q) => q)))
            .filter((q) => q.type !== "GROUP");
        AppService.selectedApp.subSurveys
            .flatMap((s) => s.sections.flatMap((section) => section.questions.flatMap((q) => q)))
            .filter((q) => q.type === "GROUP")
            .forEach((g) => g.questions.forEach((gQ) => allQuestions.push(gQ)));
        return allQuestions.filter(
            (q) =>
                q.type === "ADDRESS" &&
                (q.salesForceField?.fieldName?.toLowerCase().includes("mailingaddress") ||
                    q.salesForceField?.fieldLabel?.toLowerCase().includes("mailingaddress"))
        );
    },

    isZipCodesAreValid(mailingQuestion) {
        return (
            mailingQuestion.answers[0]?.accountableZipCode &&
            mailingQuestion.answers[0]?.accountableZipCode?.valid &&
            mailingQuestion.answers[0]?.accountableZipCode.zipCodes.length > 0
        );
    },

    markAnswerForReview(answerId, selectedQuestion, selectedSectionIndex, selectedQuestionIndex, selectedGroupQuestionIndex) {
        const question = selectedQuestion;
        if (question?.groupId) {
            const answerIndex = question.answers.findIndex((a) => a.sequenceId === answerId);
            question.answers[answerIndex].isReview = !question.answers[answerIndex].isReview;
            question.answers[answerIndex].isDisqualify = false;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            const answerIndex = question.answers.findIndex((a) => a.sequenceId === answerId);
            question.answers[answerIndex].isReview = !question.answers[answerIndex].isReview;
            question.answers[answerIndex].isDisqualify = false;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        return question;
    },
    makeAnswerDisqualified(answerId, type, selectedQuestion, selectedSectionIndex, selectedQuestionIndex, selectedGroupQuestionIndex) {
        const question = selectedQuestion;
        const answerIndex = question.answers.findIndex((a) => a.sequenceId === answerId);
        const isDisqualify = question.answers[answerIndex].disqType !== type;

        if (question?.groupId) {
            question.answers[answerIndex].isReview = false;
            question.answers[answerIndex].isDisqualify = isDisqualify;
            question.answers[answerIndex].disqType = question.answers[answerIndex].isDisqualify ? type : null;
            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex].questions[selectedGroupQuestionIndex] =
                question;
        } else {
            const answerIndex = question.answers.findIndex((a) => a.sequenceId === answerId);
            const isDisqualify = question.answers[answerIndex].disqType !== type;
            question.answers[answerIndex].isReview = false;
            question.answers[answerIndex].isDisqualify = isDisqualify;
            question.answers[answerIndex].disqType = question.answers[answerIndex].isDisqualify ? type : null;

            AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = question;
        }
        return question;
    },
    removeVisibility(question, questionsInfo) {
        question?.visibility?.forEach((visibility) => {
            const count = BuilderSurveyService.getVisibilityCount(visibility.sequenceId) - 1;
            const questionInfo = questionsInfo.find((q) => q.sequenceId === visibility.sourceQuestion);
            if (questionInfo) {
                if (questionInfo.groupId && questionInfo.groupId.length > 0) {
                    if (count <= 0) {
                        const sectionIndex = AppService.selectedApp.sections.findIndex((s) => s.sequenceId === visibility.section);
                        const groupIndex = AppService.selectedApp.sections[sectionIndex].questions.findIndex(
                            (q) => q.sequenceId === questionInfo.groupId
                        );
                        const sourceQuestionIndex = AppService.selectedApp.sections[sectionIndex].questions[groupIndex].questions.findIndex(
                            (q) => q.sequenceId === visibility.sourceQuestion
                        );
                        AppService.selectedApp.sections[sectionIndex].questions[groupIndex].questions[sourceQuestionIndex].answers.forEach(
                            (a, i) => {
                                AppService.selectedApp.sections[sectionIndex].questions[groupIndex].questions[sourceQuestionIndex].answers[
                                    i
                                ].hasVisibility = false;
                            }
                        );
                    }
                } else {
                    if (count <= 0) {
                        const sectionIndex = AppService.selectedApp.sections.findIndex((s) => s.sequenceId === visibility.section);
                        const sourceQuestionIndex = AppService.selectedApp.sections[sectionIndex].questions.findIndex(
                            (q) => q.sequenceId === visibility.sourceQuestion
                        );
                        AppService.selectedApp.sections[sectionIndex].questions[sourceQuestionIndex].answers.forEach((a, i) => {
                            AppService.selectedApp.sections[sectionIndex].questions[sourceQuestionIndex].answers[i].hasVisibility = false;
                        });
                    }
                }
            }
        });
    }
};
