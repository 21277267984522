import React from "react";
import "./AppsListComponent.scss";
import { Redirect } from "react-router-dom";
import AppItem from "../appItem/AppItemComponent";
import AppService from "../../service/AppService";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";
import { showErrorNotification, showSuccessNotification } from "../../helper/NotificationHelper";

class AppsListComponent extends React.Component {
    static contextType = SurveyBuilderContext;
    constructor(props) {
        super(props);
        this.state = {
            apps: [],
            appsFromServer: [],
            filter: JSON.parse(sessionStorage.getItem("filter")) || { apps: "All", status: "Any" },
            isLoading: false,
            currentStatus: "ANY",
            removeAvailable: true
        };
        this.navigateToBuilder = this.navigateToBuilder.bind(this);
        this.addNewVersion = this.addNewVersion.bind(this);
        this.deleteOrArchiveSurvey = this.deleteOrArchiveSurvey.bind(this);
        this.restoreSurvey = this.restoreSurvey.bind(this);
        this.loadApps = this.loadApps.bind(this);
        this.cloneApp = this.cloneApp.bind(this);
    }

    componentWillReceiveProps(props) {
        const currentFilter = this.state.filter;
        if (props.filter.name.toLowerCase() === "status") {
            switch (props.filter.value[0]) {
                case "Any":
                    currentFilter.status = "Any";
                    this.loadApps();
                    break;
                case "Under construction":
                    currentFilter.status = "CONSTRUCT";
                    this.loadApps();
                    break;
                case "Published":
                    currentFilter.status = "PUBLISH";
                    this.loadApps();
                    break;
                case "Active":
                    currentFilter.status = "ACTIVE";
                    this.loadApps();
                    break;
                case "Archived":
                    currentFilter.status = "ARCHIVED";
                    this.loadArchivedApps();
                    break;
            }
        } else {
            currentFilter[props.filter.name] = props.filter.value[0] === "Any" ? "Any" : props.filter.value[0];
        }
        this.setState({ filter: currentFilter });
        sessionStorage.setItem("filter", JSON.stringify(currentFilter));
    }

    componentWillMount() {
        this.state.currentStatus = this.state.filter.status;
        if (this.state.currentStatus === "ARCHIVED") {
            this.loadArchivedApps();
        } else {
            this.loadApps();
        }
    }

    cloneApp(app, callback) {
        return AppService.getAxios()
            .get(`/api/survey/clone/${app.id}`)
            .then(() => {
                showSuccessNotification("Survey duplication succeed", "The next minor version of selected survey has been created");
                if (this.state.filter.status === "ARCHIVED") {
                    this.loadArchivedApps(callback);
                } else {
                    this.loadApps(callback);
                }
            })
            .catch((err) => {
                showErrorNotification(
                    err.response.data.message,
                    "Survey duplication failed. The next version of current survey already exists or archived"
                );
                if (callback) {
                    callback();
                }
            });
    }

    loadApps(callback) {
        AppService.getAxios()
            .get(`/api/survey/all`)
            .then((response) => {
                if (callback) {
                    callback();
                }
                this.setState({
                    apps: this.convertAppsToLocal(response.data),
                    appsFromServer: response.data,
                    removeAvailable: true
                });
            });
    }

    loadArchivedApps(callback) {
        AppService.getAxios()
            .get(`/api/survey/archived`)
            .then((response) => {
                if (callback) {
                    callback();
                }
                this.setState({
                    apps: this.convertAppsToLocal(response.data),
                    appsFromService: response.data,
                    removeAvailable: true
                });
            });
    }

    arrayToDate(arr) {
        const stringArr = arr.map((elem) => (elem.toString().length === 1 ? "0" + elem.toString() : elem.toString()));
        if (stringArr.length === 5) {
            stringArr.push("00");
        }
        const yearMonthDay = [stringArr[0], stringArr[1], stringArr[2]].join("-");
        const time = [stringArr[3], stringArr[4], stringArr[5]].join(":");
        const finalDate = yearMonthDay + "T" + time;
        const date = new Date(finalDate);
        return `${date.toLocaleDateString().split("/").reverse().join("-")} ${date.toLocaleTimeString()}`;
    }

    addNewVersion(app, callback) {
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
            AppService.getAxios()
                .get(`/api/survey/${app.id}`)
                .then((response) => {
                    const postObject = {
                        name: response.data.name,
                        type: response.data.type,
                        description: response.data.description,
                        jsonStructure: response.data.jsonStructure,
                        internationalizationSupport: response.data.internationalizationSupport
                    };
                    AppService.getAxios()
                        .post("/api/survey", postObject)
                        .then(() => {
                            showSuccessNotification(
                                "New version created",
                                `The new version of survey "${app.text}" for "${app.type}" has been created`
                            );
                            if (this.state.filter.status === "ARCHIVED") {
                                this.loadArchivedApps(callback);
                            } else {
                                this.loadApps(callback);
                            }
                            this.setState({ isLoading: false });
                        });
                });
        }
    }

    deleteOrArchiveSurvey(app, action = "NONE", callback) {
        this.setState({
            removeAvailable: false
        });
        if (!this.state.isLoading) {
            return AppService.getAxios()
                .delete(`/api/survey/delete/${app.id}/?action=${action}`)
                .then(() => {
                    switch (action) {
                        case "DELETE":
                            showSuccessNotification(
                                "Successfully deleted",
                                `Survey "${app.text}" with version "${app.version}" has been deleted`
                            );
                            break;
                        case "ARCHIVE":
                            showSuccessNotification(
                                "Successfully archived",
                                `Survey "${app.text}" with version "${app.version}" has been archived`
                            );
                            break;
                    }
                    this.loadApps(callback);
                    this.setState({ isLoading: false });
                });
        }
    }

    restoreSurvey(app, callback) {
        this.setState({
            removeAvailable: false
        });
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
            AppService.getAxios()
                .get(`/api/survey/restore/${app.id}`)
                .then(() => {
                    showSuccessNotification(
                        "Successfully restored",
                        `Survey "${app.text}" with version "${app.version}" has been restored`
                    );
                    this.loadArchivedApps(callback);
                    this.setState({ isLoading: false });
                });
        }
    }

    navigateToBuilder(app) {
        if (AppService.newBuilderVersion) {
            this.setState({ navigateTo: `/new/${app.id}` });
            return;
        }
        AppService.getAxios()
            .get(`/api/survey/${app.id}`)
            .then((response) => {
                this.selectApp(response.data);
                this.setState({ navigateTo: "/builder" });
            });
    }

    selectApp(app) {
        const parsedApp = JSON.parse(app.jsonStructure);
        const allSubSurveySections = parsedApp.subSurveys.map((item) => {
            return [...item.sections];
        });
        AppService.selectedApp = JSON.parse(app.jsonStructure);
        AppService.selectedApp.sections = allSubSurveySections[0];
        AppService.selectedApp.allSubSurveySections = allSubSurveySections;
        AppService.selectedApp.id = app.id;
        AppService.selectedApp.surveyId = app.surveyId;
        AppService.selectedApp.version = app.version;
        AppService.selectedApp.status = app.status;
        AppService.selectedApp.active = app.active;
        AppService.selectedApp.internationalizationSupport = app.internationalizationSupport;
        if (!AppService.selectedApp.disqualificationRules) {
            AppService.selectedApp.disqualificationRules = {
                states: []
            };
        } else {
            if (AppService.selectedApp.disqualificationRules.states === undefined) {
                AppService.selectedApp.disqualificationRules.states = [];
            }
        }
        if (app.status === "CONSTRUCT") {
            this.isZipCodesExistsAndValid(allSubSurveySections);
        }
    }

    isZipCodesExistsAndValid(data) {
        let mailingAddressQuestion = data
            .flatMap((s) => s)
            .flatMap((section) => section.questions)
            .filter((q) => q?.type === "ADDRESS" && q.answers[0].accountableZipCode !== undefined)[0];
        if (mailingAddressQuestion) {
            let zip = mailingAddressQuestion.answers[0].accountableZipCode;
            mailingAddressQuestion.answers[0].accountableZipCode.valid = this.checkZipAndScore(zip.zipCodes);
            this.context.saveSurvey();
        }
    }

    checkZipAndScore(zipCodes) {
        for (let item of zipCodes) {
            if (!parseInt(item.zipCode) || !parseInt(item.score)) {
                return false;
            }
        }
        return true;
    }

    convertAppsToLocal(data) {
        const result = data.map((d) => {
            let selectedStatus;
            if (d.active) {
                selectedStatus = "ACTIVE";
            } else {
                selectedStatus = d.status;
            }
            return {
                id: d.id,
                surveyId: d.surveyId,
                canBeDeleted: d.canBeDeleted,
                status: selectedStatus,
                type: d.type,
                user: `${d.lastEditor.firstName} ${d.lastEditor.lastName}`,
                version: d.version,
                text: `${d.name} - ${d.description}`,
                versions: [],
                date: this.arrayToDate(d.updatedAt),
                internationalizationSupport: d.internationalizationSupport
            };
        });
        return result;
    }

    groupByVersion(apps) {
        const grouped = [];
        const appsResult = [];
        apps.forEach((app) => {
            if (!grouped[app.surveyId]) {
                grouped[app.surveyId] = [];
            }
            grouped[app.surveyId].push(app);
        });
        Object.keys(grouped).forEach((key) => {
            const appsList = grouped[key];
            appsList.reverse();
            const firstApp = appsList.shift();
            firstApp.versions = [];
            appsList.forEach((a) => firstApp.versions.push(a));
            appsResult.push(firstApp);
        });
        return appsResult;
    }

    filteringByName = (apps) => {
        return apps.filter((app) => app.text.toLowerCase().includes(this.props.searchField.toLocaleLowerCase()));
    };

    render() {
        if (this.state.navigateTo) {
            return <Redirect to={this.state.navigateTo} push={true} />;
        }

        const apps = this.groupByVersion(this.state.apps).reverse();
        const appsList = this.filteringByName(apps).map((app, index) => {
            const versions = app.versions.map((version, versionIndex) => {
                return (
                    <AppItem
                        app={version}
                        key={versionIndex}
                        allowActions={false}
                        onClick={this.navigateToBuilder}
                        removeAvailable={this.state.removeAvailable}
                    />
                );
            });

            if (this.state.filter.apps === "All" && this.state.filter.status === "Any") {
                return (
                    <AppItem
                        app={app}
                        key={index}
                        allowActions={true}
                        onClick={this.navigateToBuilder}
                        addNewVersion={this.addNewVersion}
                        deleteOrArchiveSurvey={this.deleteOrArchiveSurvey}
                        restoreSurvey={this.restoreSurvey}
                        cloneApp={this.cloneApp}
                        removeAvailable={this.state.removeAvailable}
                    >
                        {versions}
                    </AppItem>
                );
            }

            if (this.state.filter.apps === "All" && app.status === this.state.filter.status) {
                return (
                    <AppItem
                        app={app}
                        key={index}
                        allowActions={true}
                        onClick={this.navigateToBuilder}
                        addNewVersion={this.addNewVersion}
                        deleteOrArchiveSurvey={this.deleteOrArchiveSurvey}
                        restoreSurvey={this.restoreSurvey}
                        cloneApp={this.cloneApp}
                        removeAvailable={this.state.removeAvailable}
                    >
                        {versions}
                    </AppItem>
                );
            }

            if (this.state.filter.status === "Any" && app.type === this.state.filter.apps) {
                return (
                    <AppItem
                        app={app}
                        key={index}
                        allowActions={true}
                        onClick={this.navigateToBuilder}
                        addNewVersion={this.addNewVersion}
                        deleteOrArchiveSurvey={this.deleteOrArchiveSurvey}
                        restoreSurvey={this.restoreSurvey}
                        cloneApp={this.cloneApp}
                        removeAvailable={this.state.removeAvailable}
                    >
                        {versions}
                    </AppItem>
                );
            }

            if (this.state.filter.status === app.status && this.state.filter.apps === app.type) {
                return (
                    <AppItem
                        app={app}
                        key={index}
                        allowActions={true}
                        onClick={this.navigateToBuilder}
                        addNewVersion={this.addNewVersion}
                        deleteOrArchiveSurvey={this.deleteOrArchiveSurvey}
                        restoreSurvey={this.restoreSurvey}
                        cloneApp={this.cloneApp}
                        removeAvailable={this.state.removeAvailable}
                    >
                        {versions}
                    </AppItem>
                );
            }
            if (this.state.filter.apps === "All" || this.state.filter.apps === app.type) {
                return this.filteringByName(app.versions).map((version, versionI) => {
                    if (version.status === this.state.filter.status) {
                        return (
                            <AppItem
                                app={version}
                                key={`${index}-${versionI}`}
                                allowActions={true}
                                onClick={this.navigateToBuilder}
                                removeAvailable={this.state.removeAvailable}
                            />
                        );
                    }
                });
            }
            return null;
        });
        return <div className="apps-list">{appsList}</div>;
    }
}

export default AppsListComponent;
