import SettingsButtons from "../../settingsButton/SettingsButtonComponent";
import React from "react";
import { LanguageService } from "../../../service/LanguageService";

function YesNo({ lang, answers, onVisibilityClick }) {
    const answerButtons = answers.map((a, i) => (
        <SettingsButtons
            key={`${i}_yes_no_condition_settings_button`}
            answer={a}
            hasVisibility={a.hasVisibility}
            onVisibilityClick={onVisibilityClick}
        >
            <button className="yes_no">{LanguageService.getTranslatedText(a.text, lang)}</button>
        </SettingsButtons>
    ));

    return <div className="content">{answerButtons}</div>;
}

export default YesNo;
