import React from "react";
import close from "../../assets/images/close.svg";
import "./ModalPopupComponent.scss";

class ModalPopupComponent extends React.Component {
    render() {
        return (
            <div className={`modal-dialog ${this.props.className}`}>
                <div className="header">
                    <div className="title">{this.props.title}</div>
                    <div className="close">
                        <img src={close} alt="" onClick={this.props.onDialogClose} />
                    </div>
                </div>
                <div className="content">{this.props.children}</div>
            </div>
        );
    }
}
export default ModalPopupComponent;
