import React, { useEffect } from "react";
import close from "../../assets/images/close-icon.svg";
import "./SubSurvey.scss";
import DropDownPortalComponent from "../DropdownPortalComponent/DropDownPortalComponent";
import { LanguageService } from "../../service/LanguageService";

function SubSurvey({
    subSurvey,
    appType,
    noOneSubSurveySelectedAsADemo,
    index,
    deleteSubSurvey,
    selected,
    selectThisSubSurvey,
    changeShowValue,
    onChangeTitle,
    scrollTop,
    previousSurveyName,
    resetAnotherDemoSubSurveysToAlways,
    lang
}) {
    useEffect(() => {
        if (subSurvey.show === "As a demo") {
            resetAnotherDemoSubSurveysToAlways(index);
        }
    }, [subSurvey.show]);

    const dropDownValue = [
        { text: "Always", value: "Always" },
        { text: "If not disqualified", value: "If not disqualified" }
    ];
    const dropDownValueForGSSurvey = [...dropDownValue, { text: "As a demo", value: "As a demo" }];

    return (
        <div className={`sub-survey ${selected && "active-sub-survey"}`} onClick={() => selectThisSubSurvey(index)}>
            <div className="sub-survey-name">
                <label>Title</label>
                <input
                    type="text"
                    placeholder="Name your subsurvey"
                    value={LanguageService.getTranslatedText(subSurvey.name, lang)}
                    onChange={onChangeTitle}
                />
            </div>
            <div className="sub-survey-show">
                <span>Show</span>
                <DropDownPortalComponent
                    required={true}
                    selectedItem={subSurvey.show}
                    placeholder="Select type"
                    items={appType === "GC" ? dropDownValueForGSSurvey : dropDownValue}
                    onChange={changeShowValue}
                    scrollTop={scrollTop}
                    disabled={(appType === "GC" && noOneSubSurveySelectedAsADemo) || subSurvey.show === "As a demo"}
                />
                {previousSurveyName && (
                    <span className="previous-survey-name">
                        in &quot;{LanguageService.getTranslatedText(previousSurveyName, "EU")}&quot;
                    </span>
                )}
            </div>
            <img className="delete-survey" src={close} onClick={() => deleteSubSurvey(index)} alt="delete button" />
        </div>
    );
}

export default SubSurvey;
