import React, { useState } from "react";
import add from "../../../../assets/images/add-icon.svg";
import AppService from "../../../../service/AppService";
import SectionItems from "./SectionItems";
import SubSurveyItems from "./SubSurveyItems";

function SubSurveyAndSectionTab({ subSurveys, sections, setSubSurveys, setSections, appType }) {
    const [selectedSubSurvey, setSelectedSubSurvey] = useState(0);
    const [scrollTop, setScrollTop] = useState(null);

    const addSubSurveys = () => {
        const copySubSurvey = subSurveys;
        const copySections = sections;
        const name = AppService.selectedApp?.internationalizationSupport ? { EU: "", ESP: "" } : "";
        const newSubSurvey = {
            name: name,
            show: "",
            sequenceId: AppService.Guid()
        };
        const newSectionArray = [];
        copySections.push(newSectionArray);
        copySubSurvey.push(newSubSurvey);

        setSubSurveys([...copySubSurvey]);
        setSections([...sections]);
    };

    return (
        <div className="content">
            <div>
                <div className="sub-header">
                    subsurveys<span className="required"> * </span>
                    <img src={add} alt="add subsurvey" className="add-section" onClick={addSubSurveys} />
                </div>
                <div className="sub-survey-wrapper" onScroll={(e) => setScrollTop(e.target.scrollTop)}>
                    <SubSurveyItems
                        appType={appType}
                        subSurveys={subSurveys}
                        setSubSurveys={setSubSurveys}
                        sections={sections}
                        setSections={setSections}
                        selectedSubSurvey={selectedSubSurvey}
                        setSelectedSubSurvey={setSelectedSubSurvey}
                        scrollTop={scrollTop}
                    />
                </div>
            </div>
            <SectionItems
                sections={sections}
                setSections={setSections}
                selectedSubSurvey={selectedSubSurvey}
                disabled={subSurveys.length === 0}
            />
            {subSurveys.length === 0 && (
                <div className="sub-surveys-massage">Create at least one sub survey, on order to create sections in it.</div>
            )}
        </div>
    );
}

export default SubSurveyAndSectionTab;
