import React from "react";
import { LanguageService } from "../../../../service/LanguageService";

function Feedback({ lang, onClick, question, answer }) {
    return (
        <div
            className="feedback-item"
            onClick={(e) => {
                e.stopPropagation();
                if (onClick) {
                    onClick(question, answer);
                }
            }}
        >
            <div className="feedback-title">{LanguageService.getTranslatedText(answer.feedback.title, lang)}</div>
            <div className="feedback-text">{LanguageService.getTranslatedText(answer.feedback.text, lang)}</div>
        </div>
    );
}

export default Feedback;
