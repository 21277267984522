import React, { useContext, useEffect, useState } from "react";
import close from "../../../../../../../assets/images/close-icon.svg";
import SimpleCheckBoxComponent from "../../../../../../../components/simpleCheckBox/SimpleCheckBoxComponent";
import "./VisibilityPopup.scss";
import { NewSurveyBuilderService } from "../../../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";
import { useParams } from "react-router-dom";
import { NewSurveyBuilderContext } from "../../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import classNames from "classnames";
import { ReactComponent as Spinner } from "../../../../../../../assets/images/grid.svg";
import cloneDeep from "lodash/cloneDeep";

function VisibilityPopup({ closeModal, answer, answerIndex }) {
    const { id } = useParams();
    const { selectedSection, setSelectedSection, selectedQuestion, updateAnswer, sortAccordingToVisibility } =
        useContext(NewSurveyBuilderContext);

    const groupId = selectedQuestion?.groupId;
    const group = selectedSection.questions.find((question) => question.sequenceId === groupId);

    const [selectedSectionId, setSelectedSectionId] = useState(selectedSection.sequenceId);
    const [sections, setSections] = useState([]);
    const [sectionQuestions, setSectionQuestions] = useState([]);

    const [sectionsLoading, setSectionsLoading] = useState(!groupId);
    const [questionsLoading, setQuestionsLoading] = useState(!groupId);

    useEffect(() => {
        groupId ? setSections([group]) : (async () => getSectionsList())();
    }, []);

    useEffect(() => {
        groupId ? setSectionQuestions(prepareQuestionList(group.questions)) : (async () => getSectionQuestions())();
    }, [selectedSectionId]);

    const getSectionsList = async () => {
        setSectionsLoading(true);
        const resp = await NewSurveyBuilderService.loadSidebar(id);
        const subSurveyIndex = resp.data.findIndex((subSurvey) =>
            subSurvey.sections.some((section) => section.sequenceId === selectedSection.sequenceId)
        );
        setSections(resp.data[subSurveyIndex].sections);
        setSectionsLoading(false);
    };

    const getSectionQuestions = async () => {
        setQuestionsLoading(true);
        const resp = await NewSurveyBuilderService.loadSection(id, selectedSectionId);
        const questionsWithoutSelected = prepareQuestionList(resp.data.questions);

        setSectionQuestions(questionsWithoutSelected);
        setQuestionsLoading(false);
    };

    const prepareQuestionList = (questions) => {
        return questions
            .filter((question) => question.sequenceId !== selectedQuestion.sequenceId)
            .filter((question) => !selectedQuestion.visibility.some((v) => v.sourceQuestion === question.sequenceId));
    };

    const callVisibilityAPI = async (operation, body) => {
        if (groupId) {
            return await NewSurveyBuilderService.setupGroupVisibility(id, operation, body);
        }
        return await NewSurveyBuilderService.setupVisibility(id, operation, body);
    };

    const updateQuestionVisibility = (copyQuestion, alreadyHaveVisibility) => {
        if (alreadyHaveVisibility) {
            copyQuestion.visibility = copyQuestion.visibility.filter((v) => v.sequenceId !== answer.sequenceId);
        } else {
            copyQuestion.visibility.push({
                section: selectedSection.sequenceId,
                sequenceId: answer.sequenceId,
                sourceQuestion: selectedQuestion.sequenceId,
                targetQuestion: copyQuestion.sequenceId
            });
        }
        return copyQuestion;
    };

    const getChangedPositionQuestions = (originalQuestions, sortedQuestions) => {
        return originalQuestions.filter((question, index) => {
            const sortedIndex = sortedQuestions.findIndex((q) => q.sequenceId === question.sequenceId);
            return sortedIndex !== index;
        });
    };

    const applyVisibility = async (question) => {
        const alreadyHaveVisibility = question.visibility.some((v) => v.sequenceId === answer.sequenceId);
        const copyQuestion = cloneDeep(question);
        const body = buildVisibilityBody(question);
        const operation = alreadyHaveVisibility ? "remove" : "add";

        const isAnswerHasVisibilityResponse = await callVisibilityAPI(operation, body);
        updateAnswer("hasVisibility", isAnswerHasVisibilityResponse.data, answerIndex, false);

        const updatedQuestion = updateQuestionVisibility(copyQuestion, alreadyHaveVisibility);
        const updatedQuestionList = sectionQuestions.map((q) => (question.sequenceId === q.sequenceId ? updatedQuestion : q));
        setSectionQuestions(updatedQuestionList);

        let finalSortedQuestions;

        if (groupId) {
            const updatedGroup = group.questions.map((q) => (question.sequenceId === q.sequenceId ? updatedQuestion : q));
            const sortedGroupQuestions = sortAccordingToVisibility(updatedGroup);
            const groupQuestion = selectedSection.questions.find((q) => q.sequenceId === groupId);
            groupQuestion.questions = sortedGroupQuestions;

            finalSortedQuestions = sortAccordingToVisibility(selectedSection.questions);
        } else {
            finalSortedQuestions = sortAccordingToVisibility([
                ...selectedSection.questions.map((q) => (question.sequenceId === q.sequenceId ? updatedQuestion : q))
            ]);
        }

        setSelectedSection({ ...selectedSection, questions: finalSortedQuestions });
        let changedQuestions;

        if (groupId) {
            const groupQuestion = selectedSection.questions.find((q) => q.sequenceId === groupId);
            changedQuestions = getChangedPositionQuestions(groupQuestion.questions, finalSortedQuestions);
        } else {
            changedQuestions = getChangedPositionQuestions(selectedSection.questions, finalSortedQuestions);
        }
        for (const changedQuestion of changedQuestions) {
            let newIndex;
            if (groupId) {
                newIndex = selectedSection.questions
                    .find((q) => q.sequenceId === groupId)
                    .questions.findIndex((q) => q.sequenceId === changedQuestion.sequenceId);
                await NewSurveyBuilderService.swapQuestionInGroup(
                    id,
                    selectedSection.sequenceId,
                    groupId,
                    changedQuestion.sequenceId,
                    newIndex
                );
            } else {
                newIndex = finalSortedQuestions.findIndex((q) => q.sequenceId === changedQuestion.sequenceId);
                await NewSurveyBuilderService.swapQuestion(id, selectedSection.sequenceId, changedQuestion.sequenceId, newIndex);
            }
        }
    };

    const buildVisibilityBody = (question) => {
        return groupId
            ? {
                  answer: answer.sequenceId,
                  group: groupId,
                  sourceQuestion: selectedQuestion.sequenceId,
                  targetQuestion: question.sequenceId,
                  targetSection: selectedSectionId
              }
            : {
                  answer: answer.sequenceId,
                  sourceQuestion: selectedQuestion.sequenceId,
                  targetSection: selectedSectionId,
                  sourceSection: selectedSection.sequenceId,
                  targetQuestion: question.sequenceId
              };
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <div className="modal-title">Visibility {groupId && "(inside group)"}</div>
                    <div className="modal-search-close">
                        <input type="text" placeholder="Search" />
                        <img src={close} alt="close-icon" onClick={closeModal} />
                    </div>
                </div>
                <div className="modal-body">
                    <div className="sections-navigation">
                        {sectionsLoading ? (
                            <Spinner data-testid="sections-spinner" />
                        ) : (
                            sections.map((section) => (
                                <div
                                    key={section.sequenceId}
                                    className={classNames("section-item", {
                                        selected: selectedSectionId === section.sequenceId || groupId
                                    })}
                                    onClick={() => setSelectedSectionId(section.sequenceId)}
                                >
                                    {groupId ? section.text : section.name}
                                </div>
                            ))
                        )}
                    </div>
                    <div className="visibility-questions-list">
                        {questionsLoading ? (
                            <Spinner data-testid="questions-spinner" />
                        ) : (
                            sectionQuestions.map((question) => (
                                <div key={question.sequenceId}>
                                    <SimpleCheckBoxComponent
                                        onClick={() => applyVisibility(question)}
                                        isSelected={question.visibility.some((v) => v.sequenceId === answer.sequenceId)}
                                    />
                                    <div onClick={() => applyVisibility(question)}>{question.text}</div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VisibilityPopup;
