import React, { useContext, useEffect, useState } from "react";
import "./CreateAppPopupComponent.scss";
import close from "../../assets/images/close-icon.svg";
import AppService from "../../service/AppService";
import { useHistory } from "react-router-dom";
import Application from "../../helper/Application/Application";
import { LanguageService } from "../../service/LanguageService";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";
import ScoringTab from "./tabs/ScoringTab/ScoringTab/ScoringTab";
import InfoTab from "./tabs/InfoTab/Infotab";
import SubSurveyAndSectionTab from "./tabs/SubSurveyAndSectionTab/SubSurveyAndSectionTab";
import AcceptanceCriteriaTab from "./tabs/AcceptanceCriteriaTab/AcceptanceCriteriaTab";
import SelectButtonBasic from "./buttons/SelectButtonBasic/SelectButtonBasic";
import classNames from "classnames";
import ProgressBar from "../profgresBar/ProgressBar";
import SelectButtonGc from "./buttons/SelectButtonGc/SelectButtonGс";
import { ReactComponent as Spinner } from "../../assets/images/grid.svg";

function CreateAppPopupComponent({
    app,
    onClose,
    progressBarStatus,
    progressBar,
    progressBarLong,
    selectFirstSection,
    selectSecondSection,
    selectThirdSection,
    selectFourthSection,
    selectFirstSectionLongBar,
    selectSecondSectionLongBar,
    selectThirdSectionLongBar
}) {
    const context = useContext(SurveyBuilderContext);
    const history = useHistory();
    const [sections, setSections] = useState(app ? app.allSubSurveySections : []);
    const [subSurveys, setSubSurveys] = useState(app ? app.subSurveys : []);
    const [appType, setAppType] = useState(app ? app.type : "");
    const [ageFrom, setAgeFrom] = useState(app ? app.ageFrom : "");
    const [ageTo, setAgeTo] = useState(app ? app.ageTo : "");
    const [title, setTitle] = useState(app ? app.title : "");
    const [description, setDescription] = useState(app ? app.description : "");
    const [selectedStates, setSelectedStates] = useState(app?.disqualificationRules?.states ?? []);
    const [allowedBmi, setAllowedBmi] = useState(app?.disqualificationRules?.allowedBmi);
    const [allowedAge, setAllowedAge] = useState(app?.disqualificationRules?.allowedAge);
    const [bmiScore, setBmiScore] = useState(app ? app.bmiScore : []);
    const [ageScore, setAgeScore] = useState(app ? app.ageScore : []);
    const [ageScoringNotValid, setAgeScoringNotValid] = useState(false);
    const [bmiScoringNotValid, setBmiScoringNotValid] = useState(false);

    const [showCreateAppLoader, setShowCreateAppLoader] = useState(false);

    useEffect(() => {
        scoringValidation(ageScore, "age");
        scoringValidation(bmiScore, "bmi");
    }, [ageScore, bmiScore]);

    const scoringValidation = (scoringItems, target) => {
        const validationItems = [];
        scoringItems.forEach((item) => {
            switch (item.operator) {
                case "Between":
                    validationItems.push(AppService.filledArray(parseInt(item.valueFrom), parseInt(item.valueTo)));
                    break;
                case "\u003c":
                    validationItems.push(AppService.filledArray(0, parseInt(item.valueFrom) - 1));
                    break;
                case "\u2264":
                    validationItems.push(AppService.filledArray(0, parseInt(item.valueFrom)));
                    break;
                case "\u003e":
                    validationItems.push(AppService.filledArray(parseInt(item.valueFrom) + 1, 100));
                    break;
                case "\u2265":
                    validationItems.push(AppService.filledArray(parseInt(item.valueFrom), 100));
                    break;
                case "\u003d":
                    validationItems.push(AppService.filledArray(parseInt(item.valueFrom), parseInt(item.valueFrom)));
                    break;
            }
        });
        const flatItems = validationItems.flat();
        if (new Set(flatItems).size !== flatItems.length) {
            target === "age" ? setAgeScoringNotValid(true) : setBmiScoringNotValid(true);
        } else {
            target === "age" ? setAgeScoringNotValid(false) : setBmiScoringNotValid(false);
        }
    };
    const navigateToBuilder = () => {
        if (isFormValid()) {
            const preparedAllowedBmi = allowedBmi && (allowedBmi.from || allowedBmi.to) ? allowedBmi : undefined;
            const preparedAllowedAge = allowedAge && (allowedAge.from || allowedAge.to) ? allowedAge : undefined;
            const newSubSectionStructure = subSurveys.map((item, index) => {
                return { ...item, sections: sections[index] };
            });

            const newApp = new Application(
                title,
                description,
                appType,
                "",
                app?.user || "",
                new Date(),
                app?.region || "",
                ageFrom,
                ageTo,
                selectedStates,
                preparedAllowedBmi,
                preparedAllowedAge,
                bmiScore,
                ageScore,
                newSubSectionStructure,
                app?.internationalizationSupport || false
            );

            const postObject = {
                name: title,
                type: appType,
                description: description,
                jsonStructure: JSON.stringify(newApp)
            };
            setShowCreateAppLoader(true);
            if (app) {
                AppService.getAxios()
                    .put("/api/survey", {
                        id: AppService.selectedApp.id,
                        name: title,
                        type: appType,
                        description: description,
                        internationalizationSupport: app?.internationalizationSupport || false,
                        jsonStructure: JSON.stringify(newApp)
                    })
                    .then((response) => {
                        AppService.setSelectedApp(response);
                        AppService.selectedApp.status = response.data.status;
                        AppService.selectedApp.surveyId = response.data.surveyId;
                        AppService.selectedApp.type = response.data.type;
                        AppService.selectedApp.internationalizationSupport = response.data.internationalizationSupport;
                        if (app) {
                            onClose(false);
                        } else {
                            history.push("/builder");
                        }
                    })
                    .finally(() => setShowCreateAppLoader(false));
            } else {
                AppService.getAxios()
                    .post("/api/survey", postObject)
                    .then((response) => {
                        AppService.setSelectedApp(response);
                        AppService.selectedApp.surveyId = response.data.surveyId;
                        AppService.selectedApp.version = response.data.version;
                        AppService.selectedApp.status = response.data.status;
                        AppService.selectedApp.active = response.data.active;
                        context.surrogateDemoQuestions();
                        if (app) {
                            onClose(false);
                        } else {
                            history.push(AppService.newBuilderVersion ? `/new/${response.data.id}` : "/builder");
                        }
                    })
                    .finally(() => setShowCreateAppLoader(false));
            }
        }
    };
    const appTypeChanged = (value) => {
        setAppType(value);
        setSections([]);
        setSubSurveys([]);

        if (value === "IP" || value === "ED") {
            clearScoreAndAcceptanceCriteria();
        }
    };
    const isFormValid = () => {
        return (
            appType.length > 0 &&
            title.length > 0 &&
            description.length > 0 &&
            !isSectionsFilled() &&
            !isSubSurveysFilled() &&
            !ageScoringNotValid &&
            !bmiScoringNotValid &&
            !showCreateAppLoader
        );
    };
    const clearScoreAndAcceptanceCriteria = () => {
        setAllowedAge(null);
        setAllowedBmi(null);
        setAgeFrom(null);
        setAgeTo(null);
        setBmiScore([]);
        setAgeScore([]);
    };
    const isSectionsFilled = () => {
        return (
            sections.length === 0 ||
            sections.some((section) => section.length === 0 || LanguageService.getTranslatedText(section[0].name)?.length === 0)
        );
    };
    const isSubSurveysFilled = () => {
        return (
            subSurveys.length === 0 ||
            subSurveys.some((sub) => LanguageService.getTranslatedText(sub.name)?.length === 0 || sub.show.length === 0)
        );
    };
    const buildTabContent = (surveyType) => {
        const selectedProgressBar = surveyType === "GC" ? progressBar : progressBarLong;

        switch (progressBarStatus.inProgress) {
            case selectedProgressBar.first:
                return (
                    <InfoTab
                        appType={appType}
                        appTypeChanged={appTypeChanged}
                        title={title}
                        setTitle={setTitle}
                        description={description}
                        setDescription={setDescription}
                    />
                );
            case selectedProgressBar.second:
                return (
                    <SubSurveyAndSectionTab
                        appType={appType}
                        subSurveys={subSurveys}
                        sections={sections}
                        setSubSurveys={setSubSurveys}
                        setSections={setSections}
                    />
                );
            case selectedProgressBar.third:
                return (
                    <AcceptanceCriteriaTab
                        selectedStates={selectedStates}
                        setSelectedStates={setSelectedStates}
                        allowedBmi={allowedBmi}
                        allowedAge={allowedAge}
                        setAllowedBmi={setAllowedBmi}
                        setAllowedAge={setAllowedAge}
                    />
                );
            case selectedProgressBar.fourth:
                return <ScoringTab ageScore={ageScore} bmiScore={bmiScore} setBmiScore={setBmiScore} setAgeScore={setAgeScore} />;
        }
    };
    const modalTitle = app ? "Edit application" : "Create an application";
    const saveButtonTitle = showCreateAppLoader ? <Spinner /> : app ? "Save an application" : "Create an application";

    return (
        <div className="create-app-popup-overlay">
            <div className="create-app-popup">
                <div className="header">
                    <span>{modalTitle}</span>
                    <span onClick={() => onClose(false)}>
                        <img src={close} alt="close" />
                    </span>
                </div>
                {appType === "GC" ? (
                    <ProgressBar
                        first={progressBar.first}
                        second={progressBar.second}
                        third={progressBar.third}
                        fourth={progressBar.fourth}
                        long={false}
                        selectFirstSection={selectFirstSection}
                        selectSecondSection={selectSecondSection}
                        selectThirdSection={selectThirdSection}
                        selectFourthSection={selectFourthSection}
                    />
                ) : (
                    <ProgressBar
                        first={progressBarLong.first}
                        second={progressBarLong.second}
                        third={progressBarLong.third}
                        long={true}
                        selectFirstSection={selectFirstSectionLongBar}
                        selectSecondSection={selectSecondSectionLongBar}
                        selectThirdSection={selectThirdSectionLongBar}
                    />
                )}
                {buildTabContent(appType)}
                <div className="footer-shadow" />
                <div className="footer">
                    {ageScoringNotValid || bmiScoringNotValid ? (
                        <span className="scoring-validation">
                            Your scoring criteria is not valid <br /> Please change your scoring criteria
                        </span>
                    ) : null}
                    {appType === "GC" ? (
                        <SelectButtonGc
                            progressBar={progressBar}
                            progressBarStatus={progressBarStatus}
                            selectFirstSection={selectFirstSection}
                            selectSecondSection={selectSecondSection}
                            selectThirdSection={selectThirdSection}
                            selectFourthSection={selectFourthSection}
                            secondSectionDisabled={isSubSurveysFilled() || isSectionsFilled()}
                            createButtonClassName={classNames("create-app-button", { disabled: !isFormValid() })}
                            navigateToBuilder={navigateToBuilder}
                            saveButtonTitle={saveButtonTitle}
                        />
                    ) : (
                        <SelectButtonBasic
                            progressBarStatus={progressBarStatus}
                            progressBarLong={progressBarLong}
                            selectFirstSectionLongBar={selectFirstSectionLongBar}
                            selectSecondSectionLongBar={selectSecondSectionLongBar}
                            selectThirdSectionLongBar={selectThirdSectionLongBar}
                            secondSectionDisabled={isSubSurveysFilled() || isSectionsFilled()}
                            createButtonClassName={classNames("create-app-button", { disabled: !isFormValid() })}
                            navigateToBuilder={navigateToBuilder}
                            saveButtonTitle={saveButtonTitle}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default CreateAppPopupComponent;
