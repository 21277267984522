import React, { useContext, useEffect, useState } from "react";

import { NewSurveyBuilderContext } from "../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { LanguageService } from "../../../../../service/LanguageService";
import AppService from "../../../../../service/AppService";

import DropDown from "../../../../../components/dropDowns/dropDown/DropDownComponent";
import SimpleCheckBox from "../../../../../components/simpleCheckBox/SimpleCheckBoxComponent";
import TitleInput from "../../../../../components/TitleInput/TitleInput";
import NotesVariables from "./NotesVariables/NotesVariables";

import "./QuestionSettingsEditor.scss";
import { NewSurveyBuilderService } from "../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";
import RelationDialogComponent from "./Relation/RelationDialogComponent";
import { useParams } from "react-router-dom";
import { showErrorNotification, showSuccessNotification } from "../../../../../helper/NotificationHelper";

function QuestionSettingsEditor({
    isQuestion,
    isGroupQuestion,
    isDynamicGroup,
    isLinkToSalesforceFieldVisible,
    prepareSalesForceFields,
    handleQuestionSalesForceItem,
    salesForceItems
}) {
    const { id } = useParams();
    const [showRelationDialog, setShowRelationDialog] = useState(false);
    const [salesForceFields, setSalesForceFields] = useState([]);

    const { surveyData, selectedQuestion, selectedSection, updateQuestion } = useContext(NewSurveyBuilderContext);

    const { text, type, noteContent, hint, noAnswerScore, salesForceField } = selectedQuestion;

    const [isDisabled, setIsDisabled] = useState(surveyData?.status === "PUBLISH");
    const [selectedDynamicRelation, setSelectedDynamicRelation] = useState("");

    useEffect(() => {
        setSelectedDynamicRelation(selectedSection.questions.find((q) => q.sequenceId === selectedQuestion.dynamicGroupSource)?.text);
    }, [selectedQuestion]);

    useEffect(() => {
        selectedQuestion && getSalesForceFields();
    }, []);

    useEffect(() => {
        selectedQuestion && prepareSalesForceFields(salesForceFields);
    }, [selectedQuestion]);

    useEffect(() => {
        setIsDisabled(surveyData?.status === "PUBLISH");
    }, [surveyData?.status]);

    const getSalesForceFields = async () => {
        const response = await NewSurveyBuilderService.loadSalesForceFields(surveyData.type);
        setSalesForceFields(response.data);
        prepareSalesForceFields(response.data);
    };
    const translatedText = (textKey) => LanguageService.getTranslatedText(textKey, "EU") || "";

    const showRelation = () => {
        return (
            selectedQuestion?.type === "DROPDOWN_MULTISELECT" ||
            selectedQuestion?.type === "SELECT_MANY" ||
            selectedQuestion?.type === "MULTI_SELECT" ||
            selectedQuestion?.jsonRelation
        );
    };

    const closeRelationDialog = () => {
        setShowRelationDialog(false);
    };

    const getQuestionsSource = () => {
        if (selectedQuestion?.groupId) {
            return selectedSection?.questions.find((q) => q.sequenceId === selectedQuestion?.groupId);
        }
        return selectedSection;
    };

    const getRelatedQuestion = () => {
        let location;
        let questionText;
        if (selectedQuestion?.groupId) {
            const group = selectedSection?.questions.find((q) => q.sequenceId === selectedQuestion?.groupId);
            const relatedQuestion = group.questions.find((gQ) => gQ.sequenceId === selectedQuestion?.jsonRelation?.relatedQuestionSequence);
            location = group.text + " -> Question " + (group.questions.indexOf(relatedQuestion) + 1);
            questionText = relatedQuestion.text;
        } else {
            const relatedQuestion = selectedSection?.questions?.find(
                (q) => q.sequenceId === selectedQuestion?.jsonRelation?.relatedQuestionSequence
            );
            location = "Question " + (selectedSection?.questions.indexOf(relatedQuestion) + 1);
            questionText = relatedQuestion.text;
        }
        return (
            <div className="related-question">
                <span>{location}</span>
                <p>{questionText}</p>
            </div>
        );
    };

    const saveRelation = (selectedRelatedQuestion) => {
        const body = {
            source: selectedQuestion?.sequenceId,
            target: selectedRelatedQuestion?.sequenceId,
            section: selectedSection?.sequenceId,
            group: selectedQuestion?.groupId || null,
            items: selectedQuestion.answers.map((a) => a.text)
        };
        let response;
        if (body.group) {
            response = NewSurveyBuilderService.saveRelationInGroup(id, body);
        } else {
            response = NewSurveyBuilderService.saveRelation(id, body);
        }
        response.then((resp) => {
            setShowRelationDialog(false);
            const sourceJsonRelation = resp.data.find((r) => r.source);
            const targetJsonRelation = resp.data.find((r) => !r.source);

            let target;
            if (targetJsonRelation?.groupSequence) {
                target = selectedSection?.questions
                    .find((q) => q.sequenceId === targetJsonRelation?.groupSequence)
                    .questions.find((q) => q.sequenceId === targetJsonRelation.questionSequence);
            } else {
                target = selectedSection?.questions.find((q) => q.sequenceId === targetJsonRelation.questionSequence);
            }
            updateQuestion("jsonRelation", sourceJsonRelation);
            target.jsonRelation = targetJsonRelation;
        });
    };

    const removeRelation = () => {
        const relationId = selectedQuestion?.jsonRelation?.relationId;
        const targetQuestionSequence = selectedQuestion?.jsonRelation?.relatedQuestionSequence;
        const groupId = selectedQuestion?.jsonRelation?.groupSequence;

        let response;
        if (selectedQuestion?.groupId) {
            response = NewSurveyBuilderService.removeRelationFromGroup(
                id,
                selectedSection?.sequenceId,
                selectedQuestion?.groupId,
                relationId
            );
        } else {
            response = NewSurveyBuilderService.removeRelation(id, selectedSection?.sequenceId, relationId);
        }
        response.then(() => {
            let target;
            if (groupId) {
                target = selectedSection?.questions
                    .find((q) => q.sequenceId === groupId)
                    .questions.find((q) => q.sequenceId === targetQuestionSequence);
            } else {
                target = selectedSection?.questions.find((q) => q.sequenceId === targetQuestionSequence);
            }
            updateQuestion("jsonRelation", null);
            target.jsonRelation = null;
        });
    };

    const renderTitleInput = () => {
        if (isGroupQuestion || isDynamicGroup) {
            return <TitleInput label="Title" value={translatedText(text)} isDisabled={isDisabled} />;
        }

        return (
            <>
                <TitleInput label="Text" value={translatedText(text)} isDisabled={isDisabled} />
                {type === AppService.questionType.NOTE ? (
                    <TitleInput label="Content" name="noteContent" value={translatedText(noteContent)} isDisabled={isDisabled} />
                ) : (
                    <TitleInput label="Hint" name="hint" placeholder="Enter a hint" value={translatedText(hint)} isDisabled={isDisabled} />
                )}
            </>
        );
    };

    const setupDynamicRelation = async (text, question) => {
        const targetId = selectedQuestion.sequenceId;
        const sourceId = question.sequenceId;

        try {
            const questionAlreadyHaveAnotherRelation = selectedSection.questions.find((q) => q.dynamicGroupTarget === targetId);
            if (questionAlreadyHaveAnotherRelation) {
                await NewSurveyBuilderService.removeDynamicGroupRelation(
                    id,
                    selectedSection.sequenceId,
                    targetId,
                    questionAlreadyHaveAnotherRelation.sequenceId
                );
                delete questionAlreadyHaveAnotherRelation.dynamicGroupTarget;
            }
            await NewSurveyBuilderService.addDynamicGroupRelation(id, selectedSection.sequenceId, targetId, sourceId);
            showSuccessNotification("Relation was successfully saved");
            const sourceQ = selectedSection.questions.find((q) => q.sequenceId === sourceId);
            selectedQuestion.dynamicGroupSource = sourceId;
            sourceQ.dynamicGroupTarget = targetId;
            setSelectedDynamicRelation(text);
        } catch (e) {
            showErrorNotification("Saving relation failed", "Please try again later");
        }
    };
    const removeDynamicRelation = async () => {
        const targetId = selectedQuestion.sequenceId;
        const sourceId = selectedQuestion.dynamicGroupSource;
        try {
            await NewSurveyBuilderService.removeDynamicGroupRelation(id, selectedSection.sequenceId, targetId, sourceId);
            setSelectedDynamicRelation("");
            showSuccessNotification("Relation was successfully removed");
            const sourceQ = selectedSection.questions.find((q) => q.sequenceId === sourceId);
            delete selectedQuestion.dynamicGroupSource;
            delete sourceQ.dynamicGroupTarget;
        } catch (e) {
            showErrorNotification("Removing relation failed", "Please try again later");
        }
    };

    return (
        <>
            <div className="main-question-edit">
                <NotesVariables />
                {renderTitleInput()}
                {isLinkToSalesforceFieldVisible && (
                    <div className="salesforce-question-box">
                        <DropDown
                            label="Link to Salesforce"
                            selectedItem={salesForceField?.fieldLabel}
                            searchable={true}
                            placeholder="Please select"
                            onChange={handleQuestionSalesForceItem}
                            isDisabled={isDisabled}
                            items={salesForceItems}
                        />
                    </div>
                )}
                {isQuestion && (
                    <div className="edit-row">
                        <TitleInput
                            type="number"
                            placeholder="V."
                            className="weight"
                            value={noAnswerScore}
                            name="noAnswerScore"
                            label="Add score if no answer"
                            isDisabled={isDisabled}
                        />
                        {showRelation() && (
                            <div className="relation-box">
                                <div className="relation-header">
                                    <label>Related Question</label>
                                    {!isDisabled && selectedQuestion?.jsonRelation?.source && (
                                        <button onClick={() => removeRelation()}>X</button>
                                    )}
                                </div>

                                {selectedQuestion?.jsonRelation ? (
                                    getRelatedQuestion()
                                ) : (
                                    <div>
                                        <button onClick={() => setShowRelationDialog(true)} disabled={isDisabled}>
                                            Add relation
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {isDynamicGroup && (
                    <>
                        <TitleInput
                            label="Title for every group"
                            type="text"
                            placeholder="Enter a title"
                            name="instanceGroupName"
                            value={translatedText(selectedQuestion.instanceGroupName)}
                            isDisabled={isDisabled}
                        />
                        {selectedQuestion?.instanceGroupName?.length > 0 && (
                            <div className="column-title">
                                <SimpleCheckBox
                                    onClick={(value, isSelected) => updateQuestion("showInstanceCount", isSelected, true)}
                                    label="Show group count"
                                    isSelected={selectedQuestion.showInstanceCount}
                                    isDisabled={isDisabled}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            {showRelationDialog && (
                <RelationDialogComponent
                    currentSequence={selectedQuestion?.sequenceId}
                    source={getQuestionsSource()}
                    saveRelation={saveRelation}
                    closeDialog={closeRelationDialog}
                />
            )}
            {isDynamicGroup && (
                <div className="dynamic-relation">
                    <DropDown
                        label="Setup dynamic relation"
                        placeholder="Select question to create relation"
                        items={selectedSection.questions.filter((q) =>
                            [AppService.questionType.NUMBER, AppService.questionType.DROPDOWN].includes(q.type)
                        )}
                        selectedItem={selectedDynamicRelation}
                        onChange={setupDynamicRelation}
                    />
                    {selectedDynamicRelation && (
                        <button className="remove-dynamic-relation" onClick={removeDynamicRelation}>
                            Remove relation
                        </button>
                    )}
                </div>
            )}
        </>
    );
}

export default QuestionSettingsEditor;
