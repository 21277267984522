import React, { useState } from "react";

function ProgressWrapper({ children }) {
    const progressBarStatus = {
        inProgress: "in-progress",
        notFinished: "not-finished",
        finished: "finished"
    };

    const [progressBar, setProgressBar] = useState({
        first: progressBarStatus.inProgress,
        second: progressBarStatus.notFinished,
        third: progressBarStatus.notFinished,
        fourth: progressBarStatus.notFinished
    });
    const [progressBarLong, setProgressBarLong] = useState({
        first: progressBarStatus.inProgress,
        second: progressBarStatus.notFinished,
        third: progressBarStatus.notFinished
    });

    const selectFirstSection = () =>
        setProgressBar({
            first: progressBarStatus.inProgress,
            second: progressBarStatus.notFinished,
            third: progressBarStatus.notFinished,
            fourth: progressBarStatus.notFinished
        });
    const selectSecondSection = () =>
        setProgressBar({
            first: progressBarStatus.finished,
            second: progressBarStatus.inProgress,
            third: progressBarStatus.notFinished,
            fourth: progressBarStatus.notFinished
        });
    const selectThirdSection = () =>
        setProgressBar({
            first: progressBarStatus.finished,
            second: progressBarStatus.finished,
            third: progressBarStatus.inProgress,
            fourth: progressBarStatus.notFinished
        });
    const selectFourthSection = () =>
        setProgressBar({
            first: progressBarStatus.finished,
            second: progressBarStatus.finished,
            third: progressBarStatus.finished,
            fourth: progressBarStatus.inProgress
        });
    const selectFirstSectionLongBar = () =>
        setProgressBarLong({
            first: progressBarStatus.inProgress,
            second: progressBarStatus.notFinished,
            third: progressBarStatus.notFinished
        });
    const selectSecondSectionLongBar = () =>
        setProgressBarLong({
            first: progressBarStatus.finished,
            second: progressBarStatus.inProgress,
            third: progressBarStatus.notFinished
        });
    const selectThirdSectionLongBar = () =>
        setProgressBarLong({
            first: progressBarStatus.finished,
            second: progressBarStatus.finished,
            third: progressBarStatus.inProgress
        });

    return (
        <div>
            {React.cloneElement(children, {
                progressBar,
                progressBarLong,
                progressBarStatus,
                selectFirstSection,
                selectSecondSection,
                selectThirdSection,
                selectFourthSection,
                selectFirstSectionLongBar,
                selectSecondSectionLongBar,
                selectThirdSectionLongBar
            })}
        </div>
    );
}

export default ProgressWrapper;
