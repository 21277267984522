import React, { useContext } from "react";
import { NewSurveyBuilderContext } from "../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { ReactComponent as Edit } from "../../../../../../assets/images/edit.svg";

const SurveyInfoSection = ({ onEditClick }) => {
    const { surveyData, isInConstruct } = useContext(NewSurveyBuilderContext);

    return (
        <div className="survey-info-section">
            <div className="survey-section-header">
                <span>info</span>
                {isInConstruct() && (
                    <span className="appEdit" onClick={() => onEditClick(1)}>
                        <Edit />
                    </span>
                )}
            </div>
            <div className="survey-info-data">
                <div className="survey-info-core">
                    <div className="survey-info-type">{surveyData.type}</div>
                    <div className="survey-info-version">{surveyData.version}</div>
                </div>
                <div className="survey-info-title">{surveyData.title}</div>
                <div className="survey-info-description">{surveyData.description}</div>
            </div>
        </div>
    );
};
export default SurveyInfoSection;
