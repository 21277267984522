import React from "react";
import "./FlyoutComponent.scss";

class FlyoutComponent extends React.Component {
    render() {
        return (
            <Expire delay="3000" onClose={this.props.onClose}>
                <div className={`flyout flyout-${this.props.type}`}>{this.props.message}</div>
            </Expire>
        );
    }
}

class Expire extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: true };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.children !== this.props.children) {
            this.setTimer();
            this.setState({ visible: true });
        }
    }

    componentDidMount() {
        this.setTimer();
    }

    setTimer() {
        if (this._timer != null) {
            clearTimeout(this._timer);
        }

        this._timer = setTimeout(
            function () {
                this.setState({ visible: false });
                this._timer = null;
                this.props.onClose();
            }.bind(this),
            this.props.delay
        );
    }

    componentWillUnmount() {
        clearTimeout(this._timer);
    }

    render() {
        return this.state.visible ? <div>{this.props.children}</div> : <span />;
    }
}

export default FlyoutComponent;
