import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/login/LoginComponent";
import PrivateRoute from "./helper/PrivateRoute";
import Apps from "./pages/apps/AppsComponent";
import Profile from "./pages/profile/ProfileComponent";
import Builder from "./pages/builder/BuilderComponent";
import SurveyBuilderStore from "./SurveyBuilderContext";
import NewSurveyBuilderComponent from "./pages/new-builder/NewSurveyBuilderComponent";
import SettingsComponent from "./pages/settingsComponent/SettingsComponent";

class Routes extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <PrivateRoute path="/apps">
                        <Apps />
                    </PrivateRoute>
                    <PrivateRoute path="/profile">
                        <Profile />
                    </PrivateRoute>
                    <PrivateRoute path="/builder">
                        <SurveyBuilderStore>
                            <Builder />
                        </SurveyBuilderStore>
                    </PrivateRoute>
                    <PrivateRoute path="/settings">
                        <SettingsComponent />
                    </PrivateRoute>
                    <Route path="/new/:id">
                        <NewSurveyBuilderComponent />
                    </Route>
                    <PrivateRoute path="/*">
                        <Apps />
                    </PrivateRoute>
                </Switch>
            </Router>
        );
    }
}

export default Routes;
