import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({
    first,
    second,
    third,
    fourth,
    long,
    selectFirstSection,
    selectSecondSection,
    selectThirdSection,
    selectFourthSection
}) => {
    const longBar = long ? "long" : null;
    const firstBarText = long ? "1. INFO & SECTIONS" : "1. INFO";
    const fourthBarText = long ? "3. AGE & BMI SCORING" : "4. AGE & BMI SCORING";

    return (
        <div className="progress-bar-wrapper">
            <div className="header-shadow" />
            <div className="progress-bar-item-wrapper">
                <div className={`progress-bar-item ${first}`} onClick={selectFirstSection}>
                    <span>{firstBarText}</span>
                    <div className={`progress-bar-indicator ${first} ${longBar}`} />
                </div>
                <div className={`progress-bar-item ${second}`} onClick={selectSecondSection}>
                    <span>2. SUBSURVEYS & SECTIONS</span>
                    <div className={`progress-bar-indicator ${second} ${longBar} `} />
                </div>
                {third && (
                    <div className={`progress-bar-item ${third}`} onClick={selectThirdSection}>
                        <span>3. ACCEPTANCE CRITERIA</span>
                        <div className={`progress-bar-indicator ${third} ${longBar}`} />
                    </div>
                )}
                {fourth && (
                    <div className={`progress-bar-item ${fourth}`} onClick={selectFourthSection}>
                        <span>{fourthBarText}</span>
                        <div className={`progress-bar-indicator ${fourth}`} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProgressBar;
