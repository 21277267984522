import text from "../../../../../assets/images/types/text.svg";
import multiply from "../../../../../assets/images/types/multiply.svg";
import upload from "../../../../../assets/images/types/upload.svg";
import dropdown from "../../../../../assets/images/types/dropdown.svg";
import yesNo from "../../../../../assets/images/types/yes-no.svg";
import single from "../../../../../assets/images/types/single.svg";
import AppService from "../../../../../service/AppService";

const textTypeQuestion = {
    id: AppService.questionType.TEXT_GROUP,
    button: text,
    tooltipId: "text-tooltip",
    tooltipText: "Text",
    items: [
        { id: AppService.questionType.TEXT, title: "Free text" },
        { id: AppService.questionType.DATE, title: "Date picker" },
        { id: AppService.questionType.NUMBER, title: "Number" },
        { id: AppService.questionType.ADDRESS, title: "Address" },
        { id: AppService.questionType.PHONE, title: "Phone" }
    ]
};

const checkBoxTypeQuestion = {
    id: AppService.questionType.DROPDOWN_GROUP,
    button: multiply,
    tooltipId: "checkbox-tooltip",
    tooltipText: "Several options",
    items: [
        { id: AppService.questionType.SELECT_MANY, title: "Picklist" },
        {
            id: AppService.questionType.MULTI_SELECT,
            title: "Picklist (Multi-Select)"
        }
    ]
};

const photoUploadTypeQuestion = {
    id: AppService.questionType.UPLOAD_GROUP,
    button: upload,
    tooltipId: "upload-tooltip",
    tooltipText: "Photo Upload",
    items: [
        { id: AppService.questionType.UPLOAD, title: "Photo Upload (single)" },
        { id: AppService.questionType.MULTI_UPLOAD, title: "Photo Upload (multi)" }
    ]
};

const dropDownTypeQuestion = {
    id: AppService.questionType.DROPDOWNS_GROUP,
    button: dropdown,
    tooltipId: "dropdown-tooltip",
    tooltipText: "Dropdown",
    items: [
        { id: AppService.questionType.DROPDOWN, title: "Dropdown" },
        {
            id: AppService.questionType.DROPDOWN_MULTISELECT,
            title: "Dropdown (Multi-Select)"
        }
    ]
};

export const getTypesConfig = (isDynamicGroupQuestion) => {
    let baseTypes = [
        {
            id: AppService.questionType.YES_NO,
            button: yesNo,
            tooltipId: "yesNo-tooltip",
            tooltipText: "Yes or No"
        },
        {
            id: AppService.questionType.SINGLE_ONE,
            button: single,
            tooltipId: "single-tooltip",
            tooltipText: "Only one option"
        },
        checkBoxTypeQuestion,
        textTypeQuestion,
        dropDownTypeQuestion
    ];

    if (!isDynamicGroupQuestion) {
        baseTypes.push(photoUploadTypeQuestion);
    }

    return baseTypes;
};
