import React from "react";
import { Link, Redirect } from "react-router-dom";
import "./LoginComponent.scss";
import PasswordTextBox from "../../components/passwordTextBox/PasswordTextBoxComponent";
import AccountSecurityLayout from "../../components/accountSecurityLayout/AccountSecurityLayoutComponent";
import Auth from "../../Auth";

class LoginComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            email: "",
            isFormValid: false,
            redirectToProfile: false,
            showLoginError: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };

    handlePasswordChange = (password) => {
        this.setState({ password });
    };

    validateForm = () => {
        return this.isEmailValid() && this.isPasswordValid();
    };

    isEmailValid = () => {
        const { email } = this.state;
        return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
            email
        );
    };

    isPasswordValid = () => {
        const { password } = this.state;
        return password.length > 0;
    };

    doLogin = () => {
        const { email, password } = this.state;
        if (this.validateForm()) {
            const result = Auth.getInstance().login(email, password);
            result.then((value) => {
                if (value) {
                    this.setState({ redirectToProfile: true });
                } else {
                    this.setState({ showLoginError: true });
                }
            });
        } else {
            this.setState({ showLoginError: true });
        }
    };

    render() {
        const { email, redirectToProfile, showLoginError } = this.state;
        const isFormValid = this.validateForm();

        const isEmailValid = email.length > 0 && !this.isEmailValid();
        const emailFieldClassName = isEmailValid ? "field-error" : "";

        if (redirectToProfile) {
            return <Redirect to={"/apps"} push={true} />;
        }

        return (
            <AccountSecurityLayout>
                <h1>Welcome!</h1>
                <p className="security-form-description">Please, sign in to have access to your personal experience.</p>
                <div className="form">
                    <div className="form-item">
                        <label htmlFor="lblEmail">Email</label>
                        <input
                            type="text"
                            id="lblEmail"
                            name="email"
                            className={emailFieldClassName}
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                        {isEmailValid ? <label className="form-error">Email seems to be wrong</label> : ""}
                    </div>

                    <PasswordTextBox title="Password" id="lblPassword" onPasswordChange={this.handlePasswordChange} />

                    {showLoginError ? (
                        <div className="login-error">
                            Sorry, the email/password combination isn’t right. Want to <Link to="/reset">recover your password</Link>?
                        </div>
                    ) : (
                        ""
                    )}

                    {isFormValid ? (
                        <input type="button" value="Login" className="button login-button" onClick={this.doLogin} />
                    ) : (
                        <input type="button" value="Login" className="button login-button disabled" />
                    )}
                </div>
            </AccountSecurityLayout>
        );
    }
}

export default LoginComponent;
