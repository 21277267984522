import jwt_decode from "jwt-decode";
import axios from "axios";
import Constants from "./helper/Constants";

export default class Auth {
    static instance;
    profile = {};
    isAuthenticated = false;

    constructor() {
        this.getProfile = this.getProfile.bind(this);
        this.login = this.login.bind(this);
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new Auth();
        }

        return this.instance;
    }

    async checkAuth() {
        const decoded = this.decode();
        if (decoded?.roles !== "ADMIN") {
            this.isAuthenticated = false;
            this.props.history.push("/login");
            return false;
        }

        return this.getAxios()
            .get(`/api/user`)
            .then((response) => {
                this.profile = response.data;
                this.isAuthenticated = true;
                return this.isAuthenticated && this.profile !== null;
            });
    }

    saveToken(token) {
        localStorage.setItem("jwt", token);
    }

    getToken() {
        return localStorage.getItem("jwt");
    }

    decode() {
        const token = this.getToken();
        if (token) {
            return jwt_decode(token);
        }
        return false;
    }

    cleanToken() {
        localStorage.setItem("jwt", "");
    }

    logout() {
        this.cleanToken();
        this.props.history.push("/login");
    }

    async saveProfile(profile) {
        let result = false;
        try {
            await this.getAxios().put("/api/user", profile);
            result = true;
        } catch (err) {
            result = false;
        }
        return result;
    }

    async changePassword(oldPassword, newPassword) {
        let result = false;
        try {
            await this.getAxios().patch("/api/user/password", {
                oldPassword,
                newPassword
            });
            result = true;
        } catch (err) {
            result = false;
        }
        return result;
    }

    async login(email, password) {
        let result = false;
        try {
            const response = await this.getAxios().post("/auth/login", {
                email,
                password
            });
            this.cleanToken();
            this.saveToken(response.data.accessToken);
            this.getProfile();
            const profile = await this.getAxios().get(`/api/user`);
            this.profile = profile.data;
            const decoded = this.decode();
            if (decoded.roles === "ADMIN") {
                this.isAuthenticated = true;
                result = true;
            } else {
                this.isAuthenticated = false;
            }
        } catch (err) {
            result = false;
        }
        return result;
    }

    async getProfile() {
        return this.getAxios()
            .get(`/api/user`)
            .then((response) => {
                this.profile = response.data;
            });
    }

    getAxios() {
        return axios.create({
            baseURL: Constants.REST_API_URL,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`
            }
        });
    }

    getShortName() {
        if (!this.isAuthenticated) {
            return "";
        }
        const firstName = this.profile.firstName.length > 0 ? this.profile.firstName[0] : "";
        const lastName = this.profile.lastName.length > 0 ? this.profile.lastName[0] : "";
        return `${firstName.toUpperCase()}${lastName.toUpperCase()}`;
    }
}
