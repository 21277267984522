import React from "react";

import "./App.scss";
import { SalesforceFieldService } from "./service/salesforceField/SalesforceFieldService";
import Routes from "./Routes";

class App extends React.Component {
    componentDidMount() {
        SalesforceFieldService.retrieveSpecialSfdcFieldNames();
    }

    render() {
        return <Routes />;
    }
}

export default App;
