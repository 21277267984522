import React from "react";

function BasicPopup({ closePopup, action, header, content }) {
    return (
        <div className="status-popup-overlay">
            <div className="status-popup">
                <div className="container">
                    <div className="header">{header}</div>
                    <div className="content">{content}</div>
                    <div className="buttons">
                        <div className="button-yes" onClick={action}>
                            Yes
                        </div>
                        <div className="button-no" onClick={closePopup}>
                            No
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BasicPopup;
