import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import { NewSurveyBuilderContext } from "../../pages/new-builder/NewSurveyBuilderContext/NewSurveyBuilderContext";

function TitleInput({
    label,
    value: propValue,
    name = "text",
    placeholder = "Enter a text",
    className,
    type = "text",
    index,
    isDisabled = false
}) {
    const { selectedQuestion, updateQuestion, updateAnswer } = useContext(NewSurveyBuilderContext);

    const [inputValue, setInputValue] = useState(propValue || "");
    const [isUserInput, setIsUserInput] = useState(false);

    const FEEDBACK_TEXT = "feedbackText";
    const FEEDBACK_TITLE = "feedbackTitle";
    const ANSWER_TEXT = "answer-text";

    useEffect(() => {
        setInputValue(propValue || "");
    }, [selectedQuestion, selectedQuestion.type]);

    const determineFieldNameAndValue = () => {
        const isFeedbackText = name === FEEDBACK_TEXT;
        const isFeedbackTitle = name === FEEDBACK_TITLE;
        let fieldName = name === ANSWER_TEXT ? "text" : name === "";
        let value;

        if (isFeedbackText || isFeedbackTitle) {
            fieldName = "feedback";
            value = {
                title: isFeedbackTitle ? inputValue : selectedQuestion.answers[index].feedback?.title || "",
                text: isFeedbackText ? inputValue : selectedQuestion.answers[index].feedback?.text || ""
            };
        } else {
            value = inputValue;
        }

        return { fieldName, value };
    };

    useEffect(() => {
        if (!isUserInput) return;

        const update = () => {
            if (index === undefined) {
                updateQuestion(name, inputValue, true);
            } else {
                const { fieldName, value } = determineFieldNameAndValue();
                updateAnswer(fieldName, value, index, true);
            }
        };

        const timer = setTimeout(update, 500);
        return () => clearTimeout(timer);
    }, [inputValue]);

    const handleInputChange = (event) => {
        event.persist();
        const { target } = event;

        const isQuestion = index === undefined;
        const updateFunc = isQuestion ? updateQuestion : updateAnswer;
        let updateArgs;

        if (name === FEEDBACK_TEXT || name === FEEDBACK_TITLE) {
            const feedbackValue = {
                title: name === FEEDBACK_TITLE ? target.value : selectedQuestion.answers[index].feedback?.title || "",
                text: name === FEEDBACK_TEXT ? target.value : selectedQuestion.answers[index].feedback?.text || ""
            };
            updateArgs = ["feedback", feedbackValue, index, false];
        } else {
            const fieldName = name === ANSWER_TEXT ? "text" : name;
            updateArgs = [fieldName, target.value, index, false];
        }

        updateFunc(...updateArgs);
        setIsUserInput(true);
        setInputValue(target.value);
    };

    return (
        <div className="edit-box">
            <label>{label}</label>
            <input
                className={classNames(className)}
                type={type}
                placeholder={placeholder}
                name={name}
                value={inputValue}
                onChange={handleInputChange}
                disabled={isDisabled}
            />
        </div>
    );
}

export default TitleInput;
