import React, { useContext } from "react";
import DropDown from "../../../../../../../components/dropDowns/dropDown/DropDownComponent";
import { NewSurveyBuilderContext } from "../../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import AppService from "../../../../../../../service/AppService";

function SalesForceAnswerDropdown({ answer, salesForceItems, salesForceAnswers, isDisabled = false }) {
    const { selectedQuestion, updateAnswer } = useContext(NewSurveyBuilderContext);
    const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === answer.sequenceId);

    const findTextById = (sourceArray, idField) => {
        const item = sourceArray.find((s) => s && s.id === idField.id);
        return item ? item.text : null;
    };

    const getSelectedSalesForceAnswer = () => {
        if (selectedQuestion.type === AppService.questionType.SELECT_MANY || selectedQuestion.type === AppService.questionType.NUMBER) {
            if (answer.salesForceField) {
                return findTextById(salesForceItems, answer.salesForceField);
            }
        } else if (answer.salesForceAnswerField) {
            return findTextById(salesForceAnswers, answer.salesForceAnswerField);
        }

        return null;
    };

    const salesForceAnswerItemChanged = (text, item) => {
        const value = {
            id: item.id
        };
        updateAnswer("salesForceAnswerField", value, answerIndex, true);
    };

    const salesForceItemChanged = (id, item) => {
        const value = {
            id: item.id,
            fieldName: item.fieldName
        };
        updateAnswer("salesForceField", value, answerIndex, true);
    };
    let salesForceSelect = null;
    switch (selectedQuestion.type) {
        case AppService.questionType.SINGLE_ONE:
        case AppService.questionType.DROPDOWN:
        case AppService.questionType.MULTI_SELECT:
        case AppService.questionType.DROPDOWN_MULTISELECT:
            salesForceSelect = (
                <DropDown
                    label=""
                    selectedItem={getSelectedSalesForceAnswer()}
                    searchable={true}
                    placeholder="Select Salesforce value"
                    items={salesForceAnswers}
                    onChange={salesForceAnswerItemChanged}
                    isDisabled={isDisabled}
                />
            );
            break;
        case AppService.questionType.SELECT_MANY:
        case AppService.questionType.NUMBER:
            salesForceSelect = (
                <DropDown
                    label=""
                    selectedItem={getSelectedSalesForceAnswer()}
                    searchable={true}
                    placeholder="Link to Salesforce"
                    items={salesForceItems}
                    onChange={salesForceItemChanged}
                    isDisabled={isDisabled}
                />
            );
            break;
    }
    return <div className="salesforce-value">{salesForceSelect}</div>;
}

export default SalesForceAnswerDropdown;
