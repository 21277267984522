import AppService from "../AppService";

export const SalesforceFieldService = {
    specialSfdcFieldNames: null,

    retrieveSpecialSfdcFieldNames() {
        if (AppService.selectedApp) {
            return AppService.getAxios()
                .get("/api/sales-force-field/special-field-names")
                .then((response) => {
                    this.specialSfdcFieldNames = response.data;
                });
        }
    }
};
