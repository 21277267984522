import React, { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import QuestionComponent from "../QuestionComponent";
import AppService from "../../../service/AppService";
import { SurveyBuilderContext } from "../../../SurveyBuilderContext";

function DynamicGroup({ showActions, index, question, questions, onVisibilityClick, onVisibilityRemoveClicked, lang }) {
    const { selectedSectionIndex, saveSurvey, addQuestionToGroup, selectQuestionFromGroup, removeQuestionFromGroup, replaceDraggedItems } =
        useContext(SurveyBuilderContext);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        questions = replaceDraggedItems(questions, result);
        AppService.selectedApp.sections[selectedSectionIndex].questions[index - 1].questions = replaceDraggedItems(
            AppService.selectedApp.sections[selectedSectionIndex].questions[index - 1].questions,
            result
        );
        saveSurvey();
    };

    const questionList = questions.map((q, i) => {
        return (
            <Draggable key={`${i}_question_component_group`} draggableId={String(i)} index={i + 1}>
                {(provided, snapshot) => (
                    <QuestionComponent
                        key={`${i}_question_component_group`}
                        index={i + 1}
                        question={q}
                        isSelected={true}
                        onQuestionRemove={() => {
                            removeQuestionFromGroup(q.groupId, q.sequenceId);
                        }}
                        onClick={() => {
                            selectQuestionFromGroup(q, i);
                        }}
                        onVisibilityRemoveClicked={onVisibilityRemoveClicked}
                        showActions={false}
                        onVisibilityClick={onVisibilityClick}
                        provided={provided}
                        snapshot={snapshot}
                        lang={lang}
                        isGroup={true}
                    />
                )}
            </Draggable>
        );
    });
    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable-1">
                {(provided) => (
                    <div className="content">
                        <div className="instance-title">
                            {question.showInstanceCount && <span> #1</span>}
                            <span>{question?.instanceGroupName} </span>
                        </div>
                        <div {...provided.droppableProps} ref={provided.innerRef} {...provided.dragHandleProps}>
                            {questionList}
                            {provided.placeholder}
                        </div>
                        {showActions && (
                            <div className="add-new-question">
                                <div>
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            addQuestionToGroup();
                                        }}
                                    >
                                        Question
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="add-more">
                            <i className="plus-icon">+</i>
                        </div>
                        <span className="add-more-text">Click to add more items</span>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default DynamicGroup;
