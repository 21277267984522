import React, { useContext, useEffect, useRef, useState } from "react";
import removeLanguage from "../../../assets/images/remove-language.svg";
import "./Languages.scss";
import AppService from "../../../service/AppService";
import { LanguageService } from "../../../service/LanguageService";
import ToggleAccordion from "../../../components/ToggleAccordion/ToggleAccordion";
import GroupTableItems from "./GroupTableItems/GroupTableItems";
import NoteContentItem from "./NoteContent/NoteContent";
import FeedBackItem from "./FeedBackItem/FeedBackitem";
import { SurveyBuilderContext } from "../../../SurveyBuilderContext";
import BasicPopup from "../../../components/popups/BasicPopup/BasicPopup";

function Languages({ app, showValidateLang, setShowValidateLang }) {
    const { saveSurvey, changeSubSurvey, resetLanguageToDefault, surveyInitialValue } = useContext(SurveyBuilderContext);

    const [languages, setLanguages] = useState(["EU"]);
    const [selectedApp, setSelectedApp] = useState(AppService.selectedApp);
    const [showPopup, setShowPopup] = useState(false);
    const [allFields, setAllFields] = useState(0);
    const [filledFields, setFilledFields] = useState(0);

    const ref = useRef(null);
    useEffect(() => {
        if (app.internationalizationSupport) {
            setLanguages(["EU", "ESP"]);
            LanguageService.applyMultiLanguageValues();
            LanguageService.toggleAllEditing();
            setValidatedValues();
            LanguageService.openAllAccordion();
        }
        if (showValidateLang) {
            LanguageService.openAllAccordion();
        }
        window.onclick = (event) => {
            if (!(event.target.contains(ref.current) && event.target !== ref.current)) {
                setValidatedValues();
                setSelectedApp({ ...AppService.selectedApp });
            }
        };
    }, [showValidateLang]);

    useEffect(() => {
        setSelectedApp({ ...AppService.selectedApp });
    }, [surveyInitialValue]);

    const buildTableTitle = (lang) => {
        switch (lang) {
            case "EU":
                return "Original (EN)";
            case "ESP":
                return "Spanish (ES)";
        }
    };

    const setValidatedValues = () => {
        const { allFieldsCount, filledFieldsCount } = LanguageService.getValidatedFields();
        setAllFields(allFieldsCount);
        setFilledFields(filledFieldsCount);
    };

    const addMultiLanguage = () => {
        setShowValidateLang(false);
        app.internationalizationSupport = true;
        LanguageService.applyMultiLanguageValues();
        setValidatedValues();
        setLanguages([...languages, "ESP"]);
        saveSurvey();
        setSelectedApp({ ...app });
    };

    const onRemoveLanguage = () => {
        AppService.removingLanguages = true;
        resetLanguageToDefault();
        app.internationalizationSupport = false;
        LanguageService.removeMultiLanguageValues();
        setLanguages(["EU"]);
        setShowPopup(false);
        setSelectedApp({ ...app });
        saveSurvey();
    };

    const handleChangeSubSurvey = (event, subI) => {
        changeSubSurvey(subI);
        AppService.selectedApp.subSurveys[subI].name.ESP = event.target.value;
        setSelectedApp({ ...AppService.selectedApp });
    };

    const handleChangeSection = (event, subI, secI, sequenceId) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);
        LanguageService.changeSectionName(translatedValue, subI, secI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };
    const handleChangeQuestion = (event, subI, secI, questionI, sequenceId) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);
        LanguageService.changeQuestionName(translatedValue, subI, secI, questionI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };
    const handleChangeQuestionHint = (event, subI, secI, questionI, sequenceId) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);
        LanguageService.changeQuestionHint(translatedValue, subI, secI, questionI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };
    const handleChangeNoteContent = (event, subI, secI, questionI, sequenceId) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);
        LanguageService.changeNoteContent(translatedValue, subI, secI, questionI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };
    const handleChangeAnswer = ({ event, subI, secI, questionI, answerI, sequenceId }) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);
        LanguageService.changeAnswerText(translatedValue, subI, secI, questionI, answerI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };
    const handleChangeFeedbackTitle = ({ event, subI, secI, questionI, answerI, sequenceId }) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);
        LanguageService.changeAnswerFeedbackTitle(translatedValue, subI, secI, questionI, answerI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };
    const handleChangeFeedbackText = ({ event, subI, secI, questionI, answerI, sequenceId }) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);
        LanguageService.changeAnswerFeedbackText(translatedValue, subI, secI, questionI, answerI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };

    const handleChangeGroupQuestion = (event, subI, secI, questionI, groupI, sequenceId) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);
        LanguageService.changeGroupQuestionName(translatedValue, subI, secI, questionI, groupI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };
    const handleChangeGroupHint = (event, subI, secI, questionI, groupI, sequenceId) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);

        LanguageService.changeGroupQuestionHint(translatedValue, subI, secI, questionI, groupI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };
    const handleChangeGroupAnswer = ({ event, subI, secI, questionI, groupI, answerI, sequenceId }) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);

        LanguageService.changeGroupQuestionAnswer(translatedValue, subI, secI, questionI, groupI, answerI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };

    const handleChangeGroupNoteContent = (event, subI, secI, questionI, groupI, sequenceId) => {
        const translatedValue = event.target.value;
        changeSubSurvey(subI);

        LanguageService.changeGroupNoteContent(translatedValue, subI, secI, questionI, groupI, sequenceId);
        setSelectedApp({ ...AppService.selectedApp });
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" || event.key === "Escape") {
            LanguageService.toggleAllEditing(false);
            setSelectedApp({ ...AppService.selectedApp });
            setValidatedValues();
        }
    };
    const buildText = (value, lang) => {
        return isHaveTranslatedValue(value, lang) ? (
            <span>{getResizedText(LanguageService.getTranslatedText(value, lang))}</span>
        ) : (
            <span className={`readonly-text ${showValidateLang ? "red-text" : ""}`}>{value.EU}</span>
        );
    };
    const isHaveTranslatedValue = (value, lang) => {
        if (lang === "EU") {
            return true;
        }
        return value?.ESP?.length > 1;
    };

    const getResizedText = (value) => {
        const parts = Math.trunc(value.length / 46);
        if (parts > 0 && value.indexOf(" ") === -1) {
            let finalValue = value.substring(0, 46);
            for (let i = 1; i <= parts; i++) {
                finalValue = finalValue + " " + value.substring(46 * i, 46 * (i + 1));
            }
            return finalValue;
        }
        return value;
    };
    const isHaveQuestionHint = (question) => {
        return question.type !== "GROUP" && question.open && (question.hint.length > 0 || question.hint?.EU?.length > 0);
    };

    const popupText = {
        header: "Are you sure to remove language?",
        content: "This action cannot be undone. Upon confirmation, this language will remove."
    };

    return (
        <div className="languages" ref={ref}>
            {showPopup && (
                <BasicPopup
                    closePopup={() => setShowPopup(false)}
                    action={onRemoveLanguage}
                    header={popupText.header}
                    content={popupText.content}
                />
            )}
            {languages.map((lang, index) => (
                <div className="languages-table" key={index}>
                    <div className="table-header">
                        <span>{buildTableTitle(lang)}</span>
                        {index >= 1 && AppService.selectedApp.status === "CONSTRUCT" && (
                            <>
                                <span className="validation-fields">{`${filledFields}/${allFields}`}</span>
                                <img src={removeLanguage} onClick={() => setShowPopup(true)} className="delete-icon" />
                            </>
                        )}
                    </div>
                    {selectedApp &&
                        selectedApp.subSurveys.map((subSurvey, subI) => (
                            <div key={subI}>
                                <div
                                    className="subsurvey-item"
                                    style={{ height: LanguageService.getTableItemHeight(subSurvey.name, "subSurvey") }}
                                >
                                    <ToggleAccordion open={subSurvey.open} onClick={() => LanguageService.toggleSubSurveyAccordion(subI)} />
                                    <span onClick={() => LanguageService.toggleEditSubSurvey(subI, lang)}>
                                        {LanguageService.isEspEditing(subSurvey, lang) ? (
                                            <textarea
                                                className="edit-input"
                                                onChange={(event) => handleChangeSubSurvey(event, subI)}
                                                value={subSurvey.name[lang]}
                                                placeholder={subSurvey.name.EU}
                                                onKeyDown={handleKeyDown}
                                            />
                                        ) : (
                                            buildText(subSurvey.name, lang)
                                        )}
                                    </span>
                                </div>
                                {subSurvey.open &&
                                    subSurvey.sections.map((section, secI) => (
                                        <div key={secI}>
                                            <div
                                                className="section-item"
                                                style={{ height: LanguageService.getTableItemHeight(section.name, "section") }}
                                            >
                                                <ToggleAccordion
                                                    open={section.open}
                                                    onClick={() => LanguageService.toggleSectionAccordion(subI, secI)}
                                                />
                                                <span onClick={() => LanguageService.toggleEditSection(subI, secI, lang)}>
                                                    {LanguageService.isEspEditing(section, lang) ? (
                                                        <textarea
                                                            className="edit-input"
                                                            onChange={(event) => handleChangeSection(event, subI, secI, section.sequenceId)}
                                                            value={section.name[lang]}
                                                            placeholder={section.name.EU}
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    ) : (
                                                        buildText(section.name, lang)
                                                    )}
                                                </span>
                                            </div>
                                            {section.open &&
                                                section.questions.map((question, questionI) => (
                                                    <div key={questionI}>
                                                        <div
                                                            className="question-item"
                                                            style={{
                                                                height: LanguageService.getTableItemHeight(question.text, "question")
                                                            }}
                                                        >
                                                            <ToggleAccordion
                                                                open={question.open}
                                                                onClick={() =>
                                                                    LanguageService.toggleQuestionAccordion(subI, secI, questionI)
                                                                }
                                                            />
                                                            <span
                                                                onClick={() =>
                                                                    LanguageService.toggleEditQuestion(subI, secI, questionI, lang)
                                                                }
                                                            >
                                                                {LanguageService.isEspEditing(question, lang) ? (
                                                                    <textarea
                                                                        className="edit-input"
                                                                        onChange={(event) =>
                                                                            handleChangeQuestion(
                                                                                event,
                                                                                subI,
                                                                                secI,
                                                                                questionI,
                                                                                section.sequenceId
                                                                            )
                                                                        }
                                                                        value={question.text[lang]}
                                                                        placeholder={question.text.EU}
                                                                        onKeyDown={handleKeyDown}
                                                                    />
                                                                ) : (
                                                                    buildText(question.text, lang)
                                                                )}
                                                            </span>
                                                        </div>
                                                        {isHaveQuestionHint(question) && (
                                                            <div
                                                                className="hint-item"
                                                                style={{
                                                                    height: LanguageService.getTableItemHeight(question.hint, "hint")
                                                                }}
                                                            >
                                                                <span
                                                                    onClick={(event) =>
                                                                        LanguageService.toggleEditQuestionHint(subI, secI, questionI, lang)
                                                                    }
                                                                >
                                                                    {LanguageService.isEspHintEditing(question, lang) ? (
                                                                        <textarea
                                                                            className="edit-input"
                                                                            onChange={(event) =>
                                                                                handleChangeQuestionHint(
                                                                                    event,
                                                                                    subI,
                                                                                    secI,
                                                                                    questionI,
                                                                                    section.sequenceId
                                                                                )
                                                                            }
                                                                            value={question.hint[lang]}
                                                                            placeholder={question.hint.EU}
                                                                            onKeyDown={handleKeyDown}
                                                                        />
                                                                    ) : (
                                                                        buildText(question.hint, lang)
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                        {question.type === "GROUP" ? (
                                                            question.open && (
                                                                <GroupTableItems
                                                                    question={question}
                                                                    subI={subI}
                                                                    secI={secI}
                                                                    questionI={questionI}
                                                                    lang={lang}
                                                                    section={section}
                                                                    handleChangeGroupQuestion={handleChangeGroupQuestion}
                                                                    handleChangeGroupHint={handleChangeGroupHint}
                                                                    handleChangeGroupNoteContent={handleChangeGroupNoteContent}
                                                                    handleKeyDown={handleKeyDown}
                                                                    buildText={buildText}
                                                                    buildAnswers={buildAnswers}
                                                                />
                                                            )
                                                        ) : question.open && question.type === "NOTE" ? (
                                                            <NoteContentItem
                                                                section={section}
                                                                question={question}
                                                                subI={subI}
                                                                secI={secI}
                                                                questionI={questionI}
                                                                lang={lang}
                                                                buildText={buildText}
                                                                handleChangeNoteContent={handleChangeNoteContent}
                                                                handleKeyDown={handleKeyDown}
                                                            />
                                                        ) : (
                                                            question.open &&
                                                            buildAnswers(
                                                                question.answers,
                                                                false,
                                                                lang,
                                                                { subI, secI, questionI },
                                                                section.sequenceId
                                                            )
                                                        )}
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                            </div>
                        ))}
                </div>
            ))}
            {languages.length <= 1 && AppService.selectedApp.status === "CONSTRUCT" && (
                <div className="add-container">
                    <div className="add-language" onClick={addMultiLanguage}>
                        Add a language
                    </div>
                    <div className="grey-background" />
                </div>
            )}
        </div>
    );

    function buildAnswers(answers, group, lang, location, sequenceId) {
        const onEdit = (answerI) =>
            group
                ? LanguageService.toggleEditGroupAnswer({ ...location, answerI, lang })
                : LanguageService.toggleEditAnswer({ ...location, answerI, lang });

        const handleEdit = (answerI, event) =>
            group
                ? handleChangeGroupAnswer({ event, ...location, answerI, sequenceId })
                : handleChangeAnswer({ event, ...location, answerI, sequenceId });

        return answers.map((answer, answerI) => (
            <div key={answerI}>
                {(answer.text.length > 0 || answer.text?.EU?.length > 0) && (
                    <div
                        className={group ? "group-answer" : "answer-item"}
                        style={{ height: LanguageService.getTableItemHeight(answer.text, "answer") }}
                    >
                        <span onClick={() => onEdit(answerI)}>
                            {LanguageService.isEspEditing(answer, lang) ? (
                                <textarea
                                    className="edit-input"
                                    onChange={(event) => handleEdit(answerI, event)}
                                    value={answer.text[lang]}
                                    placeholder={answer.text.EU}
                                    onKeyDown={handleKeyDown}
                                />
                            ) : (
                                buildText(answer.text, lang)
                            )}
                        </span>
                    </div>
                )}
                <FeedBackItem
                    answer={answer}
                    answerI={answerI}
                    lang={lang}
                    location={location}
                    sequenceId={sequenceId}
                    buildText={buildText}
                    handleKeyDown={handleKeyDown}
                    handleChangeFeedbackTitle={handleChangeFeedbackTitle}
                    handleChangeFeedbackText={handleChangeFeedbackText}
                />
            </div>
        ));
    }
}

export default Languages;
