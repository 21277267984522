import React from "react";
import PasswordTextBoxComponent from "../passwordTextBox/PasswordTextBoxComponent";
import Checkbox from "../checkBoxPassword/CheckboxComponent";
import Overlay from "../overlay/OverlayComponent";
import ModalPopup from "../modalPopup/ModalPopupComponent";

import Auth from "../../Auth";

import "./ChangePasswordComponent.scss";

class ChangePasswordComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            isFormValid: false,
            passwordError: false
        };
        this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.savePassword = this.savePassword.bind(this);
    }

    getPasswordInfo() {
        const password = this.state.newPassword;
        return {
            isLengthValid: password.length > 7,
            isContainUpperLetter: /[A-Z]/.test(password),
            isContainLowerLetter: /[a-z]/.test(password),
            isContainNumers: /[0-9]/.test(password)
        };
    }

    handleOldPasswordChange(oldPassword) {
        this.setState({ oldPassword });
    }

    handleNewPasswordChange(newPassword) {
        this.setState({ newPassword });
    }

    savePassword() {
        const result = Auth.getInstance().changePassword(this.state.oldPassword, this.state.newPassword);
        result.then((val) => {
            if (!val) {
                this.setState({ passwordError: true });
            } else {
                this.props.onSave();
            }
        });
    }

    isPasswordValid() {
        const passwordInfo = this.getPasswordInfo();
        return (
            passwordInfo.isContainLowerLetter &&
            passwordInfo.isContainNumers &&
            passwordInfo.isContainUpperLetter &&
            passwordInfo.isLengthValid
        );
    }

    validateForm = () => {
        return this.state.oldPassword.length > 0 && this.isPasswordValid();
    };

    render() {
        const isFormValid = this.validateForm();
        const passwordInfo = this.getPasswordInfo();

        return (
            <Overlay>
                <ModalPopup title="Change my password" onDialogClose={this.props.onDialogClose}>
                    <div className="form">
                        <PasswordTextBoxComponent
                            error={this.state.passwordError}
                            errorText="Old password seems to be wrong"
                            title="Enter your old password"
                            id="txtOldPassword"
                            onPasswordChange={this.handleOldPasswordChange}
                        ></PasswordTextBoxComponent>
                        <PasswordTextBoxComponent
                            title="Type your new password"
                            id="txtNewPassword"
                            onPasswordChange={this.handleNewPasswordChange}
                        ></PasswordTextBoxComponent>
                        <div className="password-description">
                            Your password must be <Checkbox isChecked={passwordInfo.isLengthValid} />
                            &nbsp;<b>at least 8 characters long</b>, contain &nbsp;
                            <Checkbox isChecked={passwordInfo.isContainUpperLetter} /> <b>upper</b> and &nbsp;
                            <Checkbox isChecked={passwordInfo.isContainLowerLetter} /> <b>lowercase</b> letters and &nbsp;
                            <Checkbox isChecked={passwordInfo.isContainNumers} /> <b>numbers</b>.
                        </div>
                        {isFormValid ? (
                            <input type="button" value="Change" className="button" onClick={this.savePassword} />
                        ) : (
                            <input type="button" value="Change" className="button disabled" />
                        )}
                    </div>
                </ModalPopup>
            </Overlay>
        );
    }
}

export default ChangePasswordComponent;
