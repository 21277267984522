import React, { useState } from "react";
import "./SlidingComponent.scss";
import add from "../../assets/images/add-icon.svg";

function SlidingComponent({ title, items = [], onItemClick }) {
    const [isActive, setIsActive] = useState(false);

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    return (
        <div className="sliding-component">
            <div className="sliding-header" onClick={toggleActive}>
                <img id="addAnswer" className="add-answer-btn" src={add} alt="" />
                <div className="sliding-title">{title}</div>
            </div>
            <div className="sliding-body" style={{ display: isActive ? "block" : "none" }}>
                {items.map((item, index) => (
                    <div key={index} className="sliding-answer">
                        <div className="sliding-answer-number">{index + 1}</div>
                        <div className="sliding-answer-content" onClick={() => onItemClick(item)}>
                            {item.title}
                        </div>
                        <div className="sliding-edit">...</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SlidingComponent;
