import "./NewSurveyBuilderEditor.scss";
import { useContext, useEffect, useState } from "react";
import { NewSurveyBuilderContext } from "../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import QuestionSettingsEditor from "./QuestionSettingsEditor/QuestionSettingsEditor";
import SimpleCheckBox from "../../../../components/simpleCheckBox/SimpleCheckBoxComponent";
import AppService from "../../../../service/AppService";
import QuestionTypeEdit from "./QuestionTypeEdit/QuestionTypeEdit";
import AnswerSettingsEditor from "./AnswerSettingsEditor/AnswerSettingsEditor";
import FeedbackSettingsEditor from "./FeedbackSettingsEditor/FeedbackSettingsEditor";

const NewSurveyBuilderEditor = () => {
    const { surveyData, selectedQuestion, updateQuestion, selectedSection, selectedFeedback } = useContext(NewSurveyBuilderContext);

    const [salesForceItems, setSalesForceItems] = useState([]);
    const [salesForceAnswers, setSalesForceAnswers] = useState([]);

    useEffect(() => {
        selectedQuestion && setSalesForcesAnswers();
    }, [selectedQuestion?.salesForceField?.id]);

    const setSalesForcesAnswers = (salesForceList = salesForceItems) => {
        const result = [];
        salesForceList.forEach((s) => {
            if (s && selectedQuestion.salesForceField) {
                if (s.id === selectedQuestion.salesForceField.id) {
                    result.push(s);
                }
            }
        });
        setSalesForceAnswers(result);
    };

    const prepareSalesForceFields = (salesForceData) => {
        let result = Object.values(salesForceData)
            .flat()
            .map((item) => ({
                id: item.id,
                text: item.fieldName + (item.fieldLabel ? ` (${item.fieldLabel})` : ""),
                fieldName: item.fieldName,
                answers: getDropDownItems(item.salesForceAnswerFields)
            }))
            .sort((a, b) => a.text.localeCompare(b.text));

        if (selectedQuestion.type === "ADDRESS") {
            const filters = ["address", "mailing", "location"];
            result = result.filter((item) =>
                filters.some((filter) => item.text.toLowerCase().includes(filter) || item.fieldName.toLowerCase().includes(filter))
            );
        }
        setSalesForcesAnswers(result);
        setSalesForceItems(result);
    };

    const getDropDownItems = (source = []) =>
        source.map((s) => ({
            id: s.id,
            text: s.value
        }));

    const changeQuestionSalesForce = (_, item) => {
        if (salesForceAnswers.some((el) => el.id !== item.id)) {
            setSalesForceAnswers([...salesForceAnswers, item]);
        }
        const salesForceObject = {
            id: item.id,
            fieldLabel: item.text,
            fieldName: item.fieldName
        };
        updateQuestion("salesForceField", salesForceObject, true);
    };

    const selectSalesForceAnswers = () => {
        if (selectedQuestion.salesForceField) {
            const selectedSalesForceAnswers = salesForceAnswers
                .filter((i) => i.id === selectedQuestion.salesForceField.id)
                .map((i) => i.answers);
            return selectedSalesForceAnswers.length > 0 ? selectedSalesForceAnswers[0] : selectedSalesForceAnswers;
        }
    };
    const isQuestionInsideDynamicGroup = () => {
        const { groupId } = selectedQuestion;
        if (!groupId) return false;
        const group = selectedSection.questions.find((question) => question.sequenceId === groupId);

        return group?.type === AppService.questionType.DYNAMIC_GROUP || false;
    };

    if (!selectedQuestion) {
        return null;
    }
    const { questionType } = AppService;

    const isNotNote = selectedQuestion.type !== questionType.NOTE;
    const isNotSelectMany = selectedQuestion.type !== questionType.SELECT_MANY;
    const isNotNumber = selectedQuestion.type !== questionType.NUMBER;
    const isNotDynamicGroup = selectedQuestion.type !== questionType.DYNAMIC_GROUP;
    const isNotGroup = selectedQuestion.type !== questionType.GROUP;
    const insideDynamicGroup = isQuestionInsideDynamicGroup();

    const isLinkToSalesforceFieldVisible = isNotNote && isNotSelectMany && isNotNumber && !insideDynamicGroup;
    const isQuestion = isNotNote && isNotGroup && isNotDynamicGroup;
    const isGroupQuestion = selectedQuestion.type === questionType.GROUP;
    const isDynamicGroup = selectedQuestion.type === questionType.DYNAMIC_GROUP;
    const questionTitle = AppService.questionTitles[AppService.convertTypeNameToIndex(selectedQuestion.type)];
    const isOptionalCheckboxVisible = (isDynamicGroup || isQuestion) && !insideDynamicGroup;

    if (selectedFeedback) {
        return <FeedbackSettingsEditor />;
    }

    return (
        <>
            <div className="question-settings">
                <div className="header">
                    <span>{questionTitle}</span>
                    {isOptionalCheckboxVisible && (
                        <SimpleCheckBox
                            onClick={(value, isSelected) => updateQuestion("optional", isSelected, true)}
                            label="Optional"
                            isSelected={selectedQuestion.optional}
                            disabled={surveyData?.status === "PUBLISH"}
                        />
                    )}
                </div>
                <QuestionSettingsEditor
                    isQuestion={isQuestion}
                    isGroupQuestion={isGroupQuestion}
                    isDynamicGroup={isDynamicGroup}
                    isQuestionInsideDynamicGroup={isQuestionInsideDynamicGroup}
                    isLinkToSalesforceFieldVisible={isLinkToSalesforceFieldVisible}
                    handleQuestionSalesForceItem={changeQuestionSalesForce}
                    salesForceItems={salesForceItems}
                    prepareSalesForceFields={prepareSalesForceFields}
                />
            </div>
            {isQuestion && <QuestionTypeEdit isDynamicGroupQuestion={insideDynamicGroup} />}
            {isQuestion && (
                <AnswerSettingsEditor
                    isDynamicGroupQuestion={insideDynamicGroup}
                    salesForceItems={salesForceItems}
                    salesForceAnswers={selectSalesForceAnswers()}
                />
            )}
        </>
    );
};
export default NewSurveyBuilderEditor;
