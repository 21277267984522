import React from "react";

import "./BuilderDropDownComponent.scss";

class BuilderDropDownComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listItems: props.listItems,
            onMouseOverItem: ""
        };
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDown, false);
    }

    render() {
        const { listItems } = this.props;
        return (
            <div className="dd-wrapper">
                {this.props.listOpen && (
                    <ul className="dd-list">
                        {listItems.map((item) => (
                            <li
                                className={
                                    item.id === this.props.chosenItem
                                        ? "dd-list-item active-dd-item"
                                        : item.id === this.state.onMouseOverItem
                                        ? "dd-list-item selected-dd-item"
                                        : "dd-list-item"
                                }
                                key={item.id}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.showDropdown(false);
                                    this.props.onClickItem(item.id);
                                }}
                                onMouseOver={() => this.setState({ onMouseOverItem: item.id })}
                            >
                                {item.title}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        );
    }
}

export default BuilderDropDownComponent;
