import React from "react";
import AppService from "../../service/AppService";

function BmiInfo() {
    const allowedBmi = AppService.selectedApp.disqualificationRules.allowedBmi;
    if (allowedBmi) {
        const from = allowedBmi.from ? allowedBmi.from : "0";
        const to = allowedBmi.to ? allowedBmi.to : "∞";
        return (
            <div className="acceptance-criteria-line">
                BMI: {from} - {to}
            </div>
        );
    }
    return null;
}

export default BmiInfo;
