import React, { useState, useEffect } from "react";
import "./HintComponent.scss";

function HintComponent({ hint }) {
    const [showHint, setShowHint] = useState(false);
    const [currentHint, setCurrentHint] = useState(hint);

    useEffect(() => {
        setCurrentHint(hint);
    }, [hint]);

    if (currentHint?.length > 0) {
        return (
            <div className="help-text">
                <span onMouseEnter={() => setShowHint(true)} onMouseOut={() => setShowHint(false)} onClick={() => setShowHint(true)}>
                    Why is this question here
                </span>
                {showHint && <div className="hint">{currentHint}</div>}
            </div>
        );
    }
    return <div className="help-text" />;
}
export default HintComponent;
