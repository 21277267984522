import React from "react";
import ArrowBack from "../../assets/images/arrow-back.svg";
import AppService from "../../service/AppService";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import switchActive from "../../assets/images/switch-active.svg";
import switchInactive from "../../assets/images/switch-inactive.svg";
import classNames from "classnames";

function BuilderHeader({ languageTab, setLanguageTab, onSaveSurvey, hasAnythingChanged, showPublishPopup, setShowActivationPopup }) {
    const constructButtonClassName = classNames({
        active: !languageTab && AppService.selectedApp.status === "CONSTRUCT",
        inactive: languageTab || AppService.selectedApp.status !== "CONSTRUCT"
    });
    const publishButtonClassName = classNames({
        active: !languageTab && AppService.selectedApp.status !== "CONSTRUCT",
        inactive: languageTab || AppService.selectedApp.status === "CONSTRUCT"
    });
    const languageButtonClassName = classNames({
        active: languageTab,
        inactive: !languageTab,
        hidden: true // todo: after release remove temporarily hidden opportunity to add translation
    });

    let appVisibility = null;
    if (AppService.selectedApp.status !== "CONSTRUCT") {
        appVisibility = AppService.selectedApp.active ? (
            <div id="activeLabel">
                Active <img src={switchActive} alt="" />
            </div>
        ) : (
            <div onClick={() => setShowActivationPopup(true)}>
                Inactive <img id="activateSwitch" src={switchInactive} alt="" />
            </div>
        );
    }
    return (
        <div className="builder-header">
            <div>
                <img src={ArrowBack} alt="" onClick={hasAnythingChanged} />
            </div>
            <div className="title">Builder</div>
            <div className="survey-save-button">
                {AppService.selectedApp.status === "CONSTRUCT" && (
                    <button className="save-button" onClick={onSaveSurvey}>
                        Save
                    </button>
                )}
            </div>
            <div className="app-visible">{appVisibility}</div>
            <div className="buttons-group">
                <button className={constructButtonClassName} onClick={() => setLanguageTab(false)}>
                    Construct
                </button>
                <button className={languageButtonClassName} onClick={() => setLanguageTab(true)}>
                    Languages
                </button>
                <button className={publishButtonClassName} onClick={showPublishPopup}>
                    Publish
                </button>
            </div>
            {AppService.selectedApp.internationalizationSupport && <LanguageToggle />}
        </div>
    );
}

export default BuilderHeader;
