import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as RemoveQuestion } from "../../../../../../assets/images/close.svg";
import { NewSurveyBuilderContext } from "../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import classNames from "classnames";
import AppService from "../../../../../../service/AppService";
import AnswerItems from "../AnswerItems/AnswerItems";
import "./QuestionItem.scss";
import HintComponent from "../hint/HintComponent";
import DragIndicator from "../../../../../../assets/images/draggable_indicator.svg";
import Feedback from "../../../../../../components/question/components/Feedback/Feedback";
import cloneDeep from "lodash/cloneDeep";
import VisibilityButton from "../../../../../../components/question/components/VisibilityButton/VisibilityButton";
import { NewSurveyBuilderService } from "../../../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";
import { useParams } from "react-router-dom";
import { showErrorNotification, showSuccessNotification } from "../../../../../../helper/NotificationHelper";

function QuestionItem({ question, index, removeQuestion, provided, snapshot, isListDraggingOver, openVisibilityModal, className }) {
    const { id } = useParams();
    const { surveyData, selectedSection, setSelectedQuestion, setSelectedSection, selectedQuestion, setSelectedFeedback } =
        useContext(NewSurveyBuilderContext);
    const [showVisibilityInfo, setShowVisibilityInfo] = useState(false);
    const [isUpdatable, setIsUpdatable] = useState(surveyData?.status !== "PUBLISH");
    const questionClassNames = [
        "yes-no",
        "radio",
        "picklist",
        "multi-picklist",
        "free-text",
        "date",
        "number",
        "address",
        "phone",
        "dropdown",
        "dropdownMultiSelect",
        "upload",
        "multi-upload",
        "note",
        "group-section",
        "dynamic-group"
    ];

    useEffect(() => {
        setIsUpdatable(surveyData?.status !== "PUBLISH");
    }, [surveyData?.status]);

    const containerClassName = classNames(
        "question-item",
        questionClassNames[AppService.convertTypeNameToIndex(question.type)],
        className,
        {
            "question-item-selected": selectedQuestion?.sequenceId === question.sequenceId,
            "question-dragging": snapshot?.isDragging,
            "question-not-dragging": isListDraggingOver && !snapshot?.isDragging
        }
    );

    const selectQuestion = (e) => {
        e?.stopPropagation();
        setSelectedQuestion(question);
        setSelectedFeedback(null);
    };

    const selectFeedback = (question, answer) => {
        setSelectedQuestion(cloneDeep(question));
        setSelectedFeedback(answer);
    };

    const hasFeedback = (answer) => {
        return answer.feedback.text.length > 0 || answer.feedback.title.length > 0;
    };

    const updateAnswerVisibility = (questions, questionsVisibilityToRemove) => {
        return questions.map((q) => {
            if (questionsVisibilityToRemove.some((item) => item.questionSequence === q.sequenceId)) {
                return {
                    ...q,
                    answers: q.answers.map((a) => {
                        const answerToUpdate = questionsVisibilityToRemove.find((item) => item.answerSequence === a.sequenceId);
                        return answerToUpdate ? { ...a, hasVisibility: false } : a;
                    })
                };
            }
            return q;
        });
    };

    const handleGroupQuestionVisibility = async (question) => {
        const updatedQuestions = [...selectedSection.questions];
        const groupIndex = updatedQuestions.findIndex((q) => q.sequenceId === question.groupId);
        if (groupIndex !== -1) {
            const group = { ...updatedQuestions[groupIndex] };
            const questionIndex = group.questions.findIndex((q) => q.sequenceId === question.sequenceId);
            if (questionIndex !== -1) {
                group.questions[questionIndex].visibility = [];
            }

            try {
                const response = await NewSurveyBuilderService.removeAllVisibilityInsideGroupQuestion(
                    id,
                    selectedSection.sequenceId,
                    question.groupId,
                    question.sequenceId
                );
                const questionsVisibilityToRemove = response.data;
                const updatedGroup = updateAnswerVisibility(group.questions, questionsVisibilityToRemove);
                updatedQuestions[groupIndex] = { ...group, questions: updatedGroup };
                showSuccessNotification("Visibility successfully removed from the group question!");
            } catch (error) {
                showErrorNotification("Error during remove group question visibility ", error);
            }
        }

        setSelectedSection((prev) => ({ ...prev, questions: updatedQuestions }));
    };

    const handleQuestionVisibility = async (question) => {
        const updatedQuestions = [...selectedSection.questions];
        const questionIndex = updatedQuestions.findIndex((q) => q.sequenceId === question.sequenceId);
        if (questionIndex !== -1) {
            updatedQuestions[questionIndex].visibility = [];
        }

        try {
            const response = await NewSurveyBuilderService.removeAllVisibility(id, selectedSection.sequenceId, question.sequenceId);
            const questionsVisibilityToRemove = response.data;
            const finalUpdatedQuestions = updateAnswerVisibility(updatedQuestions, questionsVisibilityToRemove);
            setSelectedSection((prev) => ({ ...prev, questions: finalUpdatedQuestions }));
            showSuccessNotification("Visibility successfully removed from the question!");
        } catch (error) {
            showErrorNotification("Error during remove question visibility ", error);
        }
    };

    const onVisibilityRemoveClicked = async (question) => {
        if (question?.groupId) {
            await handleGroupQuestionVisibility(question);
        } else {
            await handleQuestionVisibility(question);
        }
    };

    return (
        <div ref={provided?.innerRef} {...provided?.draggableProps}>
            <div key={question.sequenceId} className={containerClassName} onClick={selectQuestion}>
                {isUpdatable && <img src={DragIndicator} alt="drag-indicator" className="drag-indicator" {...provided?.dragHandleProps} />}
                <div className="question-header">
                    <span>{index + 1}</span>
                    {isUpdatable && (
                        <RemoveQuestion
                            onClick={(e) => {
                                e.stopPropagation();
                                removeQuestion(question);
                            }}
                        />
                    )}
                </div>
                <VisibilityButton
                    question={question}
                    showVisibilityInfo={showVisibilityInfo}
                    setShowVisibilityInfo={(boolean) => setShowVisibilityInfo(boolean)}
                    onVisibilityRemoveClicked={onVisibilityRemoveClicked}
                />
                {question.optional && <div className="optional">Optional</div>}
                <div className="question-text">{question.text}</div>
                <AnswerItems question={question} openVisibilityModal={openVisibilityModal} />
                <HintComponent hint={question.hint} />
            </div>
            {question.answers?.map(
                (a) => hasFeedback(a) && <Feedback key={a.sequenceId} answer={a} question={question} onClick={selectFeedback} />
            )}
        </div>
    );
}

export default QuestionItem;
