import { useContext, useRef, useState } from "react";
import { NewSurveyBuilderContext } from "../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import "./SurveyEditDialogComponent.scss";
import { ReactComponent as Close } from "../../../../../assets/images/close.svg";
import { ReactComponent as Saved } from "../../../../../assets/images/check-icon.svg";
import { ReactComponent as Loader } from "../../../../../assets/images/grid.svg";

import classNames from "classnames";
import InfoTabComponent from "./InfoTab/InfoTabComponent";
import CriteriaTabComponent from "./AcceptanceCriteriaTab/CriteriaTabComponent";
import ScoringTabComponent from "./ScoringTab/ScoringTabComponent";
import StructureTabComponent from "./StructureTab/StructureTabComponent";

const SurveyEditDialogComponent = ({ surveyId, currentTab, onClose }) => {
    const infoRef = useRef(InfoTabComponent);
    const criteriaRef = useRef(CriteriaTabComponent);
    const scoringRef = useRef(ScoringTabComponent);
    const [selectedTab, setSelectedTab] = useState(currentTab || 1);
    const { surveyData } = useContext(NewSurveyBuilderContext);
    const [showLoader, setShowLoader] = useState(false);
    const [tabSaved, setTabSaved] = useState(false);
    const [tabFail, setTabFail] = useState(false);

    const renderSelectedTab = () => {
        switch (selectedTab) {
            case 1:
                return <InfoTabComponent infoRef={infoRef} surveyId={surveyId} onSave={onSave} />;
            case 2:
                return (
                    <StructureTabComponent
                        surveyId={surveyId}
                        onSave={onSave}
                        setShowLoader={setShowLoader}
                        setSaved={setTabSaved}
                        setFail={setTabFail}
                    />
                );
            case 3:
                return <CriteriaTabComponent criteriaRef={criteriaRef} surveyId={surveyId} onSave={onSave} />;
            case 4:
                return <ScoringTabComponent scoringRef={scoringRef} surveyId={surveyId} onSave={onSave} />;
        }
    };

    const saveTab = (index) => {
        setTabSaved(false);
        setTabFail(false);
        setShowLoader(true);
        switch (index) {
            case 1:
                infoRef.current.saveInfo();
                break;
            case 2:
                onSave(false);
                break;
            case 3:
                criteriaRef.current.saveCriteria();
                break;
            case 4:
                scoringRef.current.saveScoring();
                break;
        }
    };

    const onSave = (fail) => {
        setShowLoader(false);
        if (fail) {
            setTabFail(true);
        } else {
            setTabSaved(true);
        }

        setTimeout(() => {
            setTabSaved(false);
            setTabFail(false);
        }, 3000);
    };

    const selectTab = (index) => {
        saveTab(selectedTab);
        setSelectedTab(index);
    };

    const getSaveButtonText = () => {
        switch (selectedTab) {
            case 1:
                return "Save Information";
            case 2:
                return "Save Structure";
            case 3:
                return "Save Acceptance Criteria";
            case 4:
                return "Save Scoring";
        }
    };

    const getTabClasses = (i) => {
        return classNames("tab", {
            "selected-tab": i === selectedTab,
            "tab-25": surveyData?.type === "GC",
            "tab-33": surveyData?.type !== "GC"
        });
    };

    return (
        <div className="new-survey-dialog">
            <div className="new-survey-dialog-content">
                <div className="title">
                    <span>Edit Survey</span>
                    <Close onClick={() => onClose()} />
                </div>
                <div className="header">
                    <div className={getTabClasses(1)} onClick={() => selectTab(1)}>
                        <span>Information</span>
                        <hr />
                    </div>
                    <div className={getTabClasses(2)} onClick={() => selectTab(2)}>
                        <span>Subsurveys & Sections</span>
                        <hr />
                    </div>
                    <div className={getTabClasses(3)} onClick={() => selectTab(3)}>
                        <span>Acceptance Criteria</span>
                        <hr />
                    </div>
                    {surveyData?.type === "GC" && (
                        <div className={getTabClasses(4)} onClick={() => selectTab(4)}>
                            <span>BMI & Age Scoring</span>
                            <hr />
                        </div>
                    )}
                </div>
                <div className="body">{renderSelectedTab()}</div>
                <div className="footer">
                    {showLoader && <Loader className="loader" />}
                    {tabSaved && <Saved className="saved" />}
                    {tabFail && <Close className="failed" />}
                    <button onClick={() => saveTab(selectedTab)}>{getSaveButtonText()}</button>
                </div>
            </div>
        </div>
    );
};

export default SurveyEditDialogComponent;
