import React from "react";
import Feedback from "../Feedback/Feedback";
import ConditionsSettingsButtons from "../ConditionsSettingsButtons/ConditionsSettingsButtons";

function Conditions({ question, lang, onConditionVisibilityClick }) {
    const conditionsFeedback = [];

    if (question.conditions && question.conditions.length > 0) {
        const conditionItems = question.conditions.map((c, i) => {
            if (c.feedback && c.feedback.length > 0) {
                const feedback = {
                    feedback: { text: c.feedback }
                };
                conditionsFeedback.push(<Feedback lang={lang} key={i} answer={feedback} />);
            }
            return (
                <div className="question-conditions" key={`${i}_question_condition`}>
                    <ConditionsSettingsButtons
                        key={`${i}_condition_settings_button`}
                        condition={c}
                        questionId={question}
                        onVisibilityClick={onConditionVisibilityClick}
                    >
                        <input type="text" value={c.value} readOnly={true} />
                    </ConditionsSettingsButtons>
                </div>
            );
        });

        return (
            <div className="sub-header">
                <div className="column-title">
                    <span>Conditions</span>
                </div>
                <div>
                    {conditionItems}
                    {conditionsFeedback}
                </div>
            </div>
        );
    }
    return null;
}

export default Conditions;
