import DropDownMultiComponent from "../../dropDowns/MultiSelectComponent/MultiSelectComponent";
import React from "react";
import i18n from "i18next";

function MultiSelect({ lang, answers, onVisibilityClick }) {
    return (
        <div className="content">
            <DropDownMultiComponent lang={lang} answers={answers} placeholder={i18n.t("Select")} onVisibilityClick={onVisibilityClick} />
        </div>
    );
}

export default MultiSelect;
