import React from "react";
import AppService from "../../service/AppService";

function ScoreInformation() {
    const isScoreAdded = () => {
        const bmi = AppService.selectedApp.bmiScore;
        const age = AppService.selectedApp.ageScore;
        return AppService.selectedApp && (bmi || age) && Array.isArray(bmi) && Array.isArray(age) && (bmi.length !== 0 || age.length !== 0);
    };
    const buildScore = (score, text) => {
        return score.map((item, index) => {
            if (item.operator === "Between") {
                return (
                    <div key={`${index}_between`}>
                        {item.valueFrom && item.valueTo && item.score && (
                            <div className="score-line" key={`${index}_between_score_line`}>
                                {`${text} between ${item.valueFrom} and ${item.valueTo} score: ${item.score}`}
                            </div>
                        )}
                    </div>
                );
            }
            return (
                <div key={`${index}_score`}>
                    {item.valueFrom && item.score && (
                        <div className="score-line" key={`${index}_score_line`}>
                            {`${text} ${item.operator} ${item.valueFrom} score: ${item.score}`}
                        </div>
                    )}
                </div>
            );
        });
    };

    return (
        isScoreAdded() && (
            <div className="score-info-section">
                <div className="column-title score-title">
                    <span>Scoring</span>
                </div>
                {buildScore(AppService.selectedApp.bmiScore, "BMI")}
                {buildScore(AppService.selectedApp.ageScore, "Age")}
            </div>
        )
    );
}

export default ScoreInformation;
