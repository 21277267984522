import React, { useContext } from "react";
import Tooltip from "../../../../../../components/Tooltip/Tooltip";
import noteVariablesIcon from "../../../../../../assets/images/comment.svg";
import AppService from "../../../../../../service/AppService";
import { NewSurveyBuilderContext } from "../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";

function NotesVariables() {
    const { selectedQuestion } = useContext(NewSurveyBuilderContext);

    const availableVariables = ["{firstName}", "{lastName}", "{email}"];
    if (selectedQuestion.type === AppService.questionType.NOTE) {
        return (
            <Tooltip title="Available variables" list={availableVariables}>
                <img src={noteVariablesIcon} alt="" />
            </Tooltip>
        );
    }
    return null;
}

export default NotesVariables;
