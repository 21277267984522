import React from "react";

function ActivationPopup({ action, setShowActivationPopup }) {
    return (
        <div className="status-popup-overlay">
            <div className="status-popup">
                <div className="container">
                    <div className="header">Make this application Active?</div>
                    <div className="content">
                        Upon confirmation, this application will become active and will be displayed first in the list of versions.
                    </div>
                    <div className="buttons">
                        <div className="button-yes" onClick={action}>
                            Yes
                        </div>
                        <div className="button-no" onClick={() => setShowActivationPopup(false)}>
                            No
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ActivationPopup;
