import React from "react";
import AppService from "../../../../../../service/AppService";
import YesNo from "../../../../../../components/question/answers/YesNo";
import Phone from "../../../../../../components/question/answers/Phone";
import Radio from "../../../../../../components/question/answers/Radio";
import CheckBox from "../../../../../../components/checkBox/CheckBoxComponent";
import NumberInput from "../../../../../../components/question/answers/NumberInput";
import Text from "../../../../../../components/question/answers/Text";
import DatePicker from "../../../../../../components/question/answers/DatePicker";
import Select from "../../../../../../components/question/answers/Select";
import MultiSelect from "../../../../../../components/question/answers/MultiSelect";
import Upload from "../../../../../../components/question/answers/Upload";
import Note from "../../../../../../components/question/answers/Note";
import NewGroup from "../../../../../../components/question/answers/NewGroup";
import NewDynamicGroup from "../../../../../../components/question/answers/NewDynamicGroup";

function AnswerItems({ question, openVisibilityModal }) {
    switch (question.type) {
        case AppService.questionType.YES_NO:
            return <YesNo answers={question.answers} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.PHONE:
            return <Phone answers={question.answers} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.SINGLE_ONE:
            return <Radio answers={question.answers} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.SELECT_MANY:
            return <CheckBox answers={question.answers} showSettings={true} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.MULTI_SELECT:
            return <CheckBox answers={question.answers} showSettings={true} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.NUMBER:
            return <NumberInput answers={question.answers} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.TEXT:
            return <Text answers={question.answers} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.DATE:
            return <DatePicker readOnly={false} question={question} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.ADDRESS:
            return <Text answers={question.answers} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.DROPDOWN:
            return <Select answers={question.answers} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.DROPDOWN_MULTISELECT:
            return <MultiSelect answers={question.answers} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.UPLOAD:
            return <Upload answers={question.answers} multiple={false} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.MULTI_UPLOAD:
            return <Upload answers={question.answers} multiple={true} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.NOTE:
            return <Note content={question.noteContent} onVisibilityClick={openVisibilityModal} />;
        case AppService.questionType.GROUP:
            return <NewGroup group={question} questions={question.questions} openVisibilityModal={openVisibilityModal} />;
        case AppService.questionType.DYNAMIC_GROUP:
            return <NewDynamicGroup group={question} questions={question.questions} openVisibilityModal={openVisibilityModal} />;
    }
}

export default AnswerItems;
