import React from "react";
import "./FilterSelectComponent.scss";

class FilterSelect extends React.Component {
    constructor(props) {
        super(props);
        const defaultItem = this.props.items.length > 0 ? this.props.items[0].text : "";
        this.state = {
            selected: this.props.selected,
            showDropDown: false,
            order: "asc",
            selectedItems: [this.props.selected],
            defaultItem: defaultItem,
            simpleFilterValue: "",
            filterFrom: "",
            filterTo: ""
        };
        this.showDropDown = this.showDropDown.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.addItemToMultiSelect = this.addItemToMultiSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleDocumentClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleDocumentClick, false);
    }

    handleDocumentClick(e) {
        if (this.node && !this.node.contains(e.target)) {
            this.setState({ showDropDown: false });
        }
    }

    changeOrder(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        const order = this.state.order === "asc" ? "desc" : "asc";
        this.setState({ order });
    }

    showDropDown() {
        this.setState({ showDropDown: !this.state.showDropDown });
    }

    selectItem(item) {
        if (this.props.multiselect) {
            if (item === this.state.defaultItem) {
                const selectedItems = [];
                selectedItems.push(item);
                this.props.onChanged(this.props.name, selectedItems);
                this.setState({
                    selected: item,
                    selectedItems: selectedItems,
                    showDropDown: false
                });
            } else {
                this.addItemToMultiSelect(item);
                this.props.onChanged(this.props.name, this.state.selectedItems);
            }
        } else {
            const selectedItems = [];
            selectedItems.push(item);
            this.props.onChanged(this.props.name, selectedItems);
            this.setState({
                selected: item,
                selectedItems: selectedItems,
                showDropDown: false
            });
        }
    }

    addItemToMultiSelect(item) {
        let selectedItems = this.state.selectedItems;
        if (selectedItems.includes(item)) {
            selectedItems = selectedItems.filter((selectItem) => selectItem !== item);
        } else {
            selectedItems.push(item);
        }
        if (selectedItems.length > 1 && selectedItems[0] === this.state.defaultItem) {
            selectedItems.shift();
        }

        if (selectedItems.length === 0) {
            selectedItems.push(this.state.defaultItem);
        }

        this.setState({
            selected: selectedItems[selectedItems.length - 1],
            selectedItems: selectedItems,
            showDropDown: false
        });
    }

    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };

    render() {
        let itemsList = this.props.items;

        if (this.props.filter === "simple" && this.state.simpleFilterValue !== "") {
            itemsList = itemsList.filter((item) => {
                return (
                    item.text.toLocaleLowerCase() !== "all" &&
                    item.text.toLowerCase().indexOf(this.state.simpleFilterValue.toLowerCase()) !== -1
                );
            });
        }

        if (this.props.filter === "range" && (this.state.filterFrom !== "" || this.state.filterTo !== "")) {
            if (this.state.filterFrom !== "") {
                itemsList = itemsList.filter((item) => {
                    const itemValues = item.text.split("-");
                    if (itemValues.length === 1) {
                        return true;
                    }
                    const from = parseInt(itemValues[0]);
                    return parseInt(this.state.filterFrom) <= from;
                });
            }
            if (this.state.filterTo !== "") {
                itemsList = itemsList.filter((item) => {
                    const itemValues = item.text.split("-");
                    if (itemValues.length === 1) {
                        return true;
                    }
                    const to = parseInt(itemValues[1]);
                    return parseInt(this.state.filterTo) >= to;
                });
            }
        }

        itemsList = itemsList.map((item, index) => {
            const isSelected = this.state.selectedItems.includes(item.text);
            let className = isSelected ? "filter-selected" : "";
            if (this.props.multiselect && isSelected && item !== this.state.defaultItem) {
                className = `${className} selected-multiselect`;
            }
            return (
                <li
                    key={index}
                    className={className}
                    onClick={() => {
                        this.selectItem(item.text);
                    }}
                >
                    {item.text}
                </li>
            );
        });

        const filterOrderDirectionClassName = this.props.allowOrder ? `order-direction-${this.state.order}` : "";

        let filterContainer = null;

        if (this.props.filter === "simple") {
            filterContainer = (
                <div className="simple-filter">
                    <input
                        type="text"
                        placeholder="Search"
                        value={this.state.simpleFilterValue}
                        name="simpleFilterValue"
                        onChange={this.handleChange}
                    />
                </div>
            );
        }

        if (this.props.filter === "range") {
            filterContainer = (
                <div className="range-filter">
                    <span>
                        <input type="text" value={this.state.filterFrom} name="filterFrom" onChange={this.handleChange} />
                    </span>
                    <span className="separator">-</span>
                    <span>
                        <input type="text" value={this.state.filterTo} name="filterTo" onChange={this.handleChange} />
                    </span>
                </div>
            );
        }

        return (
            <div className="filter-container" ref={(node) => (this.node = node)}>
                <div className="filter-select" onClick={this.showDropDown}>
                    <span>
                        {this.props.text}: {this.state.selected}
                    </span>
                    {this.props.allowOrder ? <span className={filterOrderDirectionClassName} onClick={this.changeOrder}></span> : null}
                </div>

                {this.props.items.length > 0 && this.state.showDropDown ? (
                    <div className="filter-items-container">
                        {filterContainer}
                        <div className="filter-items">
                            <ul>{itemsList}</ul>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
export default FilterSelect;
