import DropDown from "../../dropDowns/dropDown/DropDownComponent";
import React from "react";
import i18n from "i18next";

function Select({ lang, answers, onVisibilityClick }) {
    return (
        <div className="content">
            <DropDown
                contentField={true}
                lang={lang}
                items={answers}
                placeholder={i18n.t("Select")}
                onVisibilityClick={onVisibilityClick}
            />
        </div>
    );
}

export default Select;
