import React from "react";

import "./AccountSecurityLayoutComponent.scss";

import logo from "../../assets/images/conceiveAbilities-logo.svg";

class AccountSecurityLayoutComponent extends React.Component {
    render() {
        return (
            <div className="App">
                <div className="row security">
                    <div className="column left-column">
                        <header>
                            <img src={logo} className="app-logo" alt="ConceiveAbilities" />
                        </header>
                        <div className="security-form">{this.props.children}</div>
                    </div>
                    <div className="column right-column"></div>
                </div>
            </div>
        );
    }
}

export default AccountSecurityLayoutComponent;
