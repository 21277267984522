import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/LayoutComponent";
import "./SettingsComponent.scss";
import SimpleCheckBox from "../../components/simpleCheckBox/SimpleCheckBoxComponent";
import AppService from "../../service/AppService";
import FlyoutComponent from "../../components/flyout/FlyoutComponent";

function SettingsComponent() {
    const [mutingData, setMutingData] = useState(null);
    const [showSuccessNotification, setShowSuccessNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);

    useEffect(() => {
        (async () => await getSettingsData())();
    }, []);

    const onActivateMuting = async (isSelected) => {
        const updatedData = { ...mutingData, enabled: isSelected };
        setMutingData(updatedData);
        await saveSettingsData(updatedData);
    };

    const handleChangeMuteScoring = async ({ target }) => {
        setShowSuccessNotification(false);
        setShowErrorNotification(false);
        const updatedData = {
            enabled: false,
            minimalScore: target.value
        };

        setMutingData(updatedData);
        if (!target.value.length) {
            await saveSettingsData(updatedData);
        }
    };

    const getSettingsData = async () => {
        const response = await AppService.getAxios().get("/api/survey/setting/current");
        response.data
            ? setMutingData(response.data)
            : setMutingData({
                  minimalScore: "",
                  enabled: false
              });
    };

    const saveSettingsData = async (body) => {
        try {
            await AppService.getAxios().post("/api/survey/setting/save", body);
            setShowSuccessNotification(true);
        } catch (e) {
            setShowErrorNotification(true);
        }
    };

    return (
        <Layout>
            {showSuccessNotification && (
                <FlyoutComponent
                    type="success"
                    message="Settings has been successfully saved"
                    onClose={() => setShowSuccessNotification(false)}
                />
            )}
            {showErrorNotification && (
                <FlyoutComponent type="error" message="An error during saving settings" onClose={() => setShowErrorNotification(false)} />
            )}
            <div className="settings">
                <h1 className="settings__title">General GC Settings</h1>
                <div className="settings__section">
                    <h2 className="settings__section-title">Profile Settings</h2>
                    {mutingData && (
                        <div className="settings__section-content">
                            <div className="settings__section-item">
                                <input
                                    type="number"
                                    value={mutingData.minimalScore || ""}
                                    placeholder="Minimal scoring value"
                                    onChange={handleChangeMuteScoring}
                                />
                                <p> - </p>
                                <SimpleCheckBox
                                    onClick={(value, isSelected) => onActivateMuting(isSelected)}
                                    label="Activate mute due to scoring value"
                                    isSelected={mutingData.enabled}
                                    disabled={!mutingData.minimalScore}
                                />
                            </div>
                            <p className="settings__section-description">
                                <span>*</span> User will be redirected to the REJECTED page if his score will be less than minimal score
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default SettingsComponent;
