import eyeGrey from "../../../../assets/images/eye-grey.svg";
import reviewGrey from "../../../../assets/images/review-grey.svg";
import disqGrey from "../../../../assets/images/disq-grey.svg";
import React from "react";

function ConditionsSettingsButtons({ children, condition, onVisibilityClick, questionId }) {
    return (
        <div>
            {children}
            <div className="settings-buttons">
                {condition.visibilities.length > 0 ? (
                    <img src={eyeGrey} alt="Visibility icon" onClick={() => onVisibilityClick(questionId, condition)} />
                ) : null}
                {condition.isReview ? <img src={reviewGrey} alt="Review icon" /> : null}
                {condition.isDisqualify ? <img src={disqGrey} alt="Disqualify icon" /> : null}
            </div>
        </div>
    );
}

export default ConditionsSettingsButtons;
