import { useContext, useState } from "react";
import { NewSurveyBuilderContext } from "../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { ReactComponent as Spinner } from "../../../../assets/images/grid.svg";
import { ReactComponent as AddItem } from "../../../../assets/images/plus-white.svg";
import "./NewSurveyBuilderContent.scss";
import { NewSurveyBuilderService } from "../../../../service/NewSurveyBuilderService/NewSurveyBuilderService";
import { DynamicGroup, Group, Question } from "../../../../helper/Application/Application";
import NewQuestionList from "./NewQuestionList/NewQuestionList";
import VisibilityPopup from "../NewSurveyBuilderEditor/AnswerSettingsEditor/answerEditComponents/VisibilityPopup/VisibilityPopup";

const NewSurveyBuilderContent = ({ surveyId }) => {
    const { surveyData, changeSectionQuestionsCount, selectedSection, setSelectedSection, setSelectedQuestion, selectedQuestion } =
        useContext(NewSurveyBuilderContext);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [answerId, setAnswerId] = useState(null);

    const addItem = (type) => {
        let newQuestion = createNewQuestion(type);
        switch (type) {
            case "GROUP":
                newQuestion = new Group("");
                break;
            case "DYNAMIC_GROUP":
                newQuestion = new DynamicGroup("");
                break;
        }
        NewSurveyBuilderService.saveQuestion(surveyId, selectedSection?.sequenceId, newQuestion).then(() => {
            const updatedSection = selectedSection;
            updatedSection.questions.push(newQuestion);
            setSelectedSection(updatedSection);
            setSelectedQuestion(newQuestion);
            changeSectionQuestionsCount(newQuestion, updatedSection, "INCREASE");
        });
    };

    const createNewQuestion = (type) => {
        switch (type) {
            case "GROUP":
                return new Group("");
            case "DYNAMIC_GROUP":
                return new DynamicGroup("");
            default:
                return new Question(type, "", "");
        }
    };

    const openVisibilityModal = (answerId) => {
        setAnswerId(answerId);
        setModalIsOpen(true);
    };
    const closeVisibilityModal = () => setModalIsOpen(false);

    const isNoSubSurveys = surveyData?.subSurveys?.length === 0;
    const isNoSectionsAdded = surveyData?.subSurveys?.flatMap((sub) => sub.sections).length === 0;

    if (isNoSubSurveys) {
        return <div className="empty-survey-message">There are no sub-surveys in current survey right now!</div>;
    }

    if (isNoSectionsAdded) {
        return <div className="empty-survey-message">There are no sections in current survey right now!</div>;
    }

    if (!selectedSection) {
        return (
            <div className="survey-section-loader">
                <Spinner />
            </div>
        );
    }

    const answer = selectedQuestion?.answers?.find((a) => a.sequenceId === answerId);
    const answerIndex = selectedQuestion?.answers?.findIndex((a) => a.sequenceId === answerId);
    return (
        <>
            <div className="survey-builder-section">
                {selectedSection?.questions?.length === 0 && (
                    <div className="empty-questions-message">There are no questions in current section yet</div>
                )}
                <NewQuestionList questions={selectedSection.questions} openVisibilityModal={openVisibilityModal} />
            </div>
            {surveyData?.status !== "PUBLISH" && (
                <div className="survey-builder-actions">
                    <button className="action-first" onClick={() => addItem("YES_NO")}>
                        <AddItem /> <span>Question</span>
                    </button>
                    <button onClick={() => addItem("NOTE")}>
                        <span>Note</span>
                    </button>
                    <button onClick={() => addItem("GROUP")}>
                        <span>Group</span>
                    </button>
                    <button className="action-last" onClick={() => addItem("DYNAMIC_GROUP")}>
                        <span>Dynamic Group</span>
                    </button>
                </div>
            )}
            {modalIsOpen && <VisibilityPopup closeModal={closeVisibilityModal} answer={answer} answerIndex={answerIndex} />}
        </>
    );
};

export default NewSurveyBuilderContent;
