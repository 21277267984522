import React from "react";
import { Route, Redirect } from "react-router-dom";

import Auth from "../Auth";
import SurveyBuilderStore from "../SurveyBuilderContext";

function PrivateRoute({ children, ...rest }) {
    let isAuth = false;
    if (Auth.getInstance().getToken()) {
        const token = Auth.getInstance().checkAuth();
        isAuth = Promise.resolve(token).catch(() => {
            window.location.href = "/login";
        });
    }
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuth ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;
