import React, { useContext, useEffect, useState } from "react";
import add from "../../../../../assets/images/add-icon.svg";
import SlidingComponent from "../../../../../components/SlidingComponent/SlidingComponent";
import * as staticAnswers from "../../../../../helper/StaticAnswers/AnswerData";
import "./AnswerSettingsEditor.scss";
import { NewSurveyBuilderContext } from "../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import AnswerItem from "./answerEditComponents/AnswerItem/AnswerItem";
import { Answer } from "../../../../../helper/Application/Application";
import { addAnswersToQuestion } from "../../../../../helper/StaticAnswers/StaticAnswers";

function AnswerSettingsEditor({ isDynamicGroupQuestion, salesForceAnswers, salesForceItems }) {
    const { surveyData, selectedQuestion, updateQuestion } = useContext(NewSurveyBuilderContext);

    const [isDisabled, setIsDisabled] = useState(surveyData?.status === "PUBLISH");

    useEffect(() => {
        setIsDisabled(surveyData?.status === "PUBLISH");
    }, [surveyData?.status]);

    const addAnswer = () => {
        const newAnswer = new Answer("", "", "");
        updateQuestion("answers", [...selectedQuestion.answers, newAnswer], true);
    };

    const addStaticAnswers = (item) => {
        const question = addAnswersToQuestion(item.answers, selectedQuestion);
        updateQuestion("answers", question.answers, true);
    };

    return (
        <div className="answer-settings">
            <div className="header">
                <div className="add-answer">
                    <span>Answers</span>
                    {!isDisabled && <img className="add-answer-btn" src={add} alt="addAnswer" onClick={addAnswer} />}
                </div>
                {!isDisabled && (
                    <SlidingComponent title="Static Answers" items={Object.values(staticAnswers)} onItemClick={addStaticAnswers} />
                )}
            </div>
            <div className="answer-list">
                {selectedQuestion?.answers?.map((answer, index) => (
                    <AnswerItem
                        answer={answer}
                        index={index}
                        key={answer.sequenceId}
                        isDynamicGroupQuestion={isDynamicGroupQuestion}
                        salesForceAnswers={salesForceAnswers}
                        salesForceItems={salesForceItems}
                        isDisabled={isDisabled}
                    />
                ))}
            </div>
        </div>
    );
}

export default AnswerSettingsEditor;
