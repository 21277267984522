import React from "react";
import AppService from "../../service/AppService";
import "./SubSurveyBuilder.scss";
import SubSurveyItem from "../subSurveyItem/SubSurveyItem";

function SubSurveyBuilder({ subSurveys }) {
    const displaySubSurveys = subSurveys.map((s, i) => {
        let subSurveyLength = 0;
        AppService.selectedApp.allSubSurveySections[i].forEach((item) => {
            subSurveyLength += item.questions.length;
        });
        return (
            <SubSurveyItem
                key={`${i}_sub-survey`}
                name={s.name}
                sections={s.sections}
                showCounts={true}
                showTitle={true}
                surveyIndex={i}
                subSurveyLength={subSurveyLength}
            />
        );
    });

    return <>{displaySubSurveys}</>;
}

export default SubSurveyBuilder;
