import DateRangePicker from "../../dateRangePicker/DateRangePickerComponent";
import React from "react";

function DatePicker({ question, onVisibilityClick, readonly }) {
    const inputs = question.answers
        ? question.answers.map((answer, index) => {
              return (
                  <DateRangePicker
                      readOnly={readonly}
                      onVisibilityClick={onVisibilityClick}
                      index={index}
                      key={`${index}_date_range_picker`}
                  />
              );
          })
        : null;

    return <div>{inputs}</div>;
}

export default DatePicker;
