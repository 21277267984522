import React from "react";
import "./AnswerComponent.scss";

import eyeDisabled from "../../assets/images/eye-disabled.svg";
import eye from "../../assets/images/eye.svg";
import warningDisabled from "../../assets/images/warning-disabled.svg";
import squireDisabled from "../../assets/images/disq-disabled.svg";
import review from "../../assets/images/review-blue.svg";
import disq from "../../assets/images/disq-blue.svg";
import close from "../../assets/images/close-icon.svg";

import comment from "../../assets/images/comment.svg";
import maskImg from "../../assets/images/maskImg.svg";
import AppService from "../../service/AppService";

import disqGrey from "../../assets/images/disq-grey.svg";
import disqSectionGrey from "../../assets/images/disq-section-grey.svg";
import disqSectionBlue from "../../assets/images/disq-section-blue.svg";

import DropDown from "../dropDowns/dropDown/DropDownComponent";
import ReactTooltip from "react-tooltip";
import NumericRangeComponent from "./numericRange/NumericRangeComponent";
import UploadZipCodeComponent from "../upload/UploadZipCodeComponent";
import { LanguageService } from "../../service/LanguageService";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";

class AnswerComponent extends React.Component {
    static contextType = SurveyBuilderContext;

    constructor(props) {
        super(props);
        const mask = (props.answer.inputMask ?? "").mask ?? "";
        this.state = {
            text: props.answer.text,
            mask: mask,
            weight: props.answer.weight ?? "",
            feedback: props.answer.feedback.text,
            showDisqPopup: false,
            type: props.type,
            salesForceAnswers: props.salesForceAnswers,
            salesForceItemSelected: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.removeAnswer = this.removeAnswer.bind(this);
        this.showDisqPopup = this.showDisqPopup.bind(this);
        // this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.setDisqSection = this.setDisqSection.bind(this);
        this.setDisqApp = this.setDisqApp.bind(this);
        // this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.salesForceItemChanged = this.salesForceItemChanged.bind(this);
        this.salesForceAnswerItemChanged = this.salesForceAnswerItemChanged.bind(this);
    }

    componentWillReceiveProps(props) {
        const mask = (props.answer.inputMask ?? "").mask;
        this.setState({
            type: props.type,
            text: LanguageService.getTranslatedText(props.answer.text, this.props.lang),
            mask: mask,
            weight: props.answer.weight ?? "",
            feedback: props.answer.feedback.text,
            salesForceAnswers: props.salesForceAnswers
        });
    }

    getSelectedSalesForceAnswer() {
        let result = null;
        if (this.state.type === AppService.questionType.SELECT_MANY || this.state.type === AppService.questionType.NUMBER) {
            this.props.salesForceItems.forEach((s) => {
                if (s && this.props.answer.salesForceField)
                    if (s.id === this.props.answer.salesForceField.id) {
                        result = s.text;
                    }
            });
        } else {
            this.state.salesForceAnswers?.forEach((s) => {
                if (s && this.props.answer.salesForceAnswerField)
                    if (s.id === this.props.answer.salesForceAnswerField.id) {
                        result = s.text;
                    }
            });
        }

        return result;
    }

    handleChange = ({ target }) => {
        let isStateShouldbeSetted = false;
        switch (target.name) {
            case "mask":
                this.props.onAnswerMaskChanged(this.props.answer.sequenceId, target.value);
                isStateShouldbeSetted = true;
                break;
            case "text":
                this.props.onAnswerTextChanged(this.props.answer.sequenceId, target.value);
                isStateShouldbeSetted = true;
                break;
            case "weight":
                this.setState({ weight: target.value });
                if (target.value.length === 0 || Number.isInteger(parseInt(target.value))) {
                    this.props.onAnswerWeightChanged(this.props.answer.sequenceId, target.value);
                    isStateShouldbeSetted = true;
                }
                break;
            case "feedback":
                this.props.onAnswerFeedbackChanged(this.props.answer.sequenceId, target.value);
                isStateShouldbeSetted = true;
                break;
            default:
                break;
        }
        if (isStateShouldbeSetted) {
            this.setState({ [target.name]: target.value });
        }
    };

    removeAnswer(answer) {
        let sectionIndex = -1;
        let questionIndex = -1;
        let groupQuestionIndex = -1;

        AppService.selectedApp.sections.forEach((section, i) => {
            section.questions.forEach((q, qi) => {
                if (q.type === AppService.questionType.GROUP || q.type === AppService.questionType.DYNAMIC_GROUP) {
                    q.questions.forEach((gq, gqI) => {
                        gq.answers.forEach((a) => {
                            if (a.sequenceId === answer.sequenceId) {
                                sectionIndex = i;
                                questionIndex = qi;
                                groupQuestionIndex = gqI;
                            }
                        });
                    });
                }
                q.answers?.forEach((a) => {
                    if (a.sequenceId === answer.sequenceId) {
                        sectionIndex = i;
                        questionIndex = qi;
                    }
                });
            });
        });
        if (sectionIndex !== -1 && questionIndex !== -1) {
            if (groupQuestionIndex !== -1) {
                AppService.selectedApp.sections[sectionIndex].questions[questionIndex].answers = AppService.selectedApp.sections[
                    sectionIndex
                ].questions[questionIndex].questions[groupQuestionIndex].answers.filter((a) => a.sequenceId !== answer.sequenceId);
            }
            const answers = (AppService.selectedApp.sections[sectionIndex].questions[questionIndex].answers =
                AppService.selectedApp.sections[sectionIndex].questions[questionIndex].answers.filter(
                    (a) => a.sequenceId !== answer.sequenceId
                ));
            this.context.onAnswersChanged(answers);
        }
        this.context.onAnswerRemove();
    }

    showDisqPopup() {
        this.setState({ showDisqPopup: !this.state.showDisqPopup });
    }

    setDisqSection() {
        this.context.makeAnswerDisqualified(this.props.answer.sequenceId, "section");
        this.showDisqPopup();
    }

    setDisqApp() {
        this.context.makeAnswerDisqualified(this.props.answer.sequenceId, "app");
        this.showDisqPopup();
    }

    salesForceAnswerItemChanged(text, obj) {
        this.props.salesForceAnswerItemChanged(this.props.answer.sequenceId, obj);
    }

    salesForceItemChanged(text, obj) {
        this.setState({ salesForceItemSelected: text });
        this.props.salesForceItemChanged(this.props.answer.sequenceId, obj);
    }

    getNumericComponents = (visibilityImage, reviewImage, disqImage) => {
        if (this.state.type === AppService.questionType.NUMBER) {
            return (
                <div className="answer-group">
                    <div>{this.props.index + 1}</div>
                    <div className="controls">
                        <div>
                            <input
                                type="text"
                                placeholder={"text"}
                                name="text"
                                className="text"
                                value={LanguageService.getTranslatedText(this.state.text, "EU") || ""}
                                onChange={this.handleChange}
                                onBlur={() => this.context.saveSurveyOnInputLeave()}
                            />
                            <input
                                type="text"
                                placeholder="V."
                                className="weight"
                                name="weight"
                                value={this.state.weight}
                                onChange={this.handleChange}
                                onBlur={() => this.context.saveSurveyOnInputLeave()}
                            />
                            <img src={close} className="close-image" onClick={() => this.removeAnswer(this.props.answer)} />
                        </div>
                        <NumericRangeComponent
                            answer={this.props.answer}
                            rangeHintTitle="Allowed validation range: "
                            range={this.props.answer.range}
                            onRangeChange={(a, b) => {
                                this.props.onAnswerRangeChanged(a, b);
                                this.forceUpdate();
                            }}
                        />
                        {!this.props.isDynamicGroupQuestion && (
                            <div className="feedback-container">
                                <img src={comment} alt="" />
                                <input
                                    type="text"
                                    placeholder="Optional Feedback"
                                    name="feedback"
                                    value={LanguageService.getTranslatedText(this.state.feedback, "EU") || ""}
                                    onChange={this.handleChange}
                                    className="feedback"
                                    onBlur={() => this.context.saveSurveyOnInputLeave()}
                                />
                            </div>
                        )}
                        {!this.props.isDynamicGroupQuestion && (
                            <>
                                <div className="question search-select salesforce-value">
                                    <DropDown
                                        label=""
                                        selectedItem={this.getSelectedSalesForceAnswer()}
                                        searchable={true}
                                        r="Link to Salesforce"
                                        items={this.props.salesForceItems}
                                        onChange={this.salesForceItemChanged}
                                        onBlur={() => this.context.saveSurveyOnInputLeave()}
                                    />
                                </div>
                                <p className="additional-title">Additional</p>
                                <div className="additional">
                                    <div className="additional-controls">
                                        <div className="additional-item">
                                            {/*rm*/}
                                            <img src={disqImage} className="disq-icon" alt="" onClick={this.showDisqPopup} />
                                            {this.state.showDisqPopup ? (
                                                <div className="disq-popup-numeric">
                                                    <ul>
                                                        <li
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setDisqSection();
                                                            }}
                                                        >
                                                            <img src={disqSectionGrey} alt="" />
                                                            Section disqualification
                                                        </li>
                                                        <li
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setDisqApp();
                                                            }}
                                                        >
                                                            <img src={disqGrey} alt="" />
                                                            Survey disqualification
                                                        </li>
                                                    </ul>
                                                </div>
                                            ) : null}
                                            <label>Disqualify</label>
                                            {/*rm*/}
                                        </div>
                                        <div className="additional-item">
                                            <div className="additional-item">
                                                <img
                                                    src={visibilityImage}
                                                    className="eye-icon"
                                                    alt=""
                                                    onClick={() => this.props.onVisibilityClick(this.props.answer.sequenceId)}
                                                />
                                                <label>Branching</label>
                                            </div>
                                        </div>
                                        <div className="additional-item">
                                            <img
                                                src={reviewImage}
                                                className="review-icon"
                                                alt=""
                                                onClick={() => this.context.markAnswerForReview(this.props.answer.sequenceId)}
                                            />
                                            <label>Review</label>
                                        </div>
                                    </div>
                                </div>
                                <NumericRangeComponent
                                    answer={this.props.answer}
                                    disabled={!this.props.answer.isDisqualify}
                                    rangeHintTitle="Allowed DQ range: "
                                    range={this.props.answer.disqualificationRange}
                                    onRangeChange={(a, b) => {
                                        this.props.onDQRangeChanged(a, b);
                                        this.forceUpdate();
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            );
        } else {
            return "";
        }
    };

    render() {
        const visibilityImage = this.props.answer.hasVisibility ? eye : eyeDisabled;
        const reviewImage = this.props.answer.isReview ? review : warningDisabled;

        const salesForceField = this.context.selectedQuestion.salesForceField?.fieldLabel;
        let zipAvailable = false;
        if (salesForceField) {
            zipAvailable =
                salesForceField.toLowerCase().includes("mailingaddress") || salesForceField.toLowerCase().includes("mailing address");
        }

        let disqImage = squireDisabled;
        if (this.props.answer.isDisqualify) {
            switch (this.props.answer.disqType) {
                case "app":
                    disqImage = disq;
                    break;
                case "section":
                    disqImage = disqSectionBlue;
                    break;
                default:
                    break;
            }
        }

        let salesForceSelect = null;
        switch (this.state.type) {
            case "SINGLE_ONE":
            case "DROPDOWN":
            case "MULTI_SELECT":
            case "DROPDOWN_MULTISELECT":
                if (this.state.salesForceAnswers?.length > 0) {
                    salesForceSelect = (
                        <div className="question search-select salesforce-value">
                            <DropDown
                                label=""
                                selectedItem={this.getSelectedSalesForceAnswer()}
                                searchable={true}
                                placeholder="Select Salesforce value"
                                items={this.state.salesForceAnswers}
                                onChange={this.salesForceAnswerItemChanged}
                            />
                        </div>
                    );
                }
                break;
            case "SELECT_MANY":
                if (this.props.salesForceItems?.length > 0 && !this.props.isDynamicGroupQuestion) {
                    salesForceSelect = (
                        <div className="question search-select salesforce-value">
                            <DropDown
                                label=""
                                selectedItem={this.getSelectedSalesForceAnswer()}
                                searchable={true}
                                placeholder="Link to Salesforce"
                                items={this.props.salesForceItems}
                                onChange={this.salesForceItemChanged}
                            />
                        </div>
                    );
                }
                break;
        }
        const isTypeDate = this.state.type === "DATE";
        const isTypePhone = this.state.type === "PHONE";

        let value = this.state.text;

        if (isTypeDate) {
            value = "MM/DD/YYYY";
        }

        if (isTypePhone) {
            value = "Phone Number";
        }

        if (this.state.type === AppService.questionType.NUMBER) {
            return this.getNumericComponents(visibilityImage, reviewImage, disqImage);
        } else {
            return (
                <div className="answer-group">
                    <div>{this.props.index + 1}</div>
                    <div className="controls">
                        <div>
                            <input
                                type="text"
                                readOnly={isTypeDate || isTypePhone}
                                placeholder={"text"}
                                name="text"
                                className="text"
                                value={LanguageService.getTranslatedText(value, "EU") || ""}
                                onChange={this.handleChange}
                                onBlur={() => this.context.saveSurveyOnInputLeave()}
                            />
                            {this.state.type !== AppService.questionType.ADDRESS && !this.props.isDynamicGroupQuestion ? (
                                <input
                                    type="text"
                                    placeholder="V."
                                    className="weight"
                                    name="weight"
                                    value={this.state.weight}
                                    onChange={this.handleChange}
                                    onBlur={() => this.context.saveSurveyOnInputLeave()}
                                />
                            ) : null}
                            <a data-tip data-for="visibility-tooltip">
                                <img
                                    src={visibilityImage}
                                    className="eye-icon"
                                    alt=""
                                    onClick={() => this.props.onVisibilityClick(this.props.answer.sequenceId)}
                                />
                                <ReactTooltip id="visibility-tooltip" className="tooltip">
                                    <p>Visibility</p>
                                </ReactTooltip>
                            </a>
                            {!this.props.isDynamicGroupQuestion && (
                                <>
                                    <a data-tip data-for="review-tooltip">
                                        <img
                                            src={reviewImage}
                                            className="review-icon"
                                            alt=""
                                            onClick={() => this.context.markAnswerForReview(this.props.answer.sequenceId)}
                                        />
                                        <ReactTooltip id="review-tooltip" className="tooltip">
                                            <p>Review</p>
                                        </ReactTooltip>
                                    </a>
                                    <a data-tip data-for="disq-tooltip">
                                        <img src={disqImage} className="disq-icon" onClick={this.showDisqPopup} />
                                        <ReactTooltip id="disq-tooltip" className="tooltip">
                                            <p>Disqualification</p>
                                        </ReactTooltip>
                                    </a>
                                </>
                            )}
                            {this.state.showDisqPopup ? (
                                <div className="disq-popup">
                                    <ul>
                                        <li
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.setDisqSection();
                                            }}
                                        >
                                            <img src={disqSectionGrey} alt="" />
                                            Section disqualification
                                        </li>
                                        <li
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.setDisqApp();
                                            }}
                                        >
                                            <img src={disqGrey} alt="" />
                                            Survey disqualification
                                        </li>
                                    </ul>
                                </div>
                            ) : null}
                            <a data-tip data-for="remove-answer-tooltip">
                                <img src={close} className="close-image" onClick={() => this.removeAnswer(this.props.answer)} />
                                <ReactTooltip id="remove-answer-tooltip" className="tooltip">
                                    <p>Remove Answer</p>
                                </ReactTooltip>
                            </a>
                        </div>
                        {this.state.type === "TEXT" ? (
                            <a data-tip data-for="mask-tooltip">
                                <div className="feedback-container">
                                    <img className="mask-icon" src={maskImg} alt="" />
                                    <input
                                        type="text"
                                        placeholder="Text Mask"
                                        name="mask"
                                        className="feedback mask"
                                        value={this.state.mask}
                                        onChange={this.handleChange}
                                        onBlur={() => this.context.saveSurveyOnInputLeave()}
                                    />
                                    <ReactTooltip
                                        id="mask-tooltip"
                                        className="tooltip"
                                        disable={!(this.state.mask === "" || this.state.mask === undefined)}
                                    >
                                        <p>&quot;*&quot; - any character placeholder</p>
                                        <p>&quot;a&quot; - letters placeholder</p>
                                        <p>&quot;0&quot; - number placeholder</p>
                                        <p>&quot;\*&quot; - asterisk separator</p>
                                        <p>
                                            &quot;\a&quot; - <b>a</b> separator
                                        </p>
                                        <p>
                                            &quot;\0&quot; - <b>0</b> separator
                                        </p>
                                        <p>any other character will behave as separator</p>
                                    </ReactTooltip>
                                </div>
                            </a>
                        ) : null}
                        {zipAvailable && this.state.type === AppService.questionType.ADDRESS ? (
                            <UploadZipCodeComponent answer={this.props.answer} loadZipScoreFile={this.props.loadZipScoreFile} />
                        ) : null}
                        {!this.props.isDynamicGroupQuestion && (
                            <div className="feedback-container">
                                <img src={comment} alt="" />
                                <input
                                    type="text"
                                    placeholder="Optional Feedback"
                                    name="feedback"
                                    value={LanguageService.getTranslatedText(this.state.feedback, "EU") || ""}
                                    onChange={this.handleChange}
                                    className="feedback"
                                />
                            </div>
                        )}
                    </div>
                    {salesForceSelect}
                </div>
            );
        }
    }
}

export default AnswerComponent;
