import React from "react";
import DropDown from "../dropDowns/dropDown/DropDownComponent";

const ScoringItem = ({
    item,
    text,
    onChangeSelectType,
    onChangeFrom,
    onChangeTo,
    onChangeScoring,
    deleteItem,
    selectedItem,
    valueFrom,
    valueTo,
    valueScore
}) => {
    const removeUnnecessaryCharacters = (event) => {
        switch (event.key) {
            case "e":
            case "E":
            case ".":
                event.preventDefault();
                break;
            case "+":
            case "-":
                if (event.target.name === "age_from" || event.target.name === "age_to") {
                    event.preventDefault();
                }
        }
        return true;
    };
    const scoringDropdownItems = [
        { text: "\u003c" },
        { text: "\u2264" },
        { text: "\u003e" },
        { text: "\u2265" },
        { text: "\u003d" },
        { text: "Between" }
    ];
    return (
        <div className="scoring-item">
            <span>{text}</span>
            <DropDown
                label={false}
                required={true}
                selectedItem={selectedItem}
                placeholder="Select type"
                items={scoringDropdownItems}
                onChange={onChangeSelectType}
            />
            <input
                type="number"
                placeholder="0"
                name="age_from"
                value={valueFrom}
                onChange={onChangeFrom}
                className="small-number-field"
                onKeyDown={removeUnnecessaryCharacters}
            />
            {item.operator === "Between" ? (
                <>
                    <span>and</span>
                    <input
                        type="number"
                        placeholder="0"
                        name="age_to"
                        value={valueTo}
                        onChange={onChangeTo}
                        className="small-number-field"
                        onKeyDown={removeUnnecessaryCharacters}
                    />
                </>
            ) : null}
            <span>score:</span>
            <input
                type="number"
                placeholder="0"
                name="age_score"
                value={valueScore}
                onChange={onChangeScoring}
                className="small-number-field"
                onKeyDown={removeUnnecessaryCharacters}
            />
            <div className="delete-button">
                <button onClick={deleteItem} />
            </div>
        </div>
    );
};

export default ScoringItem;
