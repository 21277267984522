import React, { useContext } from "react";
import { NewSurveyBuilderContext } from "../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import TitleInput from "../../../../../components/TitleInput/TitleInput";
import "./FeedbackSettingsEditor.scss";

function FeedbackSettingsEditor() {
    const { selectedSection, selectedQuestion, selectedFeedback } = useContext(NewSurveyBuilderContext);

    const answerIndex = selectedQuestion?.answers?.findIndex((answer) => answer.sequenceId === selectedFeedback.sequenceId);
    return (
        <div className="feedback-editor">
            <div className="column-title">
                <span>Optional Feedback</span>
                <span></span>
            </div>
            <div className="question-info">
                <div className="section-name">Section: {selectedSection.name}</div>
                <div className="answer-title">
                    Question: <span className="bolt-text">{selectedQuestion.text}</span>
                </div>
                <div className="answer-title">
                    Answer: <span className="bolt-text">{selectedFeedback.text}</span>
                </div>
            </div>
            <div className="form">
                <TitleInput
                    type="text"
                    placeholder="Enter title"
                    name="feedbackTitle"
                    value={selectedFeedback.feedback.title}
                    label="Title"
                    index={answerIndex}
                />
                <TitleInput
                    type="text"
                    placeholder="Enter text"
                    name="feedbackText"
                    value={selectedFeedback.feedback.text}
                    label="Optional feedback text"
                    index={answerIndex}
                />
            </div>
        </div>
    );
}

export default FeedbackSettingsEditor;
