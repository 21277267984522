import React from "react";
import "./BranchingLogicPopupComponent.scss";
import close from "../../assets/images/close-icon.svg";
import AppService from "../../service/AppService";
import Sections from "../sections/SectionsComponent";

import CheckBox from "../checkBox/CheckBoxComponent";
import { BuilderSurveyService } from "../../service/BuilderSurveyService/BuilderSurveyService";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";
import { LanguageService } from "../../service/LanguageService";

class BranchingLogicPopupComponent extends React.Component {
    static contextType = SurveyBuilderContext;
    constructor(props) {
        super(props);
        this.state = {
            selectedSection: 0,
            questions: [],
            searchCondition: "",
            conditionVisibilities: props.conditionVisibilities ?? []
        };

        this.changeSection = this.changeSection.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.setVisibility = this.setVisibility.bind(this);
        this.setVisibilityGroup = this.setVisibilityGroup.bind(this);
        this.saveSelectedQuestion = this.saveSelectedQuestion.bind(this);
        this.getQuestions = this.getQuestions.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            questions: [],
            searchCondition: "",
            conditionVisibilities: props.conditionVisibilities ?? [],
            selectedSection: this.context.selectedSectionIndex
        });
    }

    componentWillMount() {
        this.setState({
            questions: this.getQuestions(AppService.selectedApp.sections[this.state.selectedSection])
        });
    }
    setVisibilityGroup(question) {
        const { selectedAnswerId, selectedQuestion, selectedSectionIndex, selectedGroupQuestionIndex } = this.context;
        const { selectedSection } = this.state;

        const groupIndex = AppService.selectedApp.sections[selectedSection].questions.findIndex(
            (q) => q.sequenceId === selectedQuestion.groupId
        );
        const questionIndex = this.getGroupQuestions(AppService.selectedApp.sections[selectedSection]).findIndex(
            (q) => q.sequenceId === question.sequenceId
        );

        const visibilityIndex = this.getGroupQuestions(AppService.selectedApp.sections[selectedSection])[
            questionIndex
        ].visibility.findIndex((a) => a.sequenceId === selectedAnswerId);

        const sourceQuestionIndex = this.getGroupQuestions(AppService.selectedApp.sections[selectedSectionIndex]).findIndex(
            (q) => q.sequenceId === selectedQuestion.sequenceId
        );
        const answerIndex = this.getGroupQuestions(AppService.selectedApp.sections[selectedSection])[
            selectedGroupQuestionIndex
        ].answers.findIndex((a) => a.sequenceId === selectedAnswerId);
        if (visibilityIndex > -1) {
            this.getGroupQuestions(AppService.selectedApp.sections[selectedSection])[questionIndex].visibility = this.getGroupQuestions(
                AppService.selectedApp.sections[selectedSection]
            )[questionIndex].visibility.filter((v) => v.sequenceId !== selectedAnswerId);

            const count = BuilderSurveyService.getVisibilityCount(selectedAnswerId) - 1;

            AppService.selectedApp.sections[selectedSection].questions[groupIndex].questions[sourceQuestionIndex].answers[
                answerIndex
            ].hasVisibility = count > 0;
        } else {
            AppService.selectedApp.sections[selectedSection].questions[groupIndex].questions[questionIndex].visibility.push({
                section: this.props.section,
                sequenceId: selectedAnswerId,
                sourceQuestion: selectedQuestion.sequenceId,
                targetQuestion: question.sequenceId
            });
            AppService.selectedApp.sections[selectedSection].questions[groupIndex].questions[sourceQuestionIndex].answers[
                answerIndex
            ].hasVisibility = true;
        }

        this.setState({
            questions: this.getQuestions(AppService.selectedApp.sections[selectedSection])
        });
    }
    saveSelectedQuestion(question) {
        let selected = this.state.conditionVisibilities;
        if (selected.includes(question.sequenceId)) {
            selected = selected.filter((q) => q !== question.sequenceId);
        } else {
            selected.push(question.sequenceId);
        }
        this.setState({
            conditionVisibilities: selected
        });
    }

    setVisibility(question) {
        const { selectedQuestion, selectedAnswerId, selectedSectionIndex } = this.context;
        const { selectedSection } = this.state;

        if (this.props.isConditions) {
            this.saveSelectedQuestion(question);
            return;
        }

        if (selectedQuestion?.groupId && selectedQuestion.groupId.length > 0) {
            this.setVisibilityGroup(question);
            return;
        }

        const questionIndex = AppService.selectedApp.sections[selectedSection].questions.findIndex(
            (q) => q.sequenceId === question.sequenceId
        );
        const visibilityIndex = AppService.selectedApp.sections[selectedSection].questions[questionIndex].visibility.findIndex(
            (a) => a.sequenceId === selectedAnswerId
        );

        const sourceQuestionIndex = AppService.selectedApp.sections[selectedSectionIndex].questions.findIndex(
            (q) => q.sequenceId === selectedQuestion.sequenceId
        );
        const answerIndex = AppService.selectedApp.sections[selectedSectionIndex].questions[sourceQuestionIndex].answers.findIndex(
            (a) => a.sequenceId === selectedAnswerId
        );

        if (visibilityIndex > -1) {
            AppService.selectedApp.sections[selectedSection].questions[questionIndex].visibility = AppService.selectedApp.sections[
                selectedSection
            ].questions[questionIndex].visibility.filter((v) => v.sequenceId !== selectedAnswerId);

            const count = BuilderSurveyService.getVisibilityCount(selectedAnswerId);

            AppService.selectedApp.sections[selectedSectionIndex].questions[sourceQuestionIndex].answers[answerIndex].hasVisibility =
                count > 0;
        } else {
            AppService.selectedApp.sections[selectedSection].questions[questionIndex].visibility.push({
                section: this.props.section,
                sequenceId: selectedAnswerId,
                sourceQuestion: selectedQuestion.sequenceId,
                targetQuestion: question.sequenceId
            });
            AppService.selectedApp.sections[selectedSectionIndex].questions[sourceQuestionIndex].answers[answerIndex].hasVisibility = true;
        }
        this.setState({
            questions: this.getQuestions(AppService.selectedApp.sections[selectedSection])
        });
    }

    doSearch({ target }) {
        let questions = [];
        if (target.value.length === 0) {
            questions = this.getQuestions(AppService.selectedApp.sections[this.state.selectedSection]);
        } else {
            this.getQuestions(AppService.selectedApp.sections[this.state.selectedSection]).forEach((q) => {
                const text = q.text;
                if (text.length > 0 && text.indexOf(target.value) !== -1) {
                    questions.push(q);
                }
            });
        }
        this.setState({ questions: questions });
    }

    changeSection(section) {
        const selectedSectionIndex = AppService.selectedApp.sections.findIndex((s) => s.sequenceId === section.sequenceId);
        this.setState({
            selectedSection: selectedSectionIndex,
            questions: this.getQuestions(section)
        });
    }

    isGroupQuestion() {
        return this.context.selectedQuestion.groupId && this.context.selectedQuestion.groupId.length > 0;
    }

    getGroupQuestions(section) {
        return section.questions.find((gq) => gq.sequenceId === this.context.selectedQuestion.groupId)?.questions;
    }

    getQuestions(section) {
        let result = [];
        const selectedQuestionText = this.context.selectedQuestion?.text || this.context.selectedQuestion?.title;
        const questions = this.isGroupQuestion() ? this.getGroupQuestions(section) : section.questions;

        questions?.forEach((question) => {
            let text = "";
            if (question.text) {
                text = question.text;
            }
            if (question.title) {
                text = question.title;
            }
            const isChecked = this.props.isConditions
                ? this.state.conditionVisibilities.findIndex((v) => v === question.sequenceId) > -1
                : question.visibility.findIndex((v) => v.sequenceId === this.context.selectedAnswerId) > -1;

            if (
                LanguageService.getTranslatedText(text, "EU") === LanguageService.getTranslatedText(selectedQuestionText, "EU") &&
                question.visibility.length > 0
            ) {
                const visibility = question.visibility.map((v) => v.sourceQuestion);
                result = result.filter((item) => !visibility.includes(item.sequenceId));
            }

            if (text && LanguageService.getTranslatedText(text, "EU") !== LanguageService.getTranslatedText(selectedQuestionText, "EU")) {
                result.push({
                    sequenceId: question.sequenceId,
                    text: question.text || question.title,
                    isChecked: isChecked
                });
            }
        });
        return result;
    }

    onClose() {
        const { onAnswersChanged, selectedQuestion, selectedSectionIndex } = this.context;
        const { selectedSection } = this.state;

        if (selectedQuestion?.groupId) {
            const groupIndex = AppService.selectedApp.sections[selectedSection].questions.findIndex(
                (q) => q.sequenceId === this.context.selectedQuestion.groupId
            );
            const sourceGroupQuestionIndex = this.getGroupQuestions(
                AppService.selectedApp.sections[this.context.selectedSectionIndex]
            ).findIndex((q) => q.sequenceId === this.context.selectedQuestion.sequenceId);
            const groupAnswers =
                AppService.selectedApp.sections[selectedSection].questions[groupIndex].questions[sourceGroupQuestionIndex].answers;
            onAnswersChanged(groupAnswers);
        } else {
            const sourceQuestionIndex = AppService.selectedApp.sections[selectedSectionIndex].questions.findIndex(
                (q) => q.sequenceId === selectedQuestion.sequenceId
            );
            const answers = AppService.selectedApp.sections[selectedSectionIndex].questions[sourceQuestionIndex]?.answers;
            onAnswersChanged(answers);
        }
        this.props.onClose(this.state.conditionVisibilities);
    }

    render() {
        const questions = this.getQuestions(AppService.selectedApp.sections[this.state.selectedSection]);
        const sections = this.isGroupQuestion()
            ? [AppService.selectedApp.sections[this.context.selectedSectionIndex]]
            : AppService.selectedApp.sections;

        return (
            <div className="branchind-logic-overlay">
                <div className="modal-popup">
                    <div className="header">
                        <span>Visibility</span>
                        <span className="search">
                            <input type="text" placeholder="search" value={this.state.ageFrom} onChange={this.doSearch} />
                        </span>
                        <span onClick={() => this.onClose()}>
                            <img src={close} alt="" />
                        </span>
                    </div>
                    <div className="header-shadow" />
                    <div className="content">
                        <div className="left-column">
                            <div className="sub-header">Sections</div>
                            <Sections
                                sections={sections}
                                selectedVisibilitySection={AppService.selectedApp.sections[this.state.selectedSection].name}
                                changeVisibilitySection={this.changeSection}
                                disableChangeSurvey={true}
                            />
                        </div>
                        <div className="right-column">
                            <div className="sub-header">Questions</div>
                            <div>
                                <CheckBox answers={questions} onClick={this.setVisibility} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default BranchingLogicPopupComponent;
