import React from "react";
import AppService from "../../service/AppService";
import BmiInfo from "./BmiInfo";
import AgeInfo from "./AgeInfo";
import StatesInfo from "./StatesInfo";

function AcceptanceCriteriaInfo() {
    const isAnyAcceptanceCriteriaAdded = () => {
        return (
            AppService.selectedApp !== null &&
            (AppService.selectedApp.disqualificationRules.states.length > 0 ||
                AppService.selectedApp.disqualificationRules.allowedBmi ||
                AppService.selectedApp.disqualificationRules.allowedAge)
        );
    };

    return (
        <div className="acceptance-criteria-info-section">
            {isAnyAcceptanceCriteriaAdded() && (
                <div className="column-title acceptance-criteria-title">
                    <span>Acceptance Criteria</span>
                </div>
            )}
            <BmiInfo />
            <AgeInfo />
            <StatesInfo />
        </div>
    );
}

export default AcceptanceCriteriaInfo;
