import React, { useContext } from "react";
import AppService from "../../../../../../../service/AppService";
import UploadZipCodeComponent from "../../../../../../../components/upload/UploadZipCodeComponent";
import comment from "../../../../../../../assets/images/comment.svg";
import { NewSurveyBuilderContext } from "../../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import AnswerControls from "../AnswerControls/AnswerControls";
import SalesForceAnswerDropdown from "../SalesForceAnswerDropdown/SalesForceAnswerDropdown";
import TextAnswerMaskInput from "../TextAnswerMaskInput";
import TitleInput from "../../../../../../../components/TitleInput/TitleInput";
import NumericRangeComponent from "../../../../../../../components/answer/numericRange/NumericRangeComponent";
import { showErrorNotification, showSuccessNotification } from "../../../../../../../helper/NotificationHelper";

function AnswerItem({ answer, index, isDynamicGroupQuestion, salesForceAnswers, salesForceItems, isDisabled }) {
    const { selectedQuestion, surveyData, updateAnswer } = useContext(NewSurveyBuilderContext);

    const valueMap = {
        DATE: "MM/DD/YYYY",
        PHONE: "Phone Number"
    };

    const answerText = valueMap[selectedQuestion.type] || answer.text;

    const handleSaveZipCodeFile = async (zipCodeObject) => await updateAnswer("accountableZipCode", zipCodeObject, index, true);

    const validateAndSaveRange = (range, index, saveFunc) => {
        const lowerValue = getBoundaryValue(range?.lowerBoundary);
        const upperValue = getBoundaryValue(range?.upperBoundary);

        if (lowerValue !== null && upperValue !== null) {
            if (lowerValue < upperValue) {
                saveFunc(range, index, true);
                showSuccessNotification("Range was successfully saved");
            } else {
                showErrorNotification("Range cannot be saved", "Upper boundary cannot be less than or equal to the lower boundary.");
            }
        } else if (lowerValue !== null) {
            saveFunc(range, index, true);
            showSuccessNotification(`Range was successfully saved as ${lowerValue} ≤ Result Value < ∞`);
        } else if (upperValue !== null) {
            saveFunc(range, index, true);
            showSuccessNotification(`Range was successfully saved as -∞ < Result Value ≤ ${upperValue}`);
        } else {
            const emptyBoundary = {
                lowerBoundary: undefined,
                upperBoundary: undefined
            };
            saveFunc(emptyBoundary, index, true);
            showSuccessNotification("Range was cleared.");
        }
    };

    const getBoundaryValue = (boundary) => {
        if (boundary?.value !== undefined) {
            return parseInt(boundary.value);
        }
        return null;
    };

    const numberAnswerRangeChange = (answerId, range) => {
        validateAndSaveRange(range, index, updateAnswer.bind(null, "range"));
    };

    const numberAnswerDQRangeChange = (answerId, range) => {
        validateAndSaveRange(range, index, updateAnswer.bind(null, "disqualificationRange"));
    };

    const onAnswerMaskChanged = (event) => {
        const mask = {
            mask: event.target.value,
            type: "SIMPLE"
        };

        updateAnswer("inputMask", mask, index, true);
    };

    const isAddressQuestion = selectedQuestion.type === AppService.questionType.ADDRESS;
    const salesForceField = selectedQuestion.salesForceField?.fieldLabel || "";
    const showZipCodeField =
        ["mailingaddress", "mailing address"].some((subStr) => salesForceField.toLowerCase().includes(subStr)) &&
        salesForceField &&
        isAddressQuestion;

    const isNumber = selectedQuestion.type === "NUMBER";
    const isNumberOrPickList = isNumber || selectedQuestion.type === AppService.questionType.SELECT_MANY;
    const isAnswerSalesForceDropdownVisible = (salesForceAnswers?.length > 0 || isNumberOrPickList) && !isDynamicGroupQuestion;

    return (
        <div className="answer-item">
            <div className="answer-count">{index + 1}</div>
            <div className="content">
                <div className="answer-main-info">
                    <TitleInput
                        name="answer-text"
                        placeholder="text"
                        className="answer-text"
                        value={answerText}
                        index={index}
                        isDisabled={isDisabled}
                    />
                    {!isAddressQuestion && !isDynamicGroupQuestion && (
                        <TitleInput
                            type="number"
                            name="weight"
                            placeholder="V."
                            className="answer-score"
                            value={answer.weight}
                            index={index}
                            isDisabled={isDisabled}
                        />
                    )}
                    <AnswerControls
                        answer={answer}
                        isDynamicGroupQuestion={isDynamicGroupQuestion}
                        showOnlyRemove={isNumber}
                        isDisabled={isDisabled}
                    />
                </div>
                {selectedQuestion.type === "TEXT" && (
                    <TextAnswerMaskInput answer={answer} handleChange={onAnswerMaskChanged} isDisabled={isDisabled} />
                )}
                {showZipCodeField && (
                    <UploadZipCodeComponent answer={answer} saveZipCodeFile={handleSaveZipCodeFile} selectedApp={surveyData} />
                )}
                {isNumber && (
                    <NumericRangeComponent
                        answer={answer}
                        rangeHintTitle="Allowed validation range: "
                        range={answer.range}
                        onRangeChange={numberAnswerRangeChange}
                        disabled={isDisabled}
                    />
                )}
                {!isDynamicGroupQuestion && (
                    <div className="feedback-container">
                        <img src={comment} alt="" />
                        <TitleInput
                            name="feedbackText"
                            placeholder="Optional Feedback"
                            value={answer.feedback.text}
                            index={index}
                            isDisabled={isDisabled}
                        />
                    </div>
                )}
            </div>
            {isAnswerSalesForceDropdownVisible && (
                <SalesForceAnswerDropdown
                    answer={answer}
                    salesForceItems={salesForceItems}
                    salesForceAnswers={salesForceAnswers}
                    isDisabled={isDisabled}
                />
            )}
            {!isDynamicGroupQuestion && isNumber && (
                <div>
                    <p className="additional-title">Additional</p>
                    <div className="additional">
                        <AnswerControls
                            answer={answer}
                            isDynamicGroupQuestion={isDynamicGroupQuestion}
                            isNumberQuestion
                            isDisabled={isDisabled}
                        />
                    </div>
                    <NumericRangeComponent
                        answer={answer}
                        disabled={!answer.isDisqualify || isDisabled}
                        rangeHintTitle="Allowed DQ range: "
                        range={answer.disqualificationRange}
                        onRangeChange={numberAnswerDQRangeChange}
                    />
                </div>
            )}
        </div>
    );
}

export default AnswerItem;
