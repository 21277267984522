import React from "react";
import AppInfoComponent from "../appInfoSection/AppInfoComponent";
import AppService from "../../service/AppService";
import AcceptanceCriteriaInfo from "../AcceptanceCriteriaInfo/AcceptanceCriteriaInfo";
import ScoreInformation from "../ScoreInformation/ScoreInformation";
import SubSurveyBuilder from "../subSurveyBuilder/SubSurveyBuilder";

function SurveyInformationComponent({ onAppEdit }) {
    return (
        <aside className="left">
            <AppInfoComponent app={AppService.selectedApp} onEdit={onAppEdit} />
            <AcceptanceCriteriaInfo />
            <ScoreInformation />
            <SubSurveyBuilder subSurveys={AppService.selectedApp?.subSurveys} />
        </aside>
    );
}

export default SurveyInformationComponent;
