import React from "react";
import ScoringItem from "../../../../scoringItem/ScoringItem";

function BmiScore({ bmiScore, setBmiScore }) {
    const deleteScoringItem = (index, BMI = false) => {
        let shorterScoringItems;
        shorterScoringItems = bmiScore.filter((item, idx) => idx !== index);
        setBmiScore(shorterScoringItems);
    };

    return bmiScore.map((item, index) => {
        return (
            <ScoringItem
                key={index + "_bmi_scoring_item"}
                item={item}
                text="BMI"
                onChangeSelectType={(value) => {
                    const bmiCopy = bmiScore;
                    bmiCopy[index] = {
                        ...item,
                        operator: value,
                        valueTo: ""
                    };
                    setBmiScore([...bmiCopy]);
                }}
                onChangeFrom={({ target: { value } }) => {
                    const bmiCopy = bmiScore;
                    bmiCopy[index] = {
                        ...item,
                        valueFrom: value
                    };
                    setBmiScore([...bmiCopy]);
                }}
                onChangeTo={({ target: { value } }) => {
                    const bmiCopy = bmiScore;
                    bmiCopy[index] = { ...item, valueTo: value };
                    setBmiScore([...bmiCopy]);
                }}
                onChangeScoring={({ target: { value } }) => {
                    const bmiCopy = bmiScore;
                    bmiCopy[index] = { ...item, score: value };
                    setBmiScore([...bmiCopy]);
                }}
                deleteItem={() => deleteScoringItem(index, true)}
                selectedItem={bmiScore[index].operator}
                valueFrom={bmiScore[index].valueFrom}
                valueTo={bmiScore[index].valueTo}
                valueScore={bmiScore[index].score}
            />
        );
    });
}

export default BmiScore;
