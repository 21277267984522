import React from "react";
import add from "../../../../../assets/images/add-icon.svg";
import AgeScore from "../AgeScore/AgeScore";
import BmiScore from "../BmiScore/BmiScore";

function ScoringTab({ ageScore, setAgeScore, bmiScore, setBmiScore }) {
    const addNewAgeScoring = () => {
        const ageScoringItem = {
            operator: "",
            valueFrom: "",
            valueTo: "",
            score: ""
        };
        ageScore.push(ageScoringItem);

        setAgeScore([...ageScore]);
    };

    const addNewBMIScoring = () => {
        const bmiScoringItem = {
            operator: "",
            valueFrom: "",
            valueTo: "",
            score: ""
        };
        bmiScore.push(bmiScoringItem);
        setBmiScore([...bmiScore]);
    };

    return (
        <div className="content">
            <div>
                <div className="sub-header">
                    AGE
                    <img src={add} alt="add age score" className="add-section" onClick={addNewAgeScoring} />
                </div>
                <div className="scoring-list">
                    <AgeScore ageScore={ageScore} setAgeScore={setAgeScore} />
                </div>
            </div>
            <div className="border" />
            <div>
                <div className="sub-header">
                    BMI
                    <img src={add} alt="add bmi score" className="add-section" onClick={addNewBMIScoring} />
                </div>
                <div className="scoring-list">
                    <BmiScore bmiScore={bmiScore} setBmiScore={setBmiScore} />
                </div>
            </div>
        </div>
    );
}

export default ScoringTab;
