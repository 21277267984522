import React, { useContext, useEffect, useState } from "react";
import "./QuestionSettingsComponent.scss";

import AnswersSettings from "../answersSettings/AnswersSettingsComponent";
import AppService from "../../service/AppService";
import ConditionsComponent from "../conditionsCompoent/ConditionsComponent";
import DropDown from "../dropDowns/dropDown/DropDownComponent";
import SimpleCheckBox from "../simpleCheckBox/SimpleCheckBoxComponent";
import { LanguageService } from "../../service/LanguageService";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";
import cloneDeep from "lodash/cloneDeep";
import noteVariablesIcon from "../../assets/images/comment.svg";
import Tooltip from "../Tooltip/Tooltip";
function QuestionSettingsComponent({ onVisibilityClick }) {
    const context = useContext(SurveyBuilderContext);

    const [saleForceItems, setSaleForceItems] = useState([]);
    const [salesForceAnswers, setSalesForceAnswers] = useState([]);

    useEffect(() => {
        getSaleForceFields();
    }, []);

    useEffect(() => {
        setSalesForcesAnswers();
    }, [context.selectedQuestion.salesForceField?.id]);

    const setSalesForcesAnswers = (salesForceItems = saleForceItems) => {
        const result = [];
        salesForceItems.forEach((s) => {
            if (s && context.selectedQuestion.salesForceField) {
                if (s.id === context.selectedQuestion.salesForceField.id) {
                    result.push(s);
                }
            }
        });
        setSalesForceAnswers(result);
    };

    const getSaleForceFields = () => {
        AppService.getAxios()
            .get(`/api/sales-force-field?type=${AppService.selectedApp.type}`)
            .then((response) => {
                setSalesForceItems(response.data);
            });
    };

    const getDropDownItems = (source) => {
        const result = [];
        if (source) {
            source.forEach((s) => {
                result.push({
                    id: s.id,
                    text: s.value
                });
            });
        }
        return result;
    };

    const setSalesForceItems = (salesForceData) => {
        let result = [];
        Object.keys(salesForceData).forEach((key) => {
            salesForceData[key].forEach((item) => {
                result.push({
                    id: item.id,
                    text: item.fieldName + (item.fieldLabel ? ` (${item.fieldLabel})` : ""),
                    fieldName: item.fieldName,
                    answers: getDropDownItems(item.salesForceAnswerFields)
                });
            });
        });
        result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
        if (context.selectedQuestion.type === "ADDRESS") {
            result = result.filter(
                (item) =>
                    item.text.toLowerCase().includes("address") ||
                    item.text.toLowerCase().includes("mailing") ||
                    item.text.toLowerCase().includes("location") ||
                    item.fieldName.toLowerCase().includes("address") ||
                    item.fieldName.toLowerCase().includes("mailing") ||
                    item.fieldName.toLowerCase().includes("location")
            );
        }
        setSalesForcesAnswers(result);
        setSaleForceItems(result);
    };

    const onQuestionTypeChanged = (type) => {
        context.onTypeChanged(type);
    };

    const handleChange = ({ target }) => {
        switch (target.name) {
            case "questionText":
                context.onQuestionTextChanged(target.value);
                break;
            case "hint":
                context.onHintChanged(target.value);
                break;
            case "noteContent":
                context.onNoteContentChanged(target.value);
                break;
            case "noAnswerScore":
                context.onNoAnswerScoreChange(target.value);
                break;
            default:
                break;
        }
    };

    const selectSalesForceAnswers = () => {
        if (context.selectedQuestion.salesForceField) {
            const selectedSalesForceAnswers = salesForceAnswers
                .filter((i) => i.id === context.selectedQuestion.salesForceField.id)
                .map((i) => i.answers);
            return selectedSalesForceAnswers.length > 0 ? selectedSalesForceAnswers[0] : selectedSalesForceAnswers;
        }
    };

    const salesForceItemChanged = (_, item) => {
        if (salesForceAnswers.some((el) => el.id !== item.id)) {
            setSalesForceAnswers([...salesForceAnswers, item]);
        }
        context.salesForceItemChanged(item);
    };

    const { selectedQuestion, setSelectedQuestion, selectedSectionIndex, selectedQuestionIndex } = context;

    const isQuestionInsideDynamicGroup = () => {
        if (selectedQuestion.groupId) {
            const group = AppService.selectedApp.sections[selectedSectionIndex].questions.find(
                (question) => question.sequenceId === selectedQuestion.groupId
            );
            return group.type === AppService.questionType.DYNAMIC_GROUP;
        }

        return false;
    };

    const changeDynamicInstanceTitle = (value) => {
        const updatedQuestion = selectedQuestion;
        updatedQuestion.instanceGroupName = value;
        AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = updatedQuestion;
        setSelectedQuestion(cloneDeep(updatedQuestion));
    };

    const changeShowInstanceCount = (showCount) => {
        const updatedQuestion = selectedQuestion;
        updatedQuestion.showInstanceCount = showCount;
        AppService.selectedApp.sections[selectedSectionIndex].questions[selectedQuestionIndex] = updatedQuestion;
        setSelectedQuestion(cloneDeep(updatedQuestion));
    };

    const isLinkToSalesforceFieldVisible =
        selectedQuestion.type !== AppService.questionType.NOTE &&
        selectedQuestion.type !== AppService.questionType.SELECT_MANY &&
        selectedQuestion.type !== AppService.questionType.NUMBER &&
        !isQuestionInsideDynamicGroup();

    const isConditionVisible =
        selectedQuestion.type !== AppService.questionType.NOTE &&
        selectedQuestion.type !== AppService.questionType.DYNAMIC_GROUP &&
        !isQuestionInsideDynamicGroup();
    const isQuestion =
        selectedQuestion.type !== AppService.questionType.NOTE &&
        selectedQuestion.type !== AppService.questionType.GROUP &&
        selectedQuestion.type !== AppService.questionType.DYNAMIC_GROUP;
    const questionTitle = AppService.questionTitles[AppService.convertTypeNameToIndex(selectedQuestion.type)];
    const isGroupQuestion = selectedQuestion.type === AppService.questionType.GROUP;
    const isDynamicGroup = selectedQuestion.type === AppService.questionType.DYNAMIC_GROUP;
    const isOptionalCheckboxVisible =
        (selectedQuestion.type === AppService.questionType.DYNAMIC_GROUP || isQuestion) && !isQuestionInsideDynamicGroup();

    const buildNotesVariableIcon = () => {
        const availableVariables = ["{firstName}", "{lastName}", "{email}"];
        if (selectedQuestion.type === AppService.questionType.NOTE) {
            return (
                <Tooltip title="Available variables" list={availableVariables}>
                    <img src={noteVariablesIcon} alt="" />
                </Tooltip>
            );
        }
    };
    return (
        <div>
            <div className="column-title">
                <span>{questionTitle}</span>
                {isOptionalCheckboxVisible && (
                    <SimpleCheckBox
                        onClick={(value, isSelected) => context.onOptionalQuestionChanged(isSelected)}
                        label="Optional"
                        isSelected={selectedQuestion.optional}
                    />
                )}
            </div>
            {isGroupQuestion || isDynamicGroup ? (
                <div className="question">
                    <label>Title</label>
                    <input
                        type="text"
                        placeholder="Enter a text"
                        name="questionText"
                        value={LanguageService.getTranslatedText(selectedQuestion.text, "EU") || ""}
                        onChange={handleChange}
                        onBlur={() => context.saveSurveyOnInputLeave()}
                    />
                </div>
            ) : (
                <div className="question">
                    <label>Text {buildNotesVariableIcon()}</label>
                    <input
                        type="text"
                        placeholder="Enter a text"
                        name="questionText"
                        value={LanguageService.getTranslatedText(selectedQuestion.text, "EU") || ""}
                        onChange={handleChange}
                        onBlur={() => context.saveSurveyOnInputLeave()}
                    />
                    {selectedQuestion.type === AppService.questionType.NOTE ? (
                        <div>
                            <label>Content</label>
                            <input
                                type="text"
                                placeholder="Enter a content"
                                name="noteContent"
                                value={LanguageService.getTranslatedText(selectedQuestion.noteContent, "EU") || ""}
                                onChange={handleChange}
                                onBlur={() => context.saveSurveyOnInputLeave()}
                            />
                        </div>
                    ) : (
                        !isQuestionInsideDynamicGroup() && (
                            <div>
                                <label>Hint</label>
                                <input
                                    type="text"
                                    placeholder="Enter a hint"
                                    name="hint"
                                    value={LanguageService.getTranslatedText(selectedQuestion.hint, "EU") || ""}
                                    onChange={handleChange}
                                    onBlur={() => context.saveSurveyOnInputLeave()}
                                />
                            </div>
                        )
                    )}
                </div>
            )}
            {isLinkToSalesforceFieldVisible && (
                <div className="question search-select">
                    <DropDown
                        label="Link to Salesforce"
                        selectedItem={selectedQuestion.salesForceField?.fieldLabel}
                        searchable={true}
                        placeholder="Please select"
                        items={saleForceItems}
                        onChange={salesForceItemChanged}
                        isDisabled={false}
                        lang={context.lang}
                    />
                </div>
            )}
            {isQuestion && (
                <div className="question weight-container">
                    <label>Add score if no answer</label>
                    <input
                        type="number"
                        placeholder="V."
                        className="weight"
                        name="noAnswerScore"
                        value={selectedQuestion.noAnswerScore || ""}
                        onChange={handleChange}
                    />
                </div>
            )}
            {isDynamicGroup && (
                <>
                    <div className="question">
                        <label>Title for every group</label>
                        <input
                            type="text"
                            placeholder="Enter a title"
                            name="questionText"
                            value={LanguageService.getTranslatedText(selectedQuestion.instanceGroupName, "EU") || ""}
                            onChange={(e) => changeDynamicInstanceTitle(e.target.value)}
                            onBlur={() => {}}
                        />
                    </div>
                    {selectedQuestion.instanceGroupName.length > 0 && (
                        <div className="column-title">
                            <SimpleCheckBox
                                onClick={(value, isSelected) => changeShowInstanceCount(isSelected)}
                                label="Show group count"
                                isSelected={selectedQuestion.showInstanceCount}
                            />
                        </div>
                    )}
                </>
            )}
            {isQuestion && (
                <AnswersSettings
                    onQuestionTypeChanged={onQuestionTypeChanged}
                    type={selectedQuestion.type}
                    isDynamicGroupQuestion={isQuestionInsideDynamicGroup()}
                    onVisibilityClick={onVisibilityClick}
                    hasVisibility={selectedQuestion.visibility?.length > 0}
                    salesForceAnswers={selectSalesForceAnswers()}
                    salesForceItems={saleForceItems}
                    lang={context.lang}
                />
            )}
            {isConditionVisible && <ConditionsComponent onConditionAdded={context.onConditionAdded} question={selectedQuestion} />}
        </div>
    );
}
export default QuestionSettingsComponent;
