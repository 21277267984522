import React from "react";
import ScoringItem from "../../../../scoringItem/ScoringItem";

function AgeScore({ ageScore, setAgeScore }) {
    const deleteScoringItem = (index, BMI = false) => {
        let shorterScoringItems;
        shorterScoringItems = ageScore.filter((item, idx) => idx !== index);
        setAgeScore(shorterScoringItems);
    };

    return ageScore.map((item, index) => {
        return (
            <ScoringItem
                key={index + "_age_scoring_item"}
                item={item}
                text="Age"
                onChangeSelectType={(value) => {
                    const ageCopy = ageScore;
                    ageCopy[index] = {
                        ...item,
                        operator: value,
                        valueTo: ""
                    };
                    setAgeScore([...ageCopy]);
                }}
                onChangeFrom={({ target: { value } }) => {
                    const ageCopy = ageScore;
                    ageCopy[index] = {
                        ...item,
                        valueFrom: value
                    };
                    setAgeScore([...ageCopy]);
                }}
                onChangeTo={({ target: { value } }) => {
                    const ageCopy = ageScore;
                    ageCopy[index] = { ...item, valueTo: value };
                    setAgeScore([...ageCopy]);
                }}
                onChangeScoring={({ target: { value } }) => {
                    const ageCopy = ageScore;
                    ageCopy[index] = { ...item, score: value };
                    setAgeScore([...ageCopy]);
                }}
                deleteItem={() => deleteScoringItem(index)}
                selectedItem={ageScore[index].operator}
                valueFrom={ageScore[index].valueFrom}
                valueTo={ageScore[index].valueTo}
                valueScore={ageScore[index].score}
            />
        );
    });
}

export default AgeScore;
