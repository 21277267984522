import React from "react";
import maskImg from "../../../../../../assets/images/maskImg.svg";
import ReactTooltip from "react-tooltip";

function TextAnswerMaskInput({ answer, handleChange, isDisabled = false }) {
    return (
        <a data-tip data-for="mask-tooltip">
            <div className="feedback-container">
                <img className="mask-icon" src={maskImg} alt="" />
                <input
                    type="text"
                    placeholder="Text Mask"
                    name="mask"
                    value={answer.inputMask?.mask}
                    onChange={handleChange}
                    disabled={isDisabled}
                />
                <ReactTooltip id="mask-tooltip" className="tooltip">
                    <p>&quot;*&quot; - any character placeholder</p>
                    <p>&quot;a&quot; - letters placeholder</p>
                    <p>&quot;0&quot; - number placeholder</p>
                    <p>&quot;\*&quot; - asterisk separator</p>
                    <p>
                        &quot;\a&quot; - <b>a</b> separator
                    </p>
                    <p>
                        &quot;\0&quot; - <b>0</b> separator
                    </p>
                    <p>any other character will behave as separator</p>
                </ReactTooltip>
            </div>
        </a>
    );
}

export default TextAnswerMaskInput;
