import React, { useContext, useState } from "react";
import addDisabled from "../../../../assets/images/add-icon-disabled.svg";
import add from "../../../../assets/images/add-icon.svg";
import SectionItem from "../../../sectionItem/SectionItemComponent";
import AppService from "../../../../service/AppService";
import cloneDeep from "lodash/cloneDeep";
import { SurveyBuilderContext } from "../../../../SurveyBuilderContext";
import BasicPopup from "../../../popups/BasicPopup/BasicPopup";

function SectionItems({ sections, setSections, selectedSubSurvey, disabled = false }) {
    const context = useContext(SurveyBuilderContext);
    const [showPopup, setShowPopup] = useState(false);
    const [removedSectionId, setRemovedSectionId] = useState(-1);
    const addSection = () => {
        const copySections = sections;
        const name = AppService.selectedApp?.internationalizationSupport ? { EU: "", ESP: "" } : "";
        const section = {
            name: name,
            questions: [],
            sequenceId: AppService.Guid()
        };
        copySections[selectedSubSurvey].push(section);
        setSections([...copySections]);
    };

    const changeSectionName = (id, name) => {
        const changedSection = sections[selectedSubSurvey];
        changedSection.forEach((section) => {
            if (id === section.sequenceId) {
                if (AppService.selectedApp?.internationalizationSupport) {
                    section.name = {
                        EU: AppService.trimSpaces(name),
                        ESP: ""
                    };
                } else {
                    section.name = AppService.trimSpaces(name);
                }
            }
        });
        const copySections = sections;
        copySections[selectedSubSurvey] = changedSection;
        setSections([...copySections]);
    };

    const removeSection = (id) => {
        const shortestSections = sections[selectedSubSurvey].filter((section) => section.sequenceId !== id);
        const copySections = cloneDeep(sections);
        copySections[selectedSubSurvey] = shortestSections;
        setSections(copySections);
        setShowPopup(false);
    };

    const showOnRemovePopup = (id) => {
        const isSectionHaveQuestions = sections[selectedSubSurvey].find((section) => section.sequenceId === id).questions.length > 0;
        if (isSectionHaveQuestions) {
            setShowPopup(true);
            setRemovedSectionId(id);
        } else {
            removeSection(id);
        }
    };

    const popupText = {
        header: "Are you sure you want to delete this section with all questions?",
        content: "This action cannot be undone. Upon confirmation, this section will remove."
    };

    return (
        <>
            <div className={`border ${disabled && "invisible-border"}`} />
            <div>
                <div className={`sub-header ${disabled && "disabled-sub-header"}`}>
                    section <span className="required"> * </span>
                    <img
                        src={disabled ? addDisabled : add}
                        alt="add section"
                        className="add-section"
                        onClick={() => {
                            if (!disabled) {
                                addSection();
                            }
                        }}
                    />
                </div>
                <div className="sections">
                    {showPopup && (
                        <BasicPopup
                            content={popupText.content}
                            header={popupText.header}
                            closePopup={() => setShowPopup(false)}
                            action={() => removeSection(removedSectionId)}
                        />
                    )}
                    {sections[selectedSubSurvey]?.map((section, index) => (
                        <SectionItem
                            onRemove={showOnRemovePopup}
                            section={section}
                            key={index}
                            onChange={changeSectionName}
                            selectedSubSurvey={selectedSubSurvey}
                            lang={context.lang}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

export default SectionItems;
