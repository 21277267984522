import ReactDOM from "react-dom";
import React from "react";
import NotificationComponent from "../components/notification/NotificationComponent";
const triggerNotification = (title, message, status) => {
    const validStatuses = ["success", "info", "warning", "error"];
    if (!validStatuses.includes(status)) {
        throw Error("Invalid notification status");
    }

    ReactDOM.render(<NotificationComponent status={status} title={title} message={message} />, document.getElementById("notification-box"));
};

export const showErrorNotification = (title, message) => {
    triggerNotification(title, message, "error");
};

export const showSuccessNotification = (title, message) => {
    triggerNotification(title, message, "success");
};
