import ReactTooltip from "react-tooltip";

function QuestionIcon({ type, onClick, isActive }) {
    return (
        <div id={type.id} className={isActive ? "active" : ""} onClick={onClick} data-tip data-for={type.tooltipId}>
            <img src={type.button} alt="question type" />
            <ReactTooltip id={type.tooltipId} className="tooltip">
                <p>{type.tooltipText}</p>
            </ReactTooltip>
        </div>
    );
}

export default QuestionIcon;
