import { Answer } from "../Application/Application";
import { uuid } from "uuidv4";
import AppService from "../../service/AppService";
import * as AnswerData from "./AnswerData";

export function setStaticAnswersDueToSFDC(question) {
    if (Object.prototype.hasOwnProperty.call(SFDCStaticData, question.salesForceField.fieldLabel)) {
        const currentSFDCData = SFDCStaticData[question.salesForceField.fieldLabel];
        question.text = currentSFDCData.questionTitle;
        question.type = currentSFDCData.questionType;
        addAnswersToQuestion(currentSFDCData.answersText, question);
    }
}

export function addAnswersToQuestion(answers, question) {
    question.answers = [];
    answers.forEach((answerText) => {
        if (question.answers.some((answer) => answer.text === answerText)) {
            return;
        }
        const newAnswer = new Answer(answerText, "", "");
        newAnswer.sequenceId = uuid();
        question.answers.push(newAnswer);
    });
    return question;
}

const SFDCStaticData = {
    "Secondary_Race__c (Secondary Race)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your secondary race?",
        answersText: AnswerData.raceAnswers.answers
    },
    "ee_education_level__c (Level of education)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your level of education?",
        answersText: AnswerData.levelOfEducationAnswers.answers
    },
    "mr_descdiet__c (Diet)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "How would you describe your diet?",
        answersText: ["Average", "Gluten-free", "Organic", "Paleo", "Vegetarian", "Vegan", "Well-rounded", "Unknown"]
    },
    "mr_curbirthcontrol_list__c (Birth Control Method)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your current method of birth control?",
        answersText: [
            "Birth Control Pills",
            "Condoms",
            "Hormonal IUD",
            "Non-hormonal IUD",
            "Depo-Provera Shot",
            "Implanon/Nexplanon",
            "NuvaRing",
            "Tubal Ligation",
            "Partner Vasectomy",
            "Birth Control Patch",
            "Abstinence",
            "Other"
        ]
    },
    "Psychiatric_Hospitalization_fam__c (Who was hospitalized?)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Who was hospitalized?",
        answersText: AnswerData.familyPersons.answers
    },
    "Attempted_Suicide_fam__c (Who attempted suicide?)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Who attempted or committed suicide?",
        answersText: AnswerData.familyPersons.answers
    },
    "Bipolar_Disorder_fam__c (Who has manic, bipolar, schizo?)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Who has manic depression, bipolar disorder, or schizophrenia?",
        answersText: AnswerData.familyPersons.answers
    },
    "Anxiety_fam__c (Who has had anxiety?)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Who has had anxiety?",
        answersText: AnswerData.familyPersons.answers
    },
    "Situational_Depression_fam__c (Who has had situational depression?)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Who has had situational depression?",
        answersText: AnswerData.familyPersons.answers
    },
    "Learning_Disabilities_fam__c (Who has learning disabilities?)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Who has learning disabilities?",
        answersText: AnswerData.familyPersons.answers
    },
    "ADD_ADHD_fam__c (Who has ADD or ADHD?)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Who has ADD or ADHD?",
        answersText: AnswerData.familyPersons.answers
    },
    "Autism_fam__c (Who has a form of autism?)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Who has a form of autism?",
        answersText: AnswerData.familyPersons.answers
    },
    "Eating_Disorders_fam__c (Who has had an eating disorder?)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Who has had an eating disorder?",
        answersText: AnswerData.familyPersons.answers
    },
    "OCD_fam__c (Who has OCD?)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Who has OCD?",
        answersText: AnswerData.familyPersons.answers
    },
    "mo_living_deceased__c (Mother Alive/Dead?)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Is your mother living or deceased?",
        answersText: AnswerData.livingOrDeceased.answers
    },
    "fa_living_deceased__c (Father Alive/Dead?)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Is your father living or deceased?",
        answersText: AnswerData.livingOrDeceased.answers
    },
    "pf_living_deceased__c (Paternal Grandfather Alive/Dead?)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Is your paternal grandfather living or deceased?",
        answersText: AnswerData.livingOrDeceased.answers
    },
    "pm_living_deceased__c (Paternal Grandmother Alive/Dead?)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Is your paternal grandmother living or deceased?",
        answersText: AnswerData.livingOrDeceased.answers
    },
    "mf_living_deceased__c (Maternal Grandfather Alive/Dead?)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Is your maternal grandfather living or deceased?",
        answersText: AnswerData.livingOrDeceased.answers
    },
    "mm_living_deceased__c (Maternal Grandmother Alive/Dead?)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Is your maternal grandmother living or deceased?",
        answersText: AnswerData.livingOrDeceased.answers
    },
    "pf_height__c (Paternal Grandfather's Height)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "How tall is your paternal grandfather?",
        answersText: AnswerData.heightAnswers.answers
    },
    "pm_height__c (Paternal Grandmother's Height)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "How tall is your paternal grandmother?",
        answersText: AnswerData.heightAnswers.answers
    },
    "mf_height__c (Maternal Grandfather's Height)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "How tall is your maternal grandfather?",
        answersText: AnswerData.heightAnswers.answers
    },
    "mm_height__c (Maternal Grandmother's Height)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "How tall is your maternal grandmother?",
        answersText: AnswerData.heightAnswers.answers
    },
    "mo_race__c (Mother's Race)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your mother’s primary race?",
        answersText: AnswerData.raceAnswers.answers
    },
    "fa_race__c (Father's Race)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your father’s primary race?",
        answersText: AnswerData.raceAnswers.answers
    },
    "pf_race__c (Paternal Grandfather's Race)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your paternal grandfather’s primary race?",
        answersText: AnswerData.raceAnswers.answers
    },
    "pm_race__c (Paternal Grandmother's Race)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your paternal grandmother’s primary race?",
        answersText: AnswerData.raceAnswers.answers
    },
    "mm_race__c (Maternal Grandmother's Race)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your maternal grandmother’s primary race?",
        answersText: AnswerData.raceAnswers.answers
    },
    "mf_race__c (Maternal Grandfather's Race)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your maternal grandfather’s primary race?",
        answersText: AnswerData.raceAnswers.answers
    },
    "mo_heritage__c (Mother's heritage)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Please check all nationalities below that apply to your mother’s heritage.",
        answersText: AnswerData.heritageAnswers.answers
    },
    "fa_heritage__c (Father's Heritage)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Please check all nationalities below that apply to your father’s heritage.",
        answersText: AnswerData.heritageAnswers.answers
    },
    "pm_heritage__c (Paternal Grandmother's Heritage)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Please check all nationalities below that apply to your paternal grandmother’s heritage.",
        answersText: AnswerData.heritageAnswers.answers
    },
    "pf_heritage__c (Paternal Grandfather's Heritage)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Please check all nationalities below that apply to your paternal grandfather’s heritage. ",
        answersText: AnswerData.heritageAnswers.answers
    },
    "mf_heritage__c (Maternal Grandfather's Heritage)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Please check all nationalities below that apply to your maternal grandfather’s heritage.",
        answersText: AnswerData.heritageAnswers.answers
    },
    "mm_heritage__c (Maternal Grandmother's Heritage)": {
        questionType: AppService.questionType.DROPDOWN_MULTISELECT,
        questionTitle: "Please check all nationalities below that apply to your maternal grandmother’s heritage.",
        answersText: AnswerData.heritageAnswers.answers
    },
    "mo_eye_color__c (Mother's Eye Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Mother's Eye Color",
        answersText: AnswerData.eyeColorAnswers.answers
    },
    "fa_eye_color__c (Father's Eye Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Father's Eye Color",
        answersText: AnswerData.eyeColorAnswers.answers
    },
    "pf_eye_color__c (Paternal Grandfather's Eye Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandfather's Eye Color",
        answersText: AnswerData.eyeColorAnswers.answers
    },
    "pm_eye_color__c (Paternal Grandmother's Eye Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandfather's Eye Color",
        answersText: AnswerData.eyeColorAnswers.answers
    },
    "mf_eye_color__c (Maternal Grandfather's Eye Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandfather's Eye Color",
        answersText: AnswerData.eyeColorAnswers.answers
    },
    "mm_eye_color__c (Maternal Grandmother's Eye Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandmother's Eye Color",
        answersText: AnswerData.eyeColorAnswers.answers
    },
    "fa_hair_color__c (Father's Hair Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Father's Natural Hair Color",
        answersText: AnswerData.hairColorAnswers.answers
    },
    "mo_hair_color__c (Mother's Hair Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Mother's Natural Hair Color",
        answersText: AnswerData.hairColorAnswers.answers
    },
    "pf_hair_color__c (Paternal Grandfather's Hair Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandfather's Natural Hair Color",
        answersText: AnswerData.hairColorAnswers.answers
    },
    "pm_hair_color__c (Paternal Grandmother's Hair Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandmother's Natural Hair Color",
        answersText: AnswerData.hairColorAnswers.answers
    },
    "mf_hair_color__c (Maternal Grandfather's Hair Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandfather's Natural Hair Color",
        answersText: AnswerData.hairColorAnswers.answers
    },
    "mm_hair_color__c (Maternal Grandmother's Hair Color)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandmother's Natural Hair Color",
        answersText: AnswerData.hairColorAnswers.answers
    },
    "mo_hair_type__c (Mother's Hair Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Mother's Hair Type",
        answersText: AnswerData.hairTypeAnswers.answers
    },
    "fa_hair_type__c (Father's Hair Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Father's Hair Type",
        answersText: AnswerData.hairTypeAnswers.answers
    },
    "pf_hair_type__c (Paternal Grandfather's Hair Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandfather's Hair Type",
        answersText: AnswerData.hairTypeAnswers.answers
    },
    "pm_hair_type__c (Paternal Grandmother's Hair Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandmother's Hair Type",
        answersText: AnswerData.hairTypeAnswers.answers
    },
    "mf_hair_type__c (Maternal Grandfather's Hair Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandfather's Hair Type",
        answersText: AnswerData.hairTypeAnswers.answers
    },
    "mo_hair_texture__c (Mother's Hair Texture)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Mother's Hair Texture",
        answersText: AnswerData.hairTextureAnswers.answers
    },
    "fa_hair_texture__c (Father's Hair Texture)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Father's Hair Texture",
        answersText: AnswerData.hairTextureAnswers.answers
    },
    "pf_hair_texture__c (Paternal Grandfather's Hair Texture)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandfather's Hair Texture",
        answersText: AnswerData.hairTextureAnswers.answers
    },
    "pm_hair_texture__c (Paternal Grandmother's Hair Texture)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandmother's Hair Texture",
        answersText: AnswerData.hairTextureAnswers.answers
    },
    "mf_hair_texture__c (Maternal Grandfather's Hair Texture)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandfather's Hair Texture",
        answersText: AnswerData.hairTextureAnswers.answers
    },
    "mm_hair_texture__c (Maternal Grandmother's Hair Texture)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandmother's Hair Texture",
        answersText: AnswerData.hairTextureAnswers.answers
    },
    "mo_skin_complex__c (Mother's Skin Complexion)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Mother's Skin Complexion",
        answersText: AnswerData.skinComplexionAnswers.answers
    },
    "fa_skin_complex__c (Father's Skin Complexion)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Father's Skin Complexion",
        answersText: AnswerData.skinComplexionAnswers.answers
    },
    "pf_skin_complex__c (Paternal Grandfather's Skin Complexion)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandfather's Skin Complexion",
        answersText: AnswerData.skinComplexionAnswers.answers
    },
    "pm_skin_complex__c (Paternal Grandmother's Skin Complexion)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandmother's Skin Complexion",
        answersText: AnswerData.skinComplexionAnswers.answers
    },
    "mf_skin_complex__c (Maternal Grandfather's Skin Complexion)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandfather's Skin Complexion",
        answersText: AnswerData.skinComplexionAnswers.answers
    },
    "mm_skin_complex__c (Maternal Grandmother's Skin Complexion)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandmother's Skin Complexion",
        answersText: AnswerData.skinComplexionAnswers.answers
    },
    "mo_bodytype__c (Mother's Body Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Mother's Body Type",
        answersText: AnswerData.bodyTypeAnswers.answers
    },
    "fa_bodytype__c (Father's Body Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Father's Body Type",
        answersText: AnswerData.bodyTypeAnswers.answers
    },
    "pf_bodytype__c (Paternal Grandfather's Body Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandfather's Body Type",
        answersText: AnswerData.bodyTypeAnswers.answers
    },
    "pm_bodytype__c (Paternal Grandmother's Body Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Paternal Grandmother's Body Type",
        answersText: AnswerData.bodyTypeAnswers.answers
    },
    "mf_bodytype__c (Maternal Grandfather's Body Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandfather's Body Type",
        answersText: AnswerData.bodyTypeAnswers.answers
    },
    "mm_bodytype__c (Maternal Grandmother's Body Type)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "Maternal Grandfather's Body Type",
        answersText: AnswerData.bodyTypeAnswers.answers
    },
    "mo_education__c (Mother's Education Level)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your mother’s highest level of education that she has completed?",
        answersText: AnswerData.levelOfEducationAnswers.answers
    },
    "fa_education__c (Father's Education Level)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your father’s highest level of education that he has completed?",
        answersText: AnswerData.levelOfEducationAnswers.answers
    },
    "pf_education__c (Paternal Grandfather's Education Level)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your paternal grandfather’s highest level of education that he has completed?",
        answersText: AnswerData.levelOfEducationAnswers.answers
    },
    "pm_education__c (Paternal Grandmother's Education Level)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your paternal grandmother’s highest level of education that she has completed?",
        answersText: AnswerData.levelOfEducationAnswers.answers
    },
    "mf_education__c (Maternal Grandfather's Education Level)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your maternal grandfather’s highest level of education that she has completed?",
        answersText: AnswerData.levelOfEducationAnswers.answers
    },
    "mm_education__c (Maternal Grandmother's Education Level)": {
        questionType: AppService.questionType.DROPDOWN,
        questionTitle: "What is your maternal grandmother’s highest level of education that she has completed?",
        answersText: AnswerData.levelOfEducationAnswers.answers
    }
};
