import React from "react";
import { LanguageService } from "../../../../service/LanguageService";

function FeedBackItem({
    answer,
    answerI,
    handleChangeFeedbackTitle,
    handleChangeFeedbackText,
    location,
    sequenceId,
    lang,
    buildText,
    handleKeyDown
}) {
    const isHaveFeedbackTitle = (answer) => {
        return answer.feedback?.title.length > 0 || answer.feedback.title?.EU?.length > 0;
    };

    if (answer.feedback?.text.length > 0 || answer.feedback.text?.EU?.length > 0) {
        return (
            <div>
                {isHaveFeedbackTitle(answer) && (
                    <div className="answer-item" style={{ height: LanguageService.getTableItemHeight(answer.text, "answer") }}>
                        <span onClick={() => LanguageService.toggleEditFeedbackTitle({ ...location, answerI, lang })}>
                            {LanguageService.isFeedbackTitleEditing(answer, lang) ? (
                                <textarea
                                    className="edit-input"
                                    onChange={(event) => handleChangeFeedbackTitle({ event, ...location, answerI, sequenceId })}
                                    value={answer.feedback.title[lang]}
                                    placeholder={answer.feedback.title.EU}
                                    onKeyDown={handleKeyDown}
                                />
                            ) : (
                                buildText(answer.feedback.title, lang)
                            )}
                        </span>
                    </div>
                )}
                <div className="answer-item" style={{ height: LanguageService.getTableItemHeight(answer.text, "answer") }}>
                    <span onClick={() => LanguageService.toggleEditFeedbackText({ ...location, answerI, lang })}>
                        {LanguageService.isFeedbackTextEditing(answer, lang) ? (
                            <textarea
                                className="edit-input"
                                onChange={(event) => handleChangeFeedbackText({ event, ...location, answerI, sequenceId })}
                                value={answer.feedback.text[lang]}
                                placeholder={answer.feedback.text.EU}
                                onKeyDown={handleKeyDown}
                            />
                        ) : (
                            buildText(answer.feedback.text, lang)
                        )}
                    </span>
                </div>
            </div>
        );
    }
    return null;
}

export default FeedBackItem;
