import React from "react";
import "./ConditionsComponent.scss";

import { Condition } from "../../helper/Application/Application";
import AppService from "../../service/AppService";

import DropDown from "../dropDowns/dropDown/DropDownComponent";

import add from "../../assets/images/add-icon.svg";
import eyeDisabled from "../../assets/images/eye-disabled.svg";
import eye from "../../assets/images/eye.svg";
import warningDisabled from "../../assets/images/warning-disabled.svg";
import squireDisabled from "../../assets/images/disq-disabled.svg";
import review from "../../assets/images/review-blue.svg";
import disq from "../../assets/images/disq-blue.svg";
import comment from "../../assets/images/comment.svg";
import close from "../../assets/images/close-icon.svg";

import BranchingLogicModal from "../branchingLogicPopup/BranchingLogicPopupComponent";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";

class ConditionsComponent extends React.Component {
    static contextType = SurveyBuilderContext;
    constructor(props) {
        super(props);

        this.state = {
            conditions: props.question?.conditions ?? [],
            showBranchingLogicModal: false,
            conditionVisibilities: [],
            selectedConditionIndex: -1
        };

        this.addCondition = this.addCondition.bind(this);
        this.conditionTypeChanged = this.conditionTypeChanged.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
        this.feedbackChanged = this.feedbackChanged.bind(this);
        this.setDisq = this.setDisq.bind(this);
        this.setReview = this.setReview.bind(this);
        this.closeBranchingLogicPopup = this.closeBranchingLogicPopup.bind(this);
        this.showBranchingLogicModal = this.showBranchingLogicModal.bind(this);
        this.saveConditionVisibilities = this.saveConditionVisibilities.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            conditions: props.question?.conditions ?? []
        });
    }

    showBranchingLogicModal(index) {
        this.setState({
            showBranchingLogicModal: true,
            conditionVisibilities: this.state.conditions[index].visibilities,
            selectedConditionIndex: index
        });
    }

    closeBranchingLogicPopup(questions) {
        this.saveConditionVisibilities(questions);
        this.setState({ showBranchingLogicModal: false });
    }

    saveConditionVisibilities(visibilities) {
        const conditions = this.state.conditions;
        conditions[this.state.selectedConditionIndex].visibilities = visibilities;
        this.context.onConditionsChanged(conditions);
    }

    valueChanged(index, value) {
        const conditions = this.state.conditions;
        conditions[index].value = value;
        this.context.onConditionsChanged(conditions);
    }

    feedbackChanged(index, feedback) {
        const conditions = this.state.conditions;
        conditions[index].feedback = feedback;
        this.context.onConditionsChanged(conditions);
    }

    conditionTypeChanged(index, type) {
        const conditions = this.state.conditions;
        conditions[index].type = type === "Sum" ? AppService.conditionTypes.SUM : AppService.conditionTypes.ANSWER;
        this.context.onConditionsChanged(conditions);
    }

    setReview(index) {
        const conditions = this.state.conditions;
        conditions[index].isReview = !conditions[index].isReview;
        conditions[index].isDisqualify = false;
        this.context.onConditionsChanged(conditions);
    }

    setDisq(index) {
        const conditions = this.state.conditions;
        conditions[index].isDisqualify = !conditions[index].isDisqualify;
        conditions[index].isReview = false;
        this.context.onConditionsChanged(conditions);
    }

    addCondition() {
        const conditions = this.state.conditions;
        conditions.push(new Condition());
        this.context.onConditionsChanged(conditions);
    }

    removeCondition(index) {
        let questionIndex = -1;
        let sectionIndex = -1;
        AppService.selectedApp.sections.forEach((section, i) => {
            section.questions.forEach((q, qi) => {
                q.conditions.forEach((condition) => {
                    if (condition.sequenceId === this.state.conditions[index].sequenceId) {
                        sectionIndex = i;
                        questionIndex = qi;
                    }
                });
            });
        });
        if (sectionIndex !== -1 && questionIndex !== -1) {
            const newConditions = AppService.selectedApp.sections[sectionIndex].questions[questionIndex].conditions.filter(
                (c) => c.sequenceId !== this.state.conditions[index].sequenceId
            );
            AppService.selectedApp.sections[sectionIndex].questions[questionIndex].conditions = newConditions;
            this.context.onConditionsChanged(newConditions);
        }
    }

    render() {
        const conditions = this.state.conditions.map((c, i) => {
            const selectedCondition = c.type === AppService.conditionTypes.SUM ? "Sum" : "Specific Answer";

            const visibilityImage = c.visibilities.length > 0 ? eye : eyeDisabled;
            const reviewImage = c.isReview ? review : warningDisabled;
            const disqImage = c.isDisqualify ? disq : squireDisabled;

            return (
                <div className="condition-item" key={i}>
                    <div>
                        <DropDown
                            label={i + 1}
                            placeholder="Condition Type"
                            selectedItem={selectedCondition}
                            items={AppService.conditionsItems}
                            onChange={(item) => {
                                this.conditionTypeChanged(i, item);
                            }}
                        />
                    </div>
                    <div className="condition-value">
                        <input
                            type="text"
                            placeholder="Enter value"
                            value={c.value}
                            name="conditionNalue"
                            onChange={({ target }) => {
                                this.valueChanged(i, target.value);
                            }}
                        />
                        <img
                            src={visibilityImage}
                            className="eye-icon"
                            alt=""
                            onClick={() => {
                                this.showBranchingLogicModal(i);
                            }}
                        />
                        <img src={reviewImage} className="review-icon" alt="" onClick={() => this.setReview(i)} />
                        <img src={disqImage} className="disq-icon" onClick={() => this.setDisq(i)} />
                        <img src={close} alt="Close image" className="close-image" onClick={() => this.removeCondition(i)} />
                    </div>
                    <div className="feedback-container">
                        <img src={comment} alt="" />
                        <input
                            type="text"
                            placeholder="Optional Feedback"
                            value={c.feedback}
                            name="feedback"
                            className="feedback"
                            onChange={({ target }) => {
                                this.feedbackChanged(i, target.value);
                            }}
                        />
                    </div>
                </div>
            );
        });

        return (
            <div className="conditions">
                {this.state.showBranchingLogicModal ? (
                    <BranchingLogicModal
                        section={AppService.selectedApp.sections[0].sequenceId}
                        isConditions={true}
                        conditionVisibilities={this.state.conditionVisibilities}
                        question={this.props.question}
                        onClose={this.closeBranchingLogicPopup}
                    />
                ) : null}
                <div className="header">
                    <div className="column-title">
                        <span>Conditions</span> <img src={add} alt="" onClick={this.addCondition} />
                    </div>
                </div>
                <div>{conditions}</div>
            </div>
        );
    }
}

export default ConditionsComponent;
