import React, { useContext } from "react";
import { NewSurveyBuilderContext } from "../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { ReactComponent as ShowStates } from "../../../../../../assets/images/eye.svg";
import { ReactComponent as Edit } from "../../../../../../assets/images/edit.svg";

const SurveyAcceptanceSection = ({ onEditClick }) => {
    const { surveyData, isInConstruct } = useContext(NewSurveyBuilderContext);

    const buildAcceptanceCriteria = () => {
        const { allowedBmi, allowedAge, states } = surveyData.disqualificationRules;
        return (
            <>
                <div className="acceptance-criteria-item">
                    <span>BMI:</span> {allowedBmi?.from} - {allowedBmi?.to}
                </div>
                <div className="acceptance-criteria-item">
                    <span>Age:</span> {allowedAge?.from} - {allowedAge?.to}
                </div>
                <div className="acceptance-criteria-item">
                    <div className="states">
                        <div className="states-count">
                            <span>States:</span> {states.length}/51
                        </div>
                        <div className="show-states">
                            <ShowStates />
                            {statesToolTip()}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const statesToolTip = () => {
        const states = surveyData.disqualificationRules.states.join(",");
        return <div className="states-tooltip">{states}</div>;
    };

    return (
        <div className="survey-acceptance-section">
            <div className="survey-section-header">
                <span>acceptance criteria</span>
                {isInConstruct() && (
                    <span className="appEdit" onClick={() => onEditClick(3)}>
                        <Edit />
                    </span>
                )}
            </div>
            <div className="survey-acceptance-data">{buildAcceptanceCriteria()}</div>
        </div>
    );
};
export default SurveyAcceptanceSection;
