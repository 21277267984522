import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./NotificationComponent.scss";

import errorIcon from "../../assets/images/errorIcon.svg";
import successIcon from "../../assets/images/successIcon.svg";
import closeIcon from "../../assets/images/closeIcon.svg";

function NotificationComponent({ title, message, status }) {
    const [closeTimeout, setCloseTimeout] = useState(null);

    useEffect(() => {
        startClosingTimeout();
    }, []);

    const closeNotification = () => {
        clearTimeout(closeTimeout);
        ReactDOM.unmountComponentAtNode(document.getElementById("notification-box"));
    };

    const startClosingTimeout = () => {
        const timeout = setTimeout(() => closeNotification(), 10000);
        setCloseTimeout(timeout);
    };

    const getIconByStatus = (notificationStatus) => {
        switch (notificationStatus) {
            case "success":
                return successIcon;
            case "error":
                return errorIcon;
        }
    };

    return (
        <div
            className={`notification-container ${status}-notification`}
            onMouseEnter={() => clearTimeout(closeTimeout)}
            onMouseLeave={() => startClosingTimeout()}
        >
            <div className={"notification-body"}>
                <div className={"notification-icon"}>
                    <img className="notification-icon" src={getIconByStatus(status)} alt="status icon" />
                </div>
                <div className={"notification-content"}>
                    <div className={"notification-title"}>
                        <h2>{title}</h2>
                        <img src={closeIcon} alt="close" onClick={closeNotification} />
                    </div>
                    <p>{message}</p>
                </div>
            </div>
        </div>
    );
}
export default NotificationComponent;
