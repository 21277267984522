import React from "react";
import "./AnswersSettingsComponent.scss";

import yesNo from "../../assets/images/types/yes-no.svg";
import multiply from "../../assets/images/types/multiply.svg";
import single from "../../assets/images/types/single.svg";
import text from "../../assets/images/types/text.svg";
import upload from "../../assets/images/types/upload.svg";
import dropdown from "../../assets/images/types/dropdown.svg";
import add from "../../assets/images/add-icon.svg";

import { Answer } from "../../helper/Application/Application";
import AnswerItem from "../answer/AnswerComponent";
import AppService from "../../service/AppService";
import BuilderDropDownComponent from "../builderDropDown/BuilderDropDownComponent";
import ReactTooltip from "react-tooltip";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";
import SlidingComponent from "../SlidingComponent/SlidingComponent";
import * as staticAnswers from "../../helper/StaticAnswers/AnswerData";
import { addAnswersToQuestion } from "../../helper/StaticAnswers/StaticAnswers";

class AnswersSettingsComponent extends React.Component {
    static contextType = SurveyBuilderContext;

    constructor(props) {
        super(props);
        this.state = {
            questionType: this.props.type,
            answers: this.context?.selectedQuestion?.answers,
            isDynamicGroupQuestion: this.props.isDynamicGroupQuestion,
            chosenGroupType: null
        };

        this.changeQuestionType = this.changeQuestionType.bind(this);
        this.answerTextChanged = this.answerTextChanged.bind(this);
        this.answerWeightChanged = this.answerWeightChanged.bind(this);
        this.answerFeedbackChanged = this.answerFeedbackChanged.bind(this);
        this.salesForceAnswerItemChanged = this.salesForceAnswerItemChanged.bind(this);
        this.salesForceItemChanged = this.salesForceItemChanged.bind(this);
        this.addAnswers = this.addAnswers.bind(this);

        this.textTypeQuestion = {
            id: AppService.questionType.TEXT_GROUP,
            button: text,
            tooltipId: "text-tooltip",
            tooltipText: "Text",
            items: [
                { id: AppService.questionType.TEXT, title: "Free text" },
                { id: AppService.questionType.DATE, title: "Date picker" },
                { id: AppService.questionType.NUMBER, title: "Number" },
                { id: AppService.questionType.ADDRESS, title: "Address" },
                { id: AppService.questionType.PHONE, title: "Phone" }
            ]
        };

        this.checkBoxTypeQuestion = {
            id: AppService.questionType.DROPDOWN_GROUP,
            button: multiply,
            tooltipId: "checkbox-tooltip",
            tooltipText: "Several options",
            items: [
                { id: AppService.questionType.SELECT_MANY, title: "Picklist" },
                {
                    id: AppService.questionType.MULTI_SELECT,
                    title: "Picklist (Multi-Select)"
                }
            ]
        };
        (this.photoUploadTypeQuestion = {
            id: AppService.questionType.UPLOAD_GROUP,
            button: upload,
            tooltipId: "upload-tooltip",
            tooltipText: "Photo Upload",
            items: [
                { id: AppService.questionType.UPLOAD, title: "Photo Upload (single)" },
                { id: AppService.questionType.MULTI_UPLOAD, title: "Photo Upload (multi)" }
            ]
        }),
            (this.dropDownTypeQuestion = {
                id: AppService.questionType.DROPDOWNS_GROUP,
                button: dropdown,
                tooltipId: "dropdown-tooltip",
                tooltipText: "Dropdown",
                items: [
                    { id: AppService.questionType.DROPDOWN, title: "Dropdown" },
                    {
                        id: AppService.questionType.DROPDOWN_MULTISELECT,
                        title: "Dropdown (Multi-Select)"
                    }
                ]
            }),
            (this.types = [
                {
                    id: AppService.questionType.YES_NO,
                    button: yesNo,
                    tooltipId: "yesNo-tooltip",
                    tooltipText: "Yes or No"
                },
                {
                    id: AppService.questionType.SINGLE_ONE,
                    button: single,
                    tooltipId: "single-tooltip",
                    tooltipText: "Only one option"
                },
                this.checkBoxTypeQuestion,
                this.textTypeQuestion,
                this.dropDownTypeQuestion,
                this.photoUploadTypeQuestion
            ]);

        this.addAnswer = this.addAnswer.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            answers: this.context.selectedQuestion.answers,
            questionType: this.context.selectedQuestion.type,
            isDynamicGroupQuestion: props.isDynamicGroupQuestion
        });
    }

    addAnswer() {
        const answers = this.context.selectedQuestion.answers;
        const newAnswer = new Answer("", "", "");
        newAnswer.sequenceId = AppService.Guid();
        answers.push(newAnswer);
        this.setState({ answers: answers }, () => {
            this.context.onAnswersChanged(this.context.selectedQuestion.answers);
            if (this.saveSurveyOnAnswerAdd(this.state.questionType)) {
                this.context.saveSurvey();
            }
        });
    }

    saveSurveyOnAnswerAdd(questionType) {
        const answerTypesForInstantSave = ["TEXT", "PHONE", "ADDRESS", "NUMBER", "DATE", "UPLOAD", "MULTI_UPLOAD"];
        return answerTypesForInstantSave.indexOf(questionType) !== -1;
    }

    changeQuestionType(type) {
        this.props.onQuestionTypeChanged(type);
        this.setState({ questionType: type });
    }

    answerTextChanged(id, text) {
        const { selectedQuestion } = this.context;
        const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === id);
        if (AppService.selectedApp.internationalizationSupport) {
            selectedQuestion.answers[answerIndex].text = {
                ...selectedQuestion.answers[answerIndex].text,
                EU: text
            };
        } else {
            selectedQuestion.answers[answerIndex].text = text;
        }
        this.context.onAnswersChanged(selectedQuestion.answers);
    }

    answerRangeChanged = (answerId, range) => {
        const answers = this.context.selectedQuestion.answers;
        const answerIndex = answers.findIndex((a) => a.sequenceId === answerId);
        answers[answerIndex].range = range;
        this.context.onAnswersChanged(answers);
    };

    answerDQRangeChanged = (answerId, range) => {
        const answers = this.context.selectedQuestion.answers;
        const answerIndex = answers.findIndex((a) => a.sequenceId === answerId);
        answers[answerIndex].disqualificationRange = range;
        this.context.onAnswersChanged(answers);
    };

    answerMaskChanged = (id, mask) => {
        const { selectedQuestion } = this.context;
        const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === id);
        const answers = selectedQuestion.answers;
        const answer = answers[answerIndex];
        if (answer) {
            answer.inputMask = {
                mask: mask,
                type: "SIMPLE"
            };

            answers[answerIndex] = answer;
            this.setState(
                {
                    answers: answers
                },
                () => {
                    this.context.onAnswersChanged(selectedQuestion.answers);
                }
            );
        }
    };

    salesForceAnswerItemChanged(id, item) {
        const { selectedQuestion } = this.context;

        const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === id);
        selectedQuestion.answers[answerIndex].salesForceAnswerField = {
            id: item.id
        };
        this.context.onAnswersChanged(selectedQuestion.answers);
    }

    salesForceItemChanged(id, item) {
        const { selectedQuestion } = this.context;

        const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === id);
        selectedQuestion.answers[answerIndex].salesForceField = {
            id: item.id,
            fieldName: item.fieldName
        };
        this.context.onAnswersChanged(selectedQuestion.answers);
    }

    answerWeightChanged(id, weight) {
        const { selectedQuestion } = this.context;

        const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === id);
        selectedQuestion.answers[answerIndex].weight = weight;
        this.context.onAnswersChanged(selectedQuestion.answers);
    }

    answerFeedbackChanged(id, feedback) {
        const { selectedQuestion } = this.context;

        const answerIndex = selectedQuestion.answers.findIndex((a) => a.sequenceId === id);
        if (AppService.selectedApp.internationalizationSupport) {
            selectedQuestion.answers[answerIndex].feedback = {
                title: {
                    EU: selectedQuestion.answers[answerIndex].feedback.title.EU,
                    ESP: selectedQuestion.answers[answerIndex].feedback.title.ESP
                },
                text: {
                    EU: feedback,
                    ESP: selectedQuestion.answers[answerIndex].feedback.text.ESP
                }
            };
        } else {
            selectedQuestion.answers[answerIndex].feedback = {
                title: "",
                text: feedback
            };
        }
        this.context.onAnswersChanged(selectedQuestion.answers);
    }
    addAnswers(item) {
        const question = addAnswersToQuestion(item.answers, this.context.selectedQuestion);
        this.context.onAnswersChanged(question.answers);
    }
    setDropDownOpened = (isTextListOpen) => this.setState({ listOpen: isTextListOpen });

    onClickGroupItem = (id) => {
        this.setState({ chosenGroupType: id });
        this.changeQuestionType(id);
    };

    isGroupTypeQuestion = (questionType) => {
        if (questionType) {
            return (
                AppService.questionType.TEXT_GROUP === questionType ||
                AppService.questionType.DROPDOWN_GROUP === questionType ||
                AppService.questionType.DROPDOWNS_GROUP === questionType ||
                AppService.questionType.UPLOAD_GROUP === questionType
            );
        }
        return false;
    };

    isQuestionTypeInGroup = (groupType, questionType) => {
        return groupType.id === questionType || groupType.items.find((q) => q.id === questionType);
    };

    openGroupList = (type) => {
        if (!this.isGroupTypeQuestion(this.state.chosenItem) && !this.isGroupTypeQuestion(this.state.questionType)) {
            this.setState({ chosenGroupType: type.id });
        }
        this.setDropDownOpened(!this.state.listOpen);
    };

    render() {
        const questionTypes = this.state.isDynamicGroupQuestion ? this.types.filter((type) => type.id !== "UPLOAD_GROUP") : this.types;
        const header = questionTypes.map((t, i) => {
            const className = t.id === this.state.questionType ? "active" : "";
            if (this.isGroupTypeQuestion(t.id)) {
                return (
                    <div
                        key={i}
                        className={this.isQuestionTypeInGroup(t, this.state.questionType) ? "text-box-wrapper active" : "text-box-wrapper"}
                        onClick={() => this.openGroupList(t)}
                    >
                        <div id={t.id} className={className} key={i} data-tip data-for={t.tooltipId}>
                            <img src={t.button} alt="" />
                            <ReactTooltip id={t.tooltipId} className="tooltip">
                                <p>{t.tooltipText}</p>
                            </ReactTooltip>
                        </div>
                        <div>
                            <BuilderDropDownComponent
                                listItems={t.items}
                                listOpen={this.state.listOpen && this.isQuestionTypeInGroup(t, this.state.chosenGroupType)}
                                onClickItem={this.onClickGroupItem}
                                chosenItem={this.state.chosenGroupType || this.state.questionType}
                                showDropdown={this.setDropDownOpened}
                            />
                        </div>
                    </div>
                );
            } else {
                return (
                    <div
                        id={t.id}
                        className={className}
                        key={i}
                        onClick={() => {
                            this.setDropDownOpened(false);
                            this.changeQuestionType(t.id);
                        }}
                        data-tip
                        data-for={t.tooltipId}
                        data-testid={t.id}
                    >
                        <img src={t.button} alt="" />
                        <ReactTooltip id={t.tooltipId} className="tooltip">
                            <p>{t.tooltipText}</p>
                        </ReactTooltip>
                    </div>
                );
            }
        });

        const answers = this.context.selectedQuestion.answers?.map((a, i) => {
            return (
                <AnswerItem
                    answer={a}
                    index={i}
                    key={`${i}answer`}
                    question={this.props.question}
                    hasVisibility={this.props.hasVisibility}
                    onAnswerRangeChanged={this.answerRangeChanged}
                    onDQRangeChanged={this.answerDQRangeChanged}
                    onAnswerTextChanged={this.answerTextChanged}
                    onAnswerMaskChanged={this.answerMaskChanged}
                    onAnswerWeightChanged={this.answerWeightChanged}
                    onAnswersChanged={this.answersChanged}
                    onAnswerFeedbackChanged={this.answerFeedbackChanged}
                    onVisibilityClick={this.props.onVisibilityClick}
                    type={this.props.type}
                    salesForceAnswers={this.props.salesForceAnswers}
                    salesForceAnswerItemChanged={this.salesForceAnswerItemChanged}
                    salesForceItemChanged={this.salesForceItemChanged}
                    salesForceItems={this.props.salesForceItems}
                    isDynamicGroupQuestion={this.state.isDynamicGroupQuestion}
                    lang="EU"
                />
            );
        });

        return (
            <div className="answers">
                <div className="header">{header}</div>
                <div className="sub-header">
                    <div className="column-title">
                        <span>Answers</span>{" "}
                        <img id="addAnswer" className="add-answer-btn" src={add} alt="addAnswer" onClick={this.addAnswer} />
                    </div>
                    <SlidingComponent title="Static Answers" items={Object.values(staticAnswers)} onItemClick={this.addAnswers} />
                </div>
                <div className="content">{answers}</div>
            </div>
        );
    }
}
export default AnswersSettingsComponent;
