import React, { useContext } from "react";
import "./SectionsComponent.scss";
import AppService from "../../service/AppService";
import { LanguageService } from "../../service/LanguageService";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";
import classNames from "classnames";

function SectionsComponent({
    surveyIndex,
    sections,
    showCounts,
    showTitle,
    disableChangeSurvey,
    changeVisibilitySection,
    selectedVisibilitySection
}) {
    const { changeSubSurvey, changeSection, selectedSubSurvey, selectedSectionIndex, lang } = useContext(SurveyBuilderContext);
    const getSectionName = (name) => {
        return lang === "ESP" && name.ESP?.length <= 0 ? "N/A" : LanguageService.getTranslatedText(name, lang);
    };
    const onChangeSection = (section) => {
        if (!disableChangeSurvey) {
            changeSubSurvey(surveyIndex);
            changeSection(section, surveyIndex);
        } else {
            changeVisibilitySection(section);
        }
    };
    const selected = AppService.selectedApp?.allSubSurveySections[selectedSubSurvey][selectedSectionIndex];
    const allSections = sections?.map((s, i) => {
        const className = classNames("section-item", {
            "section-item-active": disableChangeSurvey ? selectedVisibilitySection === s.name : s.sequenceId === selected?.sequenceId
        });
        return (
            <div key={`${i}_section_for_${surveyIndex}_subSurvey`} className={className} onClick={() => onChangeSection(s)}>
                <span>{getSectionName(s.name)}</span>
                <span>{showCounts ? AppService.selectedApp.allSubSurveySections[surveyIndex][i]?.questions.length : ""}</span>
            </div>
        );
    });

    return (
        <div className="sections-info">
            {showTitle && (
                <div className="column-title">
                    <span>Sections</span>
                </div>
            )}
            {sections && allSections}
        </div>
    );
}
export default SectionsComponent;
