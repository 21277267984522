import SettingsButtons from "../../settingsButton/SettingsButtonComponent";
import React from "react";
import { LanguageService } from "../../../service/LanguageService";

function Radio({ lang, answers, onVisibilityClick }) {
    const answerButtons = answers.map((a, i) => {
        let answer = (
            <label className="radio-container" key={`{i}_radio_container`}>
                {LanguageService.getTranslatedText(a.text, lang)}
                <input type="radio" name="radio" onChange={() => {}} />
                <span className="checkmark" />
            </label>
        );

        if (i === 0) {
            answer = (
                <label className="radio-container" key={i}>
                    {LanguageService.getTranslatedText(a.text, lang)}
                    <input type="radio" checked="checked" name="radio" onChange={() => {}} />
                    <span className="checkmark" />
                </label>
            );
        }

        return (
            <SettingsButtons key={i} answer={a} hasVisibility={a.hasVisibility} onVisibilityClick={onVisibilityClick}>
                {answer}
            </SettingsButtons>
        );
    });
    return <div className="content">{answerButtons}</div>;
}

export default Radio;
