import SurveyInfoComponent from "./SurveyInfo/SurveyInfoComponent";
import SurveyStructureComponent from "./SurveyStructure/SurveyStructureComponent";
import { useState } from "react";
import SurveyEditDialogComponent from "./SurveyEdit/SurveyEditDialogComponent";
function NewSurveyBuilderAside({ surveyId }) {
    const [showDialog, setShowDialog] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);

    const openDialog = (tabToOpen) => {
        setCurrentTab(tabToOpen);
        setShowDialog(true);
    };

    const closeDialog = () => {
        setCurrentTab(1);
        setShowDialog(false);
    };
    return (
        <>
            <SurveyInfoComponent surveyId={surveyId} onEditClick={openDialog} />
            <SurveyStructureComponent surveyId={surveyId} onEditClick={openDialog} />
            {showDialog && <SurveyEditDialogComponent surveyId={surveyId} currentTab={currentTab} onClose={closeDialog} />}
        </>
    );
}
export default NewSurveyBuilderAside;
