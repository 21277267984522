import AppService from "./AppService";

export const LanguageService = {
    getTranslatedText(value, lang = "EU") {
        if (value && lang) {
            if (typeof value === "string") {
                return value;
            } else {
                switch (lang) {
                    case "EU":
                        return value["EU"];
                    case "ESP":
                        return value["ESP"];
                }
            }
        }
        return "";
    },

    isEspEditing(obj, lang) {
        return obj.edit && lang === "ESP";
    },
    isEspHintEditing(obj, lang) {
        return obj.editHint && lang === "ESP";
    },
    isNoteContentEditing(obj, lang) {
        return obj.editContent && lang === "ESP";
    },
    isFeedbackTitleEditing(obj, lang) {
        return obj.editFeedbackTitle && lang === "ESP";
    },
    isFeedbackTextEditing(obj, lang) {
        return obj.editFeedbackText && lang === "ESP";
    },

    removeMultiLanguageValues() {
        AppService.selectedApp.subSurveys.map((subSurvey) => {
            subSurvey.name = subSurvey.name.EU;
            this.removeMultiLanguageFromSection(subSurvey.sections);
            return subSurvey;
        });
    },

    removeMultiLanguageFromSection(sections) {
        sections.map((section) => {
            section.name = section.name.EU;
            section.questions.map((question) => {
                question.text = question.text.EU;
                question.hint = question.hint.EU;
                question.type === "NOTE" && (question.noteContent = question.noteContent.EU);
                question.type === "GROUP"
                    ? question.questions.map((groupQ) => {
                          groupQ.hint = groupQ.hint.EU;
                          groupQ.text = groupQ.text.EU;
                          groupQ.type === "NOTE" && (groupQ.noteContent = groupQ.noteContent.EU);
                          groupQ.answers.map((groupA) => {
                              groupA.text = groupA.text.EU;
                              return groupA;
                          });
                          return groupQ;
                      })
                    : question.answers.map((answer) => {
                          answer.text = answer.text.EU;
                          answer.feedback.title = answer.feedback.title.EU;
                          answer.feedback.text = answer.feedback.text.EU;
                          return answer;
                      });
                return question;
            });
            return section;
        });
    },

    applyMultiLanguageValues() {
        AppService.selectedApp.subSurveys.map((subSurvey) => {
            subSurvey.name =
                typeof subSurvey.name === "object"
                    ? subSurvey.name
                    : {
                          EU: subSurvey.name,
                          ESP: ""
                      };
            this.applyMultiLanguageForSections(subSurvey.sections);
            return subSurvey;
        });
        this.applyMultiLanguageForSections(AppService.selectedApp.sections);
        this.applyMultiLanguageForSections(AppService.selectedApp.allSubSurveySections.flatMap((subSurvey) => subSurvey));
    },

    applyMultiLanguageForSections(sections) {
        sections.map((section) => {
            section.name =
                typeof section.name === "object"
                    ? section.name
                    : {
                          EU: section.name,
                          ESP: ""
                      };
            section.questions.map((question) => {
                question.hint =
                    typeof question.hint === "object"
                        ? question.hint
                        : {
                              EU: question.hint,
                              ESP: ""
                          };
                question.text =
                    typeof question.text === "object"
                        ? question.text
                        : {
                              EU: question.text,
                              ESP: ""
                          };
                question.type === "NOTE" &&
                    (question.noteContent =
                        typeof question.noteContent === "object"
                            ? question.noteContent
                            : {
                                  EU: question.noteContent,
                                  ESP: ""
                              });
                question.type === "GROUP"
                    ? question?.questions.map((groupQ) => {
                          groupQ.hint =
                              typeof groupQ.hint === "object"
                                  ? groupQ.hint
                                  : {
                                        EU: groupQ.hint,
                                        ESP: ""
                                    };
                          groupQ.text =
                              typeof groupQ.text === "object"
                                  ? groupQ.text
                                  : {
                                        EU: groupQ.text,
                                        ESP: ""
                                    };
                          groupQ.type === "NOTE" &&
                              (groupQ.noteContent =
                                  typeof groupQ.noteContent === "object"
                                      ? groupQ.noteContent
                                      : {
                                            EU: groupQ.noteContent,
                                            ESP: ""
                                        });
                          groupQ?.answers.map((groupA) => {
                              groupA.text =
                                  typeof groupA.text === "object"
                                      ? groupA.text
                                      : {
                                            EU: groupA.text,
                                            ESP: ""
                                        };
                              return groupA;
                          });
                          return groupQ;
                      })
                    : question?.answers.map((answer) => {
                          answer.text =
                              typeof answer.text === "object"
                                  ? answer.text
                                  : {
                                        EU: answer.text,
                                        ESP: ""
                                    };
                          answer.feedback.title =
                              typeof answer.feedback.title === "object"
                                  ? answer.feedback.title
                                  : {
                                        EU: answer.feedback.title,
                                        ESP: ""
                                    };
                          answer.feedback.text =
                              typeof answer.feedback.text === "object"
                                  ? answer.feedback.text
                                  : {
                                        EU: answer.feedback.text,
                                        ESP: ""
                                    };
                          return answer;
                      });
                return question;
            });
            return section;
        });
    },

    openAllAccordion() {
        AppService.selectedApp.subSurveys.map((subSurvey) => {
            subSurvey.open = true;
            subSurvey.sections.map((section) => {
                section.open = true;
                section.questions.map((question) => {
                    question.open = true;
                    question.type === "GROUP"
                        ? question.questions.map((groupQ) => {
                              groupQ.open = true;
                              groupQ.answers.map((groupA) => {
                                  groupA.open = true;
                                  return groupA;
                              });
                              return groupQ;
                          })
                        : question.answers.map((answer) => {
                              answer.open = true;
                              return answer;
                          });
                    return question;
                });
                return section;
            });
            return subSurvey;
        });
    },

    toggleSubSurveyAccordion(index) {
        AppService.selectedApp.subSurveys[index].open = !AppService.selectedApp.subSurveys[index].open;
    },
    toggleSectionAccordion(subSurveyI, sectionI) {
        AppService.selectedApp.subSurveys[subSurveyI].sections[sectionI].open =
            !AppService.selectedApp.subSurveys[subSurveyI].sections[sectionI].open;
    },
    toggleQuestionAccordion(subSurveyI, sectionI, questionI) {
        AppService.selectedApp.subSurveys[subSurveyI].sections[sectionI].questions[questionI].open =
            !AppService.selectedApp.subSurveys[subSurveyI].sections[sectionI].questions[questionI].open;
    },

    toggleGroupQuestionAccordion(subSurveyI, sectionI, questionI, groupQuestion) {
        AppService.selectedApp.subSurveys[subSurveyI].sections[sectionI].questions[questionI].questions[groupQuestion].open =
            !AppService.selectedApp.subSurveys[subSurveyI].sections[sectionI].questions[questionI].questions[groupQuestion].open;
    },
    toggleEditSubSurvey(index, lang) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[index].edit = true;
        }
    },
    toggleEditSection(subSurveyI, sectionI, lang) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subSurveyI].sections[sectionI].edit = true;
        }
    },

    toggleEditQuestion(subSurveyI, sectionI, questionI, lang) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subSurveyI].sections[sectionI].questions[questionI].edit = true;
        }
    },

    toggleEditQuestionHint(subSurveyI, sectionI, questionI, lang) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subSurveyI].sections[sectionI].questions[questionI].editHint = true;
        }
    },
    toggleEditAnswer({ subI, secI, questionI, answerI, lang }) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].answers[answerI].edit = true;
        }
    },
    toggleEditFeedbackTitle({ subI, secI, questionI, answerI, lang }) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].answers[answerI].editFeedbackTitle = true;
        }
    },
    toggleEditFeedbackText({ subI, secI, questionI, answerI, lang }) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].answers[answerI].editFeedbackText = true;
        }
    },
    toggleEditGroupQuestion(subI, secI, questionI, groupI, lang) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].edit = true;
        }
    },
    toggleEditNoteContent(subI, secI, questionI, lang) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].editContent = true;
        }
    },
    toggleEditGroupHint(subI, secI, questionI, groupI, lang) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].editHint = true;
        }
    },
    toggleEditGroupAnswer({ subI, secI, questionI, groupI, answerI, lang }) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].answers[answerI].edit = true;
        }
    },
    toggleEditGroupNoteContent({ subI, secI, questionI, groupI, lang }) {
        if (lang === "ESP") {
            this.toggleAllEditing(false);
            AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].editContent = true;
        }
    },

    toggleAllEditing() {
        AppService.selectedApp.subSurveys.map((subSurvey) => {
            subSurvey.edit = false;
            subSurvey.sections.map((section) => {
                section.edit = false;
                section.questions.map((question) => {
                    question.edit = false;
                    question.editHint = false;
                    question.editContent = false;
                    question.type === "GROUP"
                        ? question.questions.map((groupQ) => {
                              groupQ.editHint = false;
                              groupQ.edit = false;
                              groupQ.editContent = false;
                              groupQ.answers.map((groupA) => {
                                  groupA.edit = false;
                                  return groupA;
                              });
                              return groupQ;
                          })
                        : question.answers.map((answer) => {
                              answer.edit = false;
                              answer.editFeedbackTitle = false;
                              answer.editFeedbackText = false;
                              return answer;
                          });
                    return question;
                });
                return section;
            });
            return subSurvey;
        });
    },

    changeSectionName(translatedValue, subI, secI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].name.ESP = translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.name = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].name.EU,
                    ESP: translatedValue
                };
            }
        });
    },
    changeQuestionName(translatedValue, subI, secI, questionI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].text.ESP = translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.questions[questionI].text = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].text.EU,
                    ESP: translatedValue
                };
            }
        });
    },
    changeQuestionHint(translatedValue, subI, secI, questionI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].hint.ESP = translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.questions[questionI].hint = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].hint.EU,
                    ESP: translatedValue
                };
            }
        });
    },
    changeNoteContent(translatedValue, subI, secI, questionI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].noteContent.ESP = translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.questions[questionI].noteContent = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].noteContent.EU,
                    ESP: translatedValue
                };
            }
        });
    },
    changeAnswerText(translatedValue, subI, secI, questionI, answerI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].answers[answerI].text.ESP = translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.questions[questionI].answers[answerI].text = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].answers[answerI].text.EU,
                    ESP: translatedValue
                };
            }
        });
    },
    changeAnswerFeedbackTitle(translatedValue, subI, secI, questionI, answerI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].answers[answerI].feedback.title.ESP = translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.questions[questionI].answers[answerI].feedback.title = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].answers[answerI].feedback.title.EU,
                    ESP: translatedValue
                };
            }
        });
    },
    changeAnswerFeedbackText(translatedValue, subI, secI, questionI, answerI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].answers[answerI].feedback.text.ESP = translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.questions[questionI].answers[answerI].feedback.text = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].answers[answerI].feedback.text.EU,
                    ESP: translatedValue
                };
            }
        });
    },
    changeGroupQuestionName(translatedValue, subI, secI, questionI, groupI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].text.ESP = translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.questions[questionI].questions[groupI].text = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].text.EU,
                    ESP: translatedValue
                };
            }
        });
    },
    changeGroupQuestionHint(translatedValue, subI, secI, questionI, groupI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].hint.ESP = translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.questions[questionI].questions[groupI].hint = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].hint.EU,
                    ESP: translatedValue
                };
            }
        });
    },
    changeGroupNoteContent(translatedValue, subI, secI, questionI, groupI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].noteContent.ESP = translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.questions[questionI].questions[groupI].noteContent = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].noteContent.EU,
                    ESP: translatedValue
                };
            }
        });
    },
    changeGroupQuestionAnswer(translatedValue, subI, secI, questionI, groupI, answerI, sequenceId) {
        AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].answers[answerI].text.ESP =
            translatedValue;
        AppService.selectedApp.sections.map((section) => {
            if (section.sequenceId === sequenceId) {
                section.questions[questionI].questions[groupI].answers[answerI].text = {
                    EU: AppService.selectedApp.subSurveys[subI].sections[secI].questions[questionI].questions[groupI].answers[answerI].text
                        .EU,
                    ESP: translatedValue
                };
            }
        });
    },

    getValidatedFields() {
        let allFieldsCount = 0;
        let filledFieldsCount = 0;
        if (!AppService.selectedApp || AppService.selectedApp.type !== "GC") {
            return { allFieldsCount, filledFieldsCount };
        }

        AppService.selectedApp.subSurveys.map((subSurvey) => {
            allFieldsCount++;
            subSurvey.name.ESP?.length && filledFieldsCount++;
            subSurvey?.sections.map((section) => {
                allFieldsCount++;
                section.name.ESP?.length && filledFieldsCount++;
                section.questions.map((question) => {
                    allFieldsCount++;
                    question.text.ESP?.length && filledFieldsCount++;
                    if (question.type === "NOTE") {
                        question.noteContent.EU?.length && allFieldsCount++;
                        question.noteContent.ESP?.length && filledFieldsCount++;
                    }
                    if (question.type !== "GROUP") {
                        question.hint?.EU?.length && allFieldsCount++;
                        question.hint?.ESP?.length && filledFieldsCount++;
                    }

                    question.type === "GROUP"
                        ? question.questions.map((groupQ) => {
                              allFieldsCount++;
                              groupQ.text.ESP?.length && filledFieldsCount++;
                              if (groupQ.type === "NOTE") {
                                  groupQ.noteContent.EU?.length && allFieldsCount++;
                                  groupQ.noteContent.ESP?.length && filledFieldsCount++;
                              }
                              groupQ.hint.EU?.length && allFieldsCount++;
                              groupQ.hint.ESP?.length && filledFieldsCount++;

                              groupQ.answers.map((groupA) => {
                                  groupA.text.EU?.length && allFieldsCount++;
                                  groupA.text.ESP?.length && filledFieldsCount++;
                              });
                          })
                        : question.answers.map((answer) => {
                              answer.text.EU?.length && allFieldsCount++;
                              answer.text.ESP?.length && filledFieldsCount++;
                              if (answer.feedback.text.EU?.length > 0) {
                                  answer.feedback.title.EU?.length && allFieldsCount++;
                                  answer.feedback.title.ESP?.length && filledFieldsCount++;

                                  answer.feedback.text.EU?.length && allFieldsCount++;
                                  answer.feedback.text.ESP?.length && filledFieldsCount++;
                              }
                          });
                });
            });
        });
        return { allFieldsCount, filledFieldsCount };
    },

    getTableItemHeight(text, rowType = "subSurvey") {
        if (typeof text === "string") {
            return;
        }
        let rowLength = 0;
        switch (rowType) {
            case "subSurvey":
                rowLength = 64;
                break;
            case "section":
                rowLength = 58;
                break;
            case "question":
                rowLength = 54;
                break;
            case "answer":
            case "hint":
            case "groupQuestion":
                rowLength = 46;
                break;
            case "groupAnswer":
            case "groupHint":
                rowLength = 40;
        }
        let height = 54;

        if (typeof text === "object") {
            const euLength = text.EU ? text.EU.length : 0;
            const espLength = text.ESP ? text.ESP.length : 0;
            const maxLength = espLength > euLength ? espLength : euLength;
            let rows = -1;
            for (let i = 0; i <= maxLength; i += rowLength) {
                rows++;
            }
            height += rows * 26;
            return height;
        }
    },

    cleanAllEditingValues() {}
};
