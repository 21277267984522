import React from "react";
import { LanguageService } from "../../../../service/LanguageService";

function NoteContentItem({
    section,
    question,
    subI,
    secI,
    questionI,
    groupI,
    lang,
    buildText,
    handleChangeNoteContent,
    handleChangeGroupNoteContent,
    handleKeyDown
}) {
    const className = groupI ? "note-content" : "note-content";
    const onEdit = () =>
        groupI
            ? LanguageService.toggleEditGroupNoteContent({
                  subI,
                  secI,
                  questionI,
                  groupI,
                  lang
              })
            : LanguageService.toggleEditNoteContent(subI, secI, questionI, lang);
    const handleChange = (event) =>
        groupI
            ? handleChangeGroupNoteContent(event, subI, secI, questionI, groupI, section.sequenceId)
            : handleChangeNoteContent(event, subI, secI, questionI, section.sequenceId);

    return (
        <div
            className={className}
            style={{
                height: LanguageService.getTableItemHeight(question.noteContent, "answer")
            }}
        >
            <span onClick={() => onEdit()}>
                {LanguageService.isNoteContentEditing(question, lang) ? (
                    <textarea
                        className="edit-input"
                        onChange={(event) => handleChange(event)}
                        value={question.noteContent[lang]}
                        placeholder={question.noteContent.EU}
                        onKeyDown={handleKeyDown}
                    />
                ) : (
                    buildText(question.noteContent, lang)
                )}
            </span>
        </div>
    );
}

export default NoteContentItem;
