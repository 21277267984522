import React, { useContext, useState } from "react";
import closeButton from "../../assets/images/close-left-bar.svg";
import Sections from "../sections/SectionsComponent";
import { LanguageService } from "../../service/LanguageService";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";

const SubSurveyItem = ({ surveyIndex, name, sections, selected, subSurveyLength, showCounts }) => {
    const { lang } = useContext(SurveyBuilderContext);
    const [showSections, toggleShowSections] = useState(true);
    const classNameSections = showSections ? "section-item" : "section-item hide";
    const classNameButton = showSections ? "hide-show-button" : "hide-show-button closed";
    const subSurveyName = lang === "ESP" && name.ESP?.length <= 0 ? "N/A" : LanguageService.getTranslatedText(name, lang);
    return (
        <div className="sub-survey-info">
            <div className="column-title-sub-survey">
                <span>{subSurveyName}</span>
                <div>
                    <img
                        className={classNameButton}
                        src={closeButton}
                        alt="show/hide sections"
                        onClick={() => toggleShowSections(!showSections)}
                    />
                    <span className="count">{subSurveyLength}</span>
                </div>
            </div>
            <div className={classNameSections}>
                <Sections
                    sections={sections}
                    selected={selected}
                    showCounts={showCounts}
                    showTitle={true}
                    surveyIndex={surveyIndex}
                    lang={lang}
                />
            </div>
        </div>
    );
};

export default SubSurveyItem;
