import React from "react";

import Layout from "../../components/layout/LayoutComponent";
import ChangePassword from "../../components/changePassword/ChangePasswordComponent";
import FlyoutComponent from "../../components/flyout/FlyoutComponent";

import "./ProfileComponent.scss";
import Auth from "../../Auth";

class PorfileComponent extends React.Component {
    render() {
        return (
            <Layout className="profile-page">
                <ProfileForm />
            </Layout>
        );
    }
}

class ProfileForm extends React.Component {
    constructor(props) {
        super(props);
        const profile = Auth.getInstance().profile;
        this.state = {
            fullName: `${profile.firstName} ${profile.lastName}`,
            email: profile.email,
            primaryPhoneNumber: profile.primaryPhoneNumber,
            isFormValid: false,
            showChangePassswordDialog: false,
            showSuccess: false,
            showError: false
        };

        this.closeShowPasswordDialog = this.closeShowPasswordDialog.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.isFullNameValid = this.isFullNameValid.bind(this);
        this.isEmailValid = this.isEmailValid.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.flyoutOnClose = this.flyoutOnClose.bind(this);
        this.isProfileChanged = this.isProfileChanged.bind(this);
        this.savePassword = this.savePassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        const profile = Auth.getInstance().getProfile();
        Promise.resolve(profile).then(() => {
            const profile = Auth.getInstance().profile;
            this.setState({
                fullName: profile.firstName + " " + profile.lastName,
                email: profile.email,
                primaryPhoneNumber: profile.primaryPhoneNumber,
                isFormValid: false,
                showChangePassswordDialog: false,
                showSuccess: false,
                showError: false
            });
        });
    }

    saveProfile() {
        if (this.validateForm()) {
            const nameParts = this.state.fullName.split(" ");
            const firstName = nameParts[0] ? nameParts[0] : "";
            const lastName = nameParts[1] ? nameParts[1] : "";
            const profile = {
                firstName,
                lastName,
                email: this.state.email,
                address: "",
                primaryPhoneNumber: this.state.primaryPhoneNumber,
                role: Auth.getInstance().profile.role
            };
            const res = Auth.getInstance().saveProfile(profile);
            Promise.resolve(res)
                .then((result) => {
                    if (result) {
                        const profilePromise = Auth.getInstance().getProfile();
                        Promise.resolve(profilePromise).then(() => {
                            if (this.props.onProfileUpdate) {
                                this.props.onProfileUpdate();
                            }
                            this.setState({
                                showSuccess: true,
                                showError: false,
                                showChangePassswordDialog: false
                            });
                        });
                    } else {
                        this.setState({
                            showSuccess: false,
                            showError: true,
                            showChangePassswordDialog: false
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        showSuccess: false,
                        showError: true,
                        showChangePassswordDialog: false
                    });
                });
        }
    }

    deleteAccount() {
        this.setState({ showError: true, showSuccess: false });
    }

    closeShowPasswordDialog() {
        this.setState({ showChangePassswordDialog: false, showError: false });
    }

    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };

    isProfileChanged() {
        return (
            this.state.fullName !== `${Auth.getInstance().profile.firstName} ${Auth.getInstance().profile.lastName}` ||
            this.state.email !== Auth.getInstance().profile.email ||
            this.state.primaryPhoneNumber !== Auth.getInstance().profile.primaryPhoneNumber
        );
    }

    validateForm() {
        return this.isFullNameValid() && this.isEmailValid();
    }

    savePassword() {
        this.setState({
            showSuccess: true,
            showError: false,
            showChangePassswordDialog: false
        });
    }

    isFullNameValid() {
        const { fullName } = this.state;
        return fullName.length > 0;
    }

    isEmailValid() {
        const { email } = this.state;
        return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
            email
        );
    }

    flyoutOnClose() {
        this.setState({ showError: false, showSuccess: false });
    }

    render = () => {
        const { showChangePassswordDialog, showSuccess, showError } = this.state;
        const isFormValid = this.isProfileChanged() ? this.validateForm() : false;

        return (
            <div>
                {showSuccess ? (
                    <FlyoutComponent
                        type="success"
                        message="Profile has been successfully saved"
                        onClose={() => this.flyoutOnClose()}
                    ></FlyoutComponent>
                ) : null}

                {showError ? (
                    <FlyoutComponent
                        type="error"
                        message="An error occured while try to process your request"
                        onClose={() => this.flyoutOnClose()}
                    ></FlyoutComponent>
                ) : null}

                {showChangePassswordDialog ? (
                    <ChangePassword
                        onSave={() => this.savePassword()}
                        onDialogClose={() => this.closeShowPasswordDialog()}
                    ></ChangePassword>
                ) : null}

                <div className="profile-form">
                    <div className="form">
                        <div className="form-item">
                            <label htmlFor="lblFullName">Full Name</label>
                            <input type="text" id="lblFullName" name="fullName" value={this.state.fullName} onChange={this.handleChange} />
                        </div>
                        <div className="form-item">
                            <label htmlFor="lblEmail">Email</label>
                            <input type="text" id="lblEmail" name="email" defaultValue={this.state.email} onChange={this.handleChange} />
                        </div>
                        <div className="form-item">
                            <label htmlFor="lblPrimaryPhoneNumber">Primary Phone Number</label>
                            <input
                                type="text"
                                id="lblPrimaryPhoneNumber"
                                name="primaryPhoneNumber"
                                defaultValue={this.state.primaryPhoneNumber}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="change-password">
                            <div
                                onClick={() =>
                                    this.setState({
                                        showChangePassswordDialog: true,
                                        showSuccess: false,
                                        showError: false
                                    })
                                }
                            >
                                Change Password
                            </div>
                        </div>

                        {isFormValid ? (
                            <input type="button" value="Save Changes" className="save-button" onClick={() => this.saveProfile()} />
                        ) : (
                            <input type="button" value="Save Changes" className="save-button disabled" />
                        )}
                    </div>
                </div>
            </div>
        );
    };
}

export default PorfileComponent;
