import React from "react";
import "./LayoutComponent.scss";

import AppHeader from "../appHeader/AppHeaderComponent";

class LayoutComponent extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <AppHeader />
                {this.props.children}
            </div>
        );
    }
}
export default LayoutComponent;
