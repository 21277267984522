import SettingsButtons from "../../settingsButton/SettingsButtonComponent";
import PhotoUpload from "../../photoUpload/PhotoUpload";
import React from "react";

function Upload({ answers, multiple, onVisibilityClick }) {
    const answerItems = answers.map((a, i) => {
        return (
            <SettingsButtons
                key={`${i}_settings_button_upload`}
                answer={a}
                hasVisibility={a.hasVisibility}
                onVisibilityClick={onVisibilityClick}
            >
                <PhotoUpload multiple={multiple} />
            </SettingsButtons>
        );
    });

    return <div className="content">{answerItems}</div>;
}

export default Upload;
