import React from "react";
import "./DateRangePickerComponent.scss";
import DropDown from "../dropDowns/dropDown/DropDownComponent";
import moment from "moment";
import i18n from "i18next";
import cloneDeep from "lodash/cloneDeep";

class DateRangePicker extends React.Component {
    monthItems = moment.months().map((m) => {
        return { text: m };
    });
    dayItems = Array(31)
        .fill()
        .map((_, i) => {
            return { text: i + 1 };
        });
    yearItems = Array(86)
        .fill()
        .map((_, i) => {
            return { text: 2023 - i };
        });

    render() {
        let translatedMonthItems = cloneDeep(this.monthItems);
        translatedMonthItems.map((t) => (t.text = i18n.t(t.text)));
        return (
            <div className="date-picker">
                <div className="month-container">
                    <DropDown datepicker={true} items={translatedMonthItems} placeholder={i18n.t("month")} />
                </div>
                <div className="day-container">
                    <DropDown datepicker={true} items={this.dayItems} placeholder={i18n.t("day")} />
                </div>
                <div className="year-container">
                    <DropDown datepicker={true} items={this.yearItems} placeholder={i18n.t("year")} />
                </div>
            </div>
        );
    }
}

export default DateRangePicker;
