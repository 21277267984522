import React from "react";
import { ReactComponent as Spinner } from "../../../assets/images/grid.svg";
import "./PublishPopup.scss";

function PublishPopup({ action, setShowPublishPopup, showPublishLoader }) {
    return (
        <div className="status-popup-overlay">
            <div className="status-popup">
                <div className="container">
                    <div className="header">Publish the application?</div>
                    <div className="content">
                        This action cannot be undone. Upon confirmation, this application will become published. You will not be able to
                        edit it, however, you still may do a new editable version from it.
                    </div>
                    <div className="buttons">
                        {showPublishLoader && <Spinner data-testid="spinner" />}
                        <div className="button-yes" onClick={action}>
                            Yes
                        </div>
                        <div className="button-no" onClick={() => setShowPublishPopup(false)}>
                            No
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PublishPopup;
