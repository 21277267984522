import React, { createContext, useMemo, useState } from "react";
import { NewSurveyBuilderService } from "../../../service/NewSurveyBuilderService/NewSurveyBuilderService";
import { useParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { Question } from "../../../helper/Application/Application";
import AppService from "../../../service/AppService";
import { showErrorNotification } from "../../../helper/NotificationHelper";
import BuilderConstants from "../../../helper/BuilderConstants";

const NewSurveyBuilderContext = createContext({});

const NewSurveyBuilderProvider = ({ children, defaultSelectedSection }) => {
    const { id } = useParams();
    const [selectedSection, setSelectedSection] = useState(defaultSelectedSection);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [surveyData, setSurveyData] = useState(null);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [surveyStatus, setSurveyStatus] = useState("");

    const changeSectionQuestionsCount = (question, updatedSection, action) => {
        if (!updatedSection) {
            return;
        }
        const subSurveys = cloneDeep(surveyData?.subSurveys);
        const section = subSurveys?.flatMap((sub) => sub.sections).find((sec) => sec.sequenceId === updatedSection.sequenceId);
        switch (action) {
            case "INCREASE":
                if (question.type !== "GROUP") {
                    section.questionsCount++;
                }
                break;
            case "DECREASE":
                if (question.type === "GROUP") {
                    section.questionsCount -= question.questions.length;
                } else {
                    section.questionsCount--;
                }
                break;
        }
        setSurveyData({ ...surveyData, subSurveys: subSurveys });
    };

    const addItemToGroup = async (type, group) => {
        const newQuestion = new Question(type, "", "");
        newQuestion.groupId = group.sequenceId;
        group.questions.push(newQuestion);
        setSelectedQuestion(newQuestion);
        await NewSurveyBuilderService.saveQuestionInGroup(id, selectedSection?.sequenceId, group.sequenceId, newQuestion);
        if (group.type !== "DYNAMIC_GROUP") {
            changeSectionQuestionsCount(newQuestion, selectedSection, "INCREASE");
        }
    };

    const removeQuestionFromGroup = async (question, group) => {
        setSelectedQuestion(null);
        const index = group.questions.indexOf(question);
        group.questions.splice(index, 1);
        setSelectedQuestion(index >= 1 ? group.questions[index - 1] : group);
        await NewSurveyBuilderService.removeQuestionFromGroup(id, selectedSection?.sequenceId, group.sequenceId, question.sequenceId);
        if (group.type !== "DYNAMIC_GROUP") {
            changeSectionQuestionsCount(question, selectedSection, "DECREASE");
        }
    };

    const updateGroupOrQuestion = (field, value) => {
        const updatedSection = { ...selectedSection };
        let questionToUpdate;
        if (selectedQuestion?.groupId) {
            questionToUpdate = updatedSection.questions
                .find((question) => question.sequenceId === selectedQuestion.groupId)
                .questions.find((question) => question.sequenceId === selectedQuestion.sequenceId);
        } else {
            questionToUpdate = updatedSection.questions?.find((question) => question.sequenceId === selectedQuestion.sequenceId);
        }
        if (questionToUpdate) {
            questionToUpdate[field] = value;
        }
        return updatedSection;
    };

    const updateGroupOrQuestionAnswer = (field, value, answerIndex) => {
        const updatedSection = { ...selectedSection };
        if (selectedQuestion?.groupId) {
            updatedSection.questions
                .find((question) => question.sequenceId === selectedQuestion.groupId)
                .questions.find((question) => question.sequenceId === selectedQuestion.sequenceId).answers[answerIndex][field] = value;
        } else {
            updatedSection.questions.find((question) => question.sequenceId === selectedQuestion.sequenceId).answers[answerIndex][field] =
                value;
        }
        return updatedSection;
    };

    const updateQuestion = async (field, value, withSave) => {
        const updatedQuestion = { ...selectedQuestion, [field]: value };
        const updatedSection = updateGroupOrQuestion(field, value);

        setSelectedSection(updatedSection);
        setSelectedQuestion(updatedQuestion);

        if (withSave) {
            selectedQuestion?.groupId
                ? await NewSurveyBuilderService.saveQuestionInGroup(
                      id,
                      updatedSection.sequenceId,
                      selectedQuestion.groupId,
                      updatedQuestion
                  ).catch((err) => {
                      showErrorNotification("Can't save question (" + updatedQuestion.text + ")", err.response.data.message);
                  })
                : await NewSurveyBuilderService.saveQuestion(id, updatedSection.sequenceId, updatedQuestion).catch((err) => {
                      showErrorNotification("Can't save question (" + updatedQuestion.text + ")", err.response.data.message);
                  });
        }
    };

    const updateAnswer = async (field, value, index, withSave) => {
        if (field !== undefined && value !== undefined && index !== undefined) {
            const updatedSelectedQuestion = { ...selectedQuestion };
            updatedSelectedQuestion.answers[index][field] = value;

            const updatedSection = updateGroupOrQuestionAnswer(field, value, index);
            setSelectedSection(cloneDeep(updatedSection));
            setSelectedQuestion(updatedSelectedQuestion);

            if (selectedFeedback) {
                const updatedSelectedFeedback = { ...selectedFeedback };
                updatedSelectedFeedback[field] = value;
                setSelectedFeedback(updatedSelectedFeedback);
            }
        }

        if (withSave) {
            selectedQuestion?.groupId
                ? await NewSurveyBuilderService.saveQuestionInGroup(
                      id,
                      selectedSection.sequenceId,
                      selectedQuestion.groupId,
                      selectedQuestion
                  ).catch((err) => {
                      showErrorNotification("Can't save question (" + selectedQuestion.text + ")", err.response.data.message);
                  })
                : await NewSurveyBuilderService.saveQuestion(id, selectedSection.sequenceId, selectedQuestion).catch((err) => {
                      showErrorNotification("Can't save question (" + selectedQuestion.text + ")", err.response.data.message);
                  });

            if (selectedQuestion?.jsonRelation?.source) {
                const items = selectedQuestion?.answers.map((a) => a.text);
                NewSurveyBuilderService.updateRelationItems(selectedQuestion?.jsonRelation?.relationId, items);
            }
        }
    };

    const swapQuestionsInGroup = async (result, questions, groupId) => {
        if (!result.destination) {
            return;
        }
        const draggedQuestion = questions[result.source.index];
        const questionsBeforeSort = AppService.reorder(questions, result.source.index, result.destination.index);
        const finalSortedQuestions = sortAccordingToVisibility(questionsBeforeSort);
        const newPosition = finalSortedQuestions.findIndex((q) => q.sequenceId === draggedQuestion.sequenceId);
        const updatedQuestions = selectedSection.questions.map((question) => {
            if (question.sequenceId === groupId) {
                return { ...question, questions: finalSortedQuestions };
            }
            return question;
        });

        setSelectedSection({ ...selectedSection, questions: updatedQuestions });
        await NewSurveyBuilderService.swapQuestionInGroup(id, selectedSection.sequenceId, groupId, draggedQuestion.sequenceId, newPosition);
    };

    const isInConstruct = () => {
        return surveyData?.status === BuilderConstants.CONSTRUCT;
    };

    const sortAccordingToVisibility = (initialSortedQuestions) => {
        let sortedQuestions = [...initialSortedQuestions];
        let i = 0;

        while (i < sortedQuestions.length) {
            const q = sortedQuestions[i];

            if (q.visibility && q.visibility.length > 0) {
                let moved = false;

                q.visibility.forEach((item) => {
                    const linkedQuestionId = item.sourceQuestion;
                    const questionIndex = sortedQuestions.findIndex((el) => el.sequenceId === linkedQuestionId);

                    if (questionIndex > i) {
                        const [removed] = sortedQuestions.splice(i, 1);
                        sortedQuestions.splice(questionIndex, 0, removed);
                        moved = true;
                    }
                });

                if (!moved) {
                    i++;
                }
            } else {
                i++;
            }
        }

        return sortedQuestions;
    };

    const value = useMemo(
        () => ({
            selectedSection,
            setSelectedSection,
            surveyData,
            setSurveyData,
            surveyStatus,
            setSurveyStatus,
            selectedQuestion,
            setSelectedQuestion,
            selectedFeedback,
            setSelectedFeedback,
            updateQuestion,
            updateAnswer,
            addItemToGroup,
            removeQuestionFromGroup,
            swapQuestionsInGroup,
            isInConstruct,
            changeSectionQuestionsCount,
            sortAccordingToVisibility
        }),
        [selectedSection, surveyData, surveyStatus, selectedQuestion]
    );

    return <NewSurveyBuilderContext.Provider value={value}>{children}</NewSurveyBuilderContext.Provider>;
};

export { NewSurveyBuilderContext, NewSurveyBuilderProvider };
