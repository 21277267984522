import React, { useEffect, useState } from "react";
import "./SimpleCheckBoxComponent.scss";
import classNames from "classnames";

function SimpleCheckBoxComponent({ value, isSelected, onClick, readonly, label = "", isDisabled = false }) {
    const [selected, setIsSelected] = useState(isSelected ?? false);

    useEffect(() => {
        setIsSelected(!!isSelected);
    }, [isSelected]);

    const handleChange = (e) => {
        e.stopPropagation();
        if (readonly) {
            return;
        }
        const newSelectedState = !selected;
        setIsSelected(newSelectedState);
        onClick && onClick(value, newSelectedState);
    };

    return (
        <div className="simple-checkbox-content">
            <label className={classNames("simple-checkbox-container", { "checkbox-selected": selected })}>
                {label}
                <input type="checkbox" checked={selected} name="checkbox" onChange={(e) => handleChange(e)} disabled={isDisabled} />
                <span className="checkmark" />
            </label>
        </div>
    );
}

export default SimpleCheckBoxComponent;
