import React from "react";
import closeWhite from "../../../../assets/images/close-white.svg";
import VisibilityPopup from "../VisibilityPopup/VisibilityPopup";

function VisibilityButton({ question, showVisibilityInfo, setShowVisibilityInfo, onVisibilityRemoveClicked }) {
    const hasVisibilityItems = question.visibility.length > 0;
    if (hasVisibilityItems) {
        return showVisibilityInfo ? (
            <div className="hidden-button" onMouseLeave={() => setShowVisibilityInfo(false)}>
                <span className="visibility-label-selected">
                    Hidden
                    <img
                        src={closeWhite}
                        onClick={(e) => {
                            e.stopPropagation();
                            onVisibilityRemoveClicked(question);
                        }}
                        alt="close"
                    />
                </span>
                {showVisibilityInfo && <VisibilityPopup hasVisibilityItems={hasVisibilityItems} question={question} />}
            </div>
        ) : (
            <div className="hidden-button" onMouseOver={() => setShowVisibilityInfo(true)}>
                <span className="visibility-label">Hidden</span>
            </div>
        );
    }
    return null;
}

export default VisibilityButton;
