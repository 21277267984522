import React, { useContext } from "react";
import { NewSurveyBuilderContext } from "../../../../NewSurveyBuilderContext/NewSurveyBuilderContext";
import { ReactComponent as Edit } from "../../../../../../assets/images/edit.svg";

const SurveyScoringSection = ({ onEditClick }) => {
    const { surveyData, isInConstruct } = useContext(NewSurveyBuilderContext);

    const buildScoringItem = (type, scoring, index) => (
        <div key={`${type}-${index}`} className="scoring-item">
            <span>{type}</span> {scoring.operator.toLowerCase()} {scoring.valueFrom}
            {scoring.operator.toLowerCase() === "between" ? " and " : " "}
            {scoring.valueTo} score: {scoring.score}
        </div>
    );

    const showScoring = () => {
        return surveyData.ageScore.length > 0 || surveyData.bmiScore.length > 0;
    };

    return (
        <div className="survey-scoring-section">
            <div className="survey-section-header">
                <span className="survey-scoring-title">scoring</span>
                {isInConstruct() && (
                    <span className="appEdit" onClick={() => onEditClick(4)}>
                        <Edit />
                    </span>
                )}
            </div>
            {showScoring() && (
                <div className="survey-scoring-content">
                    <div className="survey-scoring-tab">
                        {surveyData?.bmiScore.map((score, index) => buildScoringItem("BMI", score, index))}
                    </div>
                    {surveyData?.bmiScore.length > 0 && surveyData?.ageScore.length > 0 && <hr />}
                    <div className="survey-scoring-tab">
                        {surveyData?.ageScore.map((score, index) => buildScoringItem("Age", score, index))}
                    </div>
                </div>
            )}
        </div>
    );
};
export default SurveyScoringSection;
