import React from "react";
import { ReactComponent as Spinner } from "../../assets/images/grid.svg";
import "./GlobalLoaderComponent.scss";

function GlobalLoaderComponent({ title, message }) {
    return (
        <div className="global-loader">
            <div className="global-loader-content">
                <div className="loader">{<Spinner />}</div>
                <h1>{title}</h1>
                <p>{message}</p>
            </div>
        </div>
    );
}

export default GlobalLoaderComponent;
