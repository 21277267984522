import axios from "axios";

import Constants from "../helper/Constants";
import i18n from "i18next";

const AppService = {
    Apps: [],

    newBuilderVersion: true,
    selectedApp: null,
    removingLanguages: false,
    questionTitles: [
        "question - yes / no",
        "question - Select One",
        "question - PickList",
        "question - Select Many",
        "question - Text Field",
        "question - Date Picker Field",
        "question - Number",
        "question - Address Field",
        "question - Phone",
        "question - Drop-Down",
        "question - Multi select Drop-Down",
        "question - Upload single photo",
        "question - Upload up to 20 photo",
        "Note",
        "Group",
        "Dynamic Group"
    ],

    changeLanguageHandler(lang) {
        i18n.changeLanguage(lang);
    },

    convertTypeNameToIndex: function (name) {
        let result = -1;
        Object.keys(this.questionType).forEach((k, i) => {
            if (k === name) {
                result = i;
            }
        });
        return result;
    },

    filledArray(from, to) {
        const arr = [];
        while (from <= to) {
            arr.push(from);
            from = from + 1;
        }
        return arr;
    },

    reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    },

    trimSpaces(string) {
        return string.trimStart().replace(/\s\s+/g, " ");
    },

    logout() {
        this.cleanToken();
        window.location.href = `/login`;
    },

    deleteCookie(name) {
        document.cookie = name + '="";-1; path=/';
    },

    cleanToken() {
        this.deleteCookie("surrat");
        localStorage.setItem("jwt", "");
    },

    conditionTypes: {
        SUM: "SUM",
        ANSWER: "ANSWER"
    },

    questionType: {
        YES_NO: "YES_NO",
        SINGLE_ONE: "SINGLE_ONE",
        SELECT_MANY: "SELECT_MANY",
        MULTI_SELECT: "MULTI_SELECT",
        TEXT: "TEXT",
        DATE: "DATE",
        NUMBER: "NUMBER",
        ADDRESS: "ADDRESS",
        PHONE: "PHONE",
        DROPDOWN: "DROPDOWN",
        DROPDOWN_MULTISELECT: "DROPDOWN_MULTISELECT",
        UPLOAD: "UPLOAD",
        MULTI_UPLOAD: "MULTI_UPLOAD",
        NOTE: "NOTE",
        GROUP: "GROUP",
        DYNAMIC_GROUP: "DYNAMIC_GROUP",
        FREE_TEXT: "TEXT",
        TEXT_GROUP: "TEXT_GROUP",
        DROPDOWN_GROUP: "DROPDOWN_GROUP",
        DROPDOWNS_GROUP: "DROPDOWNS_GROUP",
        UPLOAD_GROUP: "UPLOAD_GROUP"
    },

    Guid: function () {
        return `f${(+new Date()).toString(16)}`;
    },

    conditionsItems: [
        { text: "Sum", value: "SUM" },
        { text: "Specific answer", value: "SPECIFIC_ANSWER" }
    ],

    appsFilter: [
        { text: "All", value: "" },
        { text: "GC", value: "GC" },
        { text: "IP", value: "IP" },
        { text: "ED", value: "ED" }
    ],

    surveyTypes: [
        { text: "GC", value: "GC" },
        { text: "IP", value: "IP" },
        { text: "ED", value: "ED" }
    ],

    statusFilter: [
        { text: "Any", value: "" },
        { text: "Published", value: "GC" },
        { text: "Under construction", value: "IP" },
        { text: "Active", value: "" },
        { text: "Archived", value: "ARCHIVED" }
    ],

    sortingFilter: [
        { text: "None", value: "" },
        { text: "Version", value: "" }
    ],

    ageFilter: [
        { text: "Any", value: "" },
        { text: "18 - 25", value: "" },
        { text: "25 - 30", value: "" },
        { text: "30 - 35", value: "" }
    ],

    regionFilter: [
        { text: "All", value: "" },
        { text: "Alabama", value: "" },
        { text: "Alaska", value: "" },
        { text: "Arizona", value: "" },
        { text: "Arkansas", value: "" },
        { text: "California", value: "" },
        { text: "Colorado", value: "" },
        { text: "Connecticut", value: "" },
        { text: "Delawar", value: "" },
        { text: "Florida", value: "" },
        { text: "Georgia", value: "" },
        { text: "Hawaii", value: "" },
        { text: "Idaho", value: "" },
        { text: "Illinois", value: "" },
        { text: "Indiana", value: "" },
        { text: "Iowa", value: "" },
        { text: "Kansas", value: "" },
        { text: "Kentucky", value: "" },
        { text: "Louisiana", value: "" },
        { text: "Maine", value: "" },
        { text: "Maryland", value: "" },
        { text: "Massachusetts", value: "" },
        { text: "Michigan", value: "" },
        { text: "Minnesota", value: "" },
        { text: "Mississippi", value: "" },
        { text: "Missouri", value: "" },
        { text: "Montana", value: "" },
        { text: "Nebraska", value: "" },
        { text: "Nevada", value: "" },
        { text: "New Hampshire", value: "" },
        { text: "New Jersey", value: "" },
        { text: "New Mexico", value: "" },
        { text: "New York", value: "" },
        { text: "North Carolina", value: "" },
        { text: "North Dakota", value: "" },
        { text: "Ohio", value: "" },
        { text: "Oklahoma", value: "" },
        { text: "Oregon", value: "" },
        { text: "Pennsylvania", value: "" },
        { text: "Rhode Island", value: "" },
        { text: "South Carolina", value: "" },
        { text: "South Dakota", value: "" },
        { text: "Tennessee", value: "" },
        { text: "Texas", value: "" },
        { text: "Utah", value: "" },
        { text: "Vermont", value: "" },
        { text: "Virginia", value: "" },
        { text: "Washington", value: "" },
        { text: "West Virginia", value: "" },
        { text: "Wisconsin", value: "" },
        { text: "Wyoming", value: "" }
    ],

    usersItems: [
        { text: "Rikesh Stevens", value: "" },
        { text: "Anne Odonnell", value: "" },
        { text: "Olli Graves", value: "" },
        { text: "Yousuf Wilkinson", value: "" },
        { text: "Joanne Nash", value: "" },
        { text: "Fearne Bain", value: "" },
        { text: "Alayna Mcgregor", value: "" },
        { text: "Kieran Sweeney", value: "" },
        { text: "Samantha Ayers", value: "" },
        { text: "Fionn Schultz", value: "" }
    ],

    apps: [
        {
            id: 1,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: [
                {
                    id: 11,
                    status: "",
                    type: "GC",
                    age: "18-22 y.o.",
                    region: "All regions",
                    user: "Leslie Hawkins",
                    date: "11/18/18 4:43 AM",
                    version: "2.1.42",
                    text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor..."
                },
                {
                    id: 12,
                    status: "",
                    type: "GC",
                    age: "18-22 y.o.",
                    region: "All regions",
                    user: "Leslie Hawkins",
                    date: "11/18/18 4:43 AM",
                    version: "2.1.41",
                    text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor..."
                },
                {
                    id: 13,
                    status: "",
                    type: "GC",
                    age: "18-22 y.o.",
                    region: "All regions",
                    user: "Leslie Hawkins",
                    date: "11/18/18 4:43 AM",
                    version: "2.1.40",
                    text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor..."
                },
                {
                    id: 14,
                    status: "",
                    type: "GC",
                    age: "18-22 y.o.",
                    region: "All regions",
                    user: "Leslie Hawkins",
                    date: "11/18/18 4:43 AM",
                    version: "2.1.39",
                    text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor..."
                },
                {
                    id: 15,
                    status: "",
                    type: "GC",
                    age: "18-22 y.o.",
                    region: "All regions",
                    user: "Leslie Hawkins",
                    date: "11/18/18 4:43 AM",
                    version: "2.1.39",
                    text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor..."
                }
            ]
        },

        {
            id: 2,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },
        {
            id: 3,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },
        {
            id: 4,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },
        {
            id: 5,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },
        {
            id: 6,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },
        {
            id: 7,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },

        {
            id: 1,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },

        {
            id: 2,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },
        {
            id: 3,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },
        {
            id: 4,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },
        {
            id: 5,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },
        {
            id: 6,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        },
        {
            id: 7,
            status: "",
            type: "GC",
            age: "18-22 y.o.",
            region: "All regions",
            user: "Leslie Hawkins",
            date: "11/18/18 4:43 AM",
            version: "2.1.43",
            text: "Main Application - amet, consectetur adipiscing elit, sed do eiusmod sed do tempor ut labore incididunt ut labore et dolore magna aliqua d tempor...",
            versions: []
        }
    ],
    salesForceFields: {
        PHONE: {
            answers: [],
            fieldName: "Phone",
            id: 31,
            text: "Phone (Phone)"
        },
        EMAIL: {
            answers: [],
            fieldName: "Email",
            id: 28,
            text: "Email (Email)"
        },
        LAST_NAME: {
            answers: [],
            fieldName: "LastName",
            id: 30,
            text: "LastName (Last Name)"
        },
        FIRST_NAME: {
            answers: [],
            fieldName: "FirstName",
            id: 29,
            text: "FirstName (First Name)"
        }
    },

    setSelectedApp(response) {
        const parsedApp = JSON.parse(response.data.jsonStructure);
        const allSubSurveySections = parsedApp.subSurveys.map((item) => {
            return [...item.sections];
        });
        this.selectedApp = JSON.parse(response.data.jsonStructure);
        this.selectedApp.sections = allSubSurveySections[0];
        this.selectedApp.reset = true;
        this.selectedApp.allSubSurveySections = allSubSurveySections;
        this.selectedApp.id = response.data.id;
    },

    getAxios: function () {
        const token = localStorage.getItem("jwt");
        return axios.create({
            baseURL: Constants.REST_API_URL,
            headers: {
                Authorization: token ? `Bearer ${token}` : ""
            }
        });
    }
};

export default AppService;
