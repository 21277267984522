import SettingsButtons from "../../settingsButton/SettingsButtonComponent";
import React from "react";
import MaskedTextInput from "./MaskedTextInput";
import { LanguageService } from "../../../service/LanguageService";

function createAnswer(answer, lang) {
    if (answer.inputMask && answer.inputMask.mask) {
        return <MaskedTextInput lang={lang} answerText={answer.text} mask={answer.inputMask.mask} />;
    }

    return <input type="text" placeholder={LanguageService.getTranslatedText(answer.text, lang)} className="text_answer" />;
}

function Text({ lang, answers, onVisibilityClick }) {
    const answerFields = answers.map((a) => {
        const answer = createAnswer(a, lang);
        return (
            <SettingsButtons key={a.sequenceId} answer={a} hasVisibility={a.hasVisibility} onVisibilityClick={onVisibilityClick}>
                {answer}
            </SettingsButtons>
        );
    });
    return <div className="content">{answerFields}</div>;
}

export default Text;
