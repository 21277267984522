import React from "react";
import "./DropDownComponent.scss";

import arrow from "../../../assets/images/arrow_down.svg";

import eyeGrey from "../../../assets/images/eye-grey.svg";
import reviewGrey from "../../../assets/images/review-grey.svg";
import disqGrey from "../../../assets/images/disq-grey.svg";
import { LanguageService } from "../../../service/LanguageService";

class DropDownComponent extends React.Component {
    constructor(props) {
        super(props);
        this.sourceSelectedItem = props.selectedItem;
        this.state = {
            showDropDown: false,
            selectedItem: props.selectedItem ?? ""
        };

        this.sourceItems = props.items;

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.keyDown = this.keyDown.bind(this);
        this.showDropDown = this.showDropDown.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.getItemText = this.getItemText.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    keyDown(event) {
        if (event.keyCode === 27 && this.state.showDropDown) {
            this.showDropDown();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedItem !== this.props.selectedItem) {
            this.setState({
                selectedItem: this.props.selectedItem ?? ""
            });
        }
        if (prevProps.items !== this.props.items) {
            this.sourceItems = this.props.items;
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keyDown, false);
        document.addEventListener("mousedown", this.handleDocumentClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDown, false);
        document.removeEventListener("mousedown", this.handleDocumentClick, false);
    }

    handleDocumentClick(e) {
        if (this.node && !this.node.contains(e.target)) {
            if (this.state.selectedItem?.length === 0) {
                if (this.props.onRemoveSalesForce) {
                    this.props.onRemoveSalesForce();
                }
            }
            this.setState({ showDropDown: false });
        }
    }

    showDropDown() {
        this.sourceItems = this.props.items;
        this.setState({ showDropDown: !this.state.showDropDown });
    }
    selectItem(text, value) {
        if (this.props.onChange) {
            this.props.onChange(text, value);
        }
        this.setState({ selectedItem: text, showDropDown: false });
    }

    handleSearchChange({ target }) {
        this.sourceItems = this.props.items.filter((i) => i.text.toLowerCase().includes(target.value.toLowerCase()));
        this.setState({ selectedItem: target.value, showDropDown: true });
    }
    handleKeyDown({ target, key }) {
        if (key === "Enter" && target.value.length === 0) {
            if (this.props.onRemoveSalesForce) {
                this.props.onRemoveSalesForce();
                this.setState({ showDropDown: false });
            }
        }
    }

    getItemText(text) {
        if (!this.props.searchable || this.state.selectedItem.length === 0) {
            return text;
        }
        var reg = new RegExp(this.state.selectedItem, "gi");
        var replaced = text.replace(reg, function (str) {
            return "<b>" + str + "</b>";
        });
        return replaced;
    }

    render() {
        const itemsList = this.sourceItems?.map((item, index) => {
            const isSelected = this.state.selectedItem === LanguageService.getTranslatedText(item.text, this.props.lang);
            const className = isSelected ? "filter-selected" : "";

            const settings = (
                <span>
                    {item.hasVisibility ? (
                        <img
                            src={eyeGrey}
                            alt=""
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.onVisibilityClick(item.sequenceId);
                            }}
                        />
                    ) : null}
                    {item.isReview ? <img src={reviewGrey} alt="" /> : null}
                    {item.isDisqualify ? <img src={disqGrey} alt="" /> : null}
                </span>
            );

            let text = LanguageService.getTranslatedText(item.text, "EU");

            if (this.props.datepicker) {
                text = item.text;
            }

            if (this.props.contentField) {
                text = LanguageService.getTranslatedText(item.text, this.props.lang);
            }

            return (
                <li
                    key={index}
                    className={className}
                    onClick={() => {
                        this.selectItem(LanguageService.getTranslatedText(item.text, this.props.lang), item);
                    }}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: text
                        }}
                    ></span>{" "}
                    {settings}
                </li>
            );
        });

        const isReadOnly = this.props.searchable ? false : true;
        const labelText = this.props.required ? (
            <label>
                {this.props.label}
                <span className="required">*</span>{" "}
            </label>
        ) : (
            <label>{this.props.label}</label>
        );
        return (
            <div className="drop-down-container" ref={(node) => (this.node = node)}>
                {this.props.label && this.props.label.length > 0 ? labelText : null}
                <div className="drop-down">
                    <input
                        type="text"
                        disabled={this.props.isDisabled}
                        value={this.state.selectedItem}
                        onChange={this.handleSearchChange}
                        placeholder={this.props.placeholder}
                        readOnly={isReadOnly}
                        onClick={this.showDropDown}
                        onKeyDown={this.handleKeyDown}
                    />
                    <img src={arrow} alt="" onClick={this.showDropDown} />
                </div>
                {this.state.showDropDown && !this.props.isDisabled ? (
                    <div className="drop-down-items">
                        <ul>{itemsList}</ul>
                    </div>
                ) : null}
            </div>
        );
    }
}
export default DropDownComponent;
