import React, { useState } from "react";
import "./AppListFilterComponent.scss";
import search from "../../assets/images/search-icon.svg";
import FilterSelect from "../filterSelect/FilterSelectComponent";
import AppService from "../../service/AppService";
import switchActive from "../../assets/images/switch-active.svg";
import switchInActive from "../../assets/images/switch-inactive.svg";

function AppListFilter({ onFilterChanged, searchFieldChanged, onAppCreate }) {
    const [toggleNewBuilder, setToggleNewBuilder] = useState(AppService.newBuilderVersion);
    const filterChanged = (name, value) => {
        onFilterChanged(name, value);
    };

    const convertFilters = (param) => {
        switch (param) {
            case "ARCHIVED":
                return "Archived";
            case "CONSTRUCT":
                return "Under construction";
            case "PUBLISH":
                return "Published";
            case "ACTIVE":
                return "Active";
            default:
                return "Any";
        }
    };

    const toggleChangeNewBuilder = () => {
        setToggleNewBuilder((prev) => !prev);
        AppService.newBuilderVersion = !AppService.newBuilderVersion;
    };

    const selectedFilters = JSON.parse(sessionStorage.getItem("filter"));

    return (
        <div className="app-list-filter">
            <div className="search">
                <img src={search} alt="" />
                <input type="text" placeholder="search" onChange={searchFieldChanged} />
                <button onClick={onAppCreate}>New Application</button>
                <div>
                    {toggleNewBuilder ? "New Builder" : "Old Version"}
                    <img src={toggleNewBuilder ? switchActive : switchInActive} alt="active switch" onClick={toggleChangeNewBuilder} />
                </div>
            </div>
            <div className="filter">
                <FilterSelect
                    text="Application"
                    name="apps"
                    onChanged={filterChanged}
                    items={AppService.appsFilter}
                    selected={selectedFilters?.apps || "All"}
                />
                <FilterSelect
                    text="Status"
                    name="status"
                    onChanged={filterChanged}
                    items={AppService.statusFilter}
                    selected={convertFilters(selectedFilters?.status)}
                />
            </div>
        </div>
    );
}
export default AppListFilter;
