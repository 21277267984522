import React, { useContext, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AppService from "../../service/AppService";
import AddSurveyItemButtons from "../AddSurveyItemButtons/AddSurveyItemButtons";
import { SurveyBuilderContext } from "../../SurveyBuilderContext";
import { BuilderSurveyService } from "../../service/BuilderSurveyService/BuilderSurveyService";
import QuestionItem from "../question/QuestionComponent";

function SurveyQuestions({
    onQuestionRemove,
    removeQuestionFromGroup,
    removeVisibility,
    showBranchingLogic,
    showConditionsBranchingLogic
}) {
    const context = useContext(SurveyBuilderContext);

    useEffect(() => {
        AppService.selectedApp.sections = JSON.parse(
            JSON.stringify(AppService.selectedApp.allSubSurveySections[context.selectedSubSurvey])
        );
    }, [context.selectedSubSurvey]);

    useEffect(() => {
        buildSectionQuestions();
    }, [context.selectedSectionIndex, context.selectedQuestion, context.selectedSubSurvey]);

    const buildSectionQuestions = () => {
        let sectionQuestions;

        const selectedSection = AppService.selectedApp.sections[context.selectedSectionIndex];
        if (selectedSection?.questions && selectedSection.questions.length > 0) {
            BuilderSurveyService.sortQuestionList(context.selectedSectionIndex);
            sectionQuestions = selectedSection.questions.map((q, i) => {
                const showActions = context.selectedQuestion && context.selectedQuestion.sequenceId === q.sequenceId;
                return (
                    <Draggable key={`${i}_question_item`} draggableId={String(i + 1)} index={i + 1}>
                        {(provided, snapshot) => (
                            <QuestionItem
                                key={`${i}_question_item`}
                                index={i + 1}
                                question={q}
                                onQuestionRemove={onQuestionRemove}
                                removeQuestionFromGroup={removeQuestionFromGroup}
                                showActions={showActions}
                                onVisibilityClick={showBranchingLogic}
                                onVisibilityRemoveClicked={removeVisibility}
                                onConditionVisibilityClick={showConditionsBranchingLogic}
                                provided={provided}
                                snapshot={snapshot}
                                onClick={context.selectQuestionWithoutSaving}
                            />
                        )}
                    </Draggable>
                );
            });
        }
        context.setSectionQuestions(sectionQuestions);
    };

    return (
        <aside className="center">
            <DragDropContext onDragEnd={context.handleOnDragEnd} onDragStart={() => context.saveSurvey()}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            className="survey-questions-wrap"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                        >
                            {context.sectionQuestions}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {AppService.selectedApp.status === "CONSTRUCT" && <AddSurveyItemButtons />}
        </aside>
    );
}

export default SurveyQuestions;
