export const familyPersons = {
    title: "Family",
    answers: [
        "Child",
        "Father",
        "Maternal Grandfather",
        "Maternal Grandmother",
        "Mother",
        "Other",
        "Paternal Grandfather",
        "Paternal Grandmother",
        "Self",
        "Sibling"
    ]
};

export const heritageAnswers = {
    title: "Heritage",
    answers: [
        "Afghan",
        "African American",
        "Argentinean",
        "Armenian",
        "Austrian",
        "Australian",
        "Bangladeshi",
        "Belgian",
        "Bolivian",
        "Bosnian",
        "Brazilian",
        "Bulgarian",
        "Cambodian",
        "Canadian",
        "Caribbean",
        "Chilean",
        "Chinese",
        "Columbian",
        "Costa Rican",
        "Croatian",
        "Cuban",
        "Czech",
        "Danish",
        "Dominican",
        "Dutch",
        "East Indian",
        "Ecuadorian",
        "Egyptian",
        "English",
        "Filipino",
        "Finnish",
        "French",
        "French Canadian",
        "German",
        "Ghanian",
        "Greek",
        "Guatemalan",
        "Honduran",
        "Hungarian",
        "Indonesian",
        "Iraqi",
        "Irish",
        "Israeli",
        "Italian",
        "Haitian",
        "Hmong",
        "Jamaican",
        "Japanese",
        "Jewish",
        "Korean",
        "Kurdish",
        "Lebanese",
        "Lithuanian",
        "Malaysian",
        "Mexican",
        "Mongolian",
        "Moroccan",
        "Nepalese",
        "Nicaraguan",
        "Norwegian",
        "Pacific Islander",
        "Pakistani",
        "Palestinian",
        "Persian",
        "Peruvian",
        "Polish",
        "Portuguese",
        "Puerto Rican",
        "Romanian",
        "Russian",
        "Salvadoran",
        "Samoan",
        "Scottish",
        "Serbian",
        "Somali",
        "Spanish",
        "Sri Lankan",
        "Sundanese",
        "Swedish",
        "Swiss",
        "Taiwanese",
        "Thai",
        "Turkish",
        "Ukrainian",
        "Venezuelan",
        "Vietnamese",
        "Welsh",
        "Yugoslavian"
    ]
};

export const livingOrDeceased = { title: "Living or Deceased", answers: ["Living", "Deceased", "Unknown"] };
export const heightAnswers = { title: "Height", answers: ["Short", "Average", "Tall", "Unknown"] };
export const raceAnswers = {
    title: "Race",
    answers: ["Asian", "Black", "Hispanic/Latino", "American Indian", "Pacific Islander", "White", "Unknown"]
};
export const eyeColorAnswers = {
    title: "Eye color",
    answers: ["Blue", "Brown", "Green", "Hazel", "Black", "Gray", "Unknown"]
};

export const hairColorAnswers = {
    title: "Hair color",
    answers: ["Black", "Blonde", "Brown", "Red", "Unknown"]
};

export const hairTypeAnswers = {
    title: "Hair type",
    answers: ["Curly", "Wavy", "Straight", "Unknown"]
};

export const hairTextureAnswers = {
    title: "Hair texture",
    answers: ["Fine", "Medium", "Thick", "Coarse", "Unknown"]
};

export const skinComplexionAnswers = {
    title: "Skin complexion",
    answers: ["Fair", "Medium", "Olive", "Dark", "Other", "Unknown"]
};

export const bodyTypeAnswers = {
    title: "Body type",
    answers: ["Small", "Medium", "Large", "Unknown"]
};

export const levelOfEducationAnswers = {
    title: "Level of education",
    answers: [
        "High School",
        "Some College",
        "Associate’s Degree",
        "Bachelor’s Degree",
        "Some Graduate College",
        "Master’s Degree",
        "PhD Degree",
        "Doctor of Medicine",
        "Juris Doctor",
        "Vocational Degree",
        "Unknown"
    ]
};
